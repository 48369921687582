@import '../../App.scss';

#case-summary-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;

    .case-container {
        height: 100%;
        width: 100%;
        gap: 2%;
        display: flex;
        flex-direction: row;

        .case-table-wrap {
            width: 100%;
        }


    }

    .sum-drawer {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 14vh;
        right: 0;
        width: 35vw;
        height: 81.8vh;
        background-color: #B0D3E7;
        margin-top: 1.6%;
        margin-left: -35vw;
        padding: 0.2%;
        padding-left: 1%;
        padding-right: 1%;
        z-index: 3;

        .drawer-header {
            display: flex;
            flex-direction: column;
            height: 10vh;
            border-bottom: 1px solid $primary-color;
            padding-bottom: 1%;

            .drawer-first-line {
                height: 50%;
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;

                .drawer-close-btn {
                    color: #000;
                    height: 53%;
                    cursor: pointer;
                    position: initial;
                }

                .summary-heading {
                    margin: auto;
                    color: $font-primay-color ;
                    font-family: 'Inter';
                    font-size: 1.9vmin;
                }
            }

            .drawer-second-line {
                height: 50%;
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;

                .drawer_global_search {
                    border-radius: 20px;
                    width: 35%;
                    height: 32px !important;
                    background: #fff;
                    margin-right: 3%;

                    input {
                        background: #fff;
                        font-family: Inter;
                    }

                    &:hover {
                        border: 1px solid $font-primay-color;
                    }
                }

                .drawer-filter {
                    cursor: pointer;
                    color: $font-primay-color;
                }

                .notice-cunt {
                    margin-left: auto;
                    color: #000;
                    font-family: 'Inter';

                    span {
                        color: $primary-color;
                        font-weight: bold;
                    }
                }
            }
        }

        .drawer-content {
            display: flex;
            flex-direction: column;
            height: 97vh;
            padding-top: 2.5%;
            overflow: auto;
            padding-bottom: -2.5% !important;

            .drawer-card {
                border-radius: 18px;
                background-color: #fff;
                min-height: 20vh;
                height: auto;
                width: 100%;
                box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
                margin-bottom: 2.5%;

                .card-first-split {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-evenly;
                    height: 35%;
                    border-bottom: 1.5px solid rgba(0, 0, 0, 0.3);

                    .content-group {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        width: max-content;
                        height: 100%;

                        .main-line {
                            font-size: 1.8vmin;
                            line-height: 3.5vmin !important;
                            font-size: bold;
                            color: #424B70;
                            font-family: 'Inter';
                            margin: 0;
                        }

                        .sub-line {
                            font-size: 1.8vmin;
                            line-height: 1.8vmin;
                            font-family: 'Inter';
                            margin: 0;
                        }
                    }

                    .view-icon {
                        color: $secondary-color;
                        cursor: pointer;
                    }
                }

                .card-second-split {
                    display: flex;
                    flex-direction: row;
                    height: 65%;
                    justify-content: space-evenly;

                    .sub-grid {
                        width: 46%;
                        height: 90%;

                        .header-line {
                            color: rgba(35, 35, 35, 0.7);
                            font-size: 1.8vmin;
                            line-height: 3.2vmin;
                            font-family: 'Inter';
                            margin: 0;
                        }

                        .desc-cover {
                            border-radius: 6px;
                            height: 70%;
                            box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                            padding: 1%;

                            .desc-sub-line {
                                font-size: 1.5vmin;
                                line-height: 1.9vmin;
                                font-family: 'Inter';
                                color: #323232;
                                flex-wrap: nowrap;
                                margin: 0;
                            }
                        }
                    }
                }

               
            }
        }

        .drawer-footer {
            display: flex;
            flex-direction: column;
            height: 7vh;
            border-top: 1px solid $primary-color;
            margin-top: 0px;
            align-items: center;
            justify-content: center;

            .ant-pagination {
                margin: 0px;
            }
        }
    }

    .stats-box p {
        font-size: 14px;
    }

    :where(.css-dev-only-do-not-override-byeoj0).ant-modal .ant-modal-title {
        font-size: 25px;
        font-weight: 600;
        color: #031742;
    }

    #gst-chart {
        width: 40%;
        border-radius: 1%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .gst-chart {
        width: 35%;
        height: 85%;
        margin-left: 3%;

    }

    .stats-box img {
        height: 26%
    }

    .chart-stat {
        width: 50%;
        display: flex;
        flex-direction: column;
    }

    .dot {
        height: 12.5px;
        width: 12.5px;
        background-color: #bbb;
        border-radius: 50%;
        display: block;
        margin-right: 3%
    }

    .gst-table {

        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 96%;
        margin-top: 3%;
        padding: 2%;

    }

    :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-column-title {
        position: relative;
        z-index: 1;
        flex: 1;
        width: max-content;
    }

    :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-thead>tr>th,
    :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-thead>tr>td {
        position: relative;
        color: rgba(0, 0, 0, 0.88);
        font-weight: 600;
        text-align: start;
        background: #ECEDF1;
        border-bottom: 1px solid #f0f0f0;
        transition: background 0.2s ease;
    }

    // .ant-pagination-total-text {
    //     position: absolute;
    //     left: 0;
    // }
    // .ant-pagination-options {
    //     position: absolute;
    //     right: 0;
    // }
    .gst-table-container {
        width: 100.1%;
        // margin-left: 4%;
    }

    .ant-checkbox .ant-checkbox-inner {
        width: 25px;
        height: 25px;
    }

    .ant-checkbox-checked .ant-checkbox-inner:after {
        transform: rotate(45deg) scale(1) translate(-50%, -75%);
    }

    .table_global_search {
        border-radius: 20px;
        width: 58%;
        height: 40px !important;
        background: rgb(243, 243, 249);

        input {
            background: rgb(243, 243, 249);
        }
    }

    .gst-header {

        height: 24vh;
        display: flex;
        justify-content: center;
    }

    .gst-stats {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        height: 38%;
    }

    .stat-item {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }

    :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-pagination.ant-pagination {
        margin: 16px 0;
        display: flex;
        justify-content: space-evenly;
    }

    #modelHeader {
        color: #232323 !important;
        font-family: Inter;
        font-size: 3.5vmin !important;
        font-weight: 600 !important;
    }

    .stat-subs {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .stat-sub {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 75%
    }

    .stats-box {
        border: 1px solid rgba(0, 0, 0, 0.324);
        height: 78%;
        width: 15%;
        border-radius: 4%;
        display: flex;

        align-items: center;
        flex-direction: column;

    }

    .stats-box p {
        font-size: 14px;
    }

    :where(.css-dev-only-do-not-override-190m0jy).ant-select .ant-select-selector {
        width: 100%;
        height: 45px !important;
        padding: 0 11px;
    }


    .stats-box p {
        font-family: Inter;
    }

    .right-container {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        margin-right: 0 !important;
        gap: 3%;
        align-items: center;
        height: 100%;
    }

    #gst-add-button {
        background: #424B70;
        color: white;
        display: flex;
        align-items: center;
        gap: 2px;
        height: 6vh;
        font-size: 16px;
    }

    #gst-chart {
        width: 40%;
        border-radius: 1%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .gst-chart {
        width: 35%;
        height: 85%;
        margin-left: 3%;

    }

    .stats-box img {
        height: 26%
    }

    .chart-stat {
        width: 50%;
        display: flex;
        flex-direction: column;
    }

    .dot {
        height: 12.5px;
        width: 12.5px;
        background-color: #bbb;
        border-radius: 50%;
        display: block;
        margin-right: 3%
    }

    .gst-table {

        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 96%;
        margin-top: 3%;
        padding: 2%;

    }

    :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-column-title {
        position: relative;
        z-index: 1;
        flex: 1;
        width: max-content;
    }

    :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-thead>tr>th,
    :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-thead>tr>td {
        position: relative;
        color: rgba(0, 0, 0, 0.88);
        font-weight: 600;
        text-align: start;
        background: #ECEDF1;
        border-bottom: 1px solid #f0f0f0;
        transition: background 0.2s ease;
    }

    .gst-table-container {
        width: 100.1%;
        // margin-left: 4%;
    }

    .ant-checkbox .ant-checkbox-inner {
        width: 25px;
        height: 25px;
    }

    .ant-checkbox-checked .ant-checkbox-inner:after {
        transform: rotate(45deg) scale(1) translate(-50%, -75%);
    }

    .table_global_search {
        border-radius: 20px;
        width: 58%;
        height: 40px !important;
        background: rgb(243, 243, 249);

        input {
            background: rgb(243, 243, 249);
        }
    }


    h2 {
        // color: black;
        // font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
        // font-weight: 400;
    }

    .stats-box p {
        font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    }

    .right-container {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        margin-right: 0 !important;
        gap: 3%;
        align-items: center;
        height: 100%;
    }

    #gst-add-button {
        background: #424B70;
        color: white;
        display: flex;
        align-items: center;
        gap: 2px;
        height: 6vh;
        font-size: 16px;
    }

    #gst-solution-button {

        background: #424B70;
        font-size: 16px;
        color: white;
        height: 60%;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        gap: 5px;
        display: flex;
        align-items: center;
        padding: 1.5%;

    }

    .count {
        font-weight: 600;

    }

    .table-container {
        display: flex;
        width: 100%;
        flex-direction: row;
        // border: 0.5px solid rgba(0,0,0,0.50);
        // border-bottom: none;
        align-items: center
    }

    .left-container {
        gap: 2%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        width: 50%;
        // margin-left: 2%;

    }

    .gst-row-filter {
        display: flex;
        flex-wrap: wrap;
        gap: 2%;
        align-items: center;

        flex-direction: row;

    }

    .gst-row-item {
        display: flex;
        flex-direction: row;
        gap: 13%;
        width: 23%;
        margin-bottom: 3%;
        align-items: center;

    }

    .gst-row-item img {
        height: 15px
    }


}

@media only screen and (min-width:320px) and (max-width:600px) {
    #case-summary-container {
        width: 95%;
        margin-right: 7vw;
        .sum-drawer {
            display: none;
        }

        .summary-view-popup {
            width: auto !important;
            padding-bottom: 0% !important;

            .ant-modal-title {
                font-size: 5vmin !important;
                text-align: center;
            }

            .ant-modal-content {
                padding-left: 0%;
                padding-right: 2%;
                padding-bottom: 0.1%;
            }

            .ant-pagination .ant-pagination-item-active a {
                font-size: 3vmin !important;
            }

            .sum-popup {
                display: flex;
                flex-direction: column;
                width: 100%;
                
                max-width: 100%;
                background-color: #ECEDF1;
                margin-top: 2%;
                padding: 0.2%;
                padding-left: 1%;
                padding-right: 1%;

                .drawer-header {
                    display: flex;
                    flex-direction: column;
                    height: 10vh;
                    border-bottom: 1px solid $primary-color;
                    padding-bottom: 1%;
                    padding-right: 4%;
                    padding-left: 4%;


                    .drawer-first-line {
                        height: 50%;
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        .drawer-close-btn {
                            color: #000;
                            height: 53%;
                            cursor: pointer;
                            position: initial;
                        }

                        .summary-heading {
                            margin: auto;
                            color: $font-primay-color ;
                            font-family: 'Inter';
                        }
                    }

                    .drawer-second-line {
                        height: 50%;
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        .drawer_global_search {
                            border-radius: 20px;
                            width: 35%;
                            height: 32px !important;
                            margin-right: 3%;

                            input {
                                background: #fff;
                                font-family: Inter;
                            }

                            &:hover {
                                border: 1px solid $font-primay-color;
                            }
                        }

                        .drawer-filter {
                            cursor: pointer;
                            color: $font-primay-color;
                        }

                        .notice-cunt {
                            margin-left: auto;
                            color: #000;
                            font-family: 'Inter';

                            span {
                                color: $primary-color;
                                font-weight: bold;
                            }
                        }
                    }
                }

                .drawer-content {
                    display: flex;
                    flex-direction: column;
                    height: 70vh;
                    padding-top: 2.5%;
                    overflow: auto;
                    background-color: green !important;
                    padding-bottom: -2.5% !important;
                    padding-right: 4%;
                    padding-left: 4%;

                    .drawer-card {
                        border-radius: 18px;
                        background-color: red;
                        height: 23vh;
                        width: 100%;
                        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
                        margin-bottom: 2.5%;

                        .card-first-split {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: space-evenly;
                            height: 35%;
                            border-bottom: 1.5px solid rgba(0, 0, 0, 0.3);

                            .content-group {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;
                                width: max-content;
                                height: 100%;

                                .main-line {
                                    font-size: 2.4vmin !important;
                                    line-height: 3.5vmin !important;
                                    font-size: bold;
                                    color: #424B70;
                                    font-family: 'Inter';
                                    margin: 0;
                                }

                                .sub-line {
                                    font-size: 2.4vmin !important;
                                    line-height: 1.8vmin;
                                    font-family: 'Inter';
                                    margin: 0;
                                }
                            }

                            .view-icon {
                                color: $secondary-color;
                                cursor: pointer;
                            }
                        }

                        .card-second-split {
                            display: flex;
                            flex-direction: row;
                            height: 65%;
                            justify-content: space-evenly;
                            padding-bottom: 10%;

                            .sub-grid {
                                width: 46%;
                                height: 90%;

                                .header-line {
                                    color: rgba(35, 35, 35, 0.7);
                                    font-size: 2.6vmin;
                                    line-height: 3.2vmin;
                                    font-family: 'Inter';
                                    margin: 0;
                                }

                                .desc-cover {
                                    border-radius: 6px;
                                    height: 70%;
                                    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    text-align: center;
                                    padding: 1%;

                                    .desc-sub-line {
                                        font-size: 2.6vmin;
                                        line-height: 2.6vmin;
                                        font-family: 'Inter';
                                        color: #323232;
                                        flex-wrap: nowrap;
                                        margin: 0;
                                    }
                                }
                            }
                        }
                    }
                }

                .sum-drawer {
                    display: none;
                }

                .drawer-footer {
                    display: flex;
                    flex-direction: column;
                    height: 7vh;
                    border-top: 1px solid $primary-color;
                    margin-top: 0px;
                    padding: 3%;
                }
            }

        }
    }
}
@media only screen and (min-width:601px) and (max-width:768px) {
    #case-summary-container {
        width: 96%;
        margin-right: 7vw;
    
        .case-container {
       
    
            .case-table-wrap {
                width: 100%;
            }
    
    
        }
    
        .sum-drawer {
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 14vh;
            right: 2vw;
            width: 60vw;
            height: 81.8vh;
            margin-top: 1.6%;
            margin-left: -35vw;
            padding: 0.2%;
            padding-left: 1%;
            padding-right: 1%;
            z-index: 3;
    
            .drawer-header {
                display: flex;
                flex-direction: column;
                height: 10vh;
                border-bottom: 1px solid $primary-color;
                padding-bottom: 1%;
    
                .drawer-first-line {
                    height: 50%;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
    
                    .drawer-close-btn {
                        color: #000;
                        height: 53%;
                        cursor: pointer;
                        position: initial;
                    }
    
                    .summary-heading {
                        margin: auto;
                        color: $font-primay-color ;
                        font-family: 'Inter';
                        font-size: 1.9vmin;
                    }
                }
    
                .drawer-second-line {
                    height: 50%;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
    
                    .drawer_global_search {
                        border-radius: 20px;
                        width: 35%;
                        height: 32px !important;
                        background: #fff;
                        margin-right: 3%;
    
                        input {
                            background: #fff;
                            font-family: Inter;
                        }
    
                        &:hover {
                            border: 1px solid $font-primay-color;
                        }
                    }
    
                    .drawer-filter {
                        cursor: pointer;
                        color: $font-primay-color;
                    }
    
                    .notice-cunt {
                        margin-left: auto;
                        color: #000;
                        font-family: 'Inter';
    
                        span {
                            color: $primary-color;
                            font-weight: bold;
                        }
                    }
                }
            }
    
            .drawer-content {
                display: flex;
                flex-direction: column;
                height: 97vh;
                padding-top: 2.5%;
                overflow: auto;
                padding-bottom: -2.5% !important;
    
                .drawer-card {
                    border-radius: 18px;
                    background-color: #fff;
                    height: 23vh;
                    width: 100%;
                    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
                    margin-bottom: 2.5%;
    
                    .card-first-split {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-evenly;
                        height: 35%;
                        border-bottom: 1.5px solid rgba(0, 0, 0, 0.3);
    
                        .content-group {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            width: max-content;
                            height: 100%;
    
                            .main-line {
                                font-size: 1.8vmin;
                                line-height: 3.5vmin !important;
                                font-size: bold;
                                color: #424B70;
                                font-family: 'Inter';
                                margin: 0;
                            }
    
                            .sub-line {
                                font-size: 1.8vmin;
                                line-height: 1.8vmin;
                                font-family: 'Inter';
                                margin: 0;
                            }
                        }
    
                        .view-icon {
                            color: $secondary-color;
                            cursor: pointer;
                        }
                    }
    
                    .card-second-split {
                        display: flex;
                        flex-direction: row;
                        height: 65%;
                        justify-content: space-evenly;
                        padding-bottom: 10%;
    
                        .sub-grid {
                            width: 46%;
                            height: 90%;
    
                            .header-line {
                                color: rgba(35, 35, 35, 0.7);
                                font-size: 1.8vmin;
                                line-height: 3.2vmin;
                                font-family: 'Inter';
                                margin: 0;
                            }
    
                            .desc-cover {
                                border-radius: 6px;
                                height: 70%;
                                box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                padding: 1%;
    
                                .desc-sub-line {
                                    font-size: 1.5vmin;
                                    line-height: 1.9vmin;
                                    font-family: 'Inter';
                                    color: #323232;
                                    flex-wrap: nowrap;
                                    margin: 0;
                                }
                            }
                        }
                    }
                }
            }
    
            .drawer-footer {
                display: flex;
                flex-direction: column;
                height: 7vh;
                border-top: 1px solid $primary-color;
                margin-top: 0px;
                align-items: center;
                justify-content: center;
    
                .ant-pagination {
                    margin: 0px;
                }
            }
        }
    
        .stats-box p {
            font-size: 14px;
        }
    
        :where(.css-dev-only-do-not-override-byeoj0).ant-modal .ant-modal-title {
            font-size: 25px;
            font-weight: 600;
            color: #031742;
        }
    
        #gst-chart {
            width: 40%;
            border-radius: 1%;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
        }
    
        .gst-chart {
            width: 35%;
            height: 85%;
            margin-left: 3%;
    
        }
    
        .stats-box img {
            height: 26%
        }
    
        .chart-stat {
            width: 50%;
            display: flex;
            flex-direction: column;
        }
    
        .dot {
            height: 12.5px;
            width: 12.5px;
            background-color: #bbb;
            border-radius: 50%;
            display: block;
            margin-right: 3%
        }
    
        .gst-table {
    
            display: flex;
            justify-content: center;
            flex-direction: column;
            width: 96%;
            margin-top: 3%;
            padding: 2%;
    
        }
    
        :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-column-title {
            position: relative;
            z-index: 1;
            flex: 1;
            width: max-content;
        }
    
        :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-thead>tr>th,
        :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-thead>tr>td {
            position: relative;
            color: rgba(0, 0, 0, 0.88);
            font-weight: 600;
            text-align: start;
            background: #ECEDF1;
            border-bottom: 1px solid #f0f0f0;
            transition: background 0.2s ease;
        }
    
        // .ant-pagination-total-text {
        //     position: absolute;
        //     left: 0;
        // }
        // .ant-pagination-options {
        //     position: absolute;
        //     right: 0;
        // }
        .gst-table-container {
            width: 100.1%;
            // margin-left: 4%;
        }
    
        .ant-checkbox .ant-checkbox-inner {
            width: 25px;
            height: 25px;
        }
    
        .ant-checkbox-checked .ant-checkbox-inner:after {
            transform: rotate(45deg) scale(1) translate(-50%, -75%);
        }
    
        .table_global_search {
            border-radius: 20px;
            width: 58%;
            height: 40px !important;
            background: rgb(243, 243, 249);
    
            input {
                background: rgb(243, 243, 249);
            }
        }
    
        .gst-header {
    
            height: 24vh;
            display: flex;
            justify-content: center;
        }
    
        .gst-stats {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            height: 38%;
        }
    
        .stat-item {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
        }
    
        :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-pagination.ant-pagination {
            margin: 16px 0;
            display: flex;
            justify-content: space-evenly;
        }
    
        #modelHeader {
            color: #232323 !important;
            font-family: Inter;
            font-size: 3.5vmin !important;
            font-weight: 600 !important;
        }
    
        .stat-subs {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    
        .stat-sub {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 75%
        }
    
        .stats-box {
            border: 1px solid rgba(0, 0, 0, 0.324);
            height: 78%;
            width: 15%;
            border-radius: 4%;
            display: flex;
    
            align-items: center;
            flex-direction: column;
    
        }
    
        .stats-box p {
            font-size: 14px;
        }
    
        :where(.css-dev-only-do-not-override-190m0jy).ant-select .ant-select-selector {
            width: 100%;
            height: 45px !important;
            padding: 0 11px;
        }
    
    
        .stats-box p {
            font-family: Inter;
        }
    
        .right-container {
            width: 50%;
            display: flex;
            justify-content: flex-end;
            margin-right: 0 !important;
            gap: 3%;
            align-items: center;
            height: 100%;
        }
    
        #gst-add-button {
            background: #424B70;
            color: white;
            display: flex;
            align-items: center;
            gap: 2px;
            height: 6vh;
            font-size: 16px;
        }
    
        #gst-chart {
            width: 40%;
            border-radius: 1%;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
        }
    
        .gst-chart {
            width: 35%;
            height: 85%;
            margin-left: 3%;
    
        }
    
        .stats-box img {
            height: 26%
        }
    
        .chart-stat {
            width: 50%;
            display: flex;
            flex-direction: column;
        }
    
        .dot {
            height: 12.5px;
            width: 12.5px;
            background-color: #bbb;
            border-radius: 50%;
            display: block;
            margin-right: 3%
        }
    
        .gst-table {
    
            display: flex;
            justify-content: center;
            flex-direction: column;
            width: 96%;
            margin-top: 3%;
            padding: 2%;
    
        }
    
        :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-column-title {
            position: relative;
            z-index: 1;
            flex: 1;
            width: max-content;
        }
    
        :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-thead>tr>th,
        :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-thead>tr>td {
            position: relative;
            color: rgba(0, 0, 0, 0.88);
            font-weight: 600;
            text-align: start;
            background: #ECEDF1;
            border-bottom: 1px solid #f0f0f0;
            transition: background 0.2s ease;
        }
    
        .gst-table-container {
            width: 100.1%;
            // margin-left: 4%;
        }
    
        .ant-checkbox .ant-checkbox-inner {
            width: 25px;
            height: 25px;
        }
    
        .ant-checkbox-checked .ant-checkbox-inner:after {
            transform: rotate(45deg) scale(1) translate(-50%, -75%);
        }
    
        .table_global_search {
            border-radius: 20px;
            width: 58%;
            height: 40px !important;
            background: rgb(243, 243, 249);
    
            input {
                background: rgb(243, 243, 249);
            }
        }
    
    
        h2 {
            // color: black;
            // font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
            // font-weight: 400;
        }
    
        .stats-box p {
            font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
        }
    
        .right-container {
            width: 50%;
            display: flex;
            justify-content: flex-end;
            margin-right: 0 !important;
            gap: 3%;
            align-items: center;
            height: 100%;
        }
    
        #gst-add-button {
            background: #424B70;
            color: white;
            display: flex;
            align-items: center;
            gap: 2px;
            height: 6vh;
            font-size: 16px;
        }
    
        #gst-solution-button {
    
            background: #424B70;
            font-size: 16px;
            color: white;
            height: 60%;
            font-family: Inter;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            gap: 5px;
            display: flex;
            align-items: center;
            padding: 1.5%;
    
        }
    
        .count {
            font-weight: 600;
    
        }
    
        .table-container {
            display: flex;
            width: 100%;
            flex-direction: row;
            // border: 0.5px solid rgba(0,0,0,0.50);
            // border-bottom: none;
            align-items: center
        }
    
        .left-container {
            gap: 2%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 100%;
            width: 50%;
            // margin-left: 2%;
    
        }
    
        .gst-row-filter {
            display: flex;
            flex-wrap: wrap;
            gap: 2%;
            align-items: center;
    
            flex-direction: row;
    
        }
    
        .gst-row-item {
            display: flex;
            flex-direction: row;
            gap: 13%;
            width: 23%;
            margin-bottom: 3%;
            align-items: center;
    
        }
    
        .gst-row-item img {
            height: 15px
        }
    
    
    }
}
@media only screen and (min-width:769px) and (max-width:1025px) {
    #case-summary-container {
       
    
        .case-container {

    
            .case-table-wrap {
            }
    
    
        }
    
        .sum-drawer {
            width: 50vw;
     margin-right: 2%;
            .drawer-header {
                
    
                .drawer-first-line {
                
    
                    .drawer-close-btn {
                      
                    }
    
                    .summary-heading {
                       
                        font-size: 1.8vmin;
                    }
                }
    
                .drawer-second-line {
                
    
                    .drawer_global_search {
                        height: 70% !important;
    
                        
                    }
    
                    .drawer-filter {
                       
                    }
    
                    .notice-cunt {
                       
                        font-size: 2vmin;
    
                        span {
                            
                            font-size: 2vmin;
                        }
                    }
                }
            }
    
            .drawer-content {
               
    
                .drawer-card {
                    
    
                    .card-first-split {

    
                        .content-group {
                    
    
                            .main-line {
                                font-size: 1.5vmin;
                          
                            }
    
                            .sub-line {
                                font-size: 1.5vmin;
                              
                            }
                        }
    
                        .view-icon {
                           
                        }
                    }
    
                    .card-second-split {
                    
    
                        .sub-grid {

    
                            .header-line {
                                font-size: 1.6vmin;
                            
                            }
    
                            .desc-cover {
                                
    
                                .desc-sub-line {
                                   
                                }
                            }
                        }
                    }
                }
            }
    
            .drawer-footer {
             
    
                .ant-pagination {
                    margin: 0px;
                }
            }
        }
    
        .stats-box p {
            font-size: 14px;
        }
    
        :where(.css-dev-only-do-not-override-byeoj0).ant-modal .ant-modal-title {
          
        }
 
    
    
    }
}
@media only screen and (min-width:1026px) and (max-width:1440px) {
    #case-summary-container {
     
    
       
    
        .sum-drawer {
            width: 40vw;
            margin-right: 2%;
    
            .drawer-header {
                
    
                .drawer-first-line {
                    
    
                    .drawer-close-btn {
                       
                    }
    
                    .summary-heading {
                       
                        font-size: 1.8vmin;
                    }
                }
    
                .drawer-second-line {
                  
    
                    .drawer_global_search {
                       
                        height: 70% !important;
                      
    
                       
                    }
    
                    .drawer-filter {
                       
                    }
    
                    .notice-cunt {
                        font-size: 2vmin;
    
                        span {
                           
                            font-size: 2vmin;
                        }
                    }
                }
            }
    
            .drawer-content {
               
    
                .drawer-card {
                   
    
                    .card-first-split {

    
                        .content-group {
         
    
                            .main-line {
                                font-size: 1.5vmin;
                               
                            }
    
                            .sub-line {
                                font-size: 1.5vmin;
                               
                            }
                        }
    
                        .view-icon {
                            color: $secondary-color;
                            cursor: pointer;
                        }
                    }
    
                  
                }
            }
    
          
        }
    
       
    
       
    
    
    }
}
@media only screen and (min-width:1441px)  {
    #case-summary-container {
       
    
        
    
        .sum-drawer {
           margin-right: 2%;
            width: 35vw;
           
    
            .drawer-header {
               
    
                .drawer-first-line {
                  
    
                    .drawer-close-btn {
                       
                    }
    
                    .summary-heading {
                       
                        font-size: 1.8vmin;
                    }
                }
    
                .drawer-second-line {
                    
    
                    .drawer_global_search {
                        height: 70% !important;
                       
    
                        input {
                          
                        }
    
                        &:hover {
                        }
                    }
    
                    .drawer-filter {
                    }
    
                    .notice-cunt {
                       font-size: 2vmin;
    
                        span {
                            font-size: 2vmin;

                        }
                    }
                }
            }
    
            .drawer-content {

    
                .drawer-card {
                   
    
                    .card-first-split {
  
    
                        .content-group {
                            
    
                            .main-line {
                                font-size: 1.6vmin;
                               
                            }
    
                            .sub-line {
                                font-size: 1.6vmin;
                               
                            }
                        }
    
                        .view-icon {
                         
                        }
                    }
    
                    .card-second-split {
                       
    
                        .sub-grid {
                          
    
                            .header-line {
                                font-size: 1.6vmin;
                               
                            }
    
                            .desc-cover {
                                
    
                                .desc-sub-line {
                                    
                                }
                            }
                        }
                    }
                }
            }
    
            .drawer-footer {
                
    
                .ant-pagination {
                }
            }
        }
    
      
    
    
    }
}