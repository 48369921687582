@import'../../App.scss';

.action-popup {
    #z-common-gst-solution-button {
        font-size: 2.2vmin;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        gap: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3% 2%;
        min-width: 20%;
        margin: auto;
    }

    .theme {
        background-color: $primary-color ;
        color: #FFFFFF;
        border-color: $primary-color;
    }

    #z-common-gst-solution-button:hover {
        background-color: $primary-color ;
        color: #FFFFFF;
        border-color: $primary-color;
    }


}

.change_pass_container {
    .MuiPaper-root {
        width: 35% !important;
        max-width: 100% !important;
        // max-height: none !important;
    }

    .MuiTypography-root {
        display: flex;
        justify-content: center;
        color: #000;
        font-family: Inter !important;
        font-size: 3.5vmin !important;
        font-style: normal;
        font-weight: 500 !important;
        padding-bottom: 1%;
        line-height: 168.023% !important;
        /* 50.407px */
    }

    .MuiDialogContent-root {
        border-top: 0 !important;
        padding-top: 8px;
    }

    .MuiStepConnector-root {
        top: 20px !important;
    }

    .css-ogijw2,
    .css-1fgbf5s {
        cursor: pointer;
    }

    .logout-head {
        justify-content: left !important;
    }

    .logout-msg {
        display: flex;
        justify-content: left;
        color: #000;
        font-family: Inter !important;
        font-size: 3.5vmin !important;
        font-style: normal;
        font-weight: 500 !important;
        line-height: 168.023% !important;
        /* 50.407px */
        margin-bottom: 5%;


    }

    .no-btn {
        position: relative;
        left: -2%;
        background: #424B70 !important;
    }

    .login-btn {
        display: flex;
        float: right;
        width: 25%;
        padding: 8px 40px;
        justify-content: center;
        align-items: center;
        margin: auto;
        border-radius: 8px;
        background: #ed7d21;
        border-style: none;

        color: #FFF;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 168.023%;
    }

    .fields {
        margin: 0 2% 0 2%;
        padding-bottom: 2%;

        .pwd {
            color: #000;
            font-family: Inter;
            font-size: 2.7vmin;
            font-style: normal;
            font-weight: 500;
            line-height: 168.023%;
            /* 40.325px */
            margin-bottom: 2%
        }

        .row {

            .input-group {
                margin-top: 1rem;
                // padding-bottom: 1rem;

                img {
                    position: relative;
                    top: 17%;
                    left: 2%;
                }

                span {
                    float: left;
                }

                .register {
                    display: flex;
                    width: 45%;
                    padding: 13px 0px;
                    justify-content: center;
                    align-items: center;
                    margin: auto;
                    border-radius: 8px;
                    background: #424B70;
                    border-style: none;
                    cursor: pointer;
                    color: #FFF;
                    font-family: Inter;
                    font-size: 2.2vmin;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 168.023%;
                    /* 33.605px */
                    text-transform: uppercase;

                    &:disabled {
                        color: #ffffff74 !important;
                        cursor: not-allowed !important;
                    }

                }



                .ph-partner-register {
                    display: flex;
                    width: 45%;
                    padding: 13px 0px;
                    justify-content: center;
                    align-items: center;
                    margin: auto;
                    border-radius: 8px;
                    background: $secondary-color;
                    border-style: none;
                    cursor: pointer;
                    color: #FFF;
                    font-family: Inter;
                    font-size: 1.8vmin;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 168.023%;
                    /* 33.605px */
                    text-transform: uppercase;
                }

                label {
                    color: #000;
                    font-family: Inter;
                    font-size: 1.6vmin;
                    font-weight: 400;
                }

                #area-label {
                    margin-bottom: 2% !important;
                }

                .css-1h51icj-MuiAutocomplete-root {
                    #area_practice {
                        height: 20px;
                        margin-top: 20px;
                    }
                }

                #radio-grp {
                    min-width: 19vw;
                    display: flex;
                    flex-direction: row;
                    margin-top: 1%;

                    #first-radio {
                        margin-left: 1%;
                    }

                    span {
                        font-size: 1.9vmin !important;
                    }
                }
            }

            #last-one {
                display: flex;
                flex-direction: row;

                .register {
                    display: flex;
                    width: 45%;
                    padding: 13px 0px;
                    justify-content: center;
                    align-items: center;
                    margin: auto;
                    border-radius: 8px;
                    background: #424B70;
                    border-style: none;
                    cursor: pointer;
                    color: #FFF;
                    font-family: Inter;
                    font-size: 1.8vmin;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 168.023%;
                    /* 33.605px */
                    text-transform: uppercase;
                }

                .ph-partner-register {
                    display: flex;
                    width: 45%;
                    padding: 13px 0px;
                    justify-content: center;
                    align-items: center;
                    margin: auto;
                    border-radius: 8px;
                    background: $secondary-color;
                    border-style: none;
                    cursor: pointer;
                    color: #FFF;
                    font-family: Inter;
                    font-size: 1.8vmin;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 168.023%;
                    /* 33.605px */
                    text-transform: uppercase;
                }
            }

            .pin_input-group {
                margin-top: 2rem;
                display: flex;
                flex-direction: row;

                justify-content: space-between;
                width: 100%;
                // padding-bottom: 1rem;

                .input-wrap {
                    width: 90%;

                    label {
                        color: #000;
                        font-family: Inter;
                        font-size: 1.6vmin;
                        font-weight: 400;
                    }

                    span {
                        float: left;
                    }

                    .input-box {
                        height: 2.6rem;
                        border-radius: 4px;
                        border: 0.5px solid rgba(0, 0, 0, 0.70);
                        margin-top: 0.4rem;
                        width: 100%;

                        img {
                            position: relative;
                            top: 17%;
                            left: 2%;
                        }

                        .eye_icon {
                            position: absolute;
                            color: #77787A;
                            right: 12%;
                            margin-top: 2%;
                            cursor: pointer;
                        }




                        span {
                            color: #E10000;
                            font-family: Inter;
                            font-size: 1.5vmin;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 142%;
                            /* 19.88px */
                            letter-spacing: 0.035px;
                            padding-top: 1%;
                            float: right !important;
                        }
                    }
                }

                .pincode_btn {
                    display: flex;
                    width: 8.5%;
                    padding: 8px 0px;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    background: $font-primay-color;
                    border-style: none;
                    cursor: pointer;
                    font-family: Inter;
                    font-size: 2vmin;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 2vmin;
                    /* 33.605px */
                    text-transform: uppercase;


                    .pincode_search {
                        color: #fff;
                    }
                }
            }

            .err-msg {
                display: flex;
                flex-direction: column;
                margin-top: 2%;

                span {
                    float: left !important;
                }
            }

            .label {
                color: #000;
                font-family: Inter;
                font-size: 1.6vmin;
                font-weight: 400;
            }

            .otp-group {
                padding: 0% 0% 5% 0%;
                width: 79%;
                margin: auto;

                .otp-cover {
                    width: 100%;
                    max-width: 100% !important;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-evenly;

                    .otp_input {
                        height: 80%;
                        width: 18%;
                        padding: 0px !important;
                    }
                }
            }
        }
    }

    .input-box {
        height: 2.6rem;
        border-radius: 4px;
        border: 0.5px solid rgba(0, 0, 0, 0.70);
        margin-top: 0.4rem;
        display: flex;
        align-items: center;

        img {
            position: relative;
            top: 17%;
            left: 2%;
        }

        .eye_icon {
            color: #77787A;
            right: 12%;
            cursor: pointer;
        }


        span {
            color: #E10000;
            font-family: Inter;
            font-size: 1.5vmin;
            font-style: normal;
            font-weight: 400;
            line-height: 142%;
            /* 19.88px */
            letter-spacing: 0.035px;
            padding-top: 1%;
            float: right !important;
        }
    }


    .err-msg {
        display: flex;
        flex-direction: column;
        margin-top: 1%;

        span {
            float: left !important;
        }
    }

    input[type="text"] {
        border: 0;
        outline: none;
        width: 85%;
        position: relative;
        top: 4%;
        height: 90%;
        margin-left: 2%;
        font-family: Inter;
    }

    input[type="password"] {
        border: 0;
        outline: none;
        width: 85%;
        position: relative;
        top: 1%;
        height: 90%;
        margin-left: 2%;
        font-family: Inter;
    }


    .cf_cover {
        display: flex;
        flex-direction: row;

        .check_box {
            height: 15px;
            width: 15px;
            margin-right: 2%;
            cursor: pointer;
            border: 0.75px solid #E96919;
        }

        .checked_box {
            height: 15px;
            width: 15px;
            margin-right: 2%;
            border: 0.75px solid #fff;
            display: inline-block;
            background-color: #E96919;
            cursor: pointer;

            span {
                position: relative;
                left: -40%;
                top: -3px;
                -ms-transform: rotate(45deg);
                /* IE 9 */
                -webkit-transform: rotate(45deg);
                /* Chrome, Safari, Opera */
                transform: rotate(45deg);

                .checkmark_stem {
                    position: relative;
                    width: 2.5px;
                    height: 12px;
                    background-color: #fff;
                    left: 3px;
                    top: 3px;
                }

                .checkmark_kick {
                    position: relative;
                    width: 3px;
                    height: 2.5px;
                    background-color: #fff;
                }
            }

        }

        margin-top: 1rem !important;
        width: 100%;
        align-items: center;

        .title {
            color: #000;
            font-family: Inter;
            font-size: 1.7vmin;
            font-style: normal;
            font-weight: 600;
            line-height: 160.523%;
            /* 22.473px */
            letter-spacing: 0.035px;
            width: 90%;
            display: flex;
            align-items: center;

            .privacy-innerline {
                color: #0193E5;
                text-decoration-line: underline;
                cursor: pointer;
                // margin-left: 0.4%;
            }

            .info-icon-wrap {
                margin-left: 5%;
                display: flex;
                align-items: center;

                .info-icon {
                    color: $primary-color;
                    cursor: pointer;
                }
            }
        }

        .check_box {
            height: 15px;
            width: 15px;
            margin-right: 2%;
            cursor: pointer;
            border: 0.75px solid #E96919;
        }

        .checked_box {
            height: 15px;
            width: 15px;
            margin-right: 2%;
            border: 0.75px solid #fff;
            display: inline-block;
            background-color: #E96919;
            cursor: pointer;

            span {
                position: relative;
                left: -40%;
                top: -3px;
                -ms-transform: rotate(45deg);
                /* IE 9 */
                -webkit-transform: rotate(45deg);
                /* Chrome, Safari, Opera */
                transform: rotate(45deg);

                .checkmark_stem {
                    position: relative;
                    width: 2.5px;
                    height: 12px;
                    background-color: #fff;
                    left: 3px;
                    top: 3px;
                }

                .checkmark_kick {
                    position: relative;
                    width: 3px;
                    height: 2.5px;
                    background-color: #fff;
                }
            }

        }


    }

    .cf_code-wrap {
        width: 80%;
        height: 50%;
        display: flex;
        flex-direction: row;
        text-align: left;
        align-items: center;
        justify-content: space-around;
        padding: 2% 0;

        span {
            color: #000;
            font-family: Roboto;
            font-size: 2.3vmin;
            font-weight: 400;
            width: 80%;
            // border-bottom: 1px solid #ccc;
            cursor: pointer;

        }

        .clipboard-icon {
            margin-left: 5%;
            color: $secondary-color;
            cursor: pointer;
        }
    }
}


/* Make the group container horizontal */
.horizontal-grouped-menu {
    z-index: 3 !important;

    .menu-groups {
        display: flex;
        flex-direction: row;
    }
}

/* Style each group so they appear in a row */
.horizontal-grouped-menu .menu-group {
    margin-right: 10px;

    /* Adjust spacing between groups */
}

.menu-item {
    transition: background-color 0.3s ease-in-out;
}

.menu-item.hovered {
    background-color: rgba(0, 0, 0, 0.1);
    /* Apply hover effect only to the specific item */
}

.horizontal-grouped-menu .ant-menu-item-group-title {
    font-weight: bold;
    font-size: 14px;
}

/* Ensure items in each group are stacked vertically */
.horizontal-grouped-menu .ant-menu-item-group-list {
    display: flex;
    flex-direction: column;
}

:where(.css-dev-only-do-not-override-1uq9j6g).ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-group-list {
    padding: 8px 0;
}

#gst-header {

    height: 10vh !important;
    max-width: 99.9%;
    padding: 0%;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.15);

    .head-top {
        display: flex;
        flex-direction: row;
        height: 100%;
        max-width: 100%;
        padding-left: 2%;

        .logo_box {
            max-width: 40%;
            // width: auto;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .official-site {
                // width: 17%;
                height: 4vh;
                margin: 2%;
                padding: 0 1%;
            }

            .week-report-band-cover {
                // border: 1px solid $secondary-color;
                color: $secondary-color;
                height: 30%;
                padding: 10px;
                border-radius: 4px;
                width: max-content;
                display: flex;
                flex-direction: column;
                align-items: start;
                justify-content: center;
                text-align: left;
                font-size: 2vmin;
                margin-left: auto;

                span {
                    color: $font-primay-color;
                }

                .week-cnt {
                    margin: 0;

                    b {
                        font-size: 2.1vmin;
                        color: $font-primay-color;
                    }

                }
            }

        }


        .nav-button-box {
            max-width: 75%;
            width: 75%;
            height: 100%;
            display: flex;
            justify-content: start;
            align-items: center;

            .menu_btn {
                // color: var(--low-contrast-text, $font-secondary-color);
                color: $primary-color;
                font-family: Roboto;
                font-size: 1.6vmin;
                font-weight: 400;
                text-transform: uppercase;
                background: #fff;
                border: none;
                // margin-left: 1%;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 5px;
                height: 50%;
                border-radius: 8px;
                min-width: 10vw !important;
                padding-right: 0.8%;
                box-shadow: none;
                transition: background-color 0.3s ease;

                &:hover {
                    background-color: #ececec;
                }
            }

            .menu_btn.hovered {
                background-color: #ececec;
            }

            .menu_btn_active {
                border: 0.631px solid rgba(0, 0, 0, 0.50) !important;
                background: #424B70 !important;
                color: #fff !important;
                font-weight: 600 !important;
            }



        }

        .profile_box {
            max-width: 25%;
            width: 25%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: end;


            .drop_options {
                position: absolute;
                top: 10%;
                // left: 35%;
                // right: 0%;
                margin-right: 2.3%;
                margin-left: auto;
                z-index: 3;
                width: 26vmin;
                // height: 8vmin;
                flex-shrink: 0;
                background: #FFF;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                align-items: center;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .optionss {
                    width: 100%;
                    height: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 2%;
                    padding: 2% 0;

                    .sync_img {
                        width: 20%;
                        color: #000;
                        margin-left: 3%;
                    }

                    span {
                        color: #000;
                        font-family: Roboto;
                        font-size: 2.1vmin;
                        font-weight: 400;
                        width: 80%;
                        // border-bottom: 1px solid #ccc;
                        text-align: left;
                        cursor: pointer;
                        align-items: center;


                        img {
                            height: 2.2vmin;
                            margin-right: 5%;
                        }
                    }

                    .refer-line {
                        font-size: 1.8vmin;
                    }

                    .clipboard-icon {
                        color: $secondary-color;
                        cursor: pointer;
                    }
                }

                .code {
                    background: #e7e6e5 !important;
                }

                .optionss:hover {
                    background: #e7e6e5 !important;
                    // span{
                    //     color: #fff !important;
                    // }

                }

                .type_option {
                    width: 100%;
                    height: 50%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 2%;
                    padding: 0%;

                    span {
                        color: #000;
                        font-family: Roboto;
                        font-size: 2vmin;
                        font-weight: 400;
                        width: 80%;
                        // border-bottom: 1px solid #ccc;
                        text-align: left;
                        cursor: pointer;
                        align-items: center;


                        img {
                            height: 2.2vmin;
                            margin-right: 5%;
                        }
                    }

                    .type_selection {
                        width: 85%;
                        padding: 0 6% !important;

                        .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
                            font-size: 2vmin !important;
                            height: 5vh;
                            border: none !important;
                        }
                    }
                }

                .type_option {}
            }

            .profile-content {
                color: #666;
                width: 100%;
                justify-content: end;
                max-width: 100%;
                font-family: Inter;
                right: 3%;
                font-size: 2.2vmin;
                margin-top: 0.3%;
                text-wrap: nowrap;
                display: flex;
                align-items: center;
                margin-left: 2%;
                margin-right: 9%;

                .search_box {
                    width: 25vw;
                    height: 5.5vh;
                    background-color: #fff;
                    border: 1px solid #d9d9d9;
                    border-radius: 16px;
                    position: absolute;
                    right: 15.7vw;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    transition: width ease-in-out 0.5s;

                    .search_type_selection {
                        width: 35%;
                        border-right: 1px solid $primary-color;
                        // padding: 0 6% !important;

                        .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
                            font-size: 2vmin !important;
                            height: 5vh;
                            border: none !important;
                        }
                    }

                    .z_dashboard_global_search {
                        border-radius: 0px;
                        width: 53%;
                        top: 0%;
                        height: 30px !important;
                        // background: rgb(243, 243, 249);
                        box-shadow: none;
                        margin-left: 1%;
                        padding: 0% 1%;

                        input {
                            background: rgb(243, 243, 249);
                            font-family: Inter;
                            box-shadow: none;
                        }
                    }
                }

                .gst_add_btn {
                    background-color: $primary-color;
                    padding: 0%;
                    margin: 0%;
                    height: 4vh;
                    width: 2.2vw;
                    margin-right: 6%;

                    img {
                        height: 3vh;
                        max-height: 70%;
                    }
                }

                b {
                    margin-right: 2%;
                    margin-left: 2%;
                    color: var(--text-black, #393938);
                    // color: black;
                    // font-weight: 500;
                    font-size: 2.3vmin;
                }

                img {
                    margin-top: -1%;
                    cursor: pointer;
                }

                .avatar {
                    background-color: $secondary-color;
                    text-transform: uppercase;
                    cursor: pointer;
                    // margin-right: 4%;
                    // margin-left: 4%;
                }

                .notif_badge {

                    margin-left: 5%;
                    margin-right: 5%;

                    .notif_icon {
                        color: $primary-color;
                        cursor: pointer;
                        font-size: 3.7vmin;
                    }
                }

                @keyframes rotation {
                    from {
                        transform: rotate(360deg);
                    }

                    to {
                        transform: rotate(0deg);
                    }
                }

                .Progress-icon {
                    animation: rotation 1.5s;
                    animation-iteration-count: infinite;
                }
            }

            .sync-box {
                width: auto;
                height: 100%;
                display: flex;
                align-items: center;

                .sync-btn {
                    background: rgb(236, 251, 228);
                    border: 1px solid rgb(82, 196, 26);
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    height: 60%;
                    color: var(--text-black, #393938);
                    font-family: Inter;
                    font-size: 1.8vmin;
                    font-weight: 500;
                }

                .Progres-btn {
                    background: #BEDCE7;
                    border: 1px solid #5FAABC;
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    height: 60%;
                    color: var(--text-black, #393938);
                    font-family: Inter;
                    font-size: 1.8vmin;
                    font-weight: 500;
                    cursor: pointer;

                    @keyframes rotation {
                        from {
                            transform: rotate(0deg);
                        }

                        to {
                            transform: rotate(360deg);
                        }
                    }

                    .Progress-icon {
                        animation: rotation 1.5s;
                        animation-iteration-count: infinite;
                    }
                }
            }

        }
    }

    .head-bottom {
        display: flex;
        height: 40%;
        max-width: 100%;
        justify-content: flex-end;
        background-color: #E7F1FA;
        padding-left: 2%;
        padding-right: 2%;


        .nav-button-box {
            max-width: 85%;
            width: 85%;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            // align-items: center;

            .menu_btn {
                color: var(--low-contrast-text, $font-secondary-color);
                font-family: Roboto;
                font-size: 1.5vmin;
                font-weight: 400;
                text-transform: uppercase;
                background: #fff;
                border: none;
                margin-left: 1%;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 5px;
                height: 80%;
                border-bottom-right-radius: 21px;
                border-bottom-left-radius: 21px;
                border-top-right-radius: 10px;
                border-top-left-radius: 10px;
                padding: 0 2.5%;
            }

            .menu_btn_active {
                border: 0.631px solid rgba(0, 0, 0, 0.50) !important;
                background: #424B70 !important;
                color: #fff !important;
                font-weight: 600 !important;
            }
        }

        .notice-left-container {
            display: flex;
            flex-direction: row;
            width: 15%;
            height: 100%;
            align-items: center;
            // display: flex;
            // justify-content: flex-start;

            .back-nav {
                border: 1px solid $primary-color;
                color: $primary-color;
                display: flex;
                align-items: center;
                justify-content: start;
                font-size: 2.1vmin;
                font-weight: bold;
                width: auto;

                .icon {
                    font-size: 2.3vmin;
                }
            }

            .header {
                width: 15%;
                color: #232323;
                font-family: Inter;
                font-size: 3.5vmin;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }

            .view-container {
                width: 85%;
                display: flex;

                .dropdown-btn {
                    width: 45%;

                    .ant-radio-group {
                        display: flex;
                        flex-direction: column;
                    }

                    .ant-select-selector {
                        height: 45px;
                        align-items: center;
                    }
                }

                .save-btn {
                    width: 20%;
                    // height: 45px;
                    color: #FFF;
                    // background: rgb(165, 166, 167);
                    margin-left: 10px;
                    // border-radius: 20px;
                    font-size: 1.8vmin;
                    border-radius: 42px;
                    background: #424B70;
                }
            }

        }

    }

}


.confirm_modal {
    .confirm_cover_wrap {
        height: 10vh !important;
    }

    :where(.css-dev-only-do-not-override-1uq9j6g).ant-modal .ant-modal-content {}

    .confirm-msg {
        display: flex;
        justify-content: left;
        color: #000;
        font-family: Inter !important;
        font-size: 2vmin !important;
        font-style: normal;
        font-weight: 500 !important;
        line-height: 168.023% !important;
        /* 50.407px */
        margin-bottom: 5%;
    }

    .no-btn {
        position: relative;
        left: 2%;
        background: #424B70 !important;
    }

    .confirm-btn {
        display: flex;
        float: right;
        width: 25%;
        padding: 6px 12px;
        justify-content: center;
        align-items: center;
        margin: auto;
        border-radius: 8px;
        background: #ed7d21;
        border-style: none;
        cursor: pointer;
        color: #FFF;
        font-family: Inter;
        font-size: 1.5vmin;
        font-style: normal;
        font-weight: 400;
        line-height: 168.023%;
    }
}

.css-1n5c6o8-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
    max-width: 24vw !important;
    min-width: 24vw !important;
    height: 55vh;
    right: 3 !important;
    margin-left: auto !important;

    // left: 970px !important;

    .notif_popover {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        max-width: 22vw !important;
        padding: 0% 5%;

        span {
            line-height: 4.4vmin;
            font-weight: bold;
            color: $secondary-color;
        }

        .link-item {
            width: 100%;
            font-size: 2vmin;
            text-decoration: underline;
            text-decoration-color: $primary-color;
            color: $primary-color;
            cursor: pointer;
            text-align: start;
        }

        .notify-wrap {
            height: 42vh;
            width: 100%;
            overflow: auto;
            scrollbar-width: none;

            .notify-block {
                min-height: 9vh;
                height: auto;
                width: 100%;
                margin-bottom: 2%;
                border-bottom: 1px solid $secondary-color;
            }
        }
    }
}

#mob-gst-header {
    display: none;
}


@media only screen and (min-width:320px) and (max-width:600px) {
    #gst-header {
        display: none;
    }

    #mob-gst-header {
        height: 9vh !important;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.15);

        .logo_box {
            width: 100%;
            height: 80%;
            display: flex;
        }

        .gst_add_btn {
            background-color: $primary-color;
            padding: 0%;
            margin: 0%;
            height: 3.8vh;
            width: 13vw;
            margin-right: 2%;

            img {
                height: 3vh;
                max-height: 70%;
            }
        }

        .notif_badge {

            margin-left: 1%;
            margin-right: 1%;

            .notif_icon {
                color: $primary-color;
                cursor: pointer;
                font-size: 8vmin;
            }
        }

        .official-site {
            width: 24%;
            height: 1.5vh;
            font-size: 2.8vmin;
            margin: 2%;
            // padding: 0;
        }

        .header-menu {
            color: $secondary-color;
            margin-right: 3%;
        }

        @keyframes rotation {
            from {
                transform: rotate(0deg);
            }

            to {
                transform: rotate(360deg);
            }
        }

        .Progress-icon {
            animation: rotation 1.5s;
            animation-iteration-count: infinite;
        }

    }

    .drawer {

        .mob-week-report-band-cover {
            // border: 1px solid $secondary-color;
            color: $secondary-color;
            padding: 10px;
            border-radius: 4px;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: center;
            text-align: left;
            font-size: 3vmin;
            margin-left: auto;


            span {
                color: $font-primay-color;
            }

            .week-cnt {
                margin: 0;

                b {
                    font-size: 3.4vmin !important;
                    color: $font-primay-color;
                }

            }
        }

        .name-wrap {
            height: 5vh;
            display: flex;
        }

        .drawer-list {
            display: flex;
            align-items: center;
            flex-direction: column;
            height: 53vh;
            padding-top: 0px;
            overflow: auto;

            #sync-btn {
                background: rgb(236, 251, 228);
                border: 1px solid rgb(82, 196, 26);
                display: flex;
                align-items: center;
                gap: 5px;
                height: 15%;
                color: var(--text-black, #393938);
                font-family: Inter;
                font-size: 2.5vmin;
                font-weight: 500;
            }

            .menu_btn {
                color: var(--low-contrast-text, $font-secondary-color);
                font-family: Roboto;
                font-size: 3.2vmin;
                font-weight: 400;
                text-transform: uppercase;
                background: transparent;
                // border: 1px solid rgb(102, 102, 102);
                display: flex;
                align-items: center;
                justify-content: flex-start;
                height: 11%;
                border-radius: 21px;
                gap: 10px;
                margin-top: 3%;
                width: 80%;

                .imagecontainer {
                    width: 20%;
                    height: 100%;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    background-color: rebeccapurple;
                }
            }

            .menu_btn_active {
                border: 0.631px solid rgba(0, 0, 0, 0.50) !important;
                background: #424B70 !important;
                color: #fff !important;
                font-weight: 600 !important;
            }


        }

        .avatar {
            background-color: $secondary-color;
            text-transform: uppercase;
            cursor: pointer;
            // margin-right: 4%;
            // margin-left: 4%;
        }

        .clipboard-icon {
            color: $secondary-color !important;
        }
    }

    .overallsyncReportmodal {
        height: 70%;
        width: 90%;

        .report-wrap {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            text-align: end;
            margin-top: 9%;

            .report-title {
                width: 65%;
                display: flex;
                flex-direction: column;
                align-items: end;
            }

            h3 {
                line-height: 0.5;
            }

            .report-cnt {
                color: $secondary-color;
                width: 15%;
                display: flex;
                flex-direction: column;
                align-items: end;
                justify-content: center;
            }
        }
    }

    .sync-report-popup {

        width: auto !important;

        .ant-modal-title {
            font-size: 3vmin !important;
            text-align: center;
        }

        .report-wrap {
            display: flex;
            justify-content: flex-start;
            flex-direction: column !important;
            text-align: end;
            margin-top: 0%;
            align-items: flex-start !important;
            width: 100% !important;
            height: 85%;

            .total_gstcontainer {
                height: 15%;
                width: 100%;
                display: flex;
                flex-direction: row;

                .totalgstlabelcontainer {
                    height: 100%;
                    width: 48%;
                    justify-content: flex-end;
                    align-items: center;
                    display: flex;

                    .gstInlabel {
                        padding-right: 5%;
                        font-weight: 600;
                        font-size: 3.5vmin !important;
                        text-align: right;
                        color: #031742;
                    }

                }

                .centercontainer {
                    width: 4%;
                    height: 100%;
                    justify-content: center;
                    align-items: center;
                    display: flex;

                    .gstInlabel2 {
                        font-weight: 600;
                        padding-left: 5%;
                        font-size: 3.5vmin !important;
                        text-align: right;
                        color: $secondary-color;
                    }
                }

                .totalAnswerlabelcontainer {
                    height: 100%;
                    width: 48%;
                    justify-content: flex-start;
                    align-items: center;
                    display: flex;

                    .gstInlabel {
                        font-weight: 600;
                        padding-left: 5%;
                        font-size: 3.5vmin !important;
                        text-align: right;
                        color: $secondary-color;
                    }

                    .gstInlabels {
                        font-weight: 600;
                        padding-left: 5%;
                        font-size: 3.5vmin !important;
                        text-align: right;
                        color: $secondary-color;
                    }
                }
            }



            .report-title {
                width: 65%;
                height: 100% !important;
                display: flex;
                flex-direction: column !important;
                align-items: flex-end !important;
                justify-content: space-evenly !important;


                .gstInlabel {
                    font-weight: 600;
                    font-size: 3.5vimn !important;
                    text-align: right;
                }
            }

            h3 {
                line-height: 0.5;
            }

            .report-cnt {
                color: $secondary-color;
                width: 15%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-evenly !important;

                .gstInlabel1 {
                    font-weight: 600;
                    font-size: 1.8vimn !important;
                    text-align: right;
                }

            }
        }
    }

    .css-1d8r0hl-MuiPaper-root-MuiAccordion-root.Mui-expanded {
        min-height: 30px !important;
        margin: 0 0 !important;
    }

    .css-eqpfi5-MuiAccordionSummary-content.Mui-expanded {
        margin: 10px 0 0 0 !important;
    }

    .css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
        min-height: 40px !important;
    }
}

@media only screen and (min-width:601px) and (max-width:768px) {
    #gst-header {
        display: none;
    }

    #mob-gst-header {
        height: 9vh !important;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.15);

        .logo_box {
            width: 100%;
            height: 80%;
            display: flex;
        }

        .official-site {
            width: 21%;
            height: 0.1vh;
            font-size: 2vmin;
            padding: 2% 2%;
            margin: 2%;
            // padding: 0;
        }

        .header-menu {
            color: $secondary-color;
            margin-right: 1%;
        }



    }

    .drawer {

        .mob-week-report-band-cover {
            // border: 1px solid $secondary-color;
            color: $secondary-color;
            padding: 10px;
            border-radius: 4px;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: center;
            text-align: left;
            font-size: 2.2vmin;
            margin-left: auto;


            span {
                color: $font-primay-color;
            }

            .week-cnt {
                margin: 0;

                b {
                    font-size: 2.1vmin;
                    color: $font-primay-color;
                }

            }
        }

        .drawer-list {
            display: flex;
            align-items: center;
            flex-direction: column;
            height: 40vh;

            #sync-btn {
                background: rgb(236, 251, 228);
                border: 1px solid rgb(82, 196, 26);
                display: flex;
                align-items: center;
                gap: 5px;
                height: 15%;
                color: var(--text-black, #393938);
                font-family: Inter;
                font-size: 2vmin;
                font-weight: 500;
            }

            .menu_btn {
                color: var(--low-contrast-text, $font-secondary-color);
                font-family: Roboto;
                font-size: 1.7vmin;
                font-weight: 400;
                text-transform: uppercase;
                background: transparent;
                border: 1px solid rgb(102, 102, 102);
                display: flex;
                align-items: center;
                justify-content: flex-start;
                height: 14%;
                border-radius: 21px;
                gap: 10px;
                margin-top: 3%;
                width: 80%;
            }

            .menu_btn_active {
                border: 0.631px solid rgba(0, 0, 0, 0.50) !important;
                background: #424B70 !important;
                color: #fff !important;
                font-weight: 600 !important;
            }
        }

    }
}

@media only screen and (min-width:769px) and (max-width:1024px) {
    #gst-header {



        .head-top {


            .logo_box {
                width: 60%;


                .official-site {
                    // width: 17%;
                    height: 4vh;
                    margin: 2%;

                    padding: 0 1%;
                }

                .week-report-band-cover {
                    // border: 1px solid $secondary-color;





                }


            }

            .profile_box {
                margin-right: 1%;


                .drop_options {

                    background-color: #fff;

                    width: 18%;

                    .optionss {
                        justify-content: space-evenly !important;

                        span {
                            font-size: 1.8vmin;


                            img {
                                height: 2vmin;
                                margin-right: 5%;
                            }
                        }

                        .refer-line {
                            font-size: 1.8vmin;
                        }

                        .clipboard-icon {
                            color: $secondary-color;
                            cursor: pointer;
                        }
                    }

                    .optionss:hover {
                        background: #e7e6e5 !important;
                        // span{
                        //     color: #fff !important;
                        // }

                    }
                }

                .profile-content {
                    font-size: 2vmin;


                    b {

                        font-size: 2.3vmin;
                    }

                    img {
                        margin-top: -1%;
                        cursor: pointer;
                    }

                    .avatar {
                        background-color: $secondary-color;

                    }

                }

                .sync-box {


                    .sync-btn {}

                    .Progres-btn {

                        border: 1px solid #5FAABC;
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        height: 60%;
                        color: var(--text-black, #393938);
                        font-family: Inter;
                        font-size: 1.8vmin;
                        font-weight: 500;
                        cursor: pointer;

                        @keyframes rotation {
                            from {
                                transform: rotate(0deg);
                            }

                            to {
                                transform: rotate(360deg);
                            }
                        }

                        .Progress-icon {
                            animation: rotation 1.5s;
                            animation-iteration-count: infinite;
                        }
                    }
                }
            }
        }

        .head-bottom {




            .nav-button-box {
                justify-content: flex-start;
                width: 100% !important;
                margin-right: 0.5%;
                margin-left: 0.5%;
                font-size: 0.8vmin;

                .menu_btn {
                    color: var(--low-contrast-text, $font-secondary-color);
                    font-family: Roboto;

                    font-size: 1vmin !important;
                    font-weight: 400;
                    width: 13% !important;
                    text-transform: uppercase;
                    border: none;
                    margin-left:
                        1%;
                    display: flex;
                    justify-content: space-evenly;
                    gap: 0px;
                    height: 80%;
                    padding: 0%;

                    .imagecontainer {
                        display: none;
                    }
                }


            }

            .nav-button-box1 {


                .menu_btn {
                    font-size: 1vmin;
                    width: 13% !important;
                    margin-left: 1%;
                    gap: 0px;
                    justify-content: space-evenly;
                    padding: 0%;

                    .imagecontainer {
                        display: none;
                    }
                }

                .menu_btn_active {
                    border: 0.631px solid rgba(0, 0, 0, 0.50) !important;
                    background: #424B70 !important;
                    color: #fff !important;
                    font-weight: 600 !important;
                }
            }

            .notice-left-container {
                width: 5%;
                margin-right: 10%;

                .back-nav {
                    border: 1px solid $primary-color;
                    color: $primary-color;
                    display: flex;
                    align-items: center !important;
                    justify-content: center !important;
                    font-size: 0vmin;
                    font-weight: bold;
                    width: 80%;

                    .icon {
                        font-size: 2.3vmin;
                    }
                }

                .header {
                    width: 15%;
                    color: #232323;
                    font-family: Inter;
                    font-size: 3.5vmin;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                .view-container {
                    width: 85%;
                    display: flex;

                    .dropdown-btn {
                        width: 45%;

                        .ant-radio-group {
                            display: flex;
                            flex-direction: column;
                        }

                        .ant-select-selector {
                            height: 45px;
                            align-items: center;
                        }
                    }

                    .save-btn {
                        width: 20%;
                        // height: 45px;
                        color: #FFF;
                        // background: rgb(165, 166, 167);
                        margin-left: 10px;
                        // border-radius: 20px;
                        font-size: 1.8vmin;
                        border-radius: 42px;
                        background: #424B70;
                    }
                }

            }

        }



        // .nav-button {
        //     display: flex;
        //     color: #666666;
        //     position: absolute;
        //     right: 10%
        // }

        // .head-top {
        //     display: flex;
        //     position: relative;
        //     width: 100%;
        //     height: 12vh;
        //     border-bottom-width: 2px;
        // }

        // .head-bottom {
        //     display: flex;
        //     position: relative;
        //     width: 100%;
        //     align-items: center;
        //     height: 12vh;
        //     border-bottom-width: 2px;
        // }

        // .profile-content {
        //     color: black;
        //     font-family: Inter;
        //     display: flex;
        //     align-items: center;
        //     position: absolute;
        //     right: 8px;
        //     font-size: 2.2vmin;
        //     margin-top: 14px;
        // }

    }

    #mob-gst-header {
        display: none;
    }

    .sync-report-popup {
        .ant-modal-title {
            font-size: 3vmin !important;
            text-align: center;
        }

        .report-wrap {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            text-align: end;
            margin-top: 9%;

            .report-title {
                width: 65%;
                display: flex;
                flex-direction: column;
                align-items: end;
            }

            h3 {
                line-height: 0.5;
            }

            .report-cnt {
                color: $secondary-color;
                width: 15%;
                display: flex;
                flex-direction: column;
                align-items: end;
                justify-content: center;
            }
        }
    }

    .drawer {

        display: none;

    }
}

@media only screen and (min-width:1025px) and (max-width:1440px) {
    #gst-header {


        .head-top {

            .logo_box {}

            .profile_box {


                .drop_options {

                    background: #FFF;


                    .optionss {


                        span {}

                        .refer-line {
                            font-size: 1.8vmin;
                        }

                        .clipboard-icon {
                            color: $secondary-color;
                            cursor: pointer;
                        }
                    }

                    .optionss:hover {
                        background: #e7e6e5 !important;
                        // span{
                        //     color: #fff !important;
                        // }

                    }
                }

                .profile-content {


                    b {}

                    img {}

                    .avatar {}

                }


            }
        }

        .head-bottom {

            .nav-button-box {
                justify-content: flex-end;
                width: 85%;
                margin-right: 0.5%;
                margin-left: 0.5%;
                font-size: 0.8vmin;

                .menu_btn {
                    width: 13% !important;
                    font-size: 1.2vmin !important;
                }


            }

            .nav-button-box1 {

                .menu_btn {
                    font-size: 1vmin !important;
                    margin-left: 0.5%;
                    margin-right: 0.5%;
                    width: 11% !important;

                    .imagecontainer {
                        display: none;
                    }
                }

                .menu_btn_active {}
            }

            .notice-left-container {


                .back-nav {
                    font-size: 0vmin;
                    width: 20%;
                    height: 80%;
                    justify-content: center;


                }

                .header {
                    width: 15%;
                    color: #232323;
                    font-family: Inter;
                    font-size: 3.5vmin;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                .view-container {
                    width: 85%;
                    background-color: rebeccapurple;
                    display: flex;

                    .dropdown-btn {
                        width: 45%;

                        .ant-radio-group {
                            display: flex;
                            flex-direction: column;
                        }

                        .ant-select-selector {
                            height: 45px;
                            align-items: center;
                        }
                    }

                    .save-btn {
                        width: 20%;
                        // height: 45px;
                        color: #FFF;
                        // background: rgb(165, 166, 167);
                        margin-left: 10px;
                        // border-radius: 20px;
                        font-size: 1.8vmin;
                        border-radius: 42px;
                        background: #424B70;
                    }
                }

            }

        }



        // .nav-button {
        //     display: flex;
        //     color: #666666;
        //     position: absolute;
        //     right: 10%
        // }

        // .head-top {
        //     display: flex;
        //     position: relative;
        //     width: 100%;
        //     height: 12vh;
        //     border-bottom-width: 2px;
        // }

        // .head-bottom {
        //     display: flex;
        //     position: relative;
        //     width: 100%;
        //     align-items: center;
        //     height: 12vh;
        //     border-bottom-width: 2px;
        // }

        // .profile-content {
        //     color: black;
        //     font-family: Inter;
        //     display: flex;
        //     align-items: center;
        //     position: absolute;
        //     right: 8px;
        //     font-size: 2.2vmin;
        //     margin-top: 14px;
        // }

    }

    #mob-gst-header {
        display: none;
    }

    .sync-report-popup {
        .ant-modal-title {
            font-size: 3vmin !important;
            text-align: center;
        }

        .report-wrap {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            text-align: end;
            margin-top: 9%;

            .report-title {
                width: 65%;
                display: flex;
                flex-direction: column;
                align-items: end;
            }

            h3 {
                line-height: 0.5;
            }

            .report-cnt {
                color: $secondary-color;
                width: 15%;
                display: flex;
                flex-direction: column;
                align-items: end;
                justify-content: center;
            }
        }
    }
}

@media only screen and (min-width:1441px) and (max-width:2560px) {
    #gst-header {


        .head-top {


            .logo_box {


                .official-site {}




            }

            .profile_box {

                .drop_options {
                    top: 9% !important;
                    background-color: #fff;

                    .optionss {


                        span {
                            font-size: 2vmin;


                            img {
                                height: 2vmin;
                            }
                        }


                    }


                }

                .profile-content {


                    b {}

                    img {}

                    .avatar {}

                }

                .sync-box {


                    .sync-btn {}

                    .Progres-btn {}
                }
            }
        }

        .head-bottom {


            .nav-button-box {
                justify-content: flex-end;


                .menu_btn {
                    color: var(--low-contrast-text, $font-secondary-color);
                    font-family: Roboto;
                    font-size: 1.5vmin;
                    font-weight: 400;
                    // width: 13% !important;
                    max-width: 14%;
                    width: auto !important;
                    text-transform: uppercase;
                    border: none;
                    margin-left: 1%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 5px;
                    height: 80%;
                    border-bottom-right-radius: 21px;
                    border-bottom-left-radius: 21px;
                    border-top-right-radius: 10px;
                    border-top-left-radius: 10px;
                    padding: 0 2.5%;

                    .imagecontainer {
                        display: none;
                    }
                }

                .menu_btn_active {
                    border: 0.631px solid rgba(0, 0, 0, 0.50) !important;
                    background: #424B70 !important;
                    color: #fff !important;
                    font-weight: 600 !important;
                }
            }

            .nav-button-box1 {
                justify-content: flex-end;

                .menu_btn {
                    font-size: 1.8vmin;
                    width: 15% !important;
                    gap: 10px;

                    .imagecontainer {
                        display: none;
                    }
                }


            }

            .notice-left-container {


                .back-nav {
                    font-size: 0vmin;
                    height: 80%;

                    .icon {
                        font-size: 2.3vmin;
                    }
                }





            }

        }




    }

    #mob-gst-header {
        display: none;
    }

}

@media only screen and (min-width:2561px) and (max-width:2740px) {
    #gst-header {


        .head-top {


            .logo_box {


                .official-site {
                    // width: 17%;

                }




            }

            .profile_box {


                .drop_options {
                    top: 10% !important;
                    background-color: #FFF;

                    .optionss {


                        span {


                            img {}
                        }

                        .refer-line {
                            font-size: 1.8vmin;
                        }

                        .clipboard-icon {
                            color: $secondary-color;
                            cursor: pointer;
                        }
                    }

                    .optionss:hover {
                        background: #e7e6e5 !important;


                    }
                }

                .profile-content {


                    b {}

                    img {

                        margin-top: -1%;
                        cursor: pointer;
                    }

                    .avatar {}

                }

                .sync-box {


                    .sync-btn {}

                    .Progres-btn {}
                }
            }
        }

        .head-bottom {


            .nav-button-box {


                .menu_btn {

                    font-size: 1.8vmin;
                    width: 15% !important;

                    gap: 10px;

                }


            }

            .nav-button-box1 {


                .menu_btn {
                    font-size: 2vmin;
                    width: 15% !important;
                    gap: 10px;

                }


            }

            .notice-left-container {


                .back-nav {

                    font-size: 0vmin;
                    width: 50%;
                    height: 80%;


                }



                .view-container {


                    .dropdown-btn {


                        .ant-radio-group {}

                        .ant-select-selector {}
                    }

                    .save-btn {}
                }

            }

        }




    }

    #mob-gst-header {
        display: none;
    }

    .sync-report-popup {
        .ant-modal-title {
            font-size: 3vmin !important;
            text-align: center;
        }

        .report-wrap {


            .report-title {}

            h3 {
                line-height: 0.5;
            }

            .report-cnt {}
        }
    }
}