// @import '../../../../index.scss';

.otp-verify-container {
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
    position: relative;
    width: 122%;
    top: 7%;
    left: -43%;
    top: 12%;

    .heading {
        margin-top: 0 !important;
    }

    .register {
        text-transform: uppercase;
        cursor: pointer;
    }

    .icons {
        display: flex;
        padding-top: 5%;
        margin: auto;

    }

    .input-group {
        padding: 5% 0 5% 0;
    }

    .forgot-link {
        display: flex;
        margin: auto;
        justify-content: center;
        text-align: center;
        // margin-top: 4.1%;
        width: 100%;
    }

    .otp-group {
        padding: 5% 0 5% 0;
        width: 79%;
        margin: auto;

        .otp-cover {
            width: 100%;
            max-width: 100% !important;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;

            .otp_input {
                height: 80%;
                width: 13%;
                padding: 0px !important;
            }
        }
    }
}

@media only screen and (min-width:320px) and (max-width:600px) {
    .otp-verify-container {
        left: 0;
        top: 0;
        width: 80%;
        padding-right: 3%;
        padding-left: 3%;

        .icons {
            height: 90px;
        }

        .otp-cover {
            .otp_input {
                width: 15% !important;
                height: 45px !important;
            }
        }
    }
}

@media only screen and (min-width:601px)and (max-width:768px) {

    .otp-verify-container {
        top: 29%;
        left: -43%;
        width: 135%;

        .icons {
            height: 85px;
        }
    }
}

@media only screen and (min-width:769px) and (max-width:1024px) {
    .otp-verify-container {
        width: 122%;
        left: -39%;
        top: 26%;

        .icons {
            height: 95px;
        }
    }
}