@import'../../App.scss';

.manage-gst-container {
    height: 100%;
    width: 100%;

    gap: 2%;
}

.syncview-icon {
    color: $secondary-color;
    cursor: pointer;
}

.manage-gst-header {

    height: 24vh;
    display: flex;
    justify-content: center;
}

.manage-gst-stats {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 38%;
}

.stat-item {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.stat-subs {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.stat-sub {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 75%
}

.stats-box {
    border: 1px solid #00000099;
    height: 90%;
    width: 18%;
    border-radius: 4%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}


:where(.css-dev-only-do-not-override-byeoj0).ant-modal .ant-modal-title {
    font-size: 25px;
    font-weight: 600;
    color: #031742;
}

#manage-gst-chart {
    border: 1px solid black;
    width: 40%;
    border-radius: 1%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.manage-gst-chart {
    width: 35%;
    height: 85%;
    margin-left: 3%;

}

.stats-box img {
    height: 35%
}

.chart-stat {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.dot {
    height: 12.5px;
    width: 12.5px;
    background-color: #bbb;
    border-radius: 50%;
    display: block;
    margin-right: 3%
}

.manage-gst-table {
    height: max-content;

    justify-content: center;
    padding: 2%;
}

h2 {
    color: black;
    font-family: Inter;
    font-weight: 400;
}

.stats-box p {
    font-family: Inter;
}

.right-container {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    margin-right: 1% !important;
    gap: 3%;
    align-items: center;
    height: 14vh;
}

#manage-gst-add-button {
    background: $primary-color;
    color: white;
    display: flex;
    align-items: center;
    gap: 2px;
    // height:100%;

    font-family: Roboto;
    font-size: 2vmin;
    font-weight: 400;
    padding: 3% 2%;
}

#manage-gst-solution-button {
    background: #ED7D31;
    font-size: 16px;
    color: white;
    gap: 5px;
    display: flex;
    align-items: center;

    font-family: Roboto;
    font-size: 2vmin;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 3% 2%;


}

.sync-all {
    background: #FCE7D9 !important;
    color: #ED7D31 !important;
}

#download-btn {
    background: #fff !important;
    color: #ED7D31;
    font-family: Inter;
    font-size: 2vmin;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    gap: 5px;
    display: flex;
    align-items: center;
    padding: 3% 2%;
}

.icon-btn {
    position: relative;
    right: 5%;
}

.count {
    font-weight: 600;

}

.manage-table-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
}

.manage-left-container {
    gap: 2%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 24%;
    width: 50%;
    // margin-left: 2%;

    .gst-head {
        color: var(--text-black, #393938);
        font-family: Roboto;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .gst-search,
    .ant-input {
        background: #F3F3F9 !important;
        border-radius: 20px;
        width: 120%;

    }

}

:where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-tbody>tr.ant-table-row-selected>td {
    background: #fae6d7;
    ;

}

:where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-tbody>tr.ant-table-row-selected:hover>td {
    background: #fae6d7;

}

:where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-thead>tr>th,
:where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-thead>tr>td {
    position: relative;
    color: rgba(0, 0, 0, 0.88);
    font-weight: 600;
    text-align: flex-start;
    background: #f3f3f3;
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.2s ease;
}

:where(.css-dev-only-do-not-override-byeoj0).ant-checkbox-checked .ant-checkbox-inner {
    background-color: #ED7D31;
    border-color: #ED7D31;
}

.ant-checkbox-inner:hover {
    background-color: red;
}

.ant-table-content {
    border: 0.5px solid rgba(0, 0, 0, 0.40);
    border-top: 0.5px solid rgba(0, 0, 0, 0.10)
}

.ant-pagination {
    display: flex;
    flex-direction: row;
    justify-content: center !important;
}

:where(.css-dev-only-do-not-override-190m0jy).ant-table-wrapper .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    width: 2px;
    background-color: #d7d7d7;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #ED7D31;
    border-color: #ED7D31;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
    background-color: #ED7D31;
    border-color: transparent;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
:where(.css-dev-only-do-not-override-190m0jy) .ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
    border-color: #ED7D31;
}

.ant-table-wrapper .ant-table-tbody>tr.ant-table-row-selected:hover>td {
    background: #FDF2EA;
}

.ant-table-wrapper .ant-table-tbody>tr.ant-table-row-selected>td {
    background: transparent;
}

.ant-pagination-item-active {
    background: $primary-color;
    border-radius: 2px;
}

.ant-pagination .ant-pagination-item-active a {
    color: #fff;
    background-color: $primary-color;
    border-radius: 2px;
}

.ant-pagination .ant-pagination-item-active:hover a {
    color: #FFF;
    background: $primary-color;
    border-radius: 2px;
}

.ant-pagination .ant-pagination-item-active,
.ant-pagination .ant-pagination-item-active:hover {
    border-color: $primary-color;
    border-radius: 2px;
}

.delete-gst {
    .ant-modal-title {
        font-size: 2.4vmin;
        text-align: center;
    }
}

.action-popup {
    .ant-modal-title {
        font-size: 3vmin !important;
        text-align: center;
    }

    .input-box {
        height: 3rem;
        border-radius: 4px;
        border: 0.5px solid rgba(0, 0, 0, 0.70);

        img {
            position: relative;
            top: 17%;
            left: 2%;
        }

        .eye_icon {
            position: absolute;
            color: #77787A;
            right: 10%;
            margin-top: 3%;
            cursor: pointer;
        }

        span {
            color: #E10000;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 142%;
            /* 19.88px */
            letter-spacing: 0.035px;
            padding-top: 1%;
            float: right !important;
        }
    }

    label {
        color: #000;
        font-family: Inter;
        font-size: 1.7vmin;
        font-weight: 400;
    }

    input[type="text"],
    input[type="password"] {
        border: 0;
        outline: none;
        width: 85%;
        position: relative;
        top: 4%;
        height: 90%;
        margin-left: 4%;
    }
}

.gstin-num {
    font-family: Inter;
    font-size: 2vmin;
    text-align: center;
    color: var(--text-black, #393938);
}

.del-header {
    font-family: Inter;
    color: var(--text-black, #393938);
    font-weight: 500;
    font-size: 2.6vmin;
}

// }



#admin-mail-container {
    border-radius: 4px;
    background: transparent;
    // box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
    position: relative;
    width: 100%;
    // top: 7%;
    // left: -43%;
    // top: 12%;

    .heading {
        margin-top: 0 !important;
    }

    .register {
        text-transform: uppercase;
        cursor: pointer;
    }

    .icons {
        display: flex;
        padding-top: 5%;
        margin: auto;

    }

    .input-group {
        padding: 5% 0 5% 0;
    }
}


#admin-syncmonitor-container {
    border-radius: 4px;
    background: transparent;
    // box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
    position: relative;
    width: 100%;
    // top: 7%;
    // left: -43%;

    // label {
    //     color: #000;
    //     font-family: Inter;
    //     font-size: 1.7vmin;
    //     font-weight: 600;
    // }
    }

.syncview-icon{
    color: $secondary-color !important;
}