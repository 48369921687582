@import '../../App.scss';


.ph-dashboard-container {
    height: 100%;
    width: 100%;

    .ph-dashboard-data-cover {
        height: 45vh;
        padding: 2%;
        padding-bottom: 0%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .ph-data-cards {
            width: 75%;
            height: 100%;
            border-radius: 15px;
            box-shadow: 0px 0px 17px 10px rgba(255, 255, 255, 0.8);
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            flex-wrap: wrap;

            .data-card-wrap {
                height: 16vh;
                width: 19%;
                background-color: #fff;
                border-radius: 13px;
                padding: 1%;
                padding-top: 0%;
                display: flex;
                justify-content: space-evenly;
                flex-direction: column;

                .value-icn-wrap {
                    display: flex;
                    flex-direction: row;

                    span {
                        font-size: 4vmin;
                        font-weight: bold;
                        margin-left: 10%;
                    }
                }
            }

            .theme1 {
                box-shadow: 0 4px 10px 0 rgba(204, 35, 104, 0.5);
                color: rgba(204, 35, 104, 0.5);
            }

            .theme2 {
                box-shadow: 0 4px 10px 0 rgba(32, 67, 138, 0.5);
                color: rgba(32, 67, 138, 0.5);
            }

            .theme3 {
                box-shadow: 0 4px 10px 0 rgba(4, 170, 78, 0.5);
                color: rgba(4, 170, 78, 0.5);
            }

            .theme4 {
                box-shadow: 0 4px 10px 0 rgba(213, 0, 0, 0.5);
                color: rgba(213, 0, 0, 0.5);
            }

            .theme5 {
                box-shadow: 0 4px 10px 0 rgba(253, 178, 60, 0.5);
                color: rgba(253, 178, 60, 0.5);
            }

            .theme6 {
                box-shadow: 0 4px 10px 0 rgba(94, 136, 255, 0.5);
                color: rgba(94, 136, 255, 0.5);
            }

            .theme7 {
                box-shadow: 0 4px 10px 0 rgba(95, 170, 188, 0.5);
                color: rgba(95, 170, 188, 0.5);
            }

            .theme8 {
                box-shadow: 0 4px 10px 0 rgba(237, 125, 49, 0.5);
                color: rgba(237, 125, 49, 0.5);
            }
        }

        .ph-chart-wrap {
            width: 20%;
            height: 100%;
            display: flex;
            flex-direction: column;

            .stats-box {
                box-shadow: 0px 0px 17px 10px rgba(255, 255, 255, 0.8);
                height: 45vh !important;
                height: auto;
                width: 100%;
                border-radius: 4%;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                border: none;

                .gst-chart {
                    width: 100%;
                    height: 55%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .chart {
                        width: 70% !important;
                    }
                }

                .chart-stat {
                    height: 35%;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;
                    align-items: center;

                    .stat-item {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        cursor: pointer;
                        width: 85%;
                        height: 4vh;
                        justify-content: space-between;

                        .stat-sub {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            width: 70%;


                            .dot {
                                height: 11px !important;
                                width: 11px !important;
                                background-color: #bbb;
                                border-radius: 50%;
                                display: block;
                                margin-right: 3%
                            }

                            p {
                                font-size: 1.9vmin !important;
                                line-height: 1.9vmin !important;
                            }
                        }


                        .stat-subs {
                            display: flex;
                            flex-direction: row;
                            width: 30%;

                            span {
                                font-weight: bold;
                            }

                            img {
                                padding-right: 0;
                            }
                        }
                    }
                }

            }

            .complaince-box {
                box-shadow: 0px 0px 17px 10px rgba(255, 255, 255, 0.8);
                height: 65vh !important;
                height: auto;
                width: 90%;
                border-radius: 4%;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                margin-top: 5%;
                padding-left: 5%;
                padding-right: 5%;
                padding-bottom: 5%;

                .header {
                    height: 13%;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .heading {
                        color: #666666;
                    }

                    .reload-img {
                        margin-left: auto;

                    }
                }

                .complaince-content-wrap {
                    height: 87%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    .complaince-content {
                        width: 48%;
                        height: 100%;
                        background-color: #666666;
                    }
                }
            }


        }
    }


    .action-popup {
        .fields {
            margin: 0 2% 0 2%;
            padding-bottom: 2%;
            width: 100%;

            .row {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;

                .input-group {
                    margin-top: 1rem;
                    display: flex;
                    flex-direction: column;
                    // padding-bottom: 1rem;

                    span {
                        float: left;
                    }

                    .register {
                        text-transform: uppercase;
                    }

                    label {
                        color: #000;
                        font-family: Inter;
                        font-size: 1.6vmin;
                        font-weight: 400;
                    }
                }
            }
        }
    }

}
@media only screen and (min-width:320px) and (max-width:600px) {
    .ph-dashboard-container {
     
    
        .ph-dashboard-data-cover {
            height: 60vh;
            flex-direction: column;
    
            .ph-data-cards {
                width: 100%;
                height: 50%;
                
    
                .data-card-wrap {
                    height: 40%;
                    width: 19%;
                    .data_label_text{
                        font-size: 2vmin;
                       
                    }
    
                    .value-icn-wrap {
                        display: flex;
                        height: 30%;
                       
                        flex-direction: row;
    
                        span {
                            font-size: 2vmin;
                            
                          
                        }
                    }
                }
    
               
            }
    
            .ph-chart-wrap {
                width: 90%;
               
                height: 40%;
                flex-direction: row;
    
                .stats-box {
                    height: 100% !important;
                    width: 100%;
                    flex-direction: row;
                    
    
                    .gst-chart {
                        width: 60%;
                        height: 100%;
                        
    
                        .chart {
                            width: 70% !important;
                        }
                    }
    
                    .chart-stat {
                        height: 100%;
                        width: 60%;
                      
    
                        .stat-item {
                            
    
                            .stat-sub {
                             
    
    
                                .dot {
                                   
                                }
    
                                p {
                                    font-size: 2.5vmin !important;
                                  
                                }
                            }
    
    
                            .stat-subs {
                                
    
                                span {
                                    font-size: 2.5vmin;
                                }
    
                                img {
                                }
                            }
                        }
                    }
    
                }
    
                
    
    
            }
        }
    }
}
@media only screen and (min-width:601px) and (max-width:768px) {
    .ph-dashboard-container {
       
    
        .ph-dashboard-data-cover {
            height: 60vh;
            flex-direction: column !important;
    
            .ph-data-cards {
                width: 100%;
                height: 50%;
               
    
                .data-card-wrap {
                    height: 40%;
                    width: 19%;
                  
                    padding: 1%;
                  
    
                    .data_label_text{
                        font-size: 2vmin;
                     
                    }
    
                    .value-icn-wrap {
                        height: 40%;
    
                        span {
                            font-size: 2.5vmin;
                         
                        }
                    }
                }
    
                
            }
    
            .ph-chart-wrap {
                width: 100%;
                height: 50%;
               margin-top: 2%;
    
                .stats-box {
                  
                    height: 100%;
                    width: 100%;
                  
                    flex-direction: row;
                    
    
                    .gst-chart {
                        width: 50%;
                        height: 60%;
                    
    
                        .chart {
                            width: 50% !important;
                        }
                    }
    
                    .chart-stat {
                        height: 70%;
                       
    
                        .stat-item {
                           
    
                            .stat-sub {
                               
    
    
                                .dot {
                                   
                                }
    
                                p {
                                    font-size: 2vmin !important;
                                }
                            }
    
    
                            .stat-subs {
                              
    
                                span {
                                    font-size: 2vmin;
                                    font-weight: bold;
                                }
    
                                img {
                                    padding-right: 0;
                                }
                            }
                        }
                    }
    
                }
    
    
    
            }
        }
    }
}
@media only screen and (min-width:769px) and (max-width:1025px) {
    .ph-dashboard-container {
       
    
        .ph-dashboard-data-cover {
           
    
            .ph-data-cards {
                width: 75%;
                height: 100%;
               
    
                .data-card-wrap {
                    height: 35%;
                    
    
                    .data_label_text{
                        font-size: 1.8vmin;
                       
                    }
    
                    .value-icn-wrap {
                       
                        height: 20%;
    
                        span {
                            font-size: 3vmin;
                         
                        }
                    }
                }
    
               
            }
    
            .ph-chart-wrap {
                width: 23%;
               
    
                .stats-box {
                   
    
                    .gst-chart {
                       
    
                        .chart {
                        }
                    }
    
                    .chart-stat {
                        
    
                        .stat-item {
                           
    
                            .stat-sub {
                               
    
    
                                .dot {
                                   
                                }
    
                                p {
                                    font-size: 1.6vmin !important;
                                }
                            }
    
    
                            .stat-subs {
                               
    
                                span {
                                    font-size: 1.6vmin;
                                    font-weight: bold;
                                }
    
                                img {
                                    padding-right: 0;
                                }
                            }
                        }
                    }
    
                }
    
    
    
            }
        }
    }
}
@media only screen and (min-width:1025px) and (max-width:1440px) {
    .ph-dashboard-container {
       
    
        .ph-dashboard-data-cover {
           
    
            .ph-data-cards {
               
    
                .data-card-wrap {
    
                    .data_label_text{
                        font-size: 2vmin;
                      
                    }
    
                    .value-icn-wrap {
                       
    
                        span {
                            font-size: 3.5vmin !important;
                         
                        }
                    }
                }
    
                
            }
    
            .ph-chart-wrap {
                width: 23%;
    
                .stats-box {
                  
    
                    .gst-chart {
                        
    
                        .chart {
                        }
                    }
    
                    .chart-stat {
                        
    
                        .stat-item {
                          
    
                            .stat-sub {
    
                                .dot {
                                 
                                }
    
                                p {
                                    font-size: 2vmin !important;
                                }
                            }
    
    
                            .stat-subs {
                               
    
                                span {
                                    font-size: 2vmin;
                              
                                }
    
                                img {
                                }
                            }
                        }
                    }
    
                }
    
    
    
            }
        }
    }
}
@media only screen and (min-width:1440px) {
    .ph-dashboard-container {
     
        .ph-dashboard-data-cover {
           
    
            .ph-data-cards {
              
    
                .data-card-wrap {
                   
    
                    .data_label_text{
                        font-size: 2.2vmin;
                       
                    }
    
                    .value-icn-wrap {
                     
                        height: 30%;
    
                        span {
                            font-size: 3.5vmin;
                           
                        }
                    }
                }
    
               
            }
    
            .ph-chart-wrap {
                width: 22%;
               
    
                .stats-box {
                   
    
                    .gst-chart {
                     
                        .chart {
                        }
                    }
    
                    .chart-stat {
                      
    
                        .stat-item {
                           
    
                            .stat-sub {
                               
    
    
                                .dot {
                                    
                                }
    
                                p {
                                    font-size: 2vmin !important;
                                }
                            }
    
    
                            .stat-subs {
                               
    
                                span {
                                    font-size: 2vmin;
                                }
    
                                img {
                                    padding-right: 0;
                                }
                            }
                        }
                    }
    
                }
    
    
    
            }
        }
    }
}