// @import '../../../../index.scss';

.mail-container {
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
    position: relative;
    width: 122%;
    top: 17%;
    left: -43%;
    top: 27%;
    height: 45vh;

    .heading {
        margin-top: 0 !important;
    }

    .register {
        text-transform: uppercase;
        cursor: pointer;
    }

    .icons {
        display: flex;
        padding-top: 5%;
        margin: auto;

    }

    .input-group {
        padding: 5% 0 5% 0;
    }
}

@media only screen and (min-width:320px) and (max-width:600px) {
    .mail-container {
        left: 0;
        top: 0;
        width: 80%;
        height: 28vh;
        padding-right: 3%;
        padding-left: 3%;
        padding-top: 3%;

        .icons {
            height: 90px;
        }


        .heading{
            margin-top: 3%;
            margin-bottom: 2%;
        }
    }
}

@media only screen and (min-width:601px)and (max-width:768px) {

    .mail-container {
        top: 29%;
        left: -43%;
        width: 135%;

        .icons {
            height: 85px;
        }
    }
}

@media only screen and (min-width:769px) and (max-width:1024px) {
    .mail-container {
        width: 122%;
        left: -39%;
        top: 26%;

        .icons {
            height: 95px;
        }
    }
}