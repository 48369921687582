@import '../../App.scss';

#assign_container {
    .MuiPaper-root {
        width: 27% !important;
        max-width: 100% !important;
        // max-height: none !important;
    }

    .MuiTypography-root {
        display: flex;
        justify-content: center;
        color: #000;
        font-family: Inter !important;
        font-size: 2.7vmin !important;
        font-style: normal;
        font-weight: 500 !important;
        padding-bottom: 1%;
        line-height: 168.023% !important;
        /* 50.407px */
    }

    .MuiDialogContent-root {
        border-top: 0 !important;
        padding-top: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: initial;

        // .dialog-content{
        .assign-form-wrap {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 32vh;


            .assign-select-container {
                height: 10vmin;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin: auto;

                .heading {
                    color: $primary-color;
                    font-weight: 400;
                    font-size: 1.7vmin;
                    font-family: 'Inter';
                }

                .dropdown-btn {
                    width: 90%;
                    margin: auto;

                    .ant-radio-group {
                        display: flex;
                        flex-direction: column;
                        z-index: 1;
                    }

                    .ant-select-selector {
                        height: 45px;
                        align-items: center;
                    }
                }

                .ant-select-dropdown-menu {
                    max-height: 200px;
                    /* Adjust the height as needed */
                }

                .save-btn {
                    width: 28%;
                    color: #FFF;
                    font-size: 1.8vmin;
                    border-radius: 42px;
                    background: #424B70;
                }
            }

            #z-common-gst-solution-button {
                font-size: 2.1vmin;
                font-family: Inter;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                justify-content: center;
                gap: 5px;
                display: flex;
                align-items: center;
                padding: 5% 2%;
                width: 40%;
                margin-bottom: 0%;
            }

            .theme2 {
                background-color: $secondary-color;
                color: #FFFFFF;
                border-color: none;
            }
        }

        // }
    }

}


.assign_btn {
    background-color: $secondary-color;
    color: #FFFFFF;
    border-color: none;

    &:hover {
        background-color: $secondary-color !important;
        color: #FFFFFF !important;
        border-color: none;
    }
}