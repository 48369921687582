@import '../../../App.scss';

.chat-body-html {

    ol {

        overflow-wrap: break-word !important;

        li {
            min-width: 110px !important;
            /* Adjust the max-width as needed */
            list-style-type: decimal !important;
            color: black;
        }

        /* Use 'disc' for filled dots */
    }

    ul {

        overflow-wrap: break-word !important;
        li {
            min-width: 110px !important;
            /* Adjust the max-width as needed */
            list-style-type: disc !important;
        }

        /* Use 'disc' for filled dots */
    }
}


.z-support-container {
    width: 99.5%;
    border-radius: 8px;
    height: auto;
    border-radius: 6px;
    position: relative;

    .z-support-body-wrap {
        width: 100%;
        display: flex;
        flex-direction: column;

        .support-body {
            display: flex;
            flex-direction: column;
            width: 100%;
            // height: 350px;
            padding: 1% 0;
            height: 100%;

            .chat-group-header {
                height: 5%;
                .invaliddatacontainer{
                    color: var(--text-black,#393938);
                    font-size: 2.5vmin;
                    font-weight: 500;
                }
            }
            .taskcontainer_text{
                text-align: center;
                margin-top: 5px;
                color: black;
                // color: var(--low-contrast-text,#666);
                font-family: inter;
                font-size: 2.5vmin;
                font-weight: 400;
              
            }
           

            .chat-body {
                width: 100%;
                height: 85%;
                // min-height: 200px;
                // max-height: 250px;
                overflow-y: auto;

                .chats {
                    display: flex;
                    color: black;
                    flex-direction: column;
                    // width: 100%;
                    // margin-top: 10%;
                    position: relative;
                    // height: 35px;

                    .chat-parent {
                        position: relative;
                        width: 100%;
                        display: flex;
                        border-bottom:1px dotted #424B70;
                        flex-direction: column;

                        .chat-child {
                            // position: absolute;
                            // height: 30px;
                            display: flex;
                            // align-items: center;
                            flex-direction: column;
                            // border-radius: 10px 0 10px 10px;
                            padding: 0.7%;
                            max-width: 45%;
                            min-width: 5%;
                            // word-break: break-word;
                            width: max-content !important;
                            
                            .html_style{
                                white-space: normal;
                                color: black;
                                font-weight: 600;
                                margin-left:  7%;
                                font-size: 2vmin;
                            }

             .chat_header{
                height: 8vh;
                width: 100%;
                display: flex;
                flex-direction: row;
            .chat_container{
                width: 6%;
                height: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .chat_image{
                    background-color: #ED7D31;
                    text-transform: uppercase;
                   
                    cursor: pointer;
                }
                .chat_image1{
                    background-color: #424B70;
                    text-transform: uppercase;
                    cursor: pointer;
                }
             
            }
            .chat_pro_Name_container{
                width: 90%;height: 100%;display: flex;justify-content: flex-start;align-items: center;

                .chat_proj_name{
                    font-weight: 600;
                    font-size: 2vmin;
                    color: #424B70;
                }
                                }
             }

                        }
                        .chat-time {
                            font-size: 1.5vmin !important;
                            text-align: left;
                            font-family: Inter;
                            margin-left: 7%;
                            color: darkblue;
                            margin-bottom: 1%;
                        }
                        .Chat_cont1{
                            right:0 ;
                            float: right;
                            margin-right: 50% !important;
                            min-width: 95%;
                           
                            max-width:auto;
                            background-color: transparent;
                            border-radius: 0;
                            color: #fff;
                            margin-bottom: 10px;


                                                        }
                           .Chat_cont2{
                                                            right:0 ;
                                                            float: right;
                                                            margin-right: 2%;
                                                            min-width: 95%;
                                                            max-width:auto;
                                                            background-color: transparent;
                                                            border-radius: 0;
                                                            color: #fff;
                                                            margin-bottom: 10px;
                                                    }

                        .attachment-span {
                            cursor: pointer;
                            max-width: 50%;
                            width: max-content;
                            padding: 0.2% 0.6%;
                            border-radius: 30px;
                            margin-bottom: 10px;
                            align-items: center;
                            background-color: #E7ECF6;
                            font-weight: 550;
                            font-family: 'Inter';
                            font-size: 1.5vmin;
                            display: flex;
                            // flex-direction: row;
                            // align-items: center;
                            // justify-content: center;
                            cursor: pointer;
                            

                            img {
                                margin-right: 5px;
                                height: 80%;
                            }
                        }
                        .chat_attachment_cont1{
                            right: 0;
                            float: right;
                            margin-left: 7%;
                            margin-right: 4%;
                        }
                        .chat_attachment_cont2{
                            right: 0;
                            float: right;
                            margin-left: 8%;
                            margin-right: 4%;
                        }
                    }
                }

            }

            .float-btn {
                position: absolute;
                right: 1%;
                bottom: 5%;
                background-color: #ECEDF1;


                .float-icon {
                    height: 50%;
                    width: 50%;
                }
            }

            .mobile-float-btn {
                display: none;
                position: absolute;
                right: 1%;
                bottom: 5%;
                background-color: #ECEDF1;


                .float-icon {
                    height: 50%;
                    width: 50%;
                }
            }

            .chat-input {
                width: 100%;
                justify-content: center;
                display: flex;
                height: 100px;
                // border: 1px solid var(--low-contrast-text, #666);
                // position: absolute;
                // bottom: 2%;

                .input-box {
                    width: 95%;
                    margin-left: 2.5%;
                    // border: 1px solid var(--low-contrast-text, #666);
                    border-radius: 91px;
                    border: 0.5px solid rgba(0, 0, 0, 0.50);
                    background: #ECEDF1;
                    display: flex;
                    flex-direction: row;
                    //     position: absolute;
                    // bottom: 4%;
                    // padding: 2%;

                    .input-field {
                        outline: none;
                        height: 90%;
                        width: 100%;
                        background: #ECEDF1;
                        border: 0;
                        margin-left: 1.5%;
                        font-size: 2vmin;
                        padding-left: 1%;
                    }

                    .action-icons {
                        position: relative;
                        left: -2.4%;
                        display: flex;
                        align-items: center;
                    }
                }


            }
        }

        .mail-icon-cover {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            width: 100%;

            .avatar-wrap {
                width: 5%;
                display: flex;
                justify-content: center;
                .avatar {
                    background-color: $secondary-color;
                    text-transform: uppercase;
                    cursor: pointer;
                }
            }

            .mail-window {
                height: 30vh;
                display: flex;
                width: 95%;
                flex-direction: column;
                border: 1px solid #DFDFDF;

                .texteditor {
                    height: 75%;
                    max-height: 85%;
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    .quill {
                        height: 99%;
                        max-height: 99%;
                    }

                    .ql-toolbar {
                        border-top: none;
                        border-right: none;
                        border-left: none;
                    }

                    .ql-container {
                        min-height: 83%;
                        max-height: 83% !important;
                        border-bottom: none;
                        border-right: none;
                        border-left: none;
                    }
                }

                .window-footer {
                    height: 25%;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    background-color: #ECEDF1;

                    .newwin-icon {
                        cursor: pointer;
                        transform: rotate(270deg);
                        margin-left: 1%;
                        background-color: $font-primay-color;
                        color: white;
                    }

                    .delete-icon {
                        margin-right: 1%;
                        cursor: pointer;
                        color: $font-primay-color;
                        font-size: 1.8vmin;
                    }

                    .attach-btn {
                        background-color: $font-primay-color;
                        cursor: pointer;
                        margin-left: auto;
                        margin-right: 1%;
                        height: 50%;
                        width: 40px;
                        z-index: 1;

                        .pin-img {
                            color: white;
                            height: 55%;
                        }
                    }


                    .send-btn {
                        height: 75%;
                        margin-right: 1%;
                        cursor: pointer;
                        background-color: $secondary-color;
                        width: 40px;
                        z-index: 1;

                        .send-img {
                            color: white;
                            width: 45%;
                        }
                    }
                }
            }
        }
    }


}



@media only screen and (min-width:320px) and (max-width:600px) {
    .chat-body-html {

        ol {
    
            overflow-wrap: break-word !important;
    
            li {
                min-width: 110px !important;
                /* Adjust the max-width as needed */
                list-style-type: decimal !important;
            }
    
            /* Use 'disc' for filled dots */
        }
    
        ul {
    
            overflow-wrap: break-word !important;
            li {
                min-width: 110px !important;
                /* Adjust the max-width as needed */
                list-style-type: disc !important;
            }
    
            /* Use 'disc' for filled dots */
        }
    }
    .z-support-container {
      
    
        .z-support-body-wrap {
          
    
            .support-body {
                
    
                .chat-group-header {
                    .invaliddatacontainer{
                        font-size: 3vmin;
                    }
                }
                .taskcontainer_text{
                    
                    font-size: 3vmin;
                  
                }
    
                .chat-body {
                   
    
                    .chats {
                       
    
                        .chat-parent {
                           
    
                            .chat-child {
                              
                                
                                .html_style{
                                    margin-left: 18%;
                                    font-size: 3vmin;
                                }
    
                 .chat_header{
                    height: 6vh;
                   
                .chat_container{
                    width: 20%;
                    justify-content: center;
                    .chat_image{
                        font-size: 3.5vmin;
                        height: 75%;
                        width: 60%;
                       
                    }
                    .chat_image1{
                        font-size: 3.5vmin;
                        height: 70%;
                        width: 70%;
                    }
                 
                }
                .chat_pro_Name_container{

    
                    .chat_proj_name{
                        font-weight: 600;
                        font-size: 3vmin;
                        color: #424B70;
                    }
                                    }
                 }
    
                            }
                            .chat-time {
                                font-size: 2.5vmin !important;
                                text-align: left;
                                font-family: Inter;
                                margin-left: 15%;
                                color: darkblue;
                                margin-bottom: 1%;
                            }
                            .Chat_cont1{
                                right:0 ;
                                float: right;
                                margin-right: 50% !important;
                                min-width: 95%;
                               
                                max-width:auto;
                                background-color: transparent;
                                border-radius: 0;
                                color: #fff;
                                margin-bottom: 10px;
    
    
                                                            }
                               .Chat_cont2{
                                                                right:0 ;
                                                                float: right;
                                                                margin-right: 2%;
                                                                min-width: 95%;
                                                                max-width:auto;
                                                                background-color: transparent;
                                                                border-radius: 0;
                                                                color: #fff;
                                                                margin-bottom: 10px;
                                                        }
    
                            .attachment-span {
                                cursor: pointer;
                                max-width: 90%;
                                width: auto;
                                padding: 0.2% 3.6%;
                                font-weight: 500;
                               
                                font-size: 2.5vmin;
                                
    
                                img {
                                    margin-right: 5px;
                                    height: 80%;
                                }
                            }
                            .chat_attachment_cont1{
                                right: 0;
                                float: right;
                                margin-left: 18%;
                                margin-right: 4%;
                            }
                            .chat_attachment_cont2{
                                right: 0;
                                float: right;
                                margin-left: 8%;
                                margin-right: 4%;
                            }
                        }
                    }
    
                }
    
                .float-btn {
                    position: absolute;
                    right: 1%;
                    bottom: 5%;
                    background-color: #ECEDF1;
    
    
                    .float-icon {
                        height: 50%;
                        width: 50%;
                    }
                }
    
                .mobile-float-btn {
                    display: none;
                    position: absolute;
                    right: 1%;
                    bottom: 5%;
                    background-color: #ECEDF1;
    
    
                    .float-icon {
                        height: 50%;
                        width: 50%;
                    }
                }
    
                .chat-input {
                    width: 100%;
                    justify-content: center;
                    display: flex;
                    height: 100px;
                    // border: 1px solid var(--low-contrast-text, #666);
                    // position: absolute;
                    // bottom: 2%;
    
                    .input-box {
                        width: 95%;
                        margin-left: 2.5%;
                        // border: 1px solid var(--low-contrast-text, #666);
                        border-radius: 91px;
                        border: 0.5px solid rgba(0, 0, 0, 0.50);
                        background: #ECEDF1;
                        display: flex;
                        flex-direction: row;
                        //     position: absolute;
                        // bottom: 4%;
                        // padding: 2%;
    
                        .input-field {
                            outline: none;
                            height: 90%;
                            width: 100%;
                            background: #ECEDF1;
                            border: 0;
                            margin-left: 1.5%;
                            font-size: 2vmin;
                            padding-left: 1%;
                        }
    
                        .action-icons {
                            position: relative;
                            left: -2.4%;
                            display: flex;
                            align-items: center;
                        }
                    }
    
    
                }
            }
    
            .mail-icon-cover {
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                width: 100%;
    
                .avatar-wrap {
                    width: 5%;
                    display: flex;
                    justify-content: center;
                    .avatar {
                        background-color: $secondary-color;
                        text-transform: uppercase;
                        cursor: pointer;
                    }
                }
    
                .mail-window {
                    height: 30vh;
                    display: flex;
                    width: 71%;
                    flex-direction: column;
                    border: 1px solid #DFDFDF;
    
                    .texteditor {
                        height: 75%;
                        max-height: 85%;
                        display: flex;
                        flex-direction: column;
                        width: 100%;
    
                        .quill {
                            height: 99%;
                            max-height: 99%;
                        }
    
                        .ql-toolbar {
                            border-top: none;
                            border-right: none;
                            border-left: none;
                        }
    
                        .ql-container {
                            min-height: 83%;
                            max-height: 83% !important;
                            border-bottom: none;
                            border-right: none;
                            border-left: none;
                        }
                    }
    
                    .window-footer {
                        height: 25%;
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        background-color: #ECEDF1;
    
                        .newwin-icon {
                            cursor: pointer;
                            transform: rotate(270deg);
                            margin-left: 1%;
                            background-color: $font-primay-color;
                            color: white;
                        }
    
                        .delete-icon {
                            margin-right: 1%;
                            cursor: pointer;
                            color: $font-primay-color;
                            font-size: 1.8vmin;
                        }
    
                        .attach-btn {
                            background-color: $font-primay-color;
                            cursor: pointer;
                            margin-left: auto;
                            margin-right: 1%;
                            height: 50%;
                            width: 40px;
    
                            .pin-img {
                                color: white;
                                height: 55%;
                            }
                        }
    
    
                        .send-btn {
                            height: 75%;
                            margin-right: 1%;
                            cursor: pointer;
                            background-color: $secondary-color;
                            width: 40px;
    
                            .send-img {
                                color: white;
                                width: 45%;
                            }
                        }
                    }
                }
            }
        }
    
    
    }
}

@media only screen and (min-width:601px) and (max-width:768px) {
    .chat-body-html {

        ol {
    
            overflow-wrap: break-word !important;
    
            li {
                min-width: 110px !important;
                /* Adjust the max-width as needed */
                list-style-type: decimal !important;
                color: black;
            }
    
            /* Use 'disc' for filled dots */
        }
    
        ul {
    
            overflow-wrap: break-word !important;
            li {
                min-width: 110px !important;
                /* Adjust the max-width as needed */
                list-style-type: disc !important;
            }
    
            /* Use 'disc' for filled dots */
        }
    }
    
    
    .z-support-container {
       
    
        .z-support-body-wrap {
            
    
            .support-body {
              
    
                .chat-group-header {
                    height: 5%;
                    .invaliddatacontainer{
                        font-size: 2.5vmin;
                    }
                }
                .taskcontainer_text{
                    font-size: 2.5vmin;
                  
                }
    
                .chat-body {
                  
    
                    .chats {
                       
                        // height: 35px;
    
                        .chat-parent {
                          
    
                            .chat-child {
                                
                                
                                .html_style{
                                    
                                    margin-left:  10%;
                                    font-size: 2.2vmin;
                                }
    
                 .chat_header{
                  
                .chat_container{
                    width: 10%;
                   
                    .chat_image{
                       
                        font-size: 2.2vmin;
                       
                    }
                    .chat_image1{
                       
                       font-size: 2.2vmin;
                      
                    }
                 
                }
                .chat_pro_Name_container{

    
                    .chat_proj_name{
                        font-size: 2.2vmin;
                    }
                                    }
                 }
    
                            }
                            .chat-time {
                                font-size: 1.8vmin !important;
                               
                            }
                            .Chat_cont1{
                               
    
    
                                                            }
                               .Chat_cont2{
                                                                
                                                        }
    
                            .attachment-span {
                                cursor: pointer;
                                margin-left: 10% !important;
                                font-size: 1.5vmin;
                               
                                
    
                                img {
                                 

                                }
                            }
                            .chat_attachment_cont1{
                              
                            }
                            .chat_attachment_cont2{
                               
                            }
                        }
                    }
    
                }
    
                .float-btn {
                    position: absolute;
                    right: 1%;
                    bottom: 5%;
                    background-color: #ECEDF1;
    
    
                    .float-icon {
                        height: 50%;
                        width: 50%;
                    }
                }
    
                .mobile-float-btn {
                    display: none;
                    position: absolute;
                    right: 1%;
                    bottom: 5%;
                    background-color: #ECEDF1;
    
    
                    .float-icon {
                        height: 50%;
                        width: 50%;
                    }
                }
    
               
            }
    
            .mail-icon-cover {
              
    
                .avatar-wrap {
          
                    .avatar {
                        background-color: $secondary-color;
                        text-transform: uppercase;
                        cursor: pointer;
                    }
                }
    
                .mail-window {
                    width: 90%;
                    
    
                    .texteditor {
                       
    
                        .quill {
                          
                        }
    
                        .ql-toolbar {
                            border-top: none;
                            border-right: none;
                            border-left: none;
                        }
    
                        .ql-container {
                           
                        }
                    }
    
                    .window-footer {
                       
    
                        .newwin-icon {
                           
                        }
    
                        .delete-icon {
                           
                        }
    
                        .attach-btn {
                          
    
                            .pin-img {
                                
                            }
                        }
    
    
                        .send-btn {
                           
                            .send-img {
                               
                            }
                        }
                    }
                }
            }
        }
    
    
    }
    
  
}

@media only screen and (min-width:769px) and (max-width:1440px) {
    .chat-body-html {

        ol {
    
            overflow-wrap: break-word !important;
    
            li {
                min-width: 110px !important;
                /* Adjust the max-width as needed */
                list-style-type: decimal !important;
                color: black;
            }
    
            /* Use 'disc' for filled dots */
        }
    
        ul {
    
            overflow-wrap: break-word !important;
            li {
                min-width: 110px !important;
                /* Adjust the max-width as needed */
                list-style-type: disc !important;
            }
    
            /* Use 'disc' for filled dots */
        }
    }
    
    
    .z-support-container {
      
    
        .z-support-body-wrap {
          
    
            .support-body {

             
    
                .chat-group-header {
                  
                    .invaliddatacontainer{
                       
                    }
                }
                .taskcontainer_text{
                   
                  
                }
               
    
                .chat-body {
                   
    
                    .chats {
                       
    
                        .chat-parent {
                           
    
                            .chat-child {
                               
                                .html_style{
                                    
                                    margin-left:  11%;
                                }
    
                 .chat_header{
                    
                .chat_container{
                   width: 8%;
                    .chat_image{
                        
                       
                    }
                    .chat_image1{
                       
                    }
                 
                }
                .chat_pro_Name_container{

    
                    .chat_proj_name{
                     
                    }
                                    }
                 }
    
                            }
                            .chat-time {
                             
                            }
                            .Chat_cont1{
                               
    
    
                                                            }
                               .Chat_cont2{
                                                   
                                                        }
    
                            .attachment-span {
                                
                                
    
                                img {
                                 
                                }
                            }
                            .chat_attachment_cont1{
                                margin-left: 12%;
                            }
                            .chat_attachment_cont2{
                               
                                margin-left: 12%;
                            }
                        }
                    }
    
                }
    
                .float-btn {
                 
    
    
                    .float-icon {
                      
                    }
                }
    
                .mobile-float-btn {
                    display: none;
                    position: absolute;
                    right: 1%;
                    bottom: 5%;
                    background-color: #ECEDF1;
    
    
                    .float-icon {
                        height: 50%;
                        width: 50%;
                    }
                }
    
             
            }
    
            .mail-icon-cover {
               
    
                .avatar-wrap {
                 
                    .avatar {
                      
                    }
                }
    
                .mail-window {
                 
                    width: 85%;
                   
    
                    .texteditor {
                        
    
                        .quill {
                         
                        }
    
                        .ql-toolbar {
                           
                        }
    
                        .ql-container {
                           
                        }
                    }
    
                    .window-footer {
                    
    
                        .newwin-icon {
                           
                        }
    
                        .delete-icon {
                            
                        }
    
                        .attach-btn {
                           
    
                            .pin-img {
                                
                            }
                        }
    
    
                        .send-btn {
                         
    
                            .send-img {
                                
                            }
                        }
                    }
                }
            }
        }
    
    
    }
}

@media only screen and (min-width:1441px) {
    .support-container {
        margin-top: 4% !important;

        .support-body-wrap {
            .support-body {
                .chat-body {
                    .chats {
                        .chat-parent {
                            .attachment-span {
                                padding: 0.5% 1%;
                            }

                            .chat-child {
                                font-size: 2vmin;
                            }
                        }
                    }
                }

                .chat-input {
                    .input-box {
                        height: 6vh !important;

                        .input-field {
                            height: 91% !important;
                        }
                    }
                }
            }
        }
    }
}