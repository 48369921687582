@import '../../App.scss';


#overview-details-container {

    height: 90vh;
    width: 99.6vw;
    display: flex;
    flex-direction: row;

    .overview-menu-wrap {
        max-height: 100%;
        height: auto;
        width: 20%;
        display: flex;
        flex-direction: column;
        align-items: end;
        background-color: #fff;

        .list_wrap {
            margin-top: 2vh;
            height: 87vh;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            .search_cover {
                display: flex;
                flex-direction: row;
                width: 100%;
                height: 10%;
                justify-content: space-evenly;
                position: relative;
                top: 1%;

                .z-table_global_search {
                    border-radius: 20px;
                    width: 65% !important;
                    height: 40px !important;
                    background: rgb(243, 243, 249);

                    input {
                        background: rgb(243, 243, 249);
                        font-family: Inter;
                    }
                }

                .gst_add_btn {
                    background-color: $primary-color;
                    padding: 0%;
                    margin: 0%;
                    height: 4vh;
                    width: 2.2vw;

                    img {
                        height: 3vh;
                    }
                }
            }

            .list_item_wrap {
                height: 90%;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                overflow: auto;

                .list_item {
                    width: 90%;
                    min-height: 15vh;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    box-shadow: 7px 5px 10px 5px #00000012;
                    background-color: #fff;
                    border-radius: 6px;
                    color: $primary-color;
                    cursor: pointer;
                    margin-bottom: 7%;
                    border-bottom: 1px solid $primary-color;

                    .type_cover {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: end;
                        margin:2% 5% 0 0;

                        .type_wrap {
                            height: 2.7vh;
                            width: auto;
                            padding: 0% 3%;
                            // margin-left: 3% !important;
                            min-width: 20%;
                            background-color: $secondary-color;
                            color: #fff;
                            border-radius: 10px;
                            font-size: 1.4vmin;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }

                    .head_wrap {
                        height: 50%;
                        width: 90%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;

                        .gst_value {
                            font-weight: bold;
                            font-size: 2.1vmin;
                        }

                        .gst_sync_btn {
                            background-color: $primary-color;
                            padding: 0%;
                            margin: 0%;
                            height: 2.9vh;
                            width: 1.6vw;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;

                            .edit_icn {
                                height: 2.2vh;
                                color: #fff;
                            }
                        }
                    }

                    .content_wrap {
                        height: 50%;
                        width: 90%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding-bottom: 5%;

                        .name_value {
                            font-size: 2.1vmin;
                        }

                        .gst_sync_btn {
                            background-color: $secondary-color;
                            padding: 0%;
                            margin: 0%;
                            height: 3vh;
                            width: 1.6vw;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;

                            .delete_icn {
                                height: 2.2vh;
                                color: #fff;
                            }
                        }
                    }

                    &:hover {
                        color: $secondary-color;
                    }
                }

                .mark-border {
                    background-color: #FFF7F3;
                    border-left: 5px solid $primary-color;

                    .content_wrap {
                        .name_value {
                            color: $secondary-color;
                        }
                    }
                }
            }

            .item_no {
                color: $primary-color;
                background-color: #ffff;
                font-size: 2vmin;
                font-weight: bold;
                cursor: pointer;
                padding: 3% 7%;
                border-radius: 5px;
                margin-bottom: 4%;

                &:hover {
                    color: $secondary-color;
                }
            }
        }

        .overview_menu {
            width: 90%;
            height: 5vh;
            border-radius: 7px;
            border: 1px solid $primary-color;
            background-color: #FFF;
            margin-bottom: 1vh;
            cursor: pointer;
            color: $primary-color;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
        }

        .active {
            color: #fff;
            background-color: $primary-color;
            transition: background 0.4s ease;
        }

        .stats-box {
            box-shadow: 0px 0px 17px 10px rgba(255, 255, 255, 0.8);
            height: 37.5vh !important;
            height: auto;
            width: 90%;
            border-radius: 4%;
            display: flex;
            flex-direction: column;
            border: none;
            justify-content: space-evenly;
            margin-top: 2vh;

            .gst-chart {
                width: 100%;
                height: 55%;
                display: flex;
                align-items: center;
                justify-content: center;

                .chart {
                    width: 70% !important;
                }
            }

            .chart-stat {
                height: 35%;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: center;

                .stat-item {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    cursor: pointer;
                    width: 85%;
                    height: 4vh;
                    justify-content: space-between;

                    .stat-sub {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        width: 70%;


                        .dot {
                            height: 11px !important;
                            width: 11px !important;
                            background-color: #bbb;
                            border-radius: 50%;
                            display: block;
                            margin-right: 3%
                        }

                        p {
                            font-size: 1.7vmin !important;
                            line-height: 1.7vmin !important;
                        }
                    }


                    .stat-subs {
                        display: flex;
                        flex-direction: row;
                        width: 30%;

                        span {
                            font-weight: bold;
                            font-size: 1.8vmin;
                        }

                        img {
                            padding-right: 0;
                        }
                    }
                }
            }

        }


    }

    .transation_container {
        display: none;
    }

    .transation_container1 {
        display: none;
    }

    .overview-upper-container {
        padding: 2%;
        padding-top: 0%;
        padding-left: 0%;
        padding-right: 1%;
        padding-bottom: 0%;
        width: 80%;
        overflow: auto;

        .overview-notice-main {

            .overview_menu_wrap {
                height: 6vh;
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: end;
                justify-content: flex-end;

                .overview_menu {
                    width: 12%;
                    height: 4.5vh;
                    border-radius: 7px;
                    border-bottom-right-radius: 0px;
                    border-bottom-left-radius: 0px;
                    margin-left: 1%;
                    // border: 1px solid $primary-color;
                    // background-color: #FFF;
                    cursor: pointer;
                    color: $primary-color;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: bold;
                    color: $primary-color;

                    span {
                        font-size: 1.6vmin;
                        text-transform: uppercase;
                    }
                }

                .active {
                    color: $secondary-color;
                    background-color: #fff;
                    transition: background 0.4s ease;
                }
            }

            .overview-notice-header-wrap {
                height: 10vh;
                width: 98%;
                background-color: #FFF;
                margin: 2%;
                margin-bottom: 0%;
                margin-top: 1%;
                border-radius: 16px;
                display: flex;
                flex-direction: row;
                align-items: center;

                .overview-content-group {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-evenly;
                    width: max-content;
                    height: 100%;

                    .overview-main-line {
                        font-size: 2.2vmin;
                        line-height: 3.5vmin !important;
                        font-size: bold;
                        color: #424B70;
                        font-family: 'Inter';
                        margin: 0;
                    }

                    .overview-sub-line {
                        font-size: 2.2vmin;
                        line-height: 1.8vmin;
                        font-family: 'Inter';
                        margin: 0;
                    }
                }

                .view-icon {
                    color: $primary-color;
                    cursor: pointer;
                }

                .tag_wrap {
                    height: 60%;
                    width: auto;
                    padding: 0% 2%;
                    margin-left: 2%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 10px;
                    border: 1px solid #0d497f;
                    background: #a3c9eb;
                    color: #0d497f;
                    font-weight: bold;
                    font-size: 2.1vmin;
                }
            }

            .z-table {
                padding-top: 0% !important;
                width: 98%;
                // padding-right: 0%;
            }

        }

    }

    .mobile_view_design {
        display: none;
    }

}

@media only screen and (min-width:320px) and (max-width:600px) {
    #overview-details-container {
        height: 91vh;

        flex-direction: column;

        .overview-menu-wrap {
            max-height: 50%;
            height: 40%;
            width: 100%;
            display: none;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;

            .over_view_container {
                height: 100%;
                width: 50%;
                justify-content: space-evenly;
                align-items: center;
                display: flex;
                flex-direction: column;

                // flex-wrap: wrap;
                .overview_menu {
                    width: 70% !important;
                    height: 15%;

                    font-size: 2.5vmin;
                }

                .active {}
            }

            .overview_chart_container {
                height: 100%;
                width: 50%;
                justify-content: center;
                align-items: center;
                display: flex;

                .stats-box {
                    box-shadow: 0px 0px 17px 10px rgba(255, 255, 255, 0.8);
                    height: 95% !important;
                    width: 90%;
                    border-radius: 4%;
                    display: flex;
                    flex-direction: column;
                    border: none;
                    justify-content: flex-start;
                    margin-top: 0vh;

                    .gst-chart {
                        width: 100%;
                        height: 50%;


                        .chart {
                            width: 70% !important;
                        }
                    }

                    .chart-stat {
                        height: 45%;
                        width: 100%;


                        .stat-item {


                            .stat-sub {



                                .dot {}

                                p {
                                    font-size: 2vmin !important;
                                }
                            }


                            .stat-subs {


                                span {

                                    font-size: 2vmin;
                                }


                            }
                        }
                    }

                }
            }







        }

        .transation_container {
            height: 5%;
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-right: 2%;
            // transition: opacity 50s ease, transform 50s ease;

        }

        .transation_container1 {
            height: 30%;
            width: 100%;
            display: flex;
            // transition: opacity 50s ease, transform 50s ease;
            flex-direction: column;
            padding-right: 2%;

            // opacity: 0;
            // transform: translatex(0%);
            .arrow_container {
                height: 15%;
                width: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding-right: 2%;
            }

            .chart_cont {
                height: 85%;
                width: 100%;
                justify-content: center;
                align-items: center;
                display: flex;

                .stats-box {
                    box-shadow: 0px 0px 17px 10px rgba(255, 255, 255, 0.8);
                    height: 95% !important;
                    width: 90%;
                    border-radius: 4%;
                    display: flex;
                    flex-direction: row;
                    border: none;
                    justify-content: flex-start;
                    margin-top: 0vh;

                    .gst-chart {
                        width: 40%;
                        height: 100%;
                        justify-content: center;
                        align-items: center;
                        display: flex;


                        .chart {
                            width: 60% !important;
                        }
                    }

                    .chart-stat {
                        height: 100%;
                        width: 60%;
                        justify-content: center;
                        align-items: center;
                        display: flex;


                        .stat-item {
                            width: 100%;

                            .stat-sub {



                                .dot {}

                                p {
                                    font-size: 2.5vmin !important;
                                }
                            }


                            .stat-subs {


                                span {

                                    font-size: 2.5vmin;
                                }


                            }
                        }
                    }

                }
            }
        }

        .overview-upper-container {
            padding: 0%;
            padding-top: 0%;
            padding-left: 0%;
            padding-right: 0%;
            padding-bottom: 0%;
            width: 100vw;
            height: 80vh;
            padding-top: 3%;
            overflow: auto;

            .overview-notice-main {
                justify-content: center;
                align-items: center;
                display: flex;
                width: 100%;

                flex-direction: column;

                .overview-notice-header-wrap {
                    margin: 0%;
                    margin-bottom: 0%;
                    width: 94%;
                    height: 7vh;
                    border-radius: 5px;
                    justify-content: space-between;

                    .overview-content-group {


                        .overview-main-line {}

                        .overview-sub-line {}
                    }

                    .view-icon {
                        color: $primary-color;
                        cursor: pointer;
                    }

                    .tag_wrap {
                        height: 50%;
                        width: auto;
                        margin-right: 2%;
                        padding: 0% 2%;
                        margin-left: 2%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 10px;
                        border: 1px solid #0d497f;
                        background: #a3c9eb;
                        color: #0d497f;
                        font-weight: bold;
                        font-size: 2.1vmin;
                    }
                }

                .z-table {
                    padding-top: 0% !important;
                    width: 98%;
                    // padding-right: 0%;
                }

            }

        }

        .mobile_view_design {
            width: 100%;
            height: 11vh;

            display: flex;
            // flex-wrap: wrap;
            flex-direction: column;
            // border-top: 1px solid  $primary-color;
            // justify-content: center !important;
            // align-items: center !important;

            .submenu_container {
                height: 51%;
                width: 100%;
                display: flex;
                flex-direction: row;

                .mob_overview_menu {
                    width: 33.2%;

                    height: 100%;
                    border-radius: 0px;
                    border-top: 1px solid $primary-color ;
                    border-bottom: 1px solid $primary-color ;
                    border-right: 1px solid $primary-color;
                    background-color: transparent;
                    margin-bottom: 1vh;
                    font-size: 2vmin;
                    cursor: pointer;
                    color: $primary-color;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: bold;
                }

                .active {
                    color: #fff;
                    background-color: $primary-color;
                    transition: background 0.4s ease;
                }
            }

            .submenu_container2 {
                height: 49%;
                width: 100%;
                display: flex;
                flex-direction: row;

                .mob_overview_menu {
                    width: 50%;

                    height: 100%;
                    border-radius: 0px;
                    // border-top:1px solid $primary-color ;
                    // border-bottom:1px solid $primary-color  ;
                    border-right: 1px solid $primary-color;
                    background-color: transparent;
                    margin-bottom: 1vh;
                    font-size: 2vmin;
                    cursor: pointer;
                    color: $primary-color;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: bold;
                }

                .active {
                    color: #fff;
                    background-color: $primary-color;
                    transition: background 0.4s ease;
                }
            }

        }

    }
}

@media only screen and (min-width:601px) and (max-width:768px) {
    #overview-details-container {
        height: 91vh;

        flex-direction: column;

        .overview-menu-wrap {
            max-height: 50%;
            height: 40%;
            width: 100%;
            display: none;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;

            .over_view_container {
                height: 100%;
                width: 50%;
                justify-content: space-evenly;
                align-items: center;
                display: flex;
                flex-direction: column;

                // flex-wrap: wrap;
                .overview_menu {
                    width: 70% !important;
                    height: 15%;

                    font-size: 2.5vmin;
                }

                .active {}
            }

            .overview_chart_container {
                height: 100%;
                width: 50%;
                justify-content: center;
                align-items: center;
                display: flex;

                .stats-box {
                    box-shadow: 0px 0px 17px 10px rgba(255, 255, 255, 0.8);
                    height: 95% !important;
                    width: 90%;
                    border-radius: 4%;
                    display: flex;
                    flex-direction: column;
                    border: none;
                    justify-content: flex-start;
                    margin-top: 0vh;

                    .gst-chart {
                        width: 100%;
                        height: 50%;


                        .chart {
                            width: 70% !important;
                        }
                    }

                    .chart-stat {
                        height: 45%;
                        width: 100%;


                        .stat-item {


                            .stat-sub {



                                .dot {}

                                p {
                                    font-size: 2vmin !important;
                                }
                            }


                            .stat-subs {


                                span {

                                    font-size: 2vmin;
                                }


                            }
                        }
                    }

                }
            }







        }

        .transation_container {
            height: 5%;
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-right: 2%;
            transition: opacity 50s ease, transform 50s ease;

        }

        .transation_container1 {
            height: 30%;
            width: 100%;
            display: flex;
            transition: opacity 50s ease, transform 50s ease;
            flex-direction: column;
            padding-right: 2%;
            // opacity: 0;
            transform: translatex(0%);

            .arrow_container {
                height: 15%;
                width: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding-right: 2%;
            }

            .chart_cont {
                height: 85%;
                width: 100%;
                justify-content: center;
                align-items: center;
                display: flex;

                .stats-box {
                    box-shadow: 0px 0px 17px 10px rgba(255, 255, 255, 0.8);
                    height: 95% !important;
                    width: 90%;
                    border-radius: 4%;
                    display: flex;
                    flex-direction: row;
                    border: none;
                    justify-content: flex-start;
                    margin-top: 0vh;

                    .gst-chart {
                        width: 40%;
                        height: 100%;
                        justify-content: center;
                        align-items: center;
                        display: flex;


                        .chart {
                            width: 60% !important;
                        }
                    }

                    .chart-stat {
                        height: 100%;
                        width: 60%;
                        justify-content: center;
                        align-items: center;
                        display: flex;


                        .stat-item {
                            width: 100%;

                            .stat-sub {



                                .dot {}

                                p {
                                    font-size: 2vmin !important;
                                }
                            }


                            .stat-subs {


                                span {

                                    font-size: 2vmin;
                                }


                            }
                        }
                    }

                }
            }
        }

        .overview-upper-container {
            padding: 0%;
            padding-top: 0%;
            padding-left: 0%;
            padding-right: 0%;
            padding-bottom: 0%;
            width: 100vw;
            height: 80vh;
            padding-top: 3%;
            overflow: auto;

            .overview-notice-main {
                justify-content: center;
                align-items: center;
                display: flex;
                width: 100%;

                flex-direction: column;

                .overview-notice-header-wrap {
                    margin: 0%;
                    margin-bottom: 0%;
                    width: 94%;
                    height: 8vh;
                    border-radius: 5px;
                    justify-content: space-between;

                    .overview-content-group {


                        .overview-main-line {}

                        .overview-sub-line {}
                    }

                    .view-icon {
                        color: $primary-color;
                        cursor: pointer;
                    }

                    .tag_wrap {
                        height: 50%;
                        width: auto;
                        margin-right: 2%;
                        padding: 0% 2%;
                        margin-left: 2%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 10px;
                        border: 1px solid #0d497f;
                        background: #a3c9eb;
                        color: #0d497f;
                        font-weight: bold;
                        font-size: 1.6vmin;
                    }
                }

                .z-table {
                    padding-top: 0% !important;
                    width: 98%;
                    // padding-right: 0%;
                }

            }

        }

        .mobile_view_design {
            width: 100%;
            height: 11vh;

            display: flex;
            // flex-wrap: wrap;
            flex-direction: column;
            // border-top: 1px solid  $primary-color;
            // justify-content: center !important;
            // align-items: center !important;

            .submenu_container {
                height: 51%;
                width: 100%;
                display: flex;
                flex-direction: row;

                .mob_overview_menu {
                    width: 33.2%;

                    height: 100%;
                    border-radius: 0px;
                    border-top: 1px solid $primary-color ;
                    border-bottom: 1px solid $primary-color ;
                    border-right: 1px solid $primary-color;
                    background-color: transparent;
                    margin-bottom: 1vh;
                    font-size: 2vmin;
                    cursor: pointer;
                    color: $primary-color;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: bold;
                }

                .active {
                    color: #fff;
                    background-color: $primary-color;
                    transition: background 0.4s ease;
                }
            }

            .submenu_container2 {
                height: 49%;
                width: 100%;
                display: flex;
                flex-direction: row;

                .mob_overview_menu {
                    width: 50%;

                    height: 100%;
                    border-radius: 0px;
                    // border-top:1px solid $primary-color ;
                    // border-bottom:1px solid $primary-color  ;
                    border-right: 1px solid $primary-color;
                    background-color: transparent;
                    margin-bottom: 1vh;
                    font-size: 2vmin;
                    cursor: pointer;
                    color: $primary-color;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: bold;
                }

                .active {
                    color: #fff;
                    background-color: $primary-color;
                    transition: background 0.4s ease;
                }
            }

        }

    }
}

@media only screen and (min-width:769px) and (max-width:1025px) {
    #overview-details-container {


        .overview-menu-wrap {
            width: 20%;
            align-items: center;
            display: flex;

            .overview_menu {
                width: 95%;
                height: 5vh;
                border-radius: 7px;
                border: 1px solid $primary-color;
                margin-bottom: 1vh;
                cursor: pointer;
                color: $primary-color;
                display: flex !important;
                align-items: center;
                justify-content: center;
                font-weight: bold;
            }

            .active {
                color: #fff;
                background-color: $primary-color;
                transition: background 0.4s ease;
            }

            .stats-box {
                width: 18vw !important;


                .gst-chart {
                    width: 90%;

                    .chart {

                        width: 60% !important;
                    }
                }

                .chart-stat {
                    height: 45%;


                    .stat-item {

                        width: 100%;


                        .stat-sub {



                            .dot {

                                margin-left: 3%
                            }

                            p {
                                font-size: 1.5vmin !important;
                            }
                        }


                        .stat-subs {


                            span {
                                font-size: 1.5vmin;
                            }

                            img {
                                padding-right: 0;
                            }
                        }
                    }
                }

            }


        }

        .transation_container {
            display: none;
        }

        .transation_container1 {
            display: none;
        }

        .overview-upper-container {

            width: 80%;
            max-height: 100% !important;


            .overview-notice-main {

                .overview-notice-header-wrap {
                    height: 8vh;
                    justify-content: space-between;
                    margin-bottom: 2%;


                    .overview-content-group {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: space-evenly;
                        width: max-content;
                        height: 100%;

                        .overview-main-line {
                            font-size: 2vmin;
                            line-height: 3.5vmin !important;
                            font-size: bold;
                            color: #424B70;
                            font-family: 'Inter';
                            margin: 0;
                        }

                        .overview-sub-line {
                            font-size: 1vmin;
                            line-height: 1.8vmin;
                            font-family: 'Inter';
                            margin: 0;
                        }
                    }

                    .view-icon {
                        color: $primary-color;
                        cursor: pointer;
                    }

                    .tag_wrap {
                        height: 50%;
                        margin-right: 1%;

                        font-size: 1.8vmin;
                    }
                }

                .z-table {
                    padding-top: 0% !important;
                    width: 98%;
                    padding-right: 0%;
                }

            }

        }

        .mobile_view_design {
            display: none;
        }

    }
}

@media only screen and (min-width:1026px) and (max-width:1440px) {
    #overview-details-container {


        .overview-menu-wrap {
            align-items: end !important;
            display: flex;

            .overview_menu {
                width: 95% !important;
                height: 5vh;
                border-radius: 7px;
                border: 1px solid $primary-color;
                background-color: #FFF;
                margin-bottom: 1vh;
                cursor: pointer;
                color: $primary-color;
                display: flex !important;
                align-items: center;
                justify-content: center;
                font-weight: bold;
            }

            .active {
                color: #fff;
                background-color: $primary-color;
                transition: background 0.4s ease;
            }

            .stats-box {

                width: 14vw;


                .gst-chart {
                    width: 90%;


                    .chart {
                        width: 70% !important;
                    }
                }

                .chart-stat {


                    .stat-item {

                        width: 100%;

                        .stat-sub {



                            .dot {

                                margin-left: 3%
                            }

                            p {
                                font-size: 1.5vmin !important;
                            }
                        }


                        .stat-subs {


                            span {
                                font-size: 1.5vmin;
                            }

                            img {
                                padding-right: 0;
                            }
                        }
                    }
                }

            }


        }

        .transation_container {
            display: none;
        }

        .transation_container1 {
            display: none;
        }

        .overview-upper-container {


            .overview-notice-main {

                .overview-notice-header-wrap {
                    height: 8vh;
                    //    justify-content: space-between;
                    margin-bottom: 0%;


                    .overview-content-group {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: space-evenly;
                        width: max-content;
                        height: 100%;

                        .overview-main-line {
                            font-size: 2.2vmin;
                            line-height: 3.5vmin !important;
                            font-size: bold;
                            color: #424B70;
                            font-family: 'Inter';
                            margin: 0;
                        }

                        .overview-sub-line {
                            font-size: 2.2vmin;
                            line-height: 1.8vmin;
                            font-family: 'Inter';
                            margin: 0;
                        }
                    }

                    .view-icon {
                        color: $primary-color;
                        cursor: pointer;
                    }

                    .tag_wrap {
                        height: 50%;


                        font-size: 1.8vmin;
                    }
                }

                .z-table {}

            }

        }

        .mobile_view_design {
            display: none;
        }

    }
}

@media only screen and (min-width:1441px) {
    #overview-details-container {


        .overview-menu-wrap {
            display: flex;

            align-items: center;

            .overview_menu {
                width: 90%;
                height: 5vh;
                border-radius: 7px;
                border: 1px solid $primary-color;
                background-color: #FFF;
                margin-bottom: 1vh;
                cursor: pointer;
                color: $primary-color;
                display: flex !important;
                align-items: center;
                justify-content: center;
                font-weight: bold;
            }

            .active {
                color: #fff;
                background-color: $primary-color;
                transition: background 0.4s ease;
            }

            .stats-box {

                width: 14vw;


                .gst-chart {
                    width: 90%;


                    .chart {
                        width: 70% !important;
                    }
                }

                .chart-stat {
                    height: 45%;


                    .stat-item {

                        width: 100%;


                        .stat-sub {



                            .dot {

                                margin-left: 3%
                            }

                            p {}
                        }


                        .stat-subs {


                            span {}

                            img {}
                        }
                    }
                }

            }


        }

        .transation_container {
            display: none;
        }

        .transation_container1 {
            display: none;
        }

        .overview-upper-container {


            .overview-notice-main {

                .overview-notice-header-wrap {
                    height: 8vh;
                    justify-content: space-between;
                    margin-bottom: 1%;


                    .overview-content-group {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: space-evenly;
                        width: max-content;
                        height: 100%;

                        .overview-main-line {
                            font-size: 2.2vmin;
                            line-height: 3.5vmin !important;
                            font-size: bold;
                            color: #424B70;
                            font-family: 'Inter';
                            margin: 0;
                        }

                        .overview-sub-line {
                            font-size: 2.2vmin;
                            line-height: 1.8vmin;
                            font-family: 'Inter';
                            margin: 0;
                        }
                    }

                    .view-icon {
                        color: $primary-color;
                        cursor: pointer;
                    }

                    .tag_wrap {
                        height: 50%;

                        margin-right: 1%;

                        font-size: 1.8vmin;
                    }
                }

                .z-table {}

            }

        }

        .mobile_view_design {
            display: none;
        }

    }
}