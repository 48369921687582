.register-container {
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
    position: relative;
    width: 122%;
    top: 7%;
    left: -43%;
    max-height: 80vh;
    // label {
    //     color: #000;
    //     font-family: Inter;
    //     font-size: 1.7vmin;
    //     font-weight: 600;
    // }



    .fields {
        margin: 0 5% 0 5%;
        padding-bottom: 5%;
        overflow: auto;
        max-height: 60vh;

        .input-group {
            margin-top: 1rem;
            display: flex;
            flex-direction: column;
            // padding-bottom: 1rem;

            span {
                float: left;
            }

            .register {
                text-transform: uppercase;
            }
        }
    }

    // .input-box {
    //     height: 3rem;
    //     border-radius: 4px;
    //     border: 0.5px solid rgba(0, 0, 0, 0.70);
    //     margin-top: 0.4rem;

    //     img {
    //         position: relative;
    //         top: 17%;
    //         left: 2%;
    //     }

    //     span {
    //         color: #E10000;
    //         font-family: Inter;
    //         font-size: 1.5vmin;
    //         font-style: normal;
    //         font-weight: 400;
    //         line-height: 142%;
    //         /* 19.88px */
    //         letter-spacing: 0.035px;
    //         padding-top: 1%;
    //         float: right !important;
    //     }

    //     .availble{
    //         color: green;
    //         font-family: Inter;
    //         font-size: 1.5vmin;
    //         font-style: normal;
    //         font-weight: 400;
    //         line-height: 142%;
    //         /* 19.88px */
    //         letter-spacing: 0.035px;
    //         padding-top: 1%;
    //         float: right !important;
    //     }
    // }

    input[type="text"] {
        border: 0;
        outline: none;
        width: 85%;
        position: relative;
        top: 4%;
        height: 90%;
        margin-left: 4%;
    }

    input[type="checkbox"] {
        visibility: hidden;
        // margin-right: 10%;
    }

    .check_box {
        height: 15px;
        width: 15px;
        margin-right: 2%;
        cursor: pointer;
        border: 0.75px solid #E96919;
    }

    .checked_box {
        height: 15px;
        width: 15px;
        margin-right: 2%;
        border: 0.75px solid #fff;
        display: inline-block;
        background-color: #E96919;
        cursor: pointer;

        span {
            position: relative;
            left: -40%;
            top: -3px;
            -ms-transform: rotate(45deg);
            /* IE 9 */
            -webkit-transform: rotate(45deg);
            /* Chrome, Safari, Opera */
            transform: rotate(45deg);

            .checkmark_stem {
                position: relative;
                width: 2.5px;
                height: 12px;
                background-color: #fff;
                left: 3px;
                top: 3px;
            }

            .checkmark_kick {
                position: relative;
                width: 3px;
                height: 2.5px;
                background-color: #fff;
            }
        }

    }

    // .title {
    //     color: #000;
    //     font-family: Inter;
    //     font-size: 1.7vmin;
    //     font-style: normal;
    //     font-weight: 600;
    //     line-height: 160.523%;
    //     /* 22.473px */
    //     letter-spacing: 0.035px;
    //     display: flex;

    //     span {
    //         color: #0193E5;
    //         text-decoration-line: underline;
    //         cursor: pointer;
    //         margin-left: 1.4%;
    //     }
    // }

    .privacy_policy {
        display: flex;
        flex-direction: row;
        margin-top: 1rem !important;
        width: 100%;
        align-items: center;

        .title {
            color: #000;
            font-family: Inter;
            font-size: 1.7vmin;
            font-style: normal;
            font-weight: 600;
            line-height: 160.523%;
            /* 22.473px */
            letter-spacing: 0.035px;
            width: 90%;

            .privacy-innerline {
                color: #0193E5;
                text-decoration-line: underline;
                cursor: pointer;
                // margin-left: 0.4%;
            }
        }
    }

    .register_btn {
        margin-top: 1.4rem !important;
    }
}

@media only screen and (min-width:320px) and (max-width:600px) {
    .register-container {
        top: 0;
        left: 0;
        width: 85%;

        .title {
            font-size: 3.2vmin !important;
        }

        .input-group {
            margin-top: 0.5rem !important;
        }

        .error-margin {
            margin-top: 0 !important;
        }
    }
}

@media only screen and (min-width:601px) and (max-width:768px) {
    .register-container {
        width: 135%;
        top: 5%;
    }
}

@media only screen and (min-width:769px) and (max-width:1024px) {
    .register-container {
        width: 123%;
        left: -39%;
        top:5%;
    }
}