@import'../../App.scss';

.breadcrumb {
    margin: auto;
    margin-left: 2%;
    text-transform: uppercase;
}

.breadcrumb ul {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
}

.breadcrumb li {
    font-size: 1.8vmin;
    margin-right: 5px;
}

.breadcrumb li span {
    color: $primary-color;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s;
}

.breadcrumb li .last {
    color: $secondary-color;
    font-weight: bold;
}

.breadcrumb li:not(:last-child)::after {
    content: '>';
    margin-left: 5px;
    color: #888;
}

@media only screen and (min-width:320px) and (max-width:600px) {
    .breadcrumb li {
        font-size: 3vmin;
    }
}