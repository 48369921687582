@import '../../App.scss';

@keyframes fadeOut {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.latest_search_cover {
    height: 48vh;
    top: 23.5vh;
    right: 4.5vw;
    width: 30vw;
    position: absolute;
    border-radius: 4px;
    z-index: 10;
    border: 0.5px solid $primary-color;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 2px 18px 0 rgba(18, 52, 77, .16), 0 2px 4px 0 rgba(18, 52, 77, .06);

    .head_box {
        height: 5vh;
        width: 100%;
        border-bottom: 0.5px solid $primary-color;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: center;

        .head_line {
            font-size: 2vmin;
            font-weight: bold;
            color: $secondary-color;
            width: 100%;
        }

        .sub-header-wrap {
            width: 100%;
            height: 60%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: end;

            .sync-box {
                width: auto;
                height: 100%;
                display: flex;
                align-items: center;
                margin-left: 5%;

                .sync-btn {
                    background: rgb(236, 251, 228);
                    border: 1px solid rgb(82, 196, 26);
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    height: 90%;
                    color: var(--text-black, #393938);
                    font-family: Inter;
                    font-size: 1.8vmin;
                    font-weight: 500;
                }

                .Progres-btn {
                    background: #BEDCE7;
                    border: 1px solid #5FAABC;
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    height: 5.3vh;
                    color: var(--text-black, #393938);
                    font-family: Inter;
                    font-size: 1.8vmin;
                    font-weight: 500;
                    cursor: pointer;

                    @keyframes rotation {
                        from {
                            transform: rotate(0deg);
                        }

                        to {
                            transform: rotate(360deg);
                        }
                    }

                    .Progress-icon {
                        animation: rotation 1.5s;
                        animation-iteration-count: infinite;
                    }
                }
            }

            .link-item {
                font-size: 2vmin;
                font-weight: bold;
                text-decoration: underline;
                text-decoration-color: $primary-color;
                color: $primary-color;
                cursor: pointer;
                text-align: start;
                margin-right: 5%;
            }
        }
    }

    .notify_content_cover {
        max-height: 42.8vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow: scroll;

        .content_block {
            min-height: 12vh;
            height: auto;
            width: 94%;
            cursor: pointer;
            display: flex;
            align-items: center;
            padding: 2% 3%;

            .avatar {
                background-color: $secondary-color;
                text-transform: uppercase;
                cursor: pointer;
                max-height: 7.4vh;
                height: 7.4vh;
                max-width: 3.7vw;
                width: 3.7vw;
            }

            .content-msg-cover {
                height: 100%;
                width: 78%;
                margin-left: 2%;
                color: $primary-color;
                display: flex;
                flex-direction: column;
                justify-content: space-around;

                .content_msg {
                    font-size: 2.2vmin;
                    font-weight: bold;
                    word-wrap: break-word;
                }

                .content_stamp {
                    font-size: 1.9vmin;
                    // color: #aaa;
                    color: #242424;
                }
            }

            &:hover {
                background-color: #e4e4e6;

                .content-msg-cover {
                    // color: #fff;
                }
            }

            &:last-child {
                margin-bottom: 1%;
            }
        }

        .content_row {
            width: 97%;
            margin: auto;
            min-height: 7vh;
            height: auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: start;
            color: #000;
            font-weight: bold;
            font-size: 2vmin;
            margin-bottom: 0.5%;
            border-radius: 5px;
            border-bottom: 1px solid #cdcbcb;

            .content_subject {
                color: $primary-color;

                .s_no {
                    color: #000;
                }

                .icon {
                    height: 20px;
                }

            }

            .content_desc {
                color: $secondary-color;
                margin-left: 2%;
                cursor: pointer;
            }

            a {
                color: #3F8DFF;
                cursor: pointer;
                font-weight: normal;
                text-decoration: underline;
            }

            .edit_icn {
                cursor: pointer;
                height: 20px;
                color: $primary-color;
                margin-left: auto;
            }

            .delete_icn {
                cursor: pointer;
                height: 20px;
                color: $secondary-color;
            }

            &:hover {
                border-bottom: 2.5px solid #cdcbcb;
            }
        }
    }

    &.fade-out {
        animation: fadeOut 0.6s forwards;
    }
}