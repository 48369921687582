@import '../../../index.scss';

p {
    color: #000;
}

.landing {
    width: 100%;
    height: 100%;
    display: flex;
}

.feed_header {
    width: 95%;
    margin: auto;
    height: 5vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1%;

    .head-line {
        font-size: 2.5vmin;
    }
}

.feed_content {
    width: 100%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;

    .input_label {
        width: 94%;
        margin-top: 7%;
        margin-bottom: 2%;
    }

    .input_field {
        height: auto;
        min-height: 18vh;
        width: 94%;
    }

    .feed_desc {
        width: 94%;
        height: max-content;
        margin-top: auto;
        margin-bottom: 7%;
        font-size: 2vmin;

        .anchor_link {
            color: #E96919;
            text-decoration: underline;
            cursor: pointer;
        }
    }
}

.feed_footer {
    width: 95%;
    margin: auto;
    height: 7vh;
    display: flex;
    flex-direction: row;
    align-items: end;
    margin-bottom: 1%;

    .feed_btn {
        background-color: #424B70;
        color: #fff;
        margin-left: auto;

        &:hover {
            background-color: #424B70;
            color: #fff;
        }
    }
}

@media only screen and (min-width:320px) and (max-width:600px) {
    .landing {

        .float_icon {
            position: 'absolute';
            margin-left: 80vw !important;
            margin-top: 80vh !important;
            z-index: 2;
        }
    }

    .feed_header .head-line {
        font-size: 4vmin;
    }

    .feed_content {
        height: 60vh !important;
        .input_field {
            .css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input {
                height: 190px !important;
            }
        }

        .feed_desc {
            font-size: 3.6vmin;
        }
    }

    .feed_footer .feed_send {
        font-size: 3.9vmin;
    }

    .feedback_drawer_cover {
        width: 233px !important;
    }
}