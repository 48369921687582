@import '../../App.scss';

.manage-gst-container {
    height: 100%;
    width: 100%;
    gap: 2%;

    .input-group {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        // padding-bottom: 1rem;

        span {
            float: left;
        }

        .register {
            text-transform: uppercase;
        }
    }

}

.manage-gst-header {

    height: 24vh;
    display: flex;
    justify-content: center;
}

.manage-gst-stats {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 38%;
}

.stat-item {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.stat-subs {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.stat-sub {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 75%
}

.stats-box {
    border: 1px solid #00000099;
    height: 90%;
    width: 18%;
    border-radius: 4%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.ant-modal-title {
    color: #031742 !important;
    font-family: Inter;
    font-size: 5vmin !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: normal !important;
}


:where(.css-dev-only-do-not-override-byeoj0).ant-modal .ant-modal-title {
    font-size: 25px;
    font-weight: 600;
    color: #031742;
}

#manage-gst-chart {
    border: 1px solid black;
    width: 40%;
    border-radius: 1%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.manage-gst-chart {
    width: 35%;
    height: 85%;
    margin-left: 3%;

}

.stats-box img {
    height: 35%
}

.chart-stat {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.dot {
    height: 12.5px;
    width: 12.5px;
    background-color: #bbb;
    border-radius: 50%;
    display: block;
    margin-right: 3%
}

.manage-gst-table {
    height: max-content;

    justify-content: center;
    padding: 2%;
}

h2 {
    color: black;
    font-family: Inter;
    font-weight: 400;
}

.stats-box p {
    font-family: Inter;
}

.right-container {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    margin-right: 1% !important;
    gap: 3%;
    align-items: center;
    height: 14vh;
}

#manage-gst-add-button {
    background: #424B70;
    color: white;
    display: flex;
    align-items: center;
    gap: 2px;
    // height:100%;

    font-family: Roboto;
    font-size: 2vmin;
    font-weight: 400;
    padding: 3% 2%;
}

#manage-gst-solution-button {
    background: #ED7D31;
    font-size: 16px;
    color: white;
    gap: 5px;
    display: flex;
    align-items: center;

    font-family: Roboto;
    font-size: 2vmin;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 3% 2%;


}

.sync-all {
    background: #FCE7D9 !important;
    color: #ED7D31 !important;
}

#download-btn {
    background: #fff !important;
    color: #ED7D31;
    font-family: Inter;
    font-size: 2vmin;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    gap: 5px;
    display: flex;
    align-items: center;
    padding: 3% 2%;
}

.icon-btn {
    position: relative;
    right: 5%;
}

.count {
    font-weight: 600;

}

.manage-table-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
}

.manage-left-container {
    gap: 2%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 24%;
    width: 50%;
    // margin-left: 2%;

    .gst-head {
        color: var(--text-black, #393938);
        font-family: Roboto;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .gst-search,
    .ant-input {
        background: #F3F3F9 !important;
        border-radius: 20px;
        width: 120%;

    }

}

:where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-tbody>tr.ant-table-row-selected>td {
    background: #fae6d7;
    ;

}

:where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-tbody>tr.ant-table-row-selected:hover>td {
    background: #fae6d7;

}

:where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-thead>tr>th,
:where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-thead>tr>td {
    position: relative;
    color: rgba(0, 0, 0, 0.88);
    font-weight: 600;
    text-align: flex-start;
    background: #f3f3f3;
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.2s ease;
}

:where(.css-dev-only-do-not-override-byeoj0).ant-checkbox-checked .ant-checkbox-inner {
    background-color: #ED7D31;
    border-color: #ED7D31;
}

.ant-checkbox-inner:hover {
    background-color: red;
}

.ant-table-content {
    border: 0.5px solid rgba(0, 0, 0, 0.40);
    border-top: 0.5px solid rgba(0, 0, 0, 0.10)
}

.ant-pagination {
    display: flex;
    flex-direction: row;
    justify-content: center !important;
}

:where(.css-dev-only-do-not-override-190m0jy).ant-table-wrapper .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    width: 2px;
    background-color: #d7d7d7;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #ED7D31;
    border-color: #ED7D31;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
    background-color: #ED7D31;
    border-color: transparent;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
:where(.css-dev-only-do-not-override-190m0jy) .ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
    border-color: #ED7D31;
}

.ant-table-wrapper .ant-table-tbody>tr.ant-table-row-selected:hover>td {
    background: #FDF2EA;
}

.ant-table-wrapper .ant-table-tbody>tr.ant-table-row-selected>td {
    background: transparent;
}

.ant-pagination-item-active {
    background: $primary-color;
}

.ant-pagination .ant-pagination-item-active a {
    color: #fff;
    background-color: $primary-color;
}

.ant-pagination .ant-pagination-item-active:hover a {
    color: #FFF;
    background: $primary-color;
}

.ant-pagination .ant-pagination-item-active,
.ant-pagination .ant-pagination-item-active:hover {
    border-color: $primary-color;
    border: 0px solid;
}

.delete-gst {
    .ant-modal-title {
        font-size: 2.4vmin;
        text-align: center;
    }
}

.action-popup {
    .ant-modal-title {
        font-size: 3vmin;
        text-align: center;
    }

    .input-box {
        height: 3rem;
        border-radius: 4px;
        border: 0.5px solid rgba(0, 0, 0, 0.70);

        img {
            position: relative;
            top: 17%;
            left: 2%;
        }

        .eye_icon {
            position: absolute;
            color: #77787A;
            right: 10%;
            margin-top: 3%;
            cursor: pointer;
        }

        span {
            color: #E10000;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 142%;
            /* 19.88px */
            letter-spacing: 0.035px;
            padding-top: 1%;
            float: right !important;
        }

        .gst-err-msg {
            color: #E10000;
            font-family: Inter;
            font-size: 1.8vmin;
            text-align: right;
            margin-top: -0%;
            float: right;
        }
    }

    label {
        color: #000;
        font-family: Inter;
        font-size: 1.7vmin;
        font-weight: 400;
    }

    input[type="text"],
    input[type="password"] {
        border: 0;
        outline: none;
        width: 85%;
        position: relative;
        top: 4%;
        height: 90%;
        margin-left: 4%;
    }
}

.sync-report-popup {
    .ant-modal-title {
        font-size: 3vmin !important;
        text-align: center;
    }

    .ant-modal-footer {
        margin-top: 6px !important;
    }

    .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
        color: white !important;
    }

    .report-view-button {
        background: #424B70;
        color: white;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        width: 40%;
        height: 5vh;
        font-size: 2vmin;
    }

    .report-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        text-align: end;
        margin-top: 3%;
        min-height: 43vh;

        .report-title {
            width: max-content;
            max-width: 50%;
            display: flex;
            flex-direction: column;
            align-items: start;
        }

        h3 {
            line-height: 0.5;
        }

        .report-cnt {
            color: $secondary-color;
            max-width: 50%;
            width: max-content;
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: center;
            padding-left: 2%;
        }
    }
}

.gstin-num {
    font-family: Inter;
    font-size: 2vmin;
    text-align: center;
    color: var(--text-black, #393938);
}

.del-header {
    font-family: Inter;
    color: var(--text-black, #393938);
    font-weight: 500;
    font-size: 2.6vmin;
}

// }

.detail-view-tab-cover-container {
    margin-top: 4% !important;
    min-height: 5.5vh;
    display: flex;
    width: 90%;
    max-width: 25vw;
    justify-content: end;
    flex-direction: row;
    border-bottom: none;
    padding-left: 5%;
    padding-right: 3%;
    margin: auto;

    .detail-view-tab-cover {
        height: 6vh;
        min-width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        border: 1px solid $primary-color;
        border-radius: 6px;

        .view-tab {
            height: 100%;
            font-family: Inter;
            color: $primary-color;
            cursor: pointer;
            font-size: 1.7vmin;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
        }

        .one {
            width: 50%;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }

        .two {
            width: 50%;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }

        .active-tab {
            background-color: $primary-color;
            color: #FFFFFF;
        }
    }
}

.table-label-wrap {
    width: 100%;
    height: 9vh;
    margin-top: 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    .table-label {
        text-align: center;

        span {
            color: $secondary-color;
            font-size: 2.4vmin;
        }
    }
}

@media only screen and (min-width:320px) and (max-width:600px) {
    .manage-gst-container {
        height: 100%;
        width: 100%;
        gap: 2%;

        .input-group {
            margin-top: 1rem;
            display: flex;
            flex-direction: column;
            // padding-bottom: 1rem;

            span {
                float: left;
            }

            .register {
                text-transform: uppercase;
            }
        }

    }

    .manage-gst-header {

        height: 24vh;
        display: flex;
        justify-content: center;
    }

    .manage-gst-stats {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        height: 38%;
    }

    .stat-item {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }

    .stat-subs {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .stat-sub {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 75%
    }

    .stats-box {
        border: 1px solid #00000099;
        height: 90%;
        width: 18%;
        border-radius: 4%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

    }

    .ant-modal-title {
        color: #031742 !important;
        font-family: Inter;
        font-size: 5vmin !important;
        font-style: normal;
        font-weight: 500 !important;
        line-height: normal !important;
    }


    :where(.css-dev-only-do-not-override-byeoj0).ant-modal .ant-modal-title {
        font-size: 25px;
        font-weight: 600;
        color: #031742;
    }

    #manage-gst-chart {
        border: 1px solid black;
        width: 40%;
        border-radius: 1%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .manage-gst-chart {
        width: 35%;
        height: 85%;
        margin-left: 3%;

    }

    .stats-box img {
        height: 35%
    }

    .chart-stat {
        width: 50%;
        display: flex;
        flex-direction: column;
    }

    .dot {
        height: 12.5px;
        width: 12.5px;
        background-color: #bbb;
        border-radius: 50%;
        display: block;
        margin-right: 3%
    }

    .manage-gst-table {
        height: max-content;

        justify-content: center;
        padding: 2%;
    }

    h2 {
        color: black;
        font-family: Inter;
        font-weight: 400;
    }

    .stats-box p {
        font-family: Inter;
    }

    .right-container {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        margin-right: 1% !important;
        gap: 3%;
        align-items: center;
        height: 14vh;
    }

    #manage-gst-add-button {
        background: #424B70;
        color: white;
        display: flex;
        align-items: center;
        gap: 2px;
        // height:100%;

        font-family: Roboto;
        font-size: 2vmin;
        font-weight: 400;
        padding: 3% 2%;
    }

    #manage-gst-solution-button {
        background: #ED7D31;
        font-size: 16px;
        color: white;
        gap: 5px;
        display: flex;
        align-items: center;

        font-family: Roboto;
        font-size: 2vmin;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 3% 2%;


    }

    .sync-all {
        background: #FCE7D9 !important;
        color: #ED7D31 !important;
    }

    #download-btn {
        background: #fff !important;
        color: #ED7D31;
        font-family: Inter;
        font-size: 2vmin;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        gap: 5px;
        display: flex;
        align-items: center;
        padding: 3% 2%;
    }

    .icon-btn {
        position: relative;
        right: 5%;
    }

    .count {
        font-weight: 600;

    }

    .manage-table-container {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
    }

    .manage-left-container {
        gap: 2%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 24%;
        width: 50%;
        // margin-left: 2%;

        .gst-head {
            color: var(--text-black, #393938);
            font-family: Roboto;
            font-size: 28px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        .gst-search,
        .ant-input {
            background: #F3F3F9 !important;
            border-radius: 20px;
            width: 120%;

        }

    }

    :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-tbody>tr.ant-table-row-selected>td {
        background: #fae6d7;
        ;

    }

    :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-tbody>tr.ant-table-row-selected:hover>td {
        background: #fae6d7;

    }

    :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-thead>tr>th,
    :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-thead>tr>td {
        position: relative;
        color: rgba(0, 0, 0, 0.88);
        font-weight: 600;
        text-align: flex-start;
        background: #f3f3f3;
        border-bottom: 1px solid #f0f0f0;
        transition: background 0.2s ease;
    }

    :where(.css-dev-only-do-not-override-byeoj0).ant-checkbox-checked .ant-checkbox-inner {
        background-color: #ED7D31;
        border-color: #ED7D31;
    }

    .ant-checkbox-inner:hover {
        background-color: red;
    }

    .ant-table-content {
        border: 0.5px solid rgba(0, 0, 0, 0.40);
        border-top: 0.5px solid rgba(0, 0, 0, 0.10)
    }

    .ant-pagination {
        display: flex;
        flex-direction: row;
        justify-content: center !important;
    }

    :where(.css-dev-only-do-not-override-190m0jy).ant-table-wrapper .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        width: 2px;
        background-color: #d7d7d7;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #ED7D31;
        border-color: #ED7D31;
    }

    .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
        background-color: #ED7D31;
        border-color: transparent;
    }

    .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
    :where(.css-dev-only-do-not-override-190m0jy) .ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
        border-color: #ED7D31;
    }

    .ant-table-wrapper .ant-table-tbody>tr.ant-table-row-selected:hover>td {
        background: #FDF2EA;
    }

    .ant-table-wrapper .ant-table-tbody>tr.ant-table-row-selected>td {
        background: transparent;
    }

    .ant-pagination-item-active {
        background: $primary-color;
    }

    .ant-pagination .ant-pagination-item-active a {
        color: #fff;
        background-color: $primary-color;
    }

    .ant-pagination .ant-pagination-item-active:hover a {
        color: #FFF;
        background: $primary-color;
    }

    .ant-pagination .ant-pagination-item-active,
    .ant-pagination .ant-pagination-item-active:hover {
        border-color: $primary-color;
        border: 0px solid;
    }

    .delete-gst {
        .ant-modal-title {
            font-size: 2.4vmin;
            text-align: center;
        }
    }

    .action-popup {
        .ant-modal-title {
            font-size: 3vmin;
            text-align: center;
        }

        .input-box {
            height: 3rem;
            border-radius: 4px;
            border: 0.5px solid rgba(0, 0, 0, 0.70);

            img {
                position: relative;
                top: 17%;
                left: 2%;
            }

            .eye_icon {
                position: absolute;
                color: #77787A;
                right: 10%;
                margin-top: 3%;
                cursor: pointer;
            }

            span {
                color: #E10000;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 142%;
                /* 19.88px */
                letter-spacing: 0.035px;
                padding-top: 1%;
                float: right !important;
            }

            .gst-err-msg {
                color: #E10000;
                font-family: Inter;
                font-size: 1.8vmin;
                text-align: right;
                margin-top: -0%;
                float: right;
            }
        }

        label {
            color: #000;
            font-family: Inter;
            font-size: 1.7vmin;
            font-weight: 400;
        }

        input[type="text"],
        input[type="password"] {
            border: 0;
            outline: none;
            width: 85%;
            position: relative;
            top: 4%;
            height: 90%;
            margin-left: 4%;
        }
    }

    .sync-report-popup {
        .ant-modal-title {
            font-size: 3vmin !important;
            text-align: center;
        }

        .ant-modal-footer {
            margin-top: 6px !important;
        }

        .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
            color: white !important;
        }

        .report-view-button {
            background: #424B70;
            color: white;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: auto;
            width: 40%;
            height: 5vh;
            font-size: 2vmin;
        }

        .report-wrap {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row !important;
            text-align: end;
            margin-top: 3%;
            min-height: 43vh;
            overflow: auto;


            .report-title {
                width: 50%;
                max-width: 50%;
                display: flex;
                flex-direction: column;
                align-items: start;
            }

            h4 {
                // line-height: 0.5;
                font-size: 3.5vmin !important;
            }

            .report-cnt {
                color: $secondary-color;
                max-width: 50%;
                width: 50%;
                display: flex;
                flex-direction: column;
                align-items: start;
                justify-content: center;
                padding-left: 2%;
            }
        }
    }

    .gstin-num {
        font-family: Inter;
        font-size: 2vmin;
        text-align: center;
        color: var(--text-black, #393938);
    }

    .del-header {
        font-family: Inter;
        color: var(--text-black, #393938);
        font-weight: 500;
        font-size: 2.6vmin;
    }

    // }

    .detail-view-tab-cover-container {
        margin-top: 4% !important;
        min-height: 5.5vh;
        display: flex;
        width: 90%;
        max-width: 25vw;
        justify-content: end;
        flex-direction: row;
        border-bottom: none;
        padding-left: 5%;
        padding-right: 3%;
        margin: auto;

        .detail-view-tab-cover {
            height: 6vh;
            min-width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
            border: 1px solid $primary-color;
            border-radius: 6px;

            .view-tab {
                height: 100%;
                font-family: Inter;
                color: $primary-color;
                cursor: pointer;
                font-size: 1.7vmin;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
            }

            .one {
                width: 50%;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }

            .two {
                width: 50%;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }

            .active-tab {
                background-color: $primary-color;
                color: #FFFFFF;
            }
        }
    }

    .table-label-wrap {
        width: 100%;
        height: 9vh;
        margin-top: 2%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;

        .table-label {
            text-align: center;

            span {
                color: $secondary-color;
                font-size: 2.4vmin;
            }
        }
    }
}