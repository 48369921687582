@import '../../../App.scss';

.mail-verify-container {
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
    position: relative;
    width: 122%;
    top: 17%;
    left: -43%;
    top: 27%;
    height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .heading {
        margin-top: 0 !important;
    }

    .register {
        text-transform: uppercase;
        cursor: pointer;
    }

    .icons {
        display: flex;
        padding-top: 5%;
        margin: auto;

    }

    .input-group {
        padding: 5% 0 5% 0;
    }

    .load_icon {
        color: $secondary-color;
        margin: auto;
        font-size: 14vmin;
        animation: infiniteZoom 2s infinite ease-in-out;
    }

    .success {
        margin: auto;
        font-size: 14vmin;
        animation: infiniteZoom 2s infinite ease-in-out;
    }

    @keyframes infiniteZoom {

        0%,
        100% {
            transform: scale(1); // Start and end at the original size
        }

        50% {
            transform: scale(1.2); // Zoom in to 1.2 times the original size
        }
    }

    .link {
        display: flex;
        justify-content: center;
        text-align: center;
        width: 100%;
    }
}

@media only screen and (min-width:320px) and (max-width:600px) {
    .mail-verify-container {
        left: 0;
        top: 0;
        width: 60%;
        height: 28vh;
        padding-right: 3%;
        padding-left: 3%;
        .heading {
            margin-top: 2% !important;
            margin-bottom: 2% !important;
        }

        .icons {
            height: 90px;
        }
    }
}

@media only screen and (min-width:601px)and (max-width:768px) {

    .mail-verify-container {
        top: 29%;
        left: -43%;
        width: 135%;

        .icons {
            height: 85px;
        }
    }
}

@media only screen and (min-width:769px) and (max-width:1024px) {
    .mail-verify-container {
        width: 122%;
        left: -39%;
        top: 26%;

        .icons {
            height: 95px;
        }
    }
}