#policy_container {
    padding: 2% 5%;
    height: auto;
    // max-height: 100vh;
    width: 90%;
    display: flex;
    flex-direction: column;

    .header {
        height: 10%;
        width: 100%;
        display: flex;
        margin-bottom: 2%;


        .logobox {
            width: 85%;
        }

        .linkbox {
            width: 15%;
            padding-top: 1.2%;

            a {
                text-decoration: none;
                color: #FFF;
                padding: 4% 6%;
                align-items: center;
                border-radius: 126.485px;
                background: #424B70;
            }
        }
    }

    .body {
        width: 100%;
        // height: 90%;
        padding: 1%;
        border-radius: 4px;
        border: 1px solid var(--low-contrast-text, #666);
        background: #FFF;

        .policy_data {
            padding: 1%;
            color: #000;
            font-family: Inter;
            font-size: 1.8vmin;
            font-weight: 400;
        }

        .body_header {
            display: flex;
            width: 100%;

            span {
                width: 10%;
                color: #000;
                font-family: Inter;
                font-size: 1.8vmin;
                font-weight: 400;
                display: flex;
                align-items: center;
                cursor: pointer;
            }

            .headline {
                font-weight: bold !important;
                margin-left: -5% !important;
                width: 90%;
                color: #232323;
                text-align: center;
                font-size: 2.7vmin;
                // font-weight: 500;
                font-family: Inter;
                line-height: normal;
            }
        }

        .sub-heading {
            font-weight: bold !important;
            margin-left: 1% !important;
            width: 90%;
            color: #232323;
            // text-align: center;
            font-size: 2.3vmin;
            // font-weight: 500;
            font-family: Inter;
            line-height: normal;
        }
    }
}

@media only screen and (min-width:320px) and (max-width:600px) {
    #policy_container {
        .header {
            display: flex;
            flex-direction: row;
            align-items: center;
            // justify-content: space-evenly;
            width: 100% !important;
            padding: 0 !important;

            .logobox {
                width: 30% !important;
                margin-left: 0 !important;
                padding: 0 !important;
            }

            .linkbox {
                margin-left: 34%;
                padding: 0%;

                a {
                    font-size: 3.2vmin !important;
                }
            }
        }

        .body {
            .body_header .headline {
                font-size: 3.2vmin !important;
            }

            overflow: auto;

            .policy_data {
                font-size: 3vmin !important;
            }
        }
    }
}