/* index.css */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import '../../App.scss';

#gst-parent-container {

    .gst-container {
        height: 100%;
        width: 100%;
        gap: 2%;
    }

    .chart-table-cover {
        width: 100%;
        display: flex;
        flex-direction: row;
        padding-bottom: 0%;

        .chart-wrap {
            width: 19vw;
            padding-top: 1.8%;
            padding-left: 1.5%;
            display: flex;
            flex-direction: column;

            .stats-box {
                box-shadow: 0px 0px 17px 10px rgba(255, 255, 255, 0.8);
                height: 45vh !important;
                height: auto;
                width: 100%;
                border-radius: 4%;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;

                .gst-chart {
                    width: 100%;
                    height: 55%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .chart {
                        width: 70% !important;
                    }
                }

                .chart-stat {
                    height: 35%;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;
                    align-items: center;

                    .stat-item {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        cursor: pointer;
                        width: 85%;
                        height: 4vh;
                        justify-content: space-between;

                        .stat-sub {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            width: 70%;


                            .dot {
                                height: 11px !important;
                                width: 11px !important;
                                background-color: #bbb;
                                border-radius: 50%;
                                display: block;
                                margin-right: 3%
                            }

                            p {
                                font-size: 1.9vmin !important;
                                line-height: 1.9vmin !important;
                            }
                        }


                        .stat-subs {
                            display: flex;
                            flex-direction: row;
                            width: 30%;

                            span {
                                font-weight: bold;
                            }

                            img {
                                padding-right: 0;
                            }
                        }
                    }
                }

            }

            .complaince-box {
                box-shadow: 0px 0px 17px 10px rgba(255, 255, 255, 0.8);
                height: 65vh !important;
                height: auto;
                width: 90%;
                border-radius: 4%;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                margin-top: 5%;
                padding-left: 5%;
                padding-right: 5%;
                padding-bottom: 5%;

                .header {
                    height: 13%;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .heading {
                        color: #666666;
                    }

                    .reload-img {
                        margin-left: auto;

                    }
                }

                .complaince-content-wrap {
                    height: 87%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    .complaince-content {
                        width: 48%;
                        height: 100%;
                        background-color: #666666;
                    }
                }
            }

        }

        .manual-table-wrap {
            max-width: 79.8vw !important;
            min-width: 79.8vw !important;
        }

    }

    .sum-drawer {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 14vh;
        right: 0;
        width: 35vw;
        height: 81.8vh;
        background-color: #B0D3E7;
        margin-top: 1.6%;
        margin-left: -35vw;
        padding: 0.2%;
        padding-left: 1%;
        padding-right: 1%;
        z-index: 3;

        .drawer-header {
            display: flex;
            flex-direction: column;
            height: 10vh;
            border-bottom: 1px solid $primary-color;
            padding-bottom: 1%;

            .drawer-first-line {
                height: 50%;
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;

                .drawer-close-btn {
                    color: #000;
                    height: 53%;
                    cursor: pointer;
                    position: initial;
                }

                .summary-heading {
                    margin: auto;
                    color: $font-primay-color ;
                    font-family: 'Inter';
                }
            }

            .drawer-second-line {
                height: 50%;
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;

                .drawer_global_search {
                    border-radius: 20px;
                    width: 35%;
                    height: 32px !important;
                    background: #fff;
                    margin-right: 3%;

                    input {
                        background: #fff;
                        font-family: Inter;
                    }

                    &:hover {
                        border: 1px solid $font-primay-color;
                    }
                }

                .drawer-filter {
                    cursor: pointer;
                    color: $font-primay-color;
                }

                .notice-cunt {
                    margin-left: auto;
                    color: #000;
                    font-family: 'Inter';

                    span {
                        color: $primary-color;
                        font-weight: bold;
                    }
                }
            }
        }

        .drawer-content {
            display: flex;
            flex-direction: column;
            height: 97vh;
            padding-top: 2.5%;
            overflow: auto;
            padding-bottom: -2.5% !important;

            .drawer-card {
                border-radius: 18px;
                background-color: #fff;
                height: 23vh;
                width: 100%;
                box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
                margin-bottom: 2.5%;

                .card-first-split {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-evenly;
                    height: 35%;
                    border-bottom: 1.5px solid rgba(0, 0, 0, 0.3);

                    .content-group {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        width: max-content;
                        height: 100%;

                        .main-line {
                            font-size: 1.8vmin;
                            line-height: 3.5vmin !important;
                            font-size: bold;
                            color: #424B70;
                            font-family: 'Inter';
                            margin: 0;
                        }

                        .sub-line {
                            font-size: 1.8vmin;
                            line-height: 1.8vmin;
                            font-family: 'Inter';
                            margin: 0;
                        }
                    }

                    .view-icon {
                        color: $secondary-color;
                        cursor: pointer;
                    }
                }

                .card-second-split {
                    display: flex;
                    flex-direction: row;
                    height: 65%;
                    justify-content: space-evenly;
                    padding-bottom: 4%;

                    .sub-grid {
                        width: 46%;
                        height: 90%;

                        .header-line {
                            color: rgba(35, 35, 35, 0.7);
                            font-size: 1.8vmin;
                            line-height: 3.2vmin;
                            font-family: 'Inter';
                            margin: 0;
                        }

                        .desc-cover {
                            border-radius: 6px;
                            height: 70%;
                            box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                            padding: 1%;

                            .desc-sub-line {
                                font-size: 1.5vmin;
                                line-height: 1.9vmin;
                                font-family: 'Inter';
                                color: #323232;
                                flex-wrap: nowrap;
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }

        .drawer-footer {
            display: flex;
            flex-direction: column;
            height: 9vh;
            border-top: 1px solid $primary-color;
            margin-top: 0px;
            align-items: center;
            justify-content: center;

            .ant-pagination {
                margin: 0px;
            }
        }
    }

    .gst-header {

        height: 24vh;
        display: flex;
        position: relative;
        flex-direction: column;
        box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.15);

    }

    .table_global_search {
        border-radius: 20px;
        width: 58%;
        height: 40px !important;
        background: rgb(243, 243, 249);

        input {
            background: rgb(243, 243, 249);
        }
    }

    .nav-button {
        display: flex;
        color: #666666;
        position: absolute;
        right: 10%
    }

    .head-top {
        display: flex;
        position: relative;
        width: 100%;
        height: 12vh;
        border-bottom-width: 2px;
    }

    .head-bottom {
        display: flex;
        position: relative;
        width: 100%;
        align-items: center;
        height: 12vh;
        border-bottom-width: 2px;
    }

    .profile-content {
        color: black;
        font-family: Inter;
        display: flex;
        align-items: center;
        position: absolute;
        right: 8px;
        font-size: 25px;
        margin-top: 14px;
    }

    .gst-stats {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        // align-items: center;
        height: 44vh;
        position: relative;
        margin-left: 1.6%;
        margin-right: 1.6%;
        margin-top: 2%;
        top: 1%;
        background-color: #E7F1FA;

        .count-card-wrap {
            width: 74.5vw;
            border-radius: 15px;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            box-shadow: 0px 0px 17px 10px rgba(255, 255, 255, 0.8);

            .count-card {
                height: 70%;
                width: 15%;
                border-radius: 6%;
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                flex-direction: column;
                cursor: pointer;

                .card-content {
                    height: 30%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    span {
                        font-size: 3.8vmin;
                        line-height: 5.2vmin;
                    }
                }
            }
        }

        .calendar-wrap {
            width: 19.5vw;
            border-radius: 4%;
            box-shadow: 0px 0px 17px 10px rgba(255, 255, 255, 0.8);
            padding: 0.2%;

            .calendar {
                border-radius: 4%;
                height: 100% !important;
            }

            :where(.css-dev-only-do-not-override-1ae8k9u).ant-picker-calendar-mini .ant-picker-panel {
                height: 85%;
            }

            thead {
                background-color: #fff !important;
            }

            :where(.css-dev-only-do-not-override-1ae8k9u).ant-picker-calendar .ant-picker-date-panel .ant-picker-content th {
                color: $primary-color !important;
                font-weight: bold;
            }

            :where(.css-dev-only-do-not-override-1ae8k9u).ant-picker-calendar .ant-picker-cell-in-view.ant-picker-cell-selected:not(.ant-picker-cell-disabled) .ant-picker-cell-inner {
                background-color: $secondary-color !important;
                border: none;
            }

            :where(.css-dev-only-do-not-override-1ae8k9u).ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item {
                color: $secondary-color;
            }

            :where(.css-dev-only-do-not-override-1ae8k9u).ant-picker-calendar .ant-picker-panel .ant-picker-content {
                height: 80%;
            }
        }
    }

    :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-pagination {
        display: flex;
        flex-wrap: wrap;
        flex-direction: inherit;
        row-gap: 8px;
    }

    .stat-item {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        cursor: pointer;
    }

    .stat-subs {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .stat-sub {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 75%
    }

    .stats-box {
        border: none;
        // height: 90%;
        height: auto;
        width: 18%;
        border-radius: 4%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        position: sticky;
        top: 7px;
    }


    :where(.css-dev-only-do-not-override-byeoj0).ant-modal .ant-modal-title {
        font-size: 25px;
        font-weight: 600;
        color: #031742;
    }

    #gst-chart {

        border: 1px solid rgb(0 0 0 / 29%);

        width: 40%;
        border-radius: 1%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .gst-chart {
        width: 35%;
        height: 85%;
        margin-left: 3%;

    }

    .stats-box img {
        // height: 35%
        padding: 5%;
        max-height: 35%;
    }

    .chart-stat {
        width: 50%;
        display: flex;
        flex-direction: column;
    }

    .dot {
        height: 12.5px;
        width: 12.5px;
        background-color: #bbb;
        border-radius: 50%;
        display: block;
        margin-right: 3%
    }

    .gst-table {

        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 96%;
        margin-top: 1%;
        padding: 2%;

    }

    :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-column-title {
        position: relative;
        z-index: 1;
        flex: 1;
        width: max-content;
    }

    :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-thead>tr>th,
    :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-thead>tr>td {
        position: relative;
        color: rgba(0, 0, 0, 0.88);
        font-weight: 600;
        text-align: start;
        background: #0000001a;
        border-bottom: 1px solid #f0f0f0;
        transition: background 0.2s ease;
    }

    // .ant-pagination-total-text {
    //     position: absolute;
    //     left: 0;
    // }

    // .ant-pagination-options {
    //     position: absolute;
    //     right: 0;
    // }

    .gst-table-container {
        width: 100.1%;
        margin-left: 0% !important;
    }

    .ant-checkbox .ant-checkbox-inner {
        width: 25px;
        height: 25px;
    }

    .ant-checkbox-checked .ant-checkbox-inner:after {
        transform: rotate(45deg) scale(1) translate(-50%, -75%);
    }

    .stats-box p {
        font-family: Inter;
    }

    .right-container {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        margin-right: 3%;
        gap: 3%;
        align-items: center;
        height: 100%;
    }

    #gst-add-button {
        background: #424B70;
        color: white;
        display: flex;
        align-items: center;
        gap: 2px;
        height: 60%;
        font-size: 16px;
    }

    #gst-solution-button {
        background: #ED7D31;
        font-size: 16px;

        color: white;
        height: 60%;

        // color: #ED7D31;
        font-family: Inter;
        // font-size: 15.002px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        // height: 60%;
        gap: 5px;
        display: flex;
        align-items: center;
        padding: 1.5%;

    }

    .count {
        font-weight: 600;

    }

    .table-container {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        border: 0.5px solid rgba(0, 0, 0, 0.50);
        border-bottom: none;
    }

    .left-container {
        gap: 2%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        width: 50%;
        margin-left: 2%;

    }

    .gst-row-filter {
        display: flex;
        flex-wrap: wrap;
        gap: 2%;
        align-items: center;

        flex-direction: row;

    }

    .gst-row-item {
        display: flex;
        flex-direction: row;
        gap: 13%;
        width: 23%;
        margin-bottom: 1.5%;
        align-items: center;

    }

    .gst-row-item img {
        height: 15px
    }

    .mob-gst-stats {
        display: none;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        height: 18%;
        width: 100%;
        position: relative;
        margin-left: 1%;
        margin-right: 1%;
        top: 1%;
    }

    .gst-dash-line {
        text-align: center;
    }

    .sync-report-popup {
        .ant-modal-title {
            font-size: 3vmin !important;
            text-align: center;
        }

        .report-wrap {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            text-align: end;
            margin-top: 9%;
            min-height: 43vh;

            .report-title {
                width: 65%;
                display: flex;
                flex-direction: column;
                align-items: end;
            }

            h3 {
                line-height: 0.5;
            }

            .report-cnt {
                color: $secondary-color;
                width: 15%;
                display: flex;
                flex-direction: column;
                align-items: end;
                justify-content: center;
            }
        }
    }

}

.select-container {
    height: 10vmin;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .heading {
        color: $primary-color;
        font-weight: 400;
        font-size: 1.7vmin;
        font-family: 'Inter';
    }

    .dropdown-btn {
        width: 100%;

        .ant-radio-group {
            display: flex;
            flex-direction: column;
        }

        .ant-select-selector {
            height: 45px;
            align-items: center;
        }
    }

    .ant-select-dropdown-menu {
        max-height: 200px;
        /* Adjust the height as needed */
    }

    .save-btn {
        width: 28%;
        color: #FFF;
        font-size: 1.8vmin;
        border-radius: 42px;
        background: #424B70;
    }
}

.notice-form-container {
    min-height: 69vh;
    max-height: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .left-grid {
        width: 50%;
        max-width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 2%;

        .notice-dropdown-btn {
            width: 100%;


            .ant-radio-group {
                display: flex;
                flex-direction: column;
            }

            .ant-select-selector {
                height: 2vh;
                align-items: center;
            }
        }

        .attach_file_line {
            font-size: 2.2vmin !important;
        }

        .attach_file_img {
            margin-right: 5px !important;
            height: 2.4vmin !important;
        }

    }

    .right-grid {
        width: 50%;
        max-width: 50%;
        height: 100%;
        padding: 0 2%;

        .attach_upload_btn {
            background: $secondary-color !important;
            color: white !important;
            border: none;
            width: 6vw;
            height: 4vh;
            font-size: 2vmin;
        }

        .attach_upload_btn.hover {
            color: white !important;
        }
    }

    .form_item {
        margin-bottom: 5px !important;

        .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
            font-size: 3.5vmin !important;
        }


        .create-popup-btn {
            height: 6vh !important;
        }


    }

    .one {
        margin-bottom: 0px !important;
    }

    .two {
        margin-bottom: 10px !important;
    }

    .gst-err-msg {
        font-size: 1.8vmin !important;
    }
}

@media only screen and (min-width:320px) and (max-width:600px) {
    #gst-parent-container {


        .chart-table-cover {

            flex-direction: column;
            background-color: #E7F1FA;

            .chart-wrap {
                width: 100%;
                height: 20vh !important;
                margin-top: 25%;

                .stats-box {
                    height: 100% !important;
                    flex-direction: row;
                    padding-left: 0%;
                    width: 94%;

                    .gst-chart {
                        height: 100%;
                        width: 30%;

                        .chart {
                            width: 85% !important;
                        }
                    }

                    .chart-stat {
                        height: 100%;
                        width: 65%;
                        justify-content: center;

                        .stat-item {


                            .stat-sub {



                                .dot {}

                                p {
                                    font-size: 2.5vmin !important;
                                }
                            }


                            .stat-subs {


                                span {
                                    font-size: 2.5vmin;
                                }

                                img {}
                            }
                        }
                    }

                }

                .complaince-box {
                    box-shadow: 0px 0px 17px 10px rgba(255, 255, 255, 0.8);
                    height: 65vh !important;
                    height: auto;
                    width: 90%;
                    background-color: red;
                    border-radius: 4%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;
                    margin-top: 5%;
                    padding-left: 5%;
                    padding-right: 5%;
                    padding-bottom: 5%;

                    .header {
                        height: 13%;
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        .heading {
                            color: #666666;
                        }

                        .reload-img {
                            margin-left: auto;

                        }
                    }

                    .complaince-content-wrap {
                        height: 87%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;

                        .complaince-content {
                            width: 48%;
                            height: 100%;
                            background-color: #666666;
                        }
                    }
                }


            }

            .table-wrap {
                height: 100vh;
                width: 100% !important;
            }

            .manual-table-wrap {
                max-width: 92.8vw !important;
                min-width: 92.8vw !important;
            }


        }

        .sum-drawer {
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 14vh;
            right: 0;
            width: 35vw;
            height: 81.8vh;
            background-color: #B0D3E7;
            margin-top: 1.6%;
            margin-left: -35vw;
            padding: 0.2%;
            padding-left: 1%;
            padding-right: 1%;
            z-index: 3;

            .drawer-header {
                display: flex;
                flex-direction: column;
                height: 10vh;
                border-bottom: 1px solid $primary-color;
                padding-bottom: 1%;

                .drawer-first-line {
                    height: 50%;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .drawer-close-btn {
                        color: #000;
                        height: 53%;
                        cursor: pointer;
                        position: initial;
                    }

                    .summary-heading {
                        margin: auto;
                        color: $font-primay-color ;
                        font-family: 'Inter';
                    }
                }

                .drawer-second-line {
                    height: 50%;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .drawer_global_search {
                        border-radius: 20px;
                        width: 35%;
                        height: 32px !important;
                        background: #fff;
                        margin-right: 3%;

                        input {
                            background: #fff;
                            font-family: Inter;
                        }

                        &:hover {
                            border: 1px solid $font-primay-color;
                        }
                    }

                    .drawer-filter {
                        cursor: pointer;
                        color: $font-primay-color;
                    }

                    .notice-cunt {
                        margin-left: auto;
                        color: #000;
                        font-family: 'Inter';

                        span {
                            color: $primary-color;
                            font-weight: bold;
                        }
                    }
                }
            }

            .drawer-content {
                display: flex;
                flex-direction: column;
                height: 97vh;
                padding-top: 2.5%;
                overflow: auto;
                padding-bottom: -2.5% !important;

                .drawer-card {
                    border-radius: 18px;
                    background-color: #fff;
                    height: 23vh;
                    width: 100%;
                    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
                    margin-bottom: 2.5%;

                    .card-first-split {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-evenly;
                        height: 35%;
                        border-bottom: 1.5px solid rgba(0, 0, 0, 0.3);

                        .content-group {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            width: max-content;
                            height: 100%;

                            .main-line {
                                font-size: 1.8vmin;
                                line-height: 3.5vmin !important;
                                font-size: bold;
                                color: #424B70;
                                font-family: 'Inter';
                                margin: 0;
                            }

                            .sub-line {
                                font-size: 1.8vmin;
                                line-height: 1.8vmin;
                                font-family: 'Inter';
                                margin: 0;
                            }
                        }

                        .view-icon {
                            color: $secondary-color;
                            cursor: pointer;
                        }
                    }

                    .card-second-split {
                        display: flex;
                        flex-direction: row;
                        height: 65%;
                        justify-content: space-evenly;
                        padding-bottom: 4%;

                        .sub-grid {
                            width: 46%;
                            height: 90%;

                            .header-line {
                                color: rgba(35, 35, 35, 0.7);
                                font-size: 1.8vmin;
                                line-height: 3.2vmin;
                                font-family: 'Inter';
                                margin: 0;
                            }

                            .desc-cover {
                                border-radius: 6px;
                                height: 70%;
                                box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                padding: 1%;

                                .desc-sub-line {
                                    font-size: 1.5vmin;
                                    line-height: 1.9vmin;
                                    font-family: 'Inter';
                                    color: #323232;
                                    flex-wrap: nowrap;
                                    margin: 0;
                                }
                            }
                        }
                    }
                }
            }

            .drawer-footer {
                display: flex;
                flex-direction: column;
                height: 9vh;
                border-top: 1px solid $primary-color;
                margin-top: 0px;
                align-items: center;
                justify-content: center;

                .ant-pagination {
                    margin: 0px;
                }
            }
        }

        .gst-header {

            height: 24vh;
            display: flex;
            position: relative;
            flex-direction: column;
            box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.15);

        }

        .table_global_search {
            border-radius: 20px;
            width: 58%;
            height: 40px !important;
            background: rgb(243, 243, 249);

            input {
                background: rgb(243, 243, 249);
            }
        }

        .nav-button {
            display: flex;
            color: #666666;
            position: absolute;
            right: 10%
        }

        .head-top {
            display: flex;
            position: relative;
            width: 100%;
            height: 12vh;
            border-bottom-width: 2px;
        }

        .head-bottom {
            display: flex;
            position: relative;
            width: 100%;
            align-items: center;
            height: 12vh;
            border-bottom-width: 2px;
        }

        .profile-content {
            color: black;
            font-family: Inter;
            display: flex;
            align-items: center;
            position: absolute;
            right: 8px;
            font-size: 25px;
            margin-top: 14px;
        }

        .gst-stats {
            flex-direction: column;
            height: 95vh;

            .count-card-wrap {
                margin-bottom: 5%;
                height: 100% !important;
                width: 100%;
                flex-wrap: wrap;

                flex-direction: row;

                .count-card {
                    height: 40%;
                    width: 28%;
                    border-radius: 6%;
                    display: flex;
                    margin: 1%;

                    .logo_image {
                        // height: 30%;
                        width: 35% !important;
                    }

                    .card-content {
                        height: 25%;
                        font-size: calc(2.3vmin);

                        span {
                            font-size: 3.8vmin;
                            line-height: 5.2vmin;
                        }
                    }
                }
            }

            .calenderAndChart {
                display: none;
            }

            .calendar-wrap {
                width: 100%;
                height: 45%;
                border-radius: 4%;
                box-shadow: 0px 0px 17px 10px rgba(255, 255, 255, 0.8);
                padding: 0.2%;
                margin-bottom: 5%;
                justify-content: center;
                align-items: center;
                display: flex;

                .calendar {
                    height: 93% !important;
                    width: 90%;
                }

                :where(.css-dev-only-do-not-override-1ae8k9u).ant-picker-calendar-mini .ant-picker-panel {
                    height: 85%;
                }

                thead {
                    background-color: #fff !important;
                }

                :where(.css-dev-only-do-not-override-1ae8k9u).ant-picker-calendar .ant-picker-date-panel .ant-picker-content th {
                    color: $primary-color !important;
                    font-weight: bold;
                }

                :where(.css-dev-only-do-not-override-1ae8k9u).ant-picker-calendar .ant-picker-cell-in-view.ant-picker-cell-selected:not(.ant-picker-cell-disabled) .ant-picker-cell-inner {
                    background-color: $primary-color !important;
                    border: none;
                }

                :where(.css-dev-only-do-not-override-1ae8k9u).ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item {
                    color: $secondary-color;
                    font-size: 3.2vmin;
                }

                :where(.css-dev-only-do-not-override-1ae8k9u).ant-picker-calendar .ant-picker-panel .ant-picker-content {
                    height: 80%;
                }
            }
        }

        :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-pagination {
            display: flex;
            flex-wrap: wrap;
            flex-direction: inherit;
            row-gap: 8px;
        }

        .stat-item {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            cursor: pointer;
        }

        .stat-subs {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        .stat-sub {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 75%
        }

        .stats-box {
            border: none;
            // height: 90%;
            height: auto;
            width: 18%;
            border-radius: 4%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-direction: column;

        }


        :where(.css-dev-only-do-not-override-byeoj0).ant-modal .ant-modal-title {
            font-size: 25px;
            font-weight: 600;
            color: #031742;
        }

        #gst-chart {

            border: 1px solid rgb(0 0 0 / 29%);

            width: 40%;
            border-radius: 1%;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
        }

        .gst-chart {
            width: 35%;
            height: 85%;
            margin-left: 3%;

        }

        .stats-box img {
            // height: 35%
            padding: 5%;
            max-height: 35%;
        }

        .chart-stat {
            width: 50%;
            display: flex;
            flex-direction: column;
        }

        .dot {
            height: 12.5px;
            width: 12.5px;
            background-color: #bbb;
            border-radius: 50%;
            display: block;
            margin-right: 3%
        }

        .gst-table {

            display: flex;
            justify-content: center;
            flex-direction: column;
            width: 96%;
            margin-top: 1%;
            padding: 2%;

        }

        :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-column-title {
            position: relative;
            z-index: 1;
            flex: 1;
            width: max-content;
        }

        :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-thead>tr>th,
        :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-thead>tr>td {
            position: relative;
            color: rgba(0, 0, 0, 0.88);
            font-weight: 600;
            text-align: start;
            background: #0000001a;
            border-bottom: 1px solid #f0f0f0;
            transition: background 0.2s ease;
        }

        // .ant-pagination-total-text {
        //     position: absolute;
        //     left: 0;
        // }

        // .ant-pagination-options {
        //     position: absolute;
        //     right: 0;
        // }

        .gst-table-container {
            width: 100.1%;
            margin-left: 0% !important;
        }

        .ant-checkbox .ant-checkbox-inner {
            width: 25px;
            height: 25px;
        }

        .ant-checkbox-checked .ant-checkbox-inner:after {
            transform: rotate(45deg) scale(1) translate(-50%, -75%);
        }

        .stats-box p {
            font-family: Inter;
        }

        .right-container {
            width: 50%;
            display: flex;
            justify-content: flex-end;
            margin-right: 3%;
            gap: 3%;
            align-items: center;
            height: 100%;
        }

        #gst-add-button {
            background: #424B70;
            color: white;
            display: flex;
            align-items: center;
            gap: 2px;
            height: 60%;
            font-size: 16px;
        }

        #gst-solution-button {
            background: #ED7D31;
            font-size: 16px;

            color: white;
            height: 60%;

            // color: #ED7D31;
            font-family: Inter;
            // font-size: 15.002px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            // height: 60%;
            gap: 5px;
            display: flex;
            align-items: center;
            padding: 1.5%;

        }

        .count {
            font-weight: 600;

        }

        .table-container {
            display: flex;
            width: 100%;
            flex-direction: row;
            align-items: center;
            border: 0.5px solid rgba(0, 0, 0, 0.50);
            border-bottom: none;
        }

        .left-container {
            gap: 2%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 100%;
            width: 50%;
            margin-left: 2%;

        }

        .gst-row-filter {
            display: flex;
            flex-wrap: wrap;
            gap: 2%;
            align-items: center;

            flex-direction: row;

        }

        .gst-row-item {

            font-size: 2.5vmin;


        }

        .gst-row-item img {
            height: 15px
        }

        .mob-gst-stats {
            display: none;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            height: 18%;
            width: 100%;
            position: relative;
            margin-left: 1%;
            margin-right: 1%;
            top: 1%;
        }

        .gst-dash-line {
            text-align: center;
        }

        .sync-report-popup {
            .ant-modal-title {
                font-size: 3vmin !important;
                text-align: center;
            }

            .report-wrap {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: row;
                text-align: end;
                margin-top: 9%;

                .report-title {
                    width: 65%;
                    display: flex;
                    flex-direction: column;
                    align-items: end;
                }

                h3 {
                    line-height: 0.5;
                }

                .report-cnt {
                    color: $secondary-color;
                    width: 15%;
                    display: flex;
                    flex-direction: column;
                    align-items: end;
                    justify-content: center;
                }
            }
        }

    }

    .select-container {
        height: 10vmin;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .heading {
            color: $primary-color;
            font-weight: 400;
            font-size: 3vmin;
            font-family: 'Inter';
        }

        .dropdown-btn {
            width: 100%;

            .ant-radio-group {
                display: flex;
                flex-direction: column;
            }

            .ant-select-selector {
                height: 45px;
                align-items: center;
            }
        }

        .ant-select-dropdown-menu {
            max-height: 200px;
            /* Adjust the height as needed */
        }

        .save-btn {
            width: 28%;
            color: #FFF;
            font-size: 1.8vmin;
            border-radius: 42px;
            background: #424B70;
        }
    }

    .notice-form-container {
        min-height: 40vh;
        max-height: 80vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-top: 2%;
        overflow-y: auto;
        justify-content: center;

        .left-grid {
            width: 95%;
            max-width: 95%;
            height: 100%;
            display: flex;
            flex-direction: column;
            padding: 0 0%;

            .notice-dropdown-btn {
                width: 100%;


                .ant-radio-group {
                    display: flex;
                    flex-direction: column;
                }

                .ant-select-selector {
                    height: 2vh;
                    align-items: center;
                }
            }

            .attach_file_line {
                font-size: 2.2vmin !important;
            }

            .attach_file_img {
                margin-right: 5px !important;
                height: 2.4vmin !important;
            }

        }

        .right-grid {
            width: 95%;
            max-width: 95%;
            height: 100%;
            padding: 0 2%;

            .attach_upload_btn {
                background: $secondary-color !important;
                color: white !important;
                border: none;
                width: 6vw;
                height: 4vh;
                font-size: 2vmin;
            }

            .attach_upload_btn.hover {
                color: white !important;
            }
        }

        .form_item {
            margin-bottom: 5px !important;

            .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
                font-size: 3.5vmin !important;
            }


            .create-popup-btn {
                height: 6vh !important;
            }


        }

        .one {
            margin-bottom: 0px !important;
        }

        .two {
            margin-bottom: 10px !important;
        }

        .gst-err-msg {
            font-size: 1.8vmin !important;
        }
    }


}

@media only screen and (min-width:601px) and (max-width:768px) {
    // :where(.css-dev-only-do-not-override-1ae8k9u).ant-modal .ant-modal-content {
    //    display: none !important;
    // }

    #gst-parent-container {
        .gst-container {}

        .Chart-table-cover {
            flex-direction: column;

            .chart-wrap {
                width: 100%;
                display: none;

                .stats-box {
                    width: 100%;

                    height: 100% !important;
                    flex-direction: row;

                    .gst-chart {
                        width: 20%;

                        .chart {
                            width: 90% !important;
                        }
                    }

                    .chart-stat {
                        height: 100% !important;
                        width: 60%;

                        .stat-item {

                            .stat-sub {





                                p {
                                    font-size: 2vmin !important;
                                }
                            }


                            .stat-subs {


                                span {
                                    font-size: 2vmin;
                                }


                            }
                        }
                    }

                }




            }

            .table-wrap {
                height: 100vh;
                width: 100% !important;
            }




        }

        .sum-drawer {
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 14vh;
            right: 0;
            width: 35vw;
            height: 81.8vh;
            background-color: #B0D3E7;
            margin-top: 1.6%;
            margin-left: -35vw;
            padding: 0.2%;
            padding-left: 1%;
            padding-right: 1%;
            z-index: 3;

            .drawer-header {
                display: flex;
                flex-direction: column;
                height: 10vh;
                border-bottom: 1px solid $primary-color;
                padding-bottom: 1%;

                .drawer-first-line {
                    height: 50%;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .drawer-close-btn {
                        color: #000;
                        height: 53%;
                        cursor: pointer;
                        position: initial;
                    }

                    .summary-heading {
                        margin: auto;
                        color: $font-primay-color ;
                        font-family: 'Inter';
                    }
                }

                .drawer-second-line {
                    height: 50%;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .drawer_global_search {
                        border-radius: 20px;
                        width: 35%;
                        height: 32px !important;
                        background: #fff;
                        margin-right: 3%;

                        input {
                            background: #fff;
                            font-family: Inter;
                        }

                        &:hover {
                            border: 1px solid $font-primay-color;
                        }
                    }

                    .drawer-filter {
                        cursor: pointer;
                        color: $font-primay-color;
                    }

                    .notice-cunt {
                        margin-left: auto;
                        color: #000;
                        font-family: 'Inter';

                        span {
                            color: $primary-color;
                            font-weight: bold;
                        }
                    }
                }
            }

            .drawer-content {
                display: flex;
                flex-direction: column;
                height: 97vh;
                padding-top: 2.5%;
                overflow: auto;
                padding-bottom: -2.5% !important;

                .drawer-card {
                    border-radius: 18px;
                    background-color: #fff;
                    height: 23vh;
                    width: 100%;
                    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
                    margin-bottom: 2.5%;

                    .card-first-split {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-evenly;
                        height: 35%;
                        border-bottom: 1.5px solid rgba(0, 0, 0, 0.3);

                        .content-group {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            width: max-content;
                            height: 100%;

                            .main-line {
                                font-size: 1.8vmin;
                                line-height: 3.5vmin !important;
                                font-size: bold;
                                color: #424B70;
                                font-family: 'Inter';
                                margin: 0;
                            }

                            .sub-line {
                                font-size: 1.8vmin;
                                line-height: 1.8vmin;
                                font-family: 'Inter';
                                margin: 0;
                            }
                        }

                        .view-icon {
                            color: $secondary-color;
                            cursor: pointer;
                        }
                    }

                    .card-second-split {
                        display: flex;
                        flex-direction: row;
                        height: 65%;
                        justify-content: space-evenly;
                        padding-bottom: 4%;

                        .sub-grid {
                            width: 46%;
                            height: 90%;

                            .header-line {
                                color: rgba(35, 35, 35, 0.7);
                                font-size: 1.8vmin;
                                line-height: 3.2vmin;
                                font-family: 'Inter';
                                margin: 0;
                            }

                            .desc-cover {
                                border-radius: 6px;
                                height: 70%;
                                box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                padding: 1%;

                                .desc-sub-line {
                                    font-size: 1.5vmin;
                                    line-height: 1.9vmin;
                                    font-family: 'Inter';
                                    color: #323232;
                                    flex-wrap: nowrap;
                                    margin: 0;
                                }
                            }
                        }
                    }
                }
            }

            .drawer-footer {
                display: flex;
                flex-direction: column;
                height: 9vh;
                border-top: 1px solid $primary-color;
                margin-top: 0px;
                align-items: center;
                justify-content: center;

                .ant-pagination {
                    margin: 0px;
                }
            }
        }


        .table_global_search {
            border-radius: 20px;
            width: 58%;
            height: 40px !important;
            background: rgb(243, 243, 249);

            input {
                background: rgb(243, 243, 249);
            }
        }

        .nav-button {
            display: flex;
            color: #666666;
            position: absolute;
            right: 10%
        }

        .head-top {
            display: flex;
            position: relative;
            width: 100%;
            height: 12vh;
            border-bottom-width: 2px;
        }

        .head-bottom {
            display: flex;
            position: relative;
            width: 100%;
            align-items: center;
            height: 12vh;
            border-bottom-width: 2px;
        }

        .profile-content {
            color: black;
            font-family: Inter;
            display: flex;
            align-items: center;
            position: absolute;
            right: 8px;
            font-size: 25px;
            margin-top: 14px;
        }

        .gst-stats {
            flex-direction: column;
            height: 80vh;
            top: 0%;

            .count-card-wrap {
                width: 100%;
                height: 40%;

                .count-card {


                    .card-content {

                        font-size: 1.5vmin;

                        span {
                            font-size: 3vmin;
                        }
                    }
                }
            }

            .calenderAndChart {
                width: 100%;
                height: 60%;
                display: flex;
                flex-direction: row;

                .cal1 {
                    width: 50%;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    height: 100%;

                    .calendar1style {
                        border-radius: 4%;
                        width: 95%;
                        height: 95% !important;
                    }

                    :where(.css-dev-only-do-not-override-1ae8k9u).ant-picker-calendar-mini .ant-picker-panel {
                        height: 85%;
                    }

                    thead {
                        background-color: #fff !important;
                    }

                    :where(.css-dev-only-do-not-override-1ae8k9u).ant-picker-calendar .ant-picker-date-panel .ant-picker-content th {
                        color: $primary-color !important;
                        font-weight: bold;
                    }

                    :where(.css-dev-only-do-not-override-1ae8k9u).ant-picker-calendar .ant-picker-cell-in-view.ant-picker-cell-selected:not(.ant-picker-cell-disabled) .ant-picker-cell-inner {
                        background-color: $primary-color !important;
                        border: none;
                    }

                    :where(.css-dev-only-do-not-override-1ae8k9u).ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item {
                        color: $secondary-color;
                    }

                    :where(.css-dev-only-do-not-override-1ae8k9u).ant-picker-calendar .ant-picker-panel .ant-picker-content {
                        height: 80%;
                    }
                }

                .cal2 {
                    width: 50%;
                    height: 100%;
                    justify-content: center;
                    align-items: center;
                    display: flex;

                    .stats-boxcal2 {
                        box-shadow: 0px 0px 17px 10px rgba(255, 255, 255, 0.8);
                        height: 95% !important;
                        height: auto;
                        width: 90%;
                        border-radius: 4%;
                        display: flex;
                        flex-direction: column;


                        .gst-chart2 {
                            width: 100%;
                            height: 45%;
                            margin-top: 10%;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            .chart {
                                width: 60% !important;
                            }
                        }

                        .chart-stat2 {
                            height: 35%;
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            align-items: center;

                            .stat-item2 {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                cursor: pointer;
                                width: 100%;
                                height: 30%;
                                justify-content: space-between;

                                .stat-sub2 {
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                    width: 70%;
                                    height: 100%;


                                    .dot2 {
                                        height: 11px !important;
                                        width: 11px !important;
                                        background-color: #bbb;
                                        border-radius: 50%;
                                        display: block;
                                        margin-left: 5%;
                                        margin-right: 3%
                                    }

                                    p {
                                        font-size: 1.9vmin !important;
                                        margin-left: 2%;
                                        line-height: 1.9vmin !important;
                                    }
                                }


                                .stat-subs2 {
                                    display: flex;
                                    flex-direction: row;
                                    width: 30%;
                                    height: 100%;
                                    justify-content: flex-end;
                                    align-items: center;

                                    span {
                                        font-weight: bold;
                                    }

                                    img {
                                        padding-right: 0;
                                    }
                                }
                            }
                        }

                    }
                }

            }

            .calendar-wrap {
                width: 100%;
                justify-content: center;
                align-items: center;
                display: none;

                .calendar {
                    border-radius: 4%;
                    width: 70%;
                    height: 95% !important;
                }

                :where(.css-dev-only-do-not-override-1ae8k9u).ant-picker-calendar-mini .ant-picker-panel {
                    height: 85%;
                }

                thead {
                    background-color: #fff !important;
                }

                :where(.css-dev-only-do-not-override-1ae8k9u).ant-picker-calendar .ant-picker-date-panel .ant-picker-content th {
                    color: $primary-color !important;
                    font-weight: bold;
                }

                :where(.css-dev-only-do-not-override-1ae8k9u).ant-picker-calendar .ant-picker-cell-in-view.ant-picker-cell-selected:not(.ant-picker-cell-disabled) .ant-picker-cell-inner {
                    background-color: $primary-color !important;
                    border: none;
                }

                :where(.css-dev-only-do-not-override-1ae8k9u).ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item {
                    color: $secondary-color;
                }

                :where(.css-dev-only-do-not-override-1ae8k9u).ant-picker-calendar .ant-picker-panel .ant-picker-content {
                    height: 80%;
                }
            }
        }

        :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-pagination {
            display: flex;
            flex-wrap: wrap;
            flex-direction: inherit;
            row-gap: 8px;
        }

        .stat-item {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            cursor: pointer;
        }

        .stat-subs {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        .stat-sub {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 75%
        }

        .stats-box {
            border: none;
            // height: 90%;
            height: auto;
            width: 18%;
            border-radius: 4%;
            display: none;
            justify-content: space-around;
            align-items: center;
            flex-direction: column;

        }


        :where(.css-dev-only-do-not-override-byeoj0).ant-modal .ant-modal-title {
            font-size: 25px;
            font-weight: 600;
            color: #031742;
        }

        #gst-chart {

            border: 1px solid rgb(0 0 0 / 29%);

            width: 40%;
            border-radius: 1%;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
        }

        .gst-chart {
            width: 35%;
            height: 85%;
            margin-left: 3%;

        }

        .stats-box img {
            // height: 35%
            padding: 5%;
            max-height: 35%;
        }

        .chart-stat {
            width: 50%;
            display: flex;
            flex-direction: column;
        }

        .dot {
            height: 12.5px;
            width: 12.5px;
            background-color: #bbb;
            border-radius: 50%;
            display: block;
            margin-left: 4%;
        }

        .gst-table {

            display: flex;
            justify-content: center;
            flex-direction: column;
            width: 96%;
            margin-top: 1%;
            padding: 2%;

        }

        :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-column-title {
            position: relative;
            z-index: 1;
            flex: 1;
            width: max-content;
        }

        :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-thead>tr>th,
        :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-thead>tr>td {
            position: relative;
            color: rgba(0, 0, 0, 0.88);
            font-weight: 600;
            text-align: start;
            background: #0000001a;
            border-bottom: 1px solid #f0f0f0;
            transition: background 0.2s ease;
        }

        // .ant-pagination-total-text {
        //     position: absolute;
        //     left: 0;
        // }

        // .ant-pagination-options {
        //     position: absolute;
        //     right: 0;
        // }

        .gst-table-container {
            width: 100.1%;
            margin-left: 0% !important;
        }

        .ant-checkbox .ant-checkbox-inner {
            width: 25px;
            height: 25px;
        }

        .ant-checkbox-checked .ant-checkbox-inner:after {
            transform: rotate(45deg) scale(1) translate(-50%, -75%);
        }

        .stats-box p {
            font-family: Inter;
        }

        .right-container {
            width: 50%;
            display: flex;
            justify-content: flex-end;
            margin-right: 3%;
            gap: 3%;
            align-items: center;
            height: 100%;
        }

        #gst-add-button {
            background: #424B70;
            color: white;
            display: flex;
            align-items: center;
            gap: 2px;
            height: 60%;
            font-size: 16px;
        }

        #gst-solution-button {
            background: #ED7D31;
            font-size: 16px;

            color: white;
            height: 60%;

            // color: #ED7D31;
            font-family: Inter;
            // font-size: 15.002px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            // height: 60%;
            gap: 5px;
            display: flex;
            align-items: center;
            padding: 1.5%;

        }

        .count {
            font-weight: 600;

        }

        .table-container {
            display: flex;
            width: 100%;
            flex-direction: row;
            align-items: center;
            border: 0.5px solid rgba(0, 0, 0, 0.50);
            border-bottom: none;
        }

        .left-container {
            gap: 2%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 100%;
            width: 50%;
            margin-left: 2%;

        }

        .gst-row-filter {
            display: flex;
            flex-wrap: wrap;
            gap: 2%;
            align-items: center;

            flex-direction: row;

        }

        .gst-row-item {
            display: flex;
            flex-direction: row;
            gap: 13%;
            width: 23%;
            margin-bottom: 1.5%;
            align-items: center;

        }

        .gst-row-item img {
            height: 15px
        }

        .mob-gst-stats {
            display: none;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            height: 18%;
            width: 100%;
            position: relative;
            margin-left: 1%;
            margin-right: 1%;
            top: 1%;
        }

        .gst-dash-line {
            text-align: center;
        }

        .sync-report-popup {
            .ant-modal-title {
                font-size: 3vmin !important;
                text-align: center;
            }

            .report-wrap {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: row;
                text-align: end;
                margin-top: 9%;

                .report-title {
                    width: 65%;
                    display: flex;
                    flex-direction: column;
                    align-items: end;
                }

                h3 {
                    line-height: 0.5;
                }

                .report-cnt {
                    color: $secondary-color;
                    width: 15%;
                    display: flex;
                    flex-direction: column;
                    align-items: end;
                    justify-content: center;
                }
            }
        }

    }

    .select-container {
        height: 10vmin;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .heading {
            color: $primary-color;
            font-weight: 400;
            font-size: 1.7vmin;
            font-family: 'Inter';
        }

        .dropdown-btn {
            width: 100%;

            .ant-radio-group {
                display: flex;
                flex-direction: column;
            }

            .ant-select-selector {
                height: 45px;
                align-items: center;
            }
        }

        .ant-select-dropdown-menu {
            max-height: 200px;
            /* Adjust the height as needed */
        }

        .save-btn {
            width: 28%;
            color: #FFF;
            font-size: 1.8vmin;
            border-radius: 42px;
            background: #424B70;
        }
    }

    .notice-form-container {
        min-height: 80vh;
        max-height: 80vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow-y: auto !important;

        .left-grid {
            width: 95%;
            max-width: 95%;
            height: 100%;
            display: flex;
            flex-direction: column;
            padding: 0 0%;

            .notice-dropdown-btn {
                width: 100%;


                .ant-radio-group {
                    display: flex;
                    flex-direction: column;
                }

                .ant-select-selector {
                    height: 2vh;
                    align-items: center;
                }
            }

            .attach_file_line {
                font-size: 2.2vmin !important;
            }

            .attach_file_img {
                margin-right: 5px !important;
                height: 2.4vmin !important;
            }

        }

        .right-grid {
            width: 95%;
            max-width: 95%;
            height: 100%;
            padding: 0 0%;

            .attach_upload_btn {
                background: $secondary-color !important;
                color: white !important;
                border: none;
                width: 6vw;
                height: 4vh;
                font-size: 2vmin;
            }

            .attach_upload_btn.hover {
                color: white !important;
            }
        }

        .form_item {
            margin-bottom: 5px !important;

            .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
                font-size: 3.5vmin !important;
            }


            .create-popup-btn {
                height: 6vh !important;
            }


        }

        .one {
            margin-bottom: 0px !important;
        }

        .two {
            margin-bottom: 10px !important;
        }

        .gst-err-msg {
            font-size: 1.8vmin !important;
        }
    }
}

@media only screen and (min-width:769px)and (max-width:1024px) {
    #gst-parent-container {

        .Chart-table-cover {

            .chart-wrap {
                width: 25%;

                .stats-box {
                    width: 95%;


                    .gst-chart {
                        height: 50%;

                        .chart {
                            width: 70% !important;
                        }
                    }

                    .chart-stat {

                        .stat-item {
                            width: 95%;

                            .stat-sub {


                                .dot {}

                                p {
                                    font-size: 1.4vmin !important;
                                }
                            }


                            .stat-subs {
                                font-size: 1.6vmin;

                                span {
                                    font-weight: bold;
                                }

                                img {
                                    padding-right: 0;
                                }
                            }
                        }
                    }

                }



            }

            .table-wrap {
                height: 100vh;
                width: 75% !important;
            }




        }

        .sum-drawer {
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 14vh;
            right: 0;
            width: 35vw;
            height: 81.8vh;
            background-color: #B0D3E7;
            margin-top: 1.6%;
            margin-left: -35vw;
            padding: 0.2%;
            padding-left: 1%;
            padding-right: 1%;
            z-index: 3;

            .drawer-header {
                display: flex;
                flex-direction: column;
                height: 10vh;
                border-bottom: 1px solid $primary-color;
                padding-bottom: 1%;

                .drawer-first-line {
                    height: 50%;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .drawer-close-btn {
                        color: #000;
                        height: 53%;
                        cursor: pointer;
                        position: initial;
                    }

                    .summary-heading {
                        margin: auto;
                        color: $font-primay-color ;
                        font-family: 'Inter';
                    }
                }

                .drawer-second-line {
                    height: 50%;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .drawer_global_search {
                        border-radius: 20px;
                        width: 35%;
                        height: 32px !important;
                        background: #fff;
                        margin-right: 3%;

                        input {
                            background: #fff;
                            font-family: Inter;
                        }

                        &:hover {
                            border: 1px solid $font-primay-color;
                        }
                    }

                    .drawer-filter {
                        cursor: pointer;
                        color: $font-primay-color;
                    }

                    .notice-cunt {
                        margin-left: auto;
                        color: #000;
                        font-family: 'Inter';

                        span {
                            color: $primary-color;
                            font-weight: bold;
                        }
                    }
                }
            }

            .drawer-content {
                display: flex;
                flex-direction: column;
                height: 97vh;
                padding-top: 2.5%;
                overflow: auto;
                padding-bottom: -2.5% !important;

                .drawer-card {
                    border-radius: 18px;
                    background-color: #fff;
                    height: 23vh;
                    width: 100%;
                    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
                    margin-bottom: 2.5%;

                    .card-first-split {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-evenly;
                        height: 35%;
                        border-bottom: 1.5px solid rgba(0, 0, 0, 0.3);

                        .content-group {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            width: max-content;
                            height: 100%;

                            .main-line {
                                font-size: 1.8vmin;
                                line-height: 3.5vmin !important;
                                font-size: bold;
                                color: #424B70;
                                font-family: 'Inter';
                                margin: 0;
                            }

                            .sub-line {
                                font-size: 1.8vmin;
                                line-height: 1.8vmin;
                                font-family: 'Inter';
                                margin: 0;
                            }
                        }

                        .view-icon {
                            color: $secondary-color;
                            cursor: pointer;
                        }
                    }

                    .card-second-split {
                        display: flex;
                        flex-direction: row;
                        height: 65%;
                        justify-content: space-evenly;
                        padding-bottom: 4%;

                        .sub-grid {
                            width: 46%;
                            height: 90%;

                            .header-line {
                                color: rgba(35, 35, 35, 0.7);
                                font-size: 1.8vmin;
                                line-height: 3.2vmin;
                                font-family: 'Inter';
                                margin: 0;
                            }

                            .desc-cover {
                                border-radius: 6px;
                                height: 70%;
                                box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                padding: 1%;

                                .desc-sub-line {
                                    font-size: 1.5vmin;
                                    line-height: 1.9vmin;
                                    font-family: 'Inter';
                                    color: #323232;
                                    flex-wrap: nowrap;
                                    margin: 0;
                                }
                            }
                        }
                    }
                }
            }

            .drawer-footer {
                display: flex;
                flex-direction: column;
                height: 9vh;
                border-top: 1px solid $primary-color;
                margin-top: 0px;
                align-items: center;
                justify-content: center;

                .ant-pagination {
                    margin: 0px;
                }
            }
        }

        .gst-header {
            height: 24vh;
            display: flex;
            position: relative;
            flex-direction: column;
            box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.15);

        }

        .table_global_search {
            border-radius: 20px;
            width: 58%;
            height: 40px !important;
            background: rgb(243, 243, 249);

            input {
                background: rgb(243, 243, 249);
            }
        }

        .nav-button {
            display: flex;
            color: #666666;
            position: absolute;
            right: 10%
        }

        .head-top {
            display: flex;
            position: relative;
            width: 100%;
            height: 12vh;
            border-bottom-width: 2px;
        }

        .head-bottom {
            display: flex;
            position: relative;
            width: 100%;
            align-items: center;
            height: 12vh;
            border-bottom-width: 2px;
        }

        .profile-content {
            color: black;
            font-family: Inter;
            display: flex;
            align-items: center;
            position: absolute;
            right: 8px;
            font-size: 25px;
            margin-top: 14px;
        }

        .gst-stats {


            .count-card-wrap {

                // overflow-x: auto;
                .count-card {
                    height: 60%;
                    width: 30% !important;
                    margin: 1%;


                    .card-content {
                        height: 30%;
                        width: 100%;

                        font-size: 1.5vmin;

                        span {
                            font-size: 2vmin;
                            line-height: 5.2vmin;
                        }
                    }
                }
            }

            .calendar-wrap {
                width: 30%;
                border-radius: 4%;
                box-shadow: 0px 0px 17px 10px rgba(255, 255, 255, 0.8);
                padding: 0%;
                justify-content: center;
                align-items: center;
                display: flex;

                .calendar {
                    border-radius: 4%;
                    width: 90%;
                    height: 95% !important;
                }

                :where(.css-dev-only-do-not-override-1ae8k9u).ant-picker-calendar-mini .ant-picker-panel {
                    height: 85%;
                }

                thead {
                    background-color: #fff !important;
                }

                :where(.css-dev-only-do-not-override-1ae8k9u).ant-picker-calendar .ant-picker-date-panel .ant-picker-content th {
                    color: $primary-color !important;
                    font-weight: bold;
                }

                :where(.css-dev-only-do-not-override-1ae8k9u).ant-picker-calendar .ant-picker-cell-in-view.ant-picker-cell-selected:not(.ant-picker-cell-disabled) .ant-picker-cell-inner {
                    background-color: $primary-color !important;
                    border: none;
                }

                :where(.css-dev-only-do-not-override-1ae8k9u).ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item {
                    color: $secondary-color;
                }

                :where(.css-dev-only-do-not-override-1ae8k9u).ant-picker-calendar .ant-picker-panel .ant-picker-content {
                    height: 80%;
                }
            }
        }

        :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-pagination {
            display: flex;
            flex-wrap: wrap;
            flex-direction: inherit;
            row-gap: 8px;
        }

        .stat-item {}

        .stat-subs {}

        .stat-sub {}

        .stats-box {}




        #gst-chart {

            border: 1px solid rgb(0 0 0 / 29%);
            width: 40%;
            border-radius: 1%;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
        }

        .gst-chart {
            width: 35%;
            height: 85%;
            margin-left: 3%;

        }

        .stats-box img {
            // height: 35%
            padding: 5%;
            max-height: 35%;
        }

        .chart-stat {
            width: 50%;
            display: flex;
            flex-direction: column;
        }

        .dot {
            height: 12.5px;
            width: 12.5px;
            background-color: #bbb;
            border-radius: 50%;
            display: block;
            margin-right: 3%
        }

        .gst-table {

            display: flex;
            justify-content: center;
            flex-direction: column;
            width: 96%;
            margin-top: 1%;
            padding: 2%;

        }

        :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-column-title {
            position: relative;
            z-index: 1;
            flex: 1;
            width: max-content;
        }

        :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-thead>tr>th,
        :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-thead>tr>td {
            position: relative;
            color: rgba(0, 0, 0, 0.88);
            font-weight: 600;
            text-align: start;
            background: #0000001a;
            border-bottom: 1px solid #f0f0f0;
            transition: background 0.2s ease;
        }

        // .ant-pagination-total-text {
        //     position: absolute;
        //     left: 0;
        // }

        // .ant-pagination-options {
        //     position: absolute;
        //     right: 0;
        // }

        .gst-table-container {
            width: 100.1%;
            margin-left: 0% !important;
        }

        .ant-checkbox .ant-checkbox-inner {
            width: 25px;
            height: 25px;
        }

        .ant-checkbox-checked .ant-checkbox-inner:after {
            transform: rotate(45deg) scale(1) translate(-50%, -75%);
        }

        .stats-box p {
            font-family: Inter;
        }

        .right-container {
            width: 50%;
            display: flex;
            justify-content: flex-end;
            margin-right: 3%;
            gap: 3%;
            align-items: center;
            height: 100%;
        }

        #gst-add-button {
            background: #424B70;
            color: white;
            display: flex;
            align-items: center;
            gap: 2px;
            height: 60%;
            font-size: 16px;
        }

        #gst-solution-button {
            background: #ED7D31;
            font-size: 16px;

            color: white;
            height: 60%;

            // color: #ED7D31;
            font-family: Inter;
            // font-size: 15.002px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            // height: 60%;
            gap: 5px;
            display: flex;
            align-items: center;
            padding: 1.5%;

        }

        .count {
            font-weight: 600;

        }

        .table-container {
            display: flex;
            width: 100%;
            flex-direction: row;
            align-items: center;
            border: 0.5px solid rgba(0, 0, 0, 0.50);
            border-bottom: none;
        }

        .left-container {
            gap: 2%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 100%;
            width: 50%;
            margin-left: 2%;

        }

        .gst-row-filter {
            display: flex;
            flex-wrap: wrap;
            gap: 2%;
            align-items: center;

            flex-direction: row;

        }

        .gst-row-item {
            display: flex;
            flex-direction: row;
            gap: 13%;
            width: 23%;
            margin-bottom: 1.5%;
            align-items: center;

        }

        .gst-row-item img {
            height: 15px
        }

        .mob-gst-stats {
            display: none;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            height: 18%;
            width: 100%;
            position: relative;
            margin-left: 1%;
            margin-right: 1%;
            top: 1%;
        }

        .gst-dash-line {
            text-align: center;
        }

        .sync-report-popup {
            .ant-modal-title {
                font-size: 3vmin !important;
                text-align: center;
            }

            .report-wrap {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: row;
                text-align: end;
                margin-top: 9%;

                .report-title {
                    width: 65%;
                    display: flex;
                    flex-direction: column;
                    align-items: end;
                }

                h3 {
                    line-height: 0.5;
                }

                .report-cnt {
                    color: $secondary-color;
                    width: 15%;
                    display: flex;
                    flex-direction: column;
                    align-items: end;
                    justify-content: center;
                }
            }
        }

    }

    .select-container {
        height: 10vmin;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .heading {
            color: $primary-color;
            font-weight: 400;
            font-size: 1.7vmin;
            font-family: 'Inter';
        }

        .dropdown-btn {
            width: 100%;

            .ant-radio-group {
                display: flex;
                flex-direction: column;
            }

            .ant-select-selector {
                height: 45px;
                align-items: center;
            }
        }

        .ant-select-dropdown-menu {
            max-height: 200px;
            /* Adjust the height as needed */
        }

        .save-btn {
            width: 28%;
            color: #FFF;
            font-size: 1.8vmin;
            border-radius: 42px;
            background: #424B70;
        }
    }

    .notice-form-container {
        min-height: 69vh;
        max-height: auto;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;

        .left-grid {
            width: 50%;
            max-width: 50%;
            height: 100%;
            display: flex;
            flex-direction: column;
            padding: 0 2%;

            .notice-dropdown-btn {
                width: 100%;


                .ant-radio-group {
                    display: flex;
                    flex-direction: column;
                }

                .ant-select-selector {
                    height: 2vh;
                    align-items: center;
                }
            }

            .attach_file_line {
                font-size: 2.2vmin !important;
            }

            .attach_file_img {
                margin-right: 5px !important;
                height: 2.4vmin !important;
            }

        }

        .right-grid {
            width: 50%;
            max-width: 50%;
            height: 100%;
            padding: 0 2%;

            .attach_upload_btn {
                background: $secondary-color !important;
                color: white !important;
                border: none;
                width: 6vw;
                height: 4vh;
                font-size: 2vmin;
            }

            .attach_upload_btn.hover {
                color: white !important;
            }
        }

        .form_item {
            margin-bottom: 5px !important;

            .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
                font-size: 3.5vmin !important;
            }


            .create-popup-btn {
                height: 6vh !important;
            }


        }

        .one {
            margin-bottom: 0px !important;
        }

        .two {
            margin-bottom: 10px !important;
        }

        .gst-err-msg {
            font-size: 1.8vmin !important;
        }
    }
}

@media only screen and (min-width:1025px) and (max-width:1440px) {
    #gst-parent-container {

        .Chart-table-cover {


            .chart-wrap {
                width: 20%;
                padding-left: 0%;
                align-items: center;
                padding-left: 1.5%;

                .stats-box {
                    height: 45vh !important;
                    width: 100%;

                    .gst-chart {

                        .chart {
                            width: 65% !important;
                        }
                    }

                    .chart-stat {


                        .stat-item {
                            width: 100%;


                            .stat-sub {



                                .dot {}

                                p {
                                    font-size: 1.7vmin !important;
                                }
                            }


                            .stat-subs {


                                span {
                                    font-size: 1.7vmin;
                                }

                                img {
                                    padding-right: 0;
                                }
                            }
                        }
                    }

                }

                .complaince-box {
                    box-shadow: 0px 0px 17px 10px rgba(255, 255, 255, 0.8);
                    height: 65vh !important;
                    height: auto;
                    width: 90%;
                    border-radius: 4%;
                    display: flex;
                    background-color: red;
                    flex-direction: column;
                    justify-content: space-evenly;
                    margin-top: 5%;
                    padding-left: 5%;
                    padding-right: 5%;
                    padding-bottom: 5%;

                    .header {
                        height: 13%;
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        .heading {
                            color: #666666;
                        }

                        .reload-img {
                            margin-left: auto;

                        }
                    }

                    .complaince-content-wrap {
                        height: 87%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;

                        .complaince-content {
                            width: 48%;
                            height: 100%;
                            background-color: #666666;
                        }
                    }
                }


            }

            .table-wrap {
                // height: 100vh;
                width: 79vw !important;
            }




        }

        .sum-drawer {
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 14vh;
            right: 0;
            width: 35vw;
            height: 81.8vh;
            // background-color: #B0D3E7;
            margin-top: 1.6%;
            margin-left: -35vw;
            padding: 0.2%;
            padding-left: 1%;
            padding-right: 1%;
            z-index: 3;

            .drawer-header {
                display: flex;
                flex-direction: column;
                height: 10vh;
                border-bottom: 1px solid $primary-color;
                padding-bottom: 1%;

                .drawer-first-line {
                    height: 50%;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .drawer-close-btn {
                        color: #000;
                        height: 53%;
                        cursor: pointer;
                        position: initial;
                    }

                    .summary-heading {
                        margin: auto;
                        color: $font-primay-color ;
                        font-family: 'Inter';
                    }
                }

                .drawer-second-line {
                    height: 50%;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .drawer_global_search {
                        border-radius: 20px;
                        width: 35%;
                        height: 32px !important;
                        background: #fff;
                        margin-right: 3%;

                        input {
                            background: #fff;
                            font-family: Inter;
                        }

                        &:hover {
                            border: 1px solid $font-primay-color;
                        }
                    }

                    .drawer-filter {
                        cursor: pointer;
                        color: $font-primay-color;
                    }

                    .notice-cunt {
                        margin-left: auto;
                        color: #000;
                        font-family: 'Inter';

                        span {
                            color: $primary-color;
                            font-weight: bold;
                        }
                    }
                }
            }

            .drawer-content {
                display: flex;
                flex-direction: column;
                height: 97vh;
                padding-top: 2.5%;
                overflow: auto;
                padding-bottom: -2.5% !important;

                .drawer-card {
                    border-radius: 18px;
                    background-color: #fff;
                    height: 23vh;
                    width: 100%;
                    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
                    margin-bottom: 2.5%;

                    .card-first-split {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-evenly;
                        height: 35%;
                        border-bottom: 1.5px solid rgba(0, 0, 0, 0.3);

                        .content-group {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            width: max-content;
                            height: 100%;

                            .main-line {
                                font-size: 1.8vmin;
                                line-height: 3.5vmin !important;
                                font-size: bold;
                                color: #424B70;
                                font-family: 'Inter';
                                margin: 0;
                            }

                            .sub-line {
                                font-size: 1.8vmin;
                                line-height: 1.8vmin;
                                font-family: 'Inter';
                                margin: 0;
                            }
                        }

                        .view-icon {
                            color: $secondary-color;
                            cursor: pointer;
                        }
                    }

                    .card-second-split {
                        display: flex;
                        flex-direction: row;
                        height: 65%;
                        justify-content: space-evenly;
                        padding-bottom: 4%;

                        .sub-grid {
                            width: 46%;
                            height: 90%;

                            .header-line {
                                color: rgba(35, 35, 35, 0.7);
                                font-size: 1.8vmin;
                                line-height: 3.2vmin;
                                font-family: 'Inter';
                                margin: 0;
                            }

                            .desc-cover {
                                border-radius: 6px;
                                height: 70%;
                                box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                padding: 1%;

                                .desc-sub-line {
                                    font-size: 1.5vmin;
                                    line-height: 1.9vmin;
                                    font-family: 'Inter';
                                    color: #323232;
                                    flex-wrap: nowrap;
                                    margin: 0;
                                }
                            }
                        }
                    }
                }
            }

            .drawer-footer {
                display: flex;
                flex-direction: column;
                height: 9vh;
                border-top: 1px solid $primary-color;
                margin-top: 0px;
                align-items: center;
                justify-content: center;

                .ant-pagination {
                    margin: 0px;
                }
            }
        }

        .gst-header {
            height: 24vh;
            display: flex;
            position: relative;
            flex-direction: column;
            box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.15);

        }

        .table_global_search {
            border-radius: 20px;
            width: 58%;
            height: 40px !important;
            background: rgb(243, 243, 249);

            input {
                background: rgb(243, 243, 249);
            }
        }

        .nav-button {
            display: flex;
            color: #666666;
            position: absolute;
            right: 10%
        }

        .head-top {
            display: flex;
            position: relative;
            width: 100%;
            height: 12vh;
            border-bottom-width: 2px;
        }

        .head-bottom {
            display: flex;
            position: relative;
            width: 100%;
            align-items: center;
            height: 12vh;
            border-bottom-width: 2px;
        }

        .profile-content {
            color: black;
            font-family: Inter;
            display: flex;
            align-items: center;
            position: absolute;
            right: 8px;
            font-size: 25px;
            margin-top: 14px;
        }

        .gst-stats {
            .count-card-wrap {
                .count-card {
                    height: 70%;

                    .card-content {
                        font-size: 2.1vmin;


                        span {
                            font-size: 3vmin;
                            line-height: 5.2vmin;
                        }
                    }
                }
            }

            .calendar-wrap {

                padding: 0.5%;

                .calendar {
                    border-radius: 4%;
                    height: 100% !important;
                }

                :where(.css-dev-only-do-not-override-1ae8k9u).ant-picker-calendar-mini .ant-picker-panel {
                    height: 85%;
                }

                thead {
                    background-color: #fff !important;
                }

                :where(.css-dev-only-do-not-override-1ae8k9u).ant-picker-calendar .ant-picker-date-panel .ant-picker-content th {
                    color: $primary-color !important;
                    font-weight: bold;
                }

                :where(.css-dev-only-do-not-override-1ae8k9u).ant-picker-calendar .ant-picker-cell-in-view.ant-picker-cell-selected:not(.ant-picker-cell-disabled) .ant-picker-cell-inner {
                    background-color: $primary-color !important;
                    border: none;
                }

                :where(.css-dev-only-do-not-override-1ae8k9u).ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item {
                    color: $secondary-color;
                }

                :where(.css-dev-only-do-not-override-1ae8k9u).ant-picker-calendar .ant-picker-panel .ant-picker-content {
                    height: 80%;
                }
            }
        }

        :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-pagination {
            display: flex;
            flex-wrap: wrap;
            flex-direction: inherit;
            row-gap: 8px;
        }

        .stat-item {}

        .stat-subs {}

        .stat-sub {}

        .stats-box {}


        :where(.css-dev-only-do-not-override-byeoj0).ant-modal .ant-modal-title {
            font-size: 25px;
            font-weight: 600;
            color: #031742;
        }

        #gst-chart {}

        .gst-chart {}

        .stats-box img {}

        .chart-stat {}

        .dot {
            height: 12.5px;
            width: 12.5px;
            background-color: red;
            border-radius: 50%;
            display: block;
            margin-right: 3%
        }

        .gst-table {

            display: flex;
            justify-content: center;
            flex-direction: column;
            width: 96%;
            margin-top: 1%;
            padding: 2%;

        }

        :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-column-title {
            position: relative;
            z-index: 1;
            flex: 1;
            width: max-content;
        }

        :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-thead>tr>th,
        :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-thead>tr>td {
            position: relative;
            color: rgba(0, 0, 0, 0.88);
            font-weight: 600;
            text-align: start;
            background: #0000001a;
            border-bottom: 1px solid #f0f0f0;
            transition: background 0.2s ease;
        }

        // .ant-pagination-total-text {
        //     position: absolute;
        //     left: 0;
        // }

        // .ant-pagination-options {
        //     position: absolute;
        //     right: 0;
        // }


        .gst-table-container {
            width: 100.1%;
            margin-left: 0% !important;
        }

        .ant-checkbox .ant-checkbox-inner {
            width: 25px;
            height: 25px;
        }

        .ant-checkbox-checked .ant-checkbox-inner:after {
            transform: rotate(45deg) scale(1) translate(-50%, -75%);
        }

        .stats-box p {
            font-family: Inter;
        }

        .right-container {
            width: 50%;
            display: flex;
            justify-content: flex-end;
            margin-right: 3%;
            gap: 3%;
            align-items: center;
            height: 100%;
        }

        #gst-add-button {
            background: #424B70;
            color: white;
            display: flex;
            align-items: center;
            gap: 2px;
            height: 60%;
            font-size: 16px;
        }

        #gst-solution-button {
            background: #ED7D31;
            font-size: 16px;

            color: white;
            height: 60%;

            // color: #ED7D31;
            font-family: Inter;
            // font-size: 15.002px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            // height: 60%;
            gap: 5px;
            display: flex;
            align-items: center;
            padding: 1.5%;

        }

        .count {
            font-weight: 600;

        }

        .table-container {
            display: flex;
            width: 100%;
            flex-direction: row;
            align-items: center;
            border: 0.5px solid rgba(0, 0, 0, 0.50);
            border-bottom: none;
        }

        .left-container {
            gap: 2%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 100%;
            width: 50%;
            margin-left: 2%;

        }

        .gst-row-filter {
            display: flex;
            flex-wrap: wrap;
            gap: 2%;
            align-items: center;

            flex-direction: row;

        }

        .gst-row-item {
            display: flex;
            flex-direction: row;
            gap: 13%;
            height: 6vh !important;
            width: 17vw;
            margin-bottom: 1.5%;
            align-items: center;

        }

        .gst-row-item img {
            height: 15px
        }

        .mob-gst-stats {
            display: none;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            height: 18%;
            width: 100%;
            position: relative;
            margin-left: 1%;
            margin-right: 1%;
            top: 1%;
        }

        .gst-dash-line {
            text-align: center;
        }

        .sync-report-popup {
            .ant-modal-title {
                font-size: 3vmin !important;
                text-align: center;
            }

            .report-wrap {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: row;
                text-align: end;
                margin-top: 9%;

                .report-title {
                    width: 65%;
                    display: flex;
                    flex-direction: column;
                    align-items: end;
                }

                h3 {
                    line-height: 0.5;
                }

                .report-cnt {
                    color: $secondary-color;
                    width: 15%;
                    display: flex;
                    flex-direction: column;
                    align-items: end;
                    justify-content: center;
                }
            }
        }

    }

    .select-container {
        height: 10vmin;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .heading {
            color: $primary-color;
            font-weight: 400;
            font-size: 1.7vmin;
            font-family: 'Inter';
        }

        .dropdown-btn {
            width: 100%;

            .ant-radio-group {
                display: flex;
                flex-direction: column;
            }

            .ant-select-selector {
                height: 45px;
                align-items: center;
            }
        }

        .ant-select-dropdown-menu {
            max-height: 200px;
            /* Adjust the height as needed */
        }

        .save-btn {
            width: 28%;
            color: #FFF;
            font-size: 1.8vmin;
            border-radius: 42px;
            background: #424B70;
        }
    }

    .notice-form-container {


        .left-grid {

            .notice-dropdown-btn {
                width: 100%;


                .ant-radio-group {
                    display: flex;
                    flex-direction: column;
                }

                .ant-select-selector {
                    height: 1.5vh;
                    align-items: center;
                }
            }

            .attach_file_line {
                font-size: 2.2vmin !important;
            }

            .attach_file_img {
                margin-right: 5px !important;
                height: 2.4vmin !important;
            }

        }

        .right-grid {
            width: 50%;
            max-width: 50%;
            height: 100%;
            padding: 0 2%;

            .attach_upload_btn {
                background: $secondary-color !important;
                color: white !important;
                border: none;
                width: 6vw;
                height: 4vh;
                font-size: 2vmin;
            }

            .attach_upload_btn.hover {
                color: white !important;
            }
        }

        .form_item {
            margin-bottom: 5px !important;

            .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
                font-size: 3.5vmin !important;
            }


            .create-popup-btn {
                height: 6vh !important;
            }


        }

        .one {
            margin-bottom: 0px !important;
        }

        .two {
            margin-bottom: 10px !important;
        }

        .gst-err-msg {
            font-size: 1.8vmin !important;
        }
    }
}

@media only screen and (min-width:1441px) {
    #gst-parent-container {


        .Chart-table-cover {

            .chart-wrap {

                .stats-box {


                    .gst-chart {


                        .chart {
                            width: 70% !important;
                        }
                    }

                    .chart-stat {


                        .stat-item {


                            .stat-sub {



                                .dot {
                                    height: 15px !important;
                                    width: 15px !important;

                                }

                                p {
                                    font-size: 2vmin !important;
                                }
                            }


                            .stat-subs {


                                span {
                                    font-size: 2vmin;
                                }

                                img {}
                            }
                        }
                    }

                }



            }

            .table-wrap {
                width: 79vw !important;
                height: auto;
            }




        }

        .sum-drawer {


            .drawer-header {
                display: flex;
                flex-direction: column;
                height: 10vh;
                background-color: rebeccapurple;
                border-bottom: 1px solid $primary-color;
                padding-bottom: 1%;

                .drawer-first-line {
                    height: 50%;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .drawer-close-btn {
                        color: #000;
                        height: 53%;
                        cursor: pointer;
                        position: initial;
                    }

                    .summary-heading {
                        margin: auto;
                        color: $font-primay-color ;
                        font-family: 'Inter';
                    }
                }

                .drawer-second-line {
                    height: 50%;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .drawer_global_search {
                        border-radius: 20px;
                        width: 35%;
                        height: 32px !important;
                        background: #fff;
                        margin-right: 3%;

                        input {
                            background: #fff;
                            font-family: Inter;
                        }

                        &:hover {
                            border: 1px solid $font-primay-color;
                        }
                    }

                    .drawer-filter {
                        cursor: pointer;
                        color: $font-primay-color;
                    }

                    .notice-cunt {
                        margin-left: auto;
                        color: #000;
                        font-family: 'Inter';

                        span {
                            color: $primary-color;
                            font-weight: bold;
                        }
                    }
                }
            }

            .drawer-content {
                display: flex;
                flex-direction: column;
                height: 97vh;
                padding-top: 2.5%;
                overflow: auto;
                padding-bottom: -2.5% !important;

                .drawer-card {
                    border-radius: 18px;
                    background-color: #fff;
                    height: 23vh;
                    width: 100%;
                    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
                    margin-bottom: 2.5%;

                    .card-first-split {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-evenly;
                        height: 35%;
                        border-bottom: 1.5px solid rgba(0, 0, 0, 0.3);

                        .content-group {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            width: max-content;
                            height: 100%;

                            .main-line {
                                font-size: 1.8vmin;
                                line-height: 3.5vmin !important;
                                font-size: bold;
                                color: #424B70;
                                font-family: 'Inter';
                                margin: 0;
                            }

                            .sub-line {
                                font-size: 1.8vmin;
                                line-height: 1.8vmin;
                                font-family: 'Inter';
                                margin: 0;
                            }
                        }

                        .view-icon {
                            color: $secondary-color;
                            cursor: pointer;
                        }
                    }

                    .card-second-split {
                        display: flex;
                        flex-direction: row;
                        height: 65%;
                        justify-content: space-evenly;
                        padding-bottom: 4%;

                        .sub-grid {
                            width: 46%;
                            height: 90%;

                            .header-line {
                                color: rgba(35, 35, 35, 0.7);
                                font-size: 1.8vmin;
                                line-height: 3.2vmin;
                                font-family: 'Inter';
                                margin: 0;
                            }

                            .desc-cover {
                                border-radius: 6px;
                                height: 70%;
                                box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                padding: 1%;

                                .desc-sub-line {
                                    font-size: 1.5vmin;
                                    line-height: 1.9vmin;
                                    font-family: 'Inter';
                                    color: #323232;
                                    flex-wrap: nowrap;
                                    margin: 0;
                                }
                            }
                        }
                    }
                }
            }

            .drawer-footer {
                display: flex;
                flex-direction: column;
                height: 9vh;
                border-top: 1px solid $primary-color;
                margin-top: 0px;
                align-items: center;
                justify-content: center;

                .ant-pagination {
                    margin: 0px;
                }
            }
        }

        .gst-header {

            height: 24vh;
            display: flex;
            position: relative;
            flex-direction: column;
            box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.15);

        }

        .table_global_search {
            border-radius: 20px;
            width: 58%;
            height: 40px !important;
            background: rgb(243, 243, 249);

            input {
                background: rgb(243, 243, 249);
            }
        }

        .nav-button {
            display: flex;
            color: #666666;
            position: absolute;
            right: 10%
        }

        .head-top {
            display: flex;
            position: relative;
            width: 100%;
            height: 12vh;
            border-bottom-width: 2px;
        }

        .head-bottom {
            display: flex;
            position: relative;
            width: 100%;
            align-items: center;
            height: 12vh;
            border-bottom-width: 2px;
        }

        .profile-content {
            color: black;
            font-family: Inter;
            display: flex;
            align-items: center;
            position: absolute;
            right: 8px;
            font-size: 25px;
            margin-top: 14px;
        }

        .gst-stats {


            .count-card-wrap {

                .count-card {
                    width: 15%;


                    .card-content {
                        height: 30%;
                        width: 100%;
                        font-size: 2.1vmin;

                        span {
                            font-size: 3.5vmin;
                        }
                    }
                }
            }

            .calendar-wrap {
                justify-content: center;
                align-items: center;
                display: flex;

                .calendar {
                    height: 95% !important;
                    width: 95%;
                }

                :where(.css-dev-only-do-not-override-1ae8k9u).ant-picker-calendar-mini .ant-picker-panel {
                    height: 85%;
                }

                thead {
                    background-color: #fff !important;
                }




            }
        }


    }


}