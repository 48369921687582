@import '../../App.scss';

#cover_wrap {
    display: flex;
    flex-direction: row;

    .profile_wrap {
        width: 24vw;
        height: max-content;
        background-color: #fff;
        border-radius: 10px;
        margin: 3vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: center;

        .title_wrap {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 87%;
            margin-top: 2vh;

            .title_line {
                color: $primary-color;
                font-weight: bold;
                font-size: 2.4vmin;
            }

            .edit_icn {
                color: $primary-color;
                font-size: 3vmin;
                cursor: pointer;
            }
        }

        .avatar {
            margin-top: 2vh;
            background-color: $secondary-color;
            text-transform: uppercase;
            cursor: pointer;
            height: 6vh;
            width: 3vw;
        }

        .name_line {
            color: $font-primay-color;
            font-weight: 800;
            font-size: 2.5vmin;
            margin-top: 2vh;
        }

        .type_line {
            color: $font-secondary-color;
            font-size: 2.2vmin;
            margin-top: 2vh;
        }

        .mail_line {
            color: $primary-color;
            font-weight: bold;
            font-size: 2.2vmin;
            margin-top: 2vh;
            margin-bottom: 5%;
        }

        .cf_code-wrap {
            width: 80%;
            height: 50%;
            display: flex;
            flex-direction: row;
            text-align: center;
            align-items: center;
            justify-content: space-around;
            padding: 0%;
            margin-bottom: 5%;

            span {
                color: #000;
                font-family: Roboto;
                font-size: 2.3vmin;
                font-weight: 400;
                width: max-content;
                // border-bottom: 1px solid #ccc;
                cursor: pointer;

            }

            .clipboard-icon {
                margin-left: 5%;
                color: $secondary-color;
                cursor: pointer;
            }
        }

        .cf-partner-register {
            display: flex;
            width: 80%;
            padding: 13px 0px;
            justify-content: center;
            align-items: center;
            margin-bottom: 5%;
            border-radius: 8px;
            background: $primary-color;
            border-style: none;
            cursor: pointer;
            color: #FFF;
            font-family: Inter;
            font-size: 1.8vmin;
            font-style: normal;
            font-weight: 400;
            line-height: 168.023%;
            /* 33.605px */
            text-transform: uppercase;
        }

        .ph-partner-register {
            display: flex;
            width: 80%;
            padding: 13px 0px;
            justify-content: center;
            align-items: center;
            margin-bottom: 4%;
            border-radius: 8px;
            background: $secondary-color;
            border-style: none;
            cursor: pointer;
            color: #FFF;
            font-family: Inter;
            font-size: 1.8vmin;
            font-style: normal;
            font-weight: 400;
            line-height: 168.023%;
            /* 33.605px */
            text-transform: uppercase;
        }

        .clear-btn {
            display: flex;
            width: 80%;
            padding: 13px 0px;
            justify-content: center;
            align-items: center;
            margin-bottom: 8%;
            border-radius: 8px;
            background: transparent;
            border: 1px solid $secondary-color;
            cursor: pointer;
            color: $secondary-color;
            font-family: Inter;
            font-size: 1.8vmin;
            font-style: normal;
            font-weight: 400;
            line-height: 168.023%;
            /* 33.605px */
            text-transform: uppercase;
        }
    }

    #profile_container {
        width: 71.5vw;
        height: 80vh;
        background-color: #fff;
        border-radius: 10px;
        margin-top: 3vh;
        overflow: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: wrap;

        .content_wrap {
            height: max-content;
            width: 48%;
            border-radius: 10px;
            margin: 1% 0%;
            border: 1px solid #e1e3e6;
            display: flex;
            flex-direction: column;
            align-items: center;

            .content_header {
                height: 6vh;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: #f6f8fb;
                border-top-right-radius: 10px;
                border-top-left-radius: 10px;
                border-bottom: 0.5px dashed #e1e3e6;

                span {
                    font-weight: bold;
                    font-size: 1.9vmin;
                    color: $primary-color;
                    padding: 0% 3%;
                }

                .edit_icn {
                    color: $primary-color;
                    font-size: 3vmin;
                    cursor: pointer;
                    margin-right: 3%;
                }
            }

            .content_cover {
                height: max-content;
                width: 100%;
                display: flex;
                flex-direction: column;
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;

                .content_row {
                    min-height: 6vh;
                    width: 94%;
                    display: flex;
                    flex-direction: row;
                    padding: 0% 3%;
                    border-bottom: 1px dashed #e1e3e6;
                    font-size: 1.9vmin;

                    .label_wrap {
                        width: 40%;
                        color: $font-secondary-color;
                        display: flex;
                        align-items: center;
                    }

                    .field_wrap {
                        width: 60%;
                        color: $primary-color;
                        font-weight: bold;
                        display: flex;
                        align-items: center;

                        .tag {
                            padding: 2%;
                            border-radius: 4px;
                        }

                        .theme_one {
                            border: 1px solid rgb(82, 196, 26);
                            color: rgb(82, 196, 26);
                            background-color: rgb(236, 251, 228);
                        }

                        .theme_two {
                            color: #0d497f;
                            background-color: #a3c9eb;
                            border: 1px solid #0d497f;
                        }
                    }

                    &:last-child {
                        border-bottom: none;
                    }
                }
            }
        }

        .MuiPaper-root {
            width: 35% !important;
            max-width: 100% !important;
            // max-height: none !important;
        }

        .MuiTypography-root {
            display: flex;
            justify-content: center;
            color: #000;
            font-family: Inter !important;
            font-size: 3.5vmin !important;
            font-style: normal;
            font-weight: 500 !important;
            padding-bottom: 1%;
            line-height: 168.023% !important;
            /* 50.407px */
        }

        .MuiDialogContent-root {
            border-top: 0 !important;
            padding-top: 8px;
        }

        .MuiStepConnector-root {
            top: 20px !important;
        }

        .css-ogijw2,
        .css-1fgbf5s {
            cursor: pointer;
        }

        .logout-head {
            justify-content: left !important;
        }

        .logout-msg {
            display: flex;
            justify-content: left;
            color: #000;
            font-family: Inter !important;
            font-size: 3.5vmin !important;
            font-style: normal;
            font-weight: 500 !important;
            line-height: 168.023% !important;
            /* 50.407px */
            margin-bottom: 5%;


        }

        .no-btn {
            position: relative;
            left: -2%;
            background: #424B70 !important;
        }

        .login-btn {
            display: flex;
            float: right;
            width: 25%;
            padding: 8px 40px;
            justify-content: center;
            align-items: center;
            margin: auto;
            border-radius: 8px;
            background: #ed7d21;
            border-style: none;

            color: #FFF;
            font-family: Inter;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 168.023%;
        }

        .fields {
            // margin: 0 2% 0 2%;
            padding-bottom: 2%;
            overflow: auto;
            height: 67vh;
            width: 100%;
            margin: auto;

            .pwd {
                color: #000;
                font-family: Inter;
                font-size: 2.7vmin;
                font-style: normal;
                font-weight: 500;
                line-height: 168.023%;
                /* 40.325px */
                margin-bottom: 2%
            }

            .row {

                .input-group {
                    margin-top: 1rem;
                    // padding-bottom: 1rem;

                    img {
                        position: relative;
                        top: 17%;
                        left: 2%;
                    }

                    span {
                        float: left;
                    }

                    .register {
                        display: flex;
                        width: 45%;
                        padding: 13px 0px;
                        justify-content: center;
                        align-items: center;
                        margin: auto;
                        border-radius: 8px;
                        background: #424B70;
                        border-style: none;
                        cursor: pointer;
                        color: #FFF;
                        font-family: Inter;
                        font-size: 2.2vmin;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 168.023%;
                        /* 33.605px */
                        text-transform: uppercase;
                    }

                    .ph-partner-register {
                        display: flex;
                        width: 45%;
                        padding: 13px 0px;
                        justify-content: center;
                        align-items: center;
                        margin: auto;
                        border-radius: 8px;
                        background: $secondary-color;
                        border-style: none;
                        cursor: pointer;
                        color: #FFF;
                        font-family: Inter;
                        font-size: 1.8vmin;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 168.023%;
                        /* 33.605px */
                        text-transform: uppercase;
                    }

                    label {
                        color: #000;
                        font-family: Inter;
                        font-size: 1.6vmin;
                        font-weight: 400;
                    }

                    #area-label {
                        margin-bottom: 2% !important;
                    }

                    .css-1h51icj-MuiAutocomplete-root {
                        #area_practice {
                            height: 20px;
                            margin-top: 20px;
                        }
                    }

                    #radio-grp {
                        min-width: 19vw;
                        display: flex;
                        flex-direction: row;
                        margin-top: 1%;

                        #first-radio {
                            margin-left: 1%;
                        }

                        span {
                            font-size: 1.9vmin !important;
                        }
                    }
                }

                #last-one {
                    display: flex;
                    flex-direction: row;

                    .register {
                        display: flex;
                        width: 45%;
                        padding: 13px 0px;
                        justify-content: center;
                        align-items: center;
                        margin: auto;
                        border-radius: 8px;
                        background: #424B70;
                        border-style: none;
                        cursor: pointer;
                        color: #FFF;
                        font-family: Inter;
                        font-size: 1.8vmin;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 168.023%;
                        /* 33.605px */
                        text-transform: uppercase;
                    }

                    .ph-partner-register {
                        display: flex;
                        width: 45%;
                        padding: 13px 0px;
                        justify-content: center;
                        align-items: center;
                        margin: auto;
                        border-radius: 8px;
                        background: $secondary-color;
                        border-style: none;
                        cursor: pointer;
                        color: #FFF;
                        font-family: Inter;
                        font-size: 1.8vmin;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 168.023%;
                        /* 33.605px */
                        text-transform: uppercase;
                    }
                }

                .pin_input-group {
                    margin-top: 2rem;
                    display: flex;
                    flex-direction: row;

                    justify-content: space-between;
                    width: 100%;
                    // padding-bottom: 1rem;

                    .input-wrap {
                        width: 90%;

                        label {
                            color: #000;
                            font-family: Inter;
                            font-size: 1.6vmin;
                            font-weight: 400;
                        }

                        span {
                            float: left;
                        }

                        .input-box {
                            height: 2.6rem;
                            border-radius: 4px;
                            border: 0.5px solid rgba(0, 0, 0, 0.70);
                            margin-top: 0.4rem;
                            width: 100%;

                            img {
                                position: relative;
                                top: 17%;
                                left: 2%;
                            }

                            .eye_icon {
                                position: absolute;
                                color: #77787A;
                                right: 12%;
                                margin-top: 2%;
                                cursor: pointer;
                            }

                            .err-msg {
                                display: flex;
                                flex-direction: column;
                                margin-top: 1%;

                                span {
                                    float: left !important;
                                }
                            }


                            span {
                                color: #E10000;
                                font-family: Inter;
                                font-size: 1.5vmin;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 142%;
                                /* 19.88px */
                                letter-spacing: 0.035px;
                                padding-top: 1%;
                                float: right !important;
                            }
                        }
                    }

                    .pincode_btn {
                        display: flex;
                        width: 8.5%;
                        padding: 8px 0px;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                        background: $font-primay-color;
                        border-style: none;
                        cursor: pointer;
                        font-family: Inter;
                        font-size: 2vmin;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 2vmin;
                        /* 33.605px */
                        text-transform: uppercase;


                        .pincode_search {
                            color: #fff;
                        }
                    }
                }

                #secondary-sav-btn {
                    margin-top: 1.2rem !important;
                }
            }
        }

        .input-box {
            height: 2.6rem;
            border-radius: 4px;
            border: 0.5px solid rgba(0, 0, 0, 0.70);
            margin-top: 0.4rem;

            img {
                position: relative;
                top: 17%;
                left: 2%;
            }

            .eye_icon {
                color: #77787A;
                right: 12%;
                cursor: pointer;
            }

            .err-msg {
                display: flex;
                flex-direction: column;
                margin-top: 1%;

                span {
                    float: left !important;
                }
            }


            span {
                color: #E10000;
                font-family: Inter;
                font-size: 1.5vmin;
                font-style: normal;
                font-weight: 400;
                line-height: 142%;
                /* 19.88px */
                letter-spacing: 0.035px;
                padding-top: 1%;
                float: right !important;
            }
        }

        input[type="text"] {
            border: 0;
            outline: none;
            width: 85%;
            position: relative;
            top: 4%;
            height: 90%;
            margin-left: 2%;
            font-family: Inter;
        }

        input[type="password"] {
            border: 0;
            outline: none;
            width: 85%;
            position: relative;
            top: 4%;
            height: 90%;
            margin-left: 2%;
            font-family: Inter;
        }


        .cf_cover {
            display: flex;
            flex-direction: row;

            .check_box {
                height: 15px;
                width: 15px;
                margin-right: 2%;
                cursor: pointer;
                border: 0.75px solid #E96919;
            }

            .checked_box {
                height: 15px;
                width: 15px;
                margin-right: 2%;
                border: 0.75px solid #fff;
                display: inline-block;
                background-color: #E96919;
                cursor: pointer;

                span {
                    position: relative;
                    left: -40%;
                    top: -3px;
                    -ms-transform: rotate(45deg);
                    /* IE 9 */
                    -webkit-transform: rotate(45deg);
                    /* Chrome, Safari, Opera */
                    transform: rotate(45deg);

                    .checkmark_stem {
                        position: relative;
                        width: 2.5px;
                        height: 12px;
                        background-color: #fff;
                        left: 3px;
                        top: 3px;
                    }

                    .checkmark_kick {
                        position: relative;
                        width: 3px;
                        height: 2.5px;
                        background-color: #fff;
                    }
                }

            }

            margin-top: 1rem !important;
            width: 100%;
            align-items: center;

            .title {
                color: #000;
                font-family: Inter;
                font-size: 1.7vmin;
                font-style: normal;
                font-weight: 600;
                line-height: 160.523%;
                /* 22.473px */
                letter-spacing: 0.035px;
                width: 90%;
                display: flex;
                align-items: center;

                .privacy-innerline {
                    color: #0193E5;
                    text-decoration-line: underline;
                    cursor: pointer;
                    // margin-left: 0.4%;
                }

                .info-icon-wrap {
                    margin-left: 5%;
                    display: flex;
                    align-items: center;

                    .info-icon {
                        color: $primary-color;
                        cursor: pointer;
                    }
                }
            }

            .check_box {
                height: 15px;
                width: 15px;
                margin-right: 2%;
                cursor: pointer;
                border: 0.75px solid #E96919;
            }

            .checked_box {
                height: 15px;
                width: 15px;
                margin-right: 2%;
                border: 0.75px solid #fff;
                display: inline-block;
                background-color: #E96919;
                cursor: pointer;

                span {
                    position: relative;
                    left: -40%;
                    top: -3px;
                    -ms-transform: rotate(45deg);
                    /* IE 9 */
                    -webkit-transform: rotate(45deg);
                    /* Chrome, Safari, Opera */
                    transform: rotate(45deg);

                    .checkmark_stem {
                        position: relative;
                        width: 2.5px;
                        height: 12px;
                        background-color: #fff;
                        left: 3px;
                        top: 3px;
                    }

                    .checkmark_kick {
                        position: relative;
                        width: 3px;
                        height: 2.5px;
                        background-color: #fff;
                    }
                }

            }


        }

        .cf_code-wrap {
            width: 80%;
            height: 50%;
            display: flex;
            flex-direction: row;
            text-align: left;
            align-items: center;
            justify-content: space-around;
            padding: 2% 0;

            span {
                color: #000;
                font-family: Roboto;
                font-size: 2.3vmin;
                font-weight: 400;
                width: 80%;
                // border-bottom: 1px solid #ccc;
                cursor: pointer;

            }

            .clipboard-icon {
                margin-left: 5%;
                color: $secondary-color;
                cursor: pointer;
            }
        }


    }
}

#pro_container {

    .MuiPaper-root {
        width: 35vw !important;
        max-width: 100% !important;
        // max-height: none !important;
    }

    .MuiTypography-root {
        display: flex;
        justify-content: center;
        color: #000;
        font-family: Inter !important;
        font-size: 3.5vmin !important;
        font-style: normal;
        font-weight: 500 !important;
        padding-bottom: 1%;
        line-height: 168.023% !important;
        /* 50.407px */
    }

    .MuiDialogContent-root {
        border-top: 0 !important;
        padding-top: 8px;
    }

    .MuiStepConnector-root {
        top: 20px !important;
    }

    .css-ogijw2,
    .css-1fgbf5s {
        cursor: pointer;
    }

    .logout-head {
        justify-content: left !important;
    }

    .logout-msg {
        display: flex;
        justify-content: left;
        color: #000;
        font-family: Inter !important;
        font-size: 3.5vmin !important;
        font-style: normal;
        font-weight: 500 !important;
        line-height: 168.023% !important;
        /* 50.407px */
        margin-bottom: 5%;


    }

    .no-btn {
        position: relative;
        left: -2%;
        background: #424B70 !important;
    }

    .login-btn {
        display: flex;
        float: right;
        width: 25%;
        padding: 8px 40px;
        justify-content: center;
        align-items: center;
        margin: auto;
        border-radius: 8px;
        background: #ed7d21;
        border-style: none;

        color: #FFF;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 168.023%;
    }

    .fields {
        // margin: 0 2% 0 2%;
        padding-bottom: 2%;
        overflow: auto;
        height: max-content;
        width: 100%;
        margin: auto;

        .pwd {
            color: #000;
            font-family: Inter;
            font-size: 2.7vmin;
            font-style: normal;
            font-weight: 500;
            line-height: 168.023%;
            /* 40.325px */
            margin-bottom: 2%
        }

        .row {

            .input-group {

                &:first-child {
                    margin-top: 0 !important;
                }

                margin-top: 0.8rem;
                // padding-bottom: 1rem;

                img {
                    position: relative;
                    top: 17%;
                    left: 2%;
                }

                span {
                    float: left;
                }

                .register {
                    display: flex;
                    width: 45%;
                    padding: 13px 0px;
                    justify-content: center;
                    align-items: center;
                    margin: auto;
                    border-radius: 8px;
                    background: #424B70;
                    border-style: none;
                    cursor: pointer;
                    color: #FFF;
                    font-family: Inter;
                    font-size: 2.2vmin;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 168.023%;
                    /* 33.605px */
                    text-transform: uppercase;
                }

                .ph-partner-register {
                    display: flex;
                    width: 45%;
                    padding: 13px 0px;
                    justify-content: center;
                    align-items: center;
                    margin: auto;
                    border-radius: 8px;
                    background: $secondary-color;
                    border-style: none;
                    cursor: pointer;
                    color: #FFF;
                    font-family: Inter;
                    font-size: 1.8vmin;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 168.023%;
                    /* 33.605px */
                    text-transform: uppercase;
                }

                label {
                    color: #000;
                    font-family: Inter;
                    font-size: 1.6vmin;
                    font-weight: 400;
                }

                #area-label {
                    margin-bottom: 2% !important;
                }

                .css-1h51icj-MuiAutocomplete-root {
                    #area_practice {
                        height: 20px;
                        margin-top: 20px;
                    }
                }

                #radio-grp {
                    min-width: 19vw;
                    display: flex;
                    flex-direction: row;
                    margin-top: 1%;

                    #first-radio {
                        margin-left: 1%;
                    }

                    span {
                        font-size: 1.9vmin !important;
                    }
                }
            }

            #last-one {
                display: flex;
                flex-direction: row;

                .register {
                    display: flex;
                    width: 45%;
                    padding: 13px 0px;
                    justify-content: center;
                    align-items: center;
                    margin: auto;
                    border-radius: 8px;
                    background: #424B70;
                    border-style: none;
                    cursor: pointer;
                    color: #FFF;
                    font-family: Inter;
                    font-size: 1.8vmin;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 168.023%;
                    /* 33.605px */
                    text-transform: uppercase;
                }

                .ph-partner-register {
                    display: flex;
                    width: 45%;
                    padding: 13px 0px;
                    justify-content: center;
                    align-items: center;
                    margin: auto;
                    border-radius: 8px;
                    background: $secondary-color;
                    border-style: none;
                    cursor: pointer;
                    color: #FFF;
                    font-family: Inter;
                    font-size: 1.8vmin;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 168.023%;
                    /* 33.605px */
                    text-transform: uppercase;
                }
            }

            .pin_input-group {
                margin-top: 0.8rem;
                display: flex;
                flex-direction: row;

                justify-content: space-between;
                width: 100%;
                // padding-bottom: 1rem;

                .input-wrap {
                    width: 90%;

                    label {
                        color: #000;
                        font-family: Inter;
                        font-size: 1.6vmin;
                        font-weight: 400;
                    }

                    span {
                        float: left;
                    }

                    .input-box {
                        height: 2.6rem;
                        border-radius: 4px;
                        border: 0.5px solid rgba(0, 0, 0, 0.70);
                        margin-top: 0.4rem;
                        width: 100%;

                        img {
                            position: relative;
                            top: 17%;
                            left: 2%;
                        }

                        .eye_icon {
                            position: absolute;
                            color: #77787A;
                            right: 12%;
                            margin-top: 2%;
                            cursor: pointer;
                        }

                        .err-msg {
                            display: flex;
                            flex-direction: column;
                            margin-top: 1%;

                            span {
                                float: left !important;
                            }
                        }


                        span {
                            color: #E10000;
                            font-family: Inter;
                            font-size: 1.5vmin;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 142%;
                            /* 19.88px */
                            letter-spacing: 0.035px;
                            padding-top: 1%;
                            float: right !important;
                        }
                    }
                }

                .pincode_btn {
                    display: flex;
                    width: 8.5%;
                    padding: 8px 0px;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    background: $font-primay-color;
                    border-style: none;
                    cursor: pointer;
                    font-family: Inter;
                    font-size: 2vmin;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 2vmin;
                    /* 33.605px */
                    text-transform: uppercase;


                    .pincode_search {
                        color: #fff;
                    }
                }
            }

            #secondary-sav-btn {
                margin-top: 1.2rem !important;
            }
        }
    }

    .input-box {
        height: 2.6rem;
        border-radius: 4px;
        border: 0.5px solid rgba(0, 0, 0, 0.70);
        margin-top: 0.4rem;

        img {
            position: relative;
            top: 17%;
            left: 2%;
        }

        .eye_icon {
            color: #77787A;
            right: 12%;
            cursor: pointer;
        }

        .err-msg {
            display: flex;
            flex-direction: column;
            margin-top: 1%;

            span {
                float: left !important;
            }
        }


        span {
            color: #E10000;
            font-family: Inter;
            font-size: 1.5vmin;
            font-style: normal;
            font-weight: 400;
            line-height: 142%;
            /* 19.88px */
            letter-spacing: 0.035px;
            padding-top: 1%;
            float: right !important;
        }
    }

    input[type="text"] {
        border: 0;
        outline: none;
        width: 85%;
        position: relative;
        top: 4%;
        height: 90%;
        margin-left: 2%;
        font-family: Inter;
    }

    .inputfield {
        padding-right: 0%;
        margin-top: 0.5rem;
        .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
            padding: 15.5px 5px;
        }
        input[type="text"] {
            border: 0;
            outline: none;
            width: 85%;
            position: relative;
            top: 4%;
            height: 90%;
            margin-left: 1.5%;
            font-family: Inter;
        }
    }

    input[type="password"] {
        border: 0;
        outline: none;
        width: 85%;
        position: relative;
        top: 4%;
        height: 90%;
        margin-left: 2%;
        font-family: Inter;
    }

    .cf_cover {
        display: flex;
        flex-direction: row;

        .check_box {
            height: 15px;
            width: 15px;
            margin-right: 2%;
            cursor: pointer;
            border: 0.75px solid #E96919;
        }

        .checked_box {
            height: 15px;
            width: 15px;
            margin-right: 2%;
            border: 0.75px solid #fff;
            display: inline-block;
            background-color: #E96919;
            cursor: pointer;

            span {
                position: relative;
                left: -40%;
                top: -3px;
                -ms-transform: rotate(45deg);
                /* IE 9 */
                -webkit-transform: rotate(45deg);
                /* Chrome, Safari, Opera */
                transform: rotate(45deg);

                .checkmark_stem {
                    position: relative;
                    width: 2.5px;
                    height: 12px;
                    background-color: #fff;
                    left: 3px;
                    top: 3px;
                }

                .checkmark_kick {
                    position: relative;
                    width: 3px;
                    height: 2.5px;
                    background-color: #fff;
                }
            }

        }

        margin-top: 1rem !important;
        width: 100%;
        align-items: center;

        .title {
            color: #000;
            font-family: Inter;
            font-size: 1.7vmin;
            font-style: normal;
            font-weight: 600;
            line-height: 160.523%;
            /* 22.473px */
            letter-spacing: 0.035px;
            width: 90%;
            display: flex;
            align-items: center;

            .privacy-innerline {
                color: #0193E5;
                text-decoration-line: underline;
                cursor: pointer;
                // margin-left: 0.4%;
            }

            .info-icon-wrap {
                margin-left: 5%;
                display: flex;
                align-items: center;

                .info-icon {
                    color: $primary-color;
                    cursor: pointer;
                }
            }
        }

        .check_box {
            height: 15px;
            width: 15px;
            margin-right: 2%;
            cursor: pointer;
            border: 0.75px solid #E96919;
        }

        .checked_box {
            height: 15px;
            width: 15px;
            margin-right: 2%;
            border: 0.75px solid #fff;
            display: inline-block;
            background-color: #E96919;
            cursor: pointer;

            span {
                position: relative;
                left: -40%;
                top: -3px;
                -ms-transform: rotate(45deg);
                /* IE 9 */
                -webkit-transform: rotate(45deg);
                /* Chrome, Safari, Opera */
                transform: rotate(45deg);

                .checkmark_stem {
                    position: relative;
                    width: 2.5px;
                    height: 12px;
                    background-color: #fff;
                    left: 3px;
                    top: 3px;
                }

                .checkmark_kick {
                    position: relative;
                    width: 3px;
                    height: 2.5px;
                    background-color: #fff;
                }
            }

        }


    }

    .cf_code-wrap {
        width: 80%;
        height: 50%;
        display: flex;
        flex-direction: row;
        text-align: left;
        align-items: center;
        justify-content: space-around;
        padding: 2% 0;

        span {
            color: #000;
            font-family: Roboto;
            font-size: 2.3vmin;
            font-weight: 400;
            width: 80%;
            // border-bottom: 1px solid #ccc;
            cursor: pointer;

        }

        .clipboard-icon {
            margin-left: 5%;
            color: $secondary-color;
            cursor: pointer;
        }
    }

}

#cf_container {

    .MuiPaper-root {
        width: 35vw !important;
        max-width: 100% !important;
        // max-height: none !important;
    }

    .MuiTypography-root {
        display: flex;
        justify-content: center;
        color: #000;
        font-family: Inter !important;
        font-size: 3.5vmin !important;
        font-style: normal;
        font-weight: 500 !important;
        padding-bottom: 1%;
        line-height: 168.023% !important;
        /* 50.407px */
    }

    .MuiDialogContent-root {
        border-top: 0 !important;
        padding-top: 8px;
    }

    .MuiStepConnector-root {
        top: 20px !important;
    }

    .css-ogijw2,
    .css-1fgbf5s {
        cursor: pointer;
    }

    .logout-head {
        justify-content: left !important;
    }

    .logout-msg {
        display: flex;
        justify-content: left;
        color: #000;
        font-family: Inter !important;
        font-size: 3.5vmin !important;
        font-style: normal;
        font-weight: 500 !important;
        line-height: 168.023% !important;
        /* 50.407px */
        margin-bottom: 5%;


    }

    .no-btn {
        position: relative;
        left: -2%;
        background: #424B70 !important;
    }

    .login-btn {
        display: flex;
        float: right;
        width: 25%;
        padding: 8px 40px;
        justify-content: center;
        align-items: center;
        margin: auto;
        border-radius: 8px;
        background: #ed7d21;
        border-style: none;

        color: #FFF;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 168.023%;
    }

    .fields {
        // margin: 0 2% 0 2%;
        padding-bottom: 2%;
        overflow: auto;
        height: max-content;
        width: 100%;
        margin: auto;

        .pwd {
            color: #000;
            font-family: Inter;
            font-size: 2.7vmin;
            font-style: normal;
            font-weight: 500;
            line-height: 168.023%;
            /* 40.325px */
            margin-bottom: 2%
        }

        .row {

            .input-group {
                margin-top: 1rem;
                // padding-bottom: 1rem;

                img {
                    position: relative;
                    top: 17%;
                    left: 2%;
                }

                span {
                    float: left;
                }

                .register {
                    display: flex;
                    width: 45%;
                    padding: 13px 0px;
                    justify-content: center;
                    align-items: center;
                    margin: auto;
                    border-radius: 8px;
                    background: #424B70;
                    border-style: none;
                    cursor: pointer;
                    color: #FFF;
                    font-family: Inter;
                    font-size: 2.2vmin;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 168.023%;
                    /* 33.605px */
                    text-transform: uppercase;
                }

                .ph-partner-register {
                    display: flex;
                    width: 45%;
                    padding: 13px 0px;
                    justify-content: center;
                    align-items: center;
                    margin: auto;
                    border-radius: 8px;
                    background: $secondary-color;
                    border-style: none;
                    cursor: pointer;
                    color: #FFF;
                    font-family: Inter;
                    font-size: 1.8vmin;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 168.023%;
                    /* 33.605px */
                    text-transform: uppercase;
                }

                label {
                    color: #000;
                    font-family: Inter;
                    font-size: 1.6vmin;
                    font-weight: 400;
                }

                #area-label {
                    margin-bottom: 2% !important;
                }

                .css-1h51icj-MuiAutocomplete-root {
                    #area_practice {
                        height: 20px;
                        margin-top: 20px;
                    }
                }

                #radio-grp {
                    min-width: 19vw;
                    display: flex;
                    flex-direction: row;
                    margin-top: 1%;

                    #first-radio {
                        margin-left: 1%;
                    }

                    span {
                        font-size: 1.9vmin !important;
                    }
                }
            }

            #last-one {
                display: flex;
                flex-direction: row;

                .register {
                    display: flex;
                    width: 45%;
                    padding: 13px 0px;
                    justify-content: center;
                    align-items: center;
                    margin: auto;
                    border-radius: 8px;
                    background: #424B70;
                    border-style: none;
                    cursor: pointer;
                    color: #FFF;
                    font-family: Inter;
                    font-size: 1.8vmin;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 168.023%;
                    /* 33.605px */
                    text-transform: uppercase;
                }

                .ph-partner-register {
                    display: flex;
                    width: 45%;
                    padding: 13px 0px;
                    justify-content: center;
                    align-items: center;
                    margin: auto;
                    border-radius: 8px;
                    background: $secondary-color;
                    border-style: none;
                    cursor: pointer;
                    color: #FFF;
                    font-family: Inter;
                    font-size: 1.8vmin;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 168.023%;
                    /* 33.605px */
                    text-transform: uppercase;
                }
            }

            .pin_input-group {
                margin-top: 2rem;
                display: flex;
                flex-direction: row;

                justify-content: space-between;
                width: 100%;
                // padding-bottom: 1rem;

                .input-wrap {
                    width: 90%;

                    label {
                        color: #000;
                        font-family: Inter;
                        font-size: 1.6vmin;
                        font-weight: 400;
                    }

                    span {
                        float: left;
                    }

                    .input-box {
                        height: 2.6rem;
                        border-radius: 4px;
                        border: 0.5px solid rgba(0, 0, 0, 0.70);
                        margin-top: 0.4rem;
                        width: 100%;

                        img {
                            position: relative;
                            top: 17%;
                            left: 2%;
                        }

                        .eye_icon {
                            position: absolute;
                            color: #77787A;
                            right: 12%;
                            margin-top: 2%;
                            cursor: pointer;
                        }

                        .err-msg {
                            display: flex;
                            flex-direction: column;
                            margin-top: 1%;

                            span {
                                float: left !important;
                            }
                        }


                        span {
                            color: #E10000;
                            font-family: Inter;
                            font-size: 1.5vmin;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 142%;
                            /* 19.88px */
                            letter-spacing: 0.035px;
                            padding-top: 1%;
                            float: right !important;
                        }
                    }
                }

                .pincode_btn {
                    display: flex;
                    width: 8.5%;
                    padding: 8px 0px;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    background: $font-primay-color;
                    border-style: none;
                    cursor: pointer;
                    font-family: Inter;
                    font-size: 2vmin;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 2vmin;
                    /* 33.605px */
                    text-transform: uppercase;


                    .pincode_search {
                        color: #fff;
                    }
                }
            }

            #secondary-sav-btn {
                margin-top: 1.2rem !important;
            }
        }
    }

    .input-box {
        height: 2.6rem;
        border-radius: 4px;
        border: 0.5px solid rgba(0, 0, 0, 0.70);
        margin-top: 0.4rem;

        img {
            position: relative;
            top: 17%;
            left: 2%;
        }

        .eye_icon {
            color: #77787A;
            right: 12%;
            cursor: pointer;
        }

        .err-msg {
            display: flex;
            flex-direction: column;
            margin-top: 1%;

            span {
                float: left !important;
            }
        }


        span {
            color: #E10000;
            font-family: Inter;
            font-size: 1.5vmin;
            font-style: normal;
            font-weight: 400;
            line-height: 142%;
            /* 19.88px */
            letter-spacing: 0.035px;
            padding-top: 1%;
            float: right !important;
        }
    }

    input[type="text"] {
        border: 0;
        outline: none;
        width: 85%;
        position: relative;
        top: 4%;
        height: 90%;
        margin-left: 2%;
        font-family: Inter;
    }

    input[type="password"] {
        border: 0;
        outline: none;
        width: 85%;
        position: relative;
        top: 4%;
        height: 90%;
        margin-left: 2%;
        font-family: Inter;
    }

    .cf_cover {
        display: flex;
        flex-direction: row;

        .check_box {
            height: 15px;
            width: 15px;
            margin-right: 2%;
            cursor: pointer;
            border: 0.75px solid #E96919;
        }

        .checked_box {
            height: 15px;
            width: 15px;
            margin-right: 2%;
            border: 0.75px solid #fff;
            display: inline-block;
            background-color: #E96919;
            cursor: pointer;

            span {
                position: relative;
                left: -40%;
                top: -3px;
                -ms-transform: rotate(45deg);
                /* IE 9 */
                -webkit-transform: rotate(45deg);
                /* Chrome, Safari, Opera */
                transform: rotate(45deg);

                .checkmark_stem {
                    position: relative;
                    width: 2.5px;
                    height: 12px;
                    background-color: #fff;
                    left: 3px;
                    top: 3px;
                }

                .checkmark_kick {
                    position: relative;
                    width: 3px;
                    height: 2.5px;
                    background-color: #fff;
                }
            }

        }

        margin-top: 1rem !important;
        width: 100%;
        align-items: center;

        .title {
            color: #000;
            font-family: Inter;
            font-size: 1.7vmin;
            font-style: normal;
            font-weight: 600;
            line-height: 160.523%;
            /* 22.473px */
            letter-spacing: 0.035px;
            width: 90%;
            display: flex;
            align-items: center;

            .privacy-innerline {
                color: #0193E5;
                text-decoration-line: underline;
                cursor: pointer;
                // margin-left: 0.4%;
            }

            .info-icon-wrap {
                margin-left: 5%;
                display: flex;
                align-items: center;

                .info-icon {
                    color: $primary-color;
                    cursor: pointer;
                }
            }
        }

        .check_box {
            height: 15px;
            width: 15px;
            margin-right: 2%;
            cursor: pointer;
            border: 0.75px solid #E96919;
        }

        .checked_box {
            height: 15px;
            width: 15px;
            margin-right: 2%;
            border: 0.75px solid #fff;
            display: inline-block;
            background-color: #E96919;
            cursor: pointer;

            span {
                position: relative;
                left: -40%;
                top: -3px;
                -ms-transform: rotate(45deg);
                /* IE 9 */
                -webkit-transform: rotate(45deg);
                /* Chrome, Safari, Opera */
                transform: rotate(45deg);

                .checkmark_stem {
                    position: relative;
                    width: 2.5px;
                    height: 12px;
                    background-color: #fff;
                    left: 3px;
                    top: 3px;
                }

                .checkmark_kick {
                    position: relative;
                    width: 3px;
                    height: 2.5px;
                    background-color: #fff;
                }
            }

        }


    }

    .cf_code-wrap {
        width: 80%;
        height: 50%;
        display: flex;
        flex-direction: row;
        text-align: left;
        align-items: center;
        justify-content: space-around;
        padding: 2% 0;

        span {
            color: #000;
            font-family: Roboto;
            font-size: 2.3vmin;
            font-weight: 400;
            width: 80%;
            // border-bottom: 1px solid #ccc;
            cursor: pointer;

        }

        .clipboard-icon {
            margin-left: 5%;
            color: $secondary-color;
            cursor: pointer;
        }
    }

}

#ph_partner_container {
    .MuiPaper-root {
        width: 70% !important;
        max-width: 100% !important;
        // max-height: none !important;
    }

    .MuiTypography-root {
        display: flex;
        justify-content: center;
        color: #000;
        font-family: Inter !important;
        font-size: 2.7vmin !important;
        font-style: normal;
        font-weight: 500 !important;
        padding-bottom: 1%;
        line-height: 168.023% !important;
        /* 50.407px */
    }

    .MuiDialogContent-root {
        border-top: 0 !important;
        padding-top: 8px;
        display: flex;
        flex-direction: column !important;

        // .dialog-content {
        .ph-content-cover {
            display: flex;
            flex-direction: row !important;
            overflow: auto;

            .ph-content-split {
                width: 50%;
                height: 76vh;
                display: flex;
                flex-direction: column;
                align-items: center;

                .fields {
                    width: 100%;
                    margin: auto;
                    padding-bottom: 2%;

                    .pwd {
                        color: #000;
                        font-family: Inter;
                        font-size: 2.7vmin;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 168.023%;
                        /* 40.325px */
                        margin-bottom: 2%
                    }

                    .row {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        .input-group {
                            margin-top: 2rem;
                            width: 95%;
                            // padding-bottom: 1rem;


                            .input-box {
                                height: 3rem;
                                border-radius: 4px;
                                border: 0.5px solid rgba(0, 0, 0, 0.70);

                                img {
                                    position: relative;
                                    height: 32%;
                                    width: 4%;
                                    top: 12%;
                                    left: 2%;
                                }
                            }

                            span {
                                float: right;
                                font-size: 1.7vmin;
                                color: rgb(225, 0, 0);
                            }

                            .register {
                                display: flex;
                                width: 45%;
                                padding: 13px 0px;
                                justify-content: center;
                                align-items: center;
                                margin: auto;
                                border-radius: 8px;
                                background: #424B70;
                                border-style: none;
                                cursor: pointer;
                                color: #FFF;
                                font-family: Inter;
                                font-size: 2.2vmin;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 168.023%;
                                /* 33.605px */
                                text-transform: uppercase;
                            }

                            .ph-partner-register {
                                display: flex;
                                width: 45%;
                                padding: 13px 0px;
                                justify-content: center;
                                align-items: center;
                                margin: auto;
                                border-radius: 8px;
                                background: $secondary-color;
                                border-style: none;
                                cursor: pointer;
                                color: #FFF;
                                font-family: Inter;
                                font-size: 1.8vmin;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 168.023%;
                                /* 33.605px */
                                text-transform: uppercase;
                            }

                            .register_ph {
                                display: flex;
                                width: 70%;
                                padding: 7px 0px;
                                justify-content: center;
                                align-items: center;
                                margin: auto;
                                border-radius: 8px;
                                background: $primary-color;
                                border-style: none;
                                cursor: pointer;
                                color: #FFF;
                                font-family: Inter;
                                font-size: 2.2vmin;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 168.023%;
                                /* 33.605px */
                                text-transform: uppercase;

                                &:disabled {
                                    cursor: not-allowed;
                                }
                            }

                            label {
                                color: #000;
                                font-family: Inter;
                                font-size: 1.6vmin;
                                font-weight: 400;
                            }

                            .title {
                                color: #000;
                                font-family: Inter;
                                font-size: 1.7vmin;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 160.523%;
                                /* 22.473px */
                                letter-spacing: 0.035px;
                                width: 90%;
                                display: flex;
                                align-items: center;

                                .privacy-innerline {
                                    color: #0193E5;
                                    text-decoration-line: underline;
                                    cursor: pointer;
                                    // margin-left: 0.4%;
                                }

                                .info-icon-wrap {
                                    margin-left: 5%;
                                    display: flex;
                                    align-items: center;

                                    .info-icon {
                                        color: $primary-color;
                                        cursor: pointer;
                                    }
                                }
                            }

                            .chip-input-container {
                                display: flex;
                                flex-wrap: wrap;
                                align-items: center;

                                .input-box {
                                    height: 3rem;
                                    border-radius: 4px;
                                    border: 0.5px solid rgba(0, 0, 0, 0.70);
                                    width: 100%;

                                    img {
                                        position: relative;
                                        height: 32%;
                                        width: 4%;
                                        top: 12%;
                                        left: 2%;
                                    }
                                }
                            }

                            .chip {
                                background-color: $secondary-color;
                                border-radius: 16px;
                                padding: 4px 8px;
                                margin: 4px;
                                display: flex;
                                align-items: center;
                                color: #fff;
                                font-size: 1.6vmin;
                            }

                            .chip button {
                                margin-left: 4px;
                                background-color: transparent;
                                border: none;
                                cursor: pointer;
                            }


                        }


                        #last-one {}

                        .checkbox-over-wrap {
                            height: 6vh;
                            width: 100%;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-evenly;

                            .checkbox-cover {
                                height: 100%;
                                width: max-content !important;
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                justify-content: center;

                                .check_box {
                                    height: 10px;
                                    min-width: 10px;
                                    margin-right: 2%;
                                    cursor: pointer;
                                    background-color: #D9D9D9;
                                    border-radius: 50%;
                                }

                                .checked_box {
                                    height: 10px;
                                    min-width: 10px;
                                    margin-right: 2%;
                                    display: inline-block;
                                    background-color: #E96919;
                                    border-radius: 50%;
                                    cursor: pointer;
                                }

                                .label {
                                    color: #000;
                                    font-family: Inter;
                                    font-size: 1.6vmin;
                                    font-weight: bold;
                                    margin-left: 5px;
                                    text-wrap: nowrap;
                                    cursor: pointer;
                                }
                            }
                        }

                    }

                    .pin_input-group {
                        margin-top: 2rem;
                        display: flex;
                        flex-direction: row;

                        justify-content: space-between;
                        width: 90%;
                        // padding-bottom: 1rem;

                        .input-wrap {
                            width: 90%;

                            label {
                                color: #000;
                                font-family: Inter;
                                font-size: 1.6vmin;
                                font-weight: 400;
                            }

                            span {
                                float: left;
                            }

                            .input-box {
                                height: 2.6rem;
                                border-radius: 4px;
                                border: 0.5px solid rgba(0, 0, 0, 0.70);
                                margin-top: 0.4rem;
                                width: 100%;

                                img {
                                    position: relative;
                                    top: 17%;
                                    left: 2%;
                                }

                                .eye_icon {
                                    position: absolute;
                                    color: #77787A;
                                    right: 12%;
                                    margin-top: 2%;
                                    cursor: pointer;
                                }

                                .err-msg {
                                    display: flex;
                                    flex-direction: column;
                                    margin-top: 1%;

                                    span {
                                        float: left !important;
                                    }
                                }


                                span {
                                    color: #E10000;
                                    font-family: Inter;
                                    font-size: 1.5vmin;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 142%;
                                    /* 19.88px */
                                    letter-spacing: 0.035px;
                                    padding-top: 1%;
                                    float: right !important;
                                }
                            }
                        }

                        .pincode_btn {
                            display: flex;
                            width: 9%;
                            padding: 8px 0px;
                            justify-content: center;
                            align-items: center;
                            border-radius: 50%;
                            background: $font-primay-color;
                            border-style: none;
                            cursor: pointer;
                            font-family: Inter;
                            font-size: 2vmin;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 2vmin;
                            /* 33.605px */
                            text-transform: uppercase;


                            .pincode_search {
                                color: #fff;
                            }
                        }
                    }
                }
            }

        }

        .btn-group {
            margin-top: 1rem;
            width: 95%;
            display: flex;

            .register_ph {
                display: flex;
                width: 40%;
                padding: 7px 0px;
                justify-content: center;
                align-items: center;
                margin: auto;
                border-radius: 8px;
                background: $primary-color;
                border-style: none;
                cursor: pointer;
                color: #FFF;
                font-family: Inter;
                font-size: 2.2vmin;
                font-style: normal;
                font-weight: 400;
                line-height: 168.023%;
                /* 33.605px */
                text-transform: uppercase;

                &:disabled {
                    cursor: not-allowed;
                }
            }
        }
    }


}

.verify-container {
    border-radius: 4px;
    background: #FFF;
    width: 100%;
    padding-bottom: 0%;

    .heading {
        margin-top: 0 !important;
    }

    .register {
        text-transform: uppercase;
        cursor: pointer;
    }

    .icons {
        display: flex;
        padding-top: 5%;
        margin: auto;

    }

    .input-group {
        padding: 5% 0 5% 0;

        .submt_btn {
            width: 60%;
        }
    }

    .forgot-link {
        display: flex;
        margin: auto;
        justify-content: center;
        text-align: center;
        // margin-top: 4.1%;
        width: 100%;
    }

    .otp-group {
        padding: 5% 0 5% 0;
        width: 79%;
        margin: auto;

        .otp-cover {
            width: 100%;
            max-width: 100% !important;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;

            .otp_input {
                height: 80%;
                width: 18%;
                padding: 0px !important;
            }
        }
    }
}

.css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    margin-top: 0 !important;
    font-family: Inter;
    font-size: 1.5vmin;
}

@media only screen and (min-width:320px) and (max-width:600px) {
    #cover_wrap {
        #profile_container {
            .MuiPaper-root {
                width: 90% !important;

                .err-msg {
                    margin-top: 5% !important;
                }
            }


            .MuiTypography-root {
                font-size: 4.3vmin !important;
            }

            .fields {


                .input-group {
                    margin-top: 0.5rem !important;

                    label {
                        font-size: 2.6vmin !important;
                    }

                    .register {
                        font-size: 2.9vmin !important;
                    }
                }

                .pin_input-group {
                    .input-wrap {

                        label {
                            font-size: 2.6vmin !important;
                        }
                    }

                    .pincode_btn {
                        padding: 1px 0px;
                    }
                }
            }

            #last-one {
                margin-top: 6rem !important;
            }

            #secondary-sav-btn {
                margin-top: 1.8rem !important;
            }

            .input-box {
                span {
                    font-size: 2.6vmin !important;
                }

                .err-msg div {
                    display: flex;
                    flex-direction: row;
                    // justify-content: center;
                    align-items: center;
                }
            }
        }
    }

    .css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel {
        font-size: 3vmin !important;
    }

    .cf_cover {
        .title {
            font-size: 2.5vmin !important;
        }
    }
}

@media only screen and (min-width:320px) and (max-width:600px) {
    #cover_wrap {

        #profile_container {
            .MuiPaper-root {
                width: 85% !important;
                height: 100%;
            }

            .MuiTypography-root {
                font-size: 3vmin !important;
                padding-bottom: 0%;
                /* 50.407px */
            }

            .MuiDialogContent-root {}

            .MuiStepConnector-root {
                top: 20px !important;
            }

            .css-ogijw2,
            .css-1fgbf5s {
                cursor: pointer;
            }

            .logout-head {
                justify-content: left !important;
            }

            .logout-msg {
                display: flex;
                background-color: red;
                justify-content: left;
                color: #000;
                font-family: Inter !important;
                font-size: 3.5vmin !important;
                font-style: normal;
                font-weight: 500 !important;
                line-height: 168.023% !important;
                /* 50.407px */
                margin-bottom: 5%;


            }

            .no-btn {
                position: relative;
                left: -2%;
                background: #424B70 !important;
            }

            .login-btn {
                display: flex;
                float: right;
                width: 25%;
                padding: 8px 40px;
                justify-content: center;
                align-items: center;
                margin: auto;
                border-radius: 8px;
                background: #ed7d21;
                border-style: none;

                color: #FFF;
                font-family: Inter;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 168.023%;
            }

            .fields {
                width: 100%;
                margin: auto;
                padding-bottom: 2%;

                .pwd {
                    color: #000;
                    font-family: Inter;
                    font-size: 2.7vmin;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 168.023%;
                    /* 40.325px */
                    margin-bottom: 2%
                }

                .row {

                    .input-group {
                        margin-top: 2rem;
                        // padding-bottom: 1rem;

                        img {
                            position: relative;
                            top: 17%;
                            left: 2%;
                        }

                        span {
                            float: left;
                        }

                        .register {
                            display: flex;
                            width: 45%;
                            padding: 13px 0px;
                            justify-content: center;
                            align-items: center;
                            margin: auto;
                            border-radius: 8px;
                            background: #424B70;
                            border-style: none;
                            cursor: pointer;
                            color: #FFF;
                            font-family: Inter;
                            font-size: 1.8vmin;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 168.023%;
                            /* 33.605px */
                            text-transform: uppercase;
                        }

                        .ph-partner-register {
                            display: flex;
                            width: 45%;
                            padding: 13px 0px;
                            justify-content: center;
                            align-items: center;
                            margin: auto;
                            border-radius: 8px;
                            background: $secondary-color;
                            border-style: none;
                            cursor: pointer;
                            color: #FFF;
                            font-family: Inter;
                            font-size: 2.2vmin;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 168.023%;
                            /* 33.605px */
                            text-transform: uppercase;
                        }

                        label {
                            color: #000;
                            font-family: Inter;
                            font-size: 1.8vmin;
                            font-weight: 400;
                        }

                        #area-label {
                            margin-bottom: 2% !important;
                        }

                        .css-1h51icj-MuiAutocomplete-root {
                            #area_practice {
                                height: 20px;
                                margin-top: 20px;
                            }
                        }
                    }

                    #last-one {
                        display: flex;
                        flex-direction: row;

                        .register {}

                        .ph-partner-register {}
                    }

                    .pin_input-group {


                        .input-wrap {
                            width: 90%;

                            label {}

                            span {
                                float: left;
                            }

                            .input-box {


                                img {}

                                .eye_icon {}

                                .err-msg {


                                    span {
                                        float: left !important;
                                    }
                                }


                                span {
                                    color: #E10000;

                                }
                            }
                        }

                        .pincode_btn {



                            .pincode_search {
                                color: #fff;
                            }
                        }
                    }

                }
            }

            .input-box {


                img {}

                .eye_icon {}

                .err-msg {
                    display: flex;
                    flex-direction: column;
                    margin-top: 1%;

                    span {
                        float: left !important;
                    }
                }


                span {
                    color: #E10000;
                    font-family: Inter;
                    font-size: 1.5vmin;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 142%;
                    /* 19.88px */
                    letter-spacing: 0.035px;
                    padding-top: 1%;
                    float: right !important;
                }
            }

            input[type="text"] {
                border: 0;
                outline: none;
                width: 85%;
                position: relative;
                top: 4%;
                font-size: 2.5vmin;
                height: 90%;
                margin-left: 2%;
                font-family: Inter;
            }

            .css-ffg8md-MuiNativeSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-ffg8md-MuiNativeSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-ffg8md-MuiNativeSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
                padding-right: 32px;
                font-size: 2.5vmin;
            }

            input[type="password"] {
                border: 0;
                outline: none;
                width: 85%;
                position: relative;
                top: 4%;
                height: 90%;
                margin-left: 2%;
                font-family: Inter;
            }


            .cf_cover {
                display: flex;
                flex-direction: row;

                .check_box {
                    height: 15px;
                    width: 15px;
                    margin-right: 2%;
                    cursor: pointer;
                    border: 0.75px solid #E96919;
                }

                .checked_box {
                    height: 15px;
                    width: 15px;
                    margin-right: 2%;
                    border: 0.75px solid #fff;
                    display: inline-block;
                    background-color: #E96919;
                    cursor: pointer;

                    span {
                        position: relative;
                        left: -40%;
                        top: -3px;
                        -ms-transform: rotate(45deg);
                        /* IE 9 */
                        -webkit-transform: rotate(45deg);
                        /* Chrome, Safari, Opera */
                        transform: rotate(45deg);

                        .checkmark_stem {
                            position: relative;
                            width: 2.5px;
                            height: 12px;
                            left: 3px;
                            top: 3px;
                        }

                        .checkmark_kick {
                            position: relative;
                            width: 3px;
                            height: 2.5px;
                            background-color: #fff;
                        }
                    }

                }

                margin-top: 1rem !important;
                width: 100%;
                align-items: center;

                .title {
                    color: #000;
                    font-family: Inter;
                    font-size: 2vmin;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 160.523%;
                    /* 22.473px */
                    letter-spacing: 0.035px;
                    width: 90%;
                    display: flex;
                    align-items: center;

                    .privacy-innerline {
                        color: #0193E5;
                        text-decoration-line: underline;
                        cursor: pointer;
                        // margin-left: 0.4%;
                    }

                    .info-icon-wrap {
                        margin-left: 5%;
                        display: flex;
                        align-items: center;

                        .info-icon {
                            color: $primary-color;
                            cursor: pointer;
                        }
                    }
                }

                .check_box {
                    height: 15px;
                    width: 15px;
                    margin-right: 2%;
                    cursor: pointer;
                    border: 0.75px solid #E96919;
                }

                .checked_box {
                    height: 15px;
                    width: 15px;
                    margin-right: 2%;
                    border: 0.75px solid #fff;
                    display: inline-block;
                    background-color: #E96919;
                    cursor: pointer;

                    span {
                        position: relative;
                        left: -40%;
                        top: -3px;
                        -ms-transform: rotate(45deg);
                        /* IE 9 */
                        -webkit-transform: rotate(45deg);
                        /* Chrome, Safari, Opera */
                        transform: rotate(45deg);

                        .checkmark_stem {
                            position: relative;
                            width: 2.5px;
                            height: 12px;
                            background-color: #fff;
                            left: 3px;
                            top: 3px;
                        }

                        .checkmark_kick {
                            position: relative;
                            width: 3px;
                            height: 2.5px;
                            background-color: #fff;
                        }
                    }

                }


            }

            .cf_code-wrap {
                width: 80%;
                height: 50%;
                display: flex;
                flex-direction: row;
                text-align: left;
                align-items: center;
                justify-content: space-around;
                padding: 2% 0;

                span {
                    color: #000;
                    font-family: Roboto;
                    font-size: 2.3vmin;
                    font-weight: 400;
                    width: 80%;
                    // border-bottom: 1px solid #ccc;
                    cursor: pointer;

                }

                .clipboard-icon {
                    margin-left: 5%;
                    color: $secondary-color;
                    cursor: pointer;
                }
            }
        }
    }

    #ph_partner_container {
        .MuiPaper-root {
            width: 70% !important;
            max-width: 100% !important;
            // max-height: none !important;
        }

        .MuiTypography-root {
            display: flex;
            justify-content: center;
            color: #000;
            font-family: Inter !important;
            font-size: 2.7vmin !important;
            font-style: normal;
            font-weight: 500 !important;
            padding-bottom: 1%;
            line-height: 168.023% !important;
            /* 50.407px */
        }

        .MuiDialogContent-root {
            border-top: 0 !important;
            padding-top: 8px;
            display: flex;
            flex-direction: column !important;

            // .dialog-content {
            .ph-content-cover {
                display: flex;
                flex-direction: row !important;
                overflow: auto;

                .ph-content-split {
                    width: 50%;
                    height: 76vh;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .fields {
                        width: 100%;
                        margin: auto;
                        padding-bottom: 2%;

                        .pwd {
                            color: #000;
                            font-family: Inter;
                            font-size: 2.7vmin;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 168.023%;
                            /* 40.325px */
                            margin-bottom: 2%
                        }

                        .row {
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: center;

                            .input-group {
                                margin-top: 2rem;
                                width: 95%;
                                // padding-bottom: 1rem;


                                .input-box {
                                    height: 3rem;
                                    border-radius: 4px;
                                    border: 0.5px solid rgba(0, 0, 0, 0.70);

                                    img {
                                        position: relative;
                                        height: 32%;
                                        width: 4%;
                                        top: 12%;
                                        left: 2%;
                                    }
                                }

                                span {
                                    float: right;
                                    font-size: 1.7vmin;
                                    color: rgb(225, 0, 0);
                                }

                                .register {
                                    display: flex;
                                    width: 45%;
                                    padding: 13px 0px;
                                    justify-content: center;
                                    align-items: center;
                                    margin: auto;
                                    border-radius: 8px;
                                    background: #424B70;
                                    border-style: none;
                                    cursor: pointer;
                                    color: #FFF;
                                    font-family: Inter;
                                    font-size: 2.2vmin;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 168.023%;
                                    /* 33.605px */
                                    text-transform: uppercase;
                                }

                                .ph-partner-register {
                                    display: flex;
                                    width: 45%;
                                    padding: 13px 0px;
                                    justify-content: center;
                                    align-items: center;
                                    margin: auto;
                                    border-radius: 8px;
                                    background: rebeccapurple;
                                    border-style: none;
                                    cursor: pointer;
                                    color: #FFF;
                                    font-family: Inter;
                                    font-size: 1.8vmin;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 168.023%;
                                    /* 33.605px */
                                    text-transform: uppercase;
                                }

                                .register_ph {
                                    display: flex;
                                    width: 70%;
                                    padding: 7px 0px;
                                    justify-content: center;
                                    align-items: center;
                                    margin: auto;
                                    border-radius: 8px;
                                    background: $primary-color;
                                    border-style: none;
                                    cursor: pointer;
                                    color: #FFF;
                                    font-family: Inter;
                                    font-size: 2.2vmin;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 168.023%;
                                    /* 33.605px */
                                    text-transform: uppercase;

                                    &:disabled {
                                        cursor: not-allowed;
                                    }
                                }

                                label {
                                    color: #000;
                                    font-family: Inter;
                                    font-size: 1.6vmin;
                                    font-weight: 400;
                                }

                                .title {
                                    color: #000;
                                    font-family: Inter;
                                    font-size: 1.7vmin;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 160.523%;
                                    /* 22.473px */
                                    letter-spacing: 0.035px;
                                    width: 90%;
                                    display: flex;
                                    align-items: center;

                                    .privacy-innerline {
                                        color: #0193E5;
                                        text-decoration-line: underline;
                                        cursor: pointer;
                                        // margin-left: 0.4%;
                                    }

                                    .info-icon-wrap {
                                        margin-left: 5%;
                                        display: flex;
                                        align-items: center;

                                        .info-icon {
                                            color: $primary-color;
                                            cursor: pointer;
                                        }
                                    }
                                }

                                .chip-input-container {
                                    display: flex;
                                    flex-wrap: wrap;
                                    align-items: center;

                                    .input-box {
                                        height: 3rem;
                                        border-radius: 4px;
                                        border: 0.5px solid rgba(0, 0, 0, 0.70);
                                        width: 100%;

                                        img {
                                            position: relative;
                                            height: 32%;
                                            width: 4%;
                                            top: 12%;
                                            left: 2%;
                                        }
                                    }
                                }

                                .chip {
                                    background-color: $secondary-color;
                                    border-radius: 16px;
                                    padding: 4px 8px;
                                    margin: 4px;
                                    display: flex;
                                    align-items: center;
                                    color: #fff;
                                    font-size: 1.6vmin;
                                }

                                .chip button {
                                    margin-left: 4px;
                                    background-color: transparent;
                                    border: none;
                                    cursor: pointer;
                                }
                            }

                            #last-one {}

                            .checkbox-over-wrap {
                                height: 6vh;
                                width: 100%;
                                display: flex;
                                background-color: #E10000;
                                flex-direction: row;
                                justify-content: space-evenly;

                                .checkbox-cover {
                                    height: 100%;
                                    width: max-content !important;
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                    justify-content: center;

                                    .check_box {
                                        height: 10px;
                                        min-width: 10px;
                                        margin-right: 2%;
                                        cursor: pointer;
                                        background-color: #D9D9D9;
                                        border-radius: 50%;
                                    }

                                    .checked_box {
                                        height: 10px;
                                        min-width: 10px;
                                        margin-right: 2%;
                                        display: inline-block;
                                        background-color: #E96919;
                                        border-radius: 50%;
                                        cursor: pointer;
                                    }

                                    .label {
                                        color: #000;
                                        font-family: Inter;
                                        font-size: 1.6vmin;
                                        font-weight: bold;
                                        margin-left: 5px;
                                        text-wrap: nowrap;
                                    }
                                }
                            }
                        }

                        .pin_input-group {
                            margin-top: 2rem;
                            display: flex;
                            flex-direction: row;

                            justify-content: space-between;
                            width: 90%;
                            // padding-bottom: 1rem;

                            .input-wrap {
                                width: 90%;

                                label {
                                    color: #000;
                                    font-family: Inter;
                                    font-size: 1.6vmin;
                                    font-weight: 400;
                                }

                                span {
                                    float: left;
                                }

                                .input-box {
                                    height: 2.6rem;
                                    border-radius: 4px;
                                    border: 0.5px solid rgba(0, 0, 0, 0.70);
                                    margin-top: 0.4rem;
                                    width: 100%;

                                    img {
                                        position: relative;
                                        top: 17%;
                                        left: 2%;
                                    }

                                    .eye_icon {
                                        position: absolute;
                                        color: #77787A;
                                        right: 12%;
                                        margin-top: 2%;
                                        cursor: pointer;
                                    }

                                    .err-msg {
                                        display: flex;
                                        flex-direction: column;
                                        margin-top: 1%;

                                        span {
                                            float: left !important;
                                        }
                                    }


                                    span {
                                        color: #E10000;
                                        font-family: Inter;
                                        font-size: 1.5vmin;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 142%;
                                        /* 19.88px */
                                        letter-spacing: 0.035px;
                                        padding-top: 1%;
                                        float: right !important;
                                    }
                                }
                            }

                            .pincode_btn {
                                display: flex;
                                width: 9%;
                                padding: 8px 0px;
                                justify-content: center;
                                align-items: center;
                                border-radius: 50%;
                                background: $font-primay-color;
                                border-style: none;
                                cursor: pointer;
                                font-family: Inter;
                                font-size: 2vmin;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 2vmin;
                                /* 33.605px */
                                text-transform: uppercase;


                                .pincode_search {
                                    color: #fff;
                                }
                            }
                        }
                    }
                }

            }

            .btn-group {
                margin-top: 1rem;
                width: 95%;
                display: flex;

                .register_ph {
                    display: flex;
                    width: 40%;
                    padding: 7px 0px;
                    justify-content: center;
                    align-items: center;
                    margin: auto;
                    border-radius: 8px;
                    background: $primary-color;
                    border-style: none;
                    cursor: pointer;
                    color: #FFF;
                    font-family: Inter;
                    font-size: 2.2vmin;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 168.023%;
                    /* 33.605px */
                    text-transform: uppercase;

                    &:disabled {
                        cursor: not-allowed;
                    }
                }
            }
        }


    }
}

@media only screen and (min-width:601px) and (max-width:768px) {
    #cover_wrap {

        #profile_container {

            input[type=text] {
                font-size: 2vmin !important;
            }

            .MuiPaper-root {
                width: 70% !important;
            }

            .input-box {
                span {
                    font-size: 2vmin !important;
                }

                .err-msg {
                    div {
                        display: flex;
                        flex-direction: row;
                        // justify-content: center;
                        align-items: center;
                    }
                }
            }

            .fields .input-group {
                label {
                    font-size: 2vmin !important;
                }
            }
        }
    }

    .css-ffg8md-MuiNativeSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-ffg8md-MuiNativeSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-ffg8md-MuiNativeSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
        font-size: 2vmin;
    }

    .css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel {
        font-size: 2vmin !important;
    }
}

@media only screen and (min-width:769px) and (max-width:1025px) {
    #cover_wrap {

        #profile_container {
            .MuiPaper-root {
                width: 55% !important;
                // max-height: none !important;
            }

            .MuiTypography-root {
                font-size: 2.3vmin !important;

                /* 50.407px */
            }

            .MuiDialogContent-root {
                border-top: 0 !important;
                padding-top: 8px;

            }

            .MuiStepConnector-root {
                top: 20px !important;
            }

            .css-ogijw2,
            .css-1fgbf5s {
                cursor: pointer;
            }

            .logout-head {
                justify-content: left !important;
            }

            .logout-msg {

                font-size: 3vmin !important;



            }

            .no-btn {
                position: relative;
                left: -2%;
                background: #424B70 !important;
            }

            .login-btn {

                font-size: 1.8vmin !important;

            }

            .fields {
                width: 100%;
                margin: auto;
                padding-bottom: 2%;

                .pwd {

                    font-size: 2vmin;

                }

                .row {

                    .input-group {
                        margin-top: 0.2rem;
                        // padding-bottom: 1rem;

                        img {
                            position: relative;
                            top: 17%;
                            left: 2%;
                        }

                        span {
                            float: left;
                        }

                        .register {
                            display: flex;
                            width: 45%;
                            padding: 13px 0px;
                            justify-content: center;
                            align-items: center;
                            margin: auto;
                            border-radius: 8px;
                            background: #424B70;
                            border-style: none;
                            cursor: pointer;
                            color: #FFF;
                            font-family: Inter;
                            font-size: 2.2vmin;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 168.023%;
                            /* 33.605px */
                            text-transform: uppercase;
                        }

                        .ph-partner-register {
                            display: flex;
                            width: 45%;
                            padding: 13px 0px;
                            justify-content: center;
                            align-items: center;
                            margin: auto;
                            border-radius: 8px;
                            background: $secondary-color;
                            border-style: none;
                            cursor: pointer;
                            color: #FFF;
                            font-family: Inter;
                            font-size: 1.8vmin;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 168.023%;
                            /* 33.605px */
                            text-transform: uppercase;
                        }

                        label {
                            color: #000;
                            font-family: Inter;
                            font-size: 1.6vmin;
                            font-weight: 400;
                        }

                        #area-label {
                            margin-bottom: 2% !important;
                        }

                        .css-1h51icj-MuiAutocomplete-root {
                            #area_practice {
                                height: 20px;
                                margin-top: 20px;
                            }
                        }

                        #radio-grp {
                            min-width: 19vw;
                            display: flex;
                            flex-direction: row;
                            margin-top: 1%;

                            #first-radio {
                                margin-left: 1%;
                            }

                            span {
                                font-size: 2.1vmin !important;
                            }
                        }
                    }

                    #last-one {
                        display: flex;
                        flex-direction: row;

                        .register {
                            display: flex;
                            width: 45%;
                            padding: 13px 0px;
                            justify-content: center;
                            align-items: center;
                            margin: auto;
                            border-radius: 8px;
                            background: #424B70;
                            border-style: none;
                            cursor: pointer;
                            color: #FFF;
                            font-family: Inter;
                            font-size: 1.6vmin;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 168.023%;
                            /* 33.605px */
                            text-transform: uppercase;
                        }

                        .ph-partner-register {
                            display: flex;
                            width: 45%;
                            padding: 13px 0px;
                            justify-content: center;
                            align-items: center;
                            margin: auto;
                            border-radius: 8px;
                            background: $secondary-color;
                            border-style: none;
                            cursor: pointer;
                            color: #FFF;
                            font-family: Inter;
                            font-size: 1.6vmin;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 168.023%;
                            /* 33.605px */
                            text-transform: uppercase;
                        }
                    }

                    .pin_input-group {
                        margin-top: 2rem;
                        display: flex;
                        flex-direction: row;

                        justify-content: space-between;
                        width: 100%;
                        // padding-bottom: 1rem;

                        .input-wrap {
                            width: 90%;

                            label {
                                color: #000;
                                font-family: Inter;
                                font-size: 1.6vmin;
                                font-weight: 400;
                            }

                            span {
                                float: left;
                            }

                            .input-box {
                                height: 2.6rem;
                                border-radius: 4px;
                                border: 0.5px solid rgba(0, 0, 0, 0.70);
                                margin-top: 0.4rem;
                                width: 100%;

                                img {
                                    position: relative;
                                    top: 17%;
                                    left: 2%;
                                }

                                .eye_icon {
                                    position: absolute;
                                    color: #77787A;
                                    right: 12%;
                                    margin-top: 2%;
                                    cursor: pointer;
                                }

                                .err-msg {
                                    display: flex;
                                    flex-direction: column;
                                    margin-top: 1%;

                                    span {
                                        float: left !important;
                                    }
                                }


                                span {
                                    color: #E10000;
                                    font-family: Inter;
                                    font-size: 1.5vmin;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 142%;
                                    /* 19.88px */
                                    letter-spacing: 0.035px;
                                    padding-top: 1%;
                                    float: right !important;
                                }
                            }
                        }

                        .pincode_btn {
                            display: flex;
                            width: 12%;
                            height: 6vh;
                            margin-left: 1vw;
                            padding: 0px;
                            justify-content: center;
                            align-items: center;
                            border-radius: 50%;
                            background: $font-primay-color;
                            border-style: none;
                            cursor: pointer;
                            font-family: Inter;
                            font-size: 2vmin;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 2vmin;
                            /* 33.605px */
                            text-transform: uppercase;


                            .pincode_search {
                                color: #fff;
                            }
                        }
                    }

                }
            }

            .input-box {
                height: 2.6rem;
                border-radius: 4px;
                border: 0.5px solid rgba(0, 0, 0, 0.70);
                margin-top: 0.4rem;

                img {
                    position: relative;
                    top: 17%;
                    left: 2%;
                }

                .eye_icon {
                    color: #77787A;
                    right: 12%;
                    cursor: pointer;
                }

                .err-msg {
                    display: flex;
                    flex-direction: column;
                    margin-top: 1%;

                    span {
                        float: left !important;
                    }
                }


                span {
                    color: #E10000;
                    font-family: Inter;
                    font-size: 1.5vmin;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 142%;
                    /* 19.88px */
                    letter-spacing: 0.035px;
                    padding-top: 1%;
                    float: right !important;
                }
            }

            input[type="text"] {
                border: 0;
                outline: none;
                width: 85%;
                position: relative;
                top: 4%;
                height: 90%;
                margin-left: 2%;
                font-family: Inter;
            }

            input[type="password"] {
                border: 0;
                outline: none;
                width: 85%;
                position: relative;
                top: 4%;
                height: 90%;
                margin-left: 2%;
                font-family: Inter;
            }


            .cf_cover {
                display: flex;
                flex-direction: row;

                .check_box {
                    height: 15px;
                    width: 15px;
                    margin-right: 2%;
                    cursor: pointer;
                    border: 0.75px solid #E96919;
                }

                .checked_box {
                    height: 15px;
                    width: 15px;
                    margin-right: 2%;
                    border: 0.75px solid #fff;
                    display: inline-block;
                    background-color: #E96919;
                    cursor: pointer;

                    span {
                        position: relative;
                        left: -40%;
                        top: -3px;
                        -ms-transform: rotate(45deg);
                        /* IE 9 */
                        -webkit-transform: rotate(45deg);
                        /* Chrome, Safari, Opera */
                        transform: rotate(45deg);

                        .checkmark_stem {
                            position: relative;
                            width: 2.5px;
                            height: 12px;
                            background-color: #fff;
                            left: 3px;
                            top: 3px;
                        }

                        .checkmark_kick {
                            position: relative;
                            width: 3px;
                            height: 2.5px;
                            background-color: #fff;
                        }
                    }

                }

                margin-top: 1rem !important;
                width: 100%;
                align-items: center;

                .title {
                    color: #000;
                    font-family: Inter;
                    font-size: 1.7vmin;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 160.523%;
                    /* 22.473px */
                    letter-spacing: 0.035px;
                    width: 90%;
                    display: flex;
                    align-items: center;

                    .privacy-innerline {
                        color: #0193E5;
                        text-decoration-line: underline;
                        cursor: pointer;
                        // margin-left: 0.4%;
                    }

                    .info-icon-wrap {
                        margin-left: 5%;
                        display: flex;
                        align-items: center;

                        .info-icon {
                            color: $primary-color;
                            cursor: pointer;
                        }
                    }
                }

                .check_box {
                    height: 15px;
                    width: 15px;
                    margin-right: 2%;
                    cursor: pointer;
                    border: 0.75px solid #E96919;
                }

                .checked_box {
                    height: 15px;
                    width: 15px;
                    margin-right: 2%;
                    border: 0.75px solid #fff;
                    display: inline-block;
                    background-color: #E96919;
                    cursor: pointer;

                    span {
                        position: relative;
                        left: -40%;
                        top: -3px;
                        -ms-transform: rotate(45deg);
                        /* IE 9 */
                        -webkit-transform: rotate(45deg);
                        /* Chrome, Safari, Opera */
                        transform: rotate(45deg);

                        .checkmark_stem {
                            position: relative;
                            width: 2.5px;
                            height: 12px;
                            background-color: #fff;
                            left: 3px;
                            top: 3px;
                        }

                        .checkmark_kick {
                            position: relative;
                            width: 3px;
                            height: 2.5px;
                            background-color: #fff;
                        }
                    }

                }


            }

            .cf_code-wrap {
                width: 80%;
                height: 50%;
                display: flex;
                flex-direction: row;
                text-align: left;
                align-items: center;
                justify-content: space-around;
                padding: 2% 0;

                span {
                    color: #000;
                    font-family: Roboto;
                    font-size: 2.3vmin;
                    font-weight: 400;
                    width: 80%;
                    // border-bottom: 1px solid #ccc;
                    cursor: pointer;

                }

                .clipboard-icon {
                    margin-left: 5%;
                    color: $secondary-color;
                    cursor: pointer;
                }
            }
        }

    }

    #ph_partner_container {
        .MuiPaper-root {
            width: 70% !important;
            max-width: 100% !important;
            // max-height: none !important;
        }

        .MuiTypography-root {
            display: flex;
            justify-content: center;
            color: #000;
            font-family: Inter !important;
            font-size: 2.7vmin !important;
            font-style: normal;
            font-weight: 500 !important;
            padding-bottom: 1%;
            line-height: 168.023% !important;
            /* 50.407px */
        }

        .MuiDialogContent-root {
            border-top: 0 !important;
            padding-top: 8px;
            display: flex;
            flex-direction: column !important;

            // .dialog-content {
            .ph-content-cover {
                display: flex;
                flex-direction: row !important;
                overflow: auto;

                .ph-content-split {
                    width: 50%;
                    height: 76vh;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .fields {
                        width: 100%;
                        margin: auto;
                        padding-bottom: 2%;
                        width: 100%;

                        .pwd {
                            color: #000;
                            font-family: Inter;
                            font-size: 2.7vmin;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 168.023%;
                            /* 40.325px */
                            margin-bottom: 2%
                        }

                        .row {
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: center;

                            .input-group {
                                margin-top: 2rem;
                                width: 95%;
                                // padding-bottom: 1rem;


                                .input-box {
                                    height: 3rem;
                                    border-radius: 4px;
                                    border: 0.5px solid rgba(0, 0, 0, 0.70);

                                    img {
                                        position: relative;
                                        height: 32%;
                                        width: 4%;
                                        top: 12%;
                                        left: 2%;
                                    }
                                }

                                span {
                                    float: right;
                                    font-size: 1.7vmin;
                                    color: rgb(225, 0, 0);
                                }

                                .register {
                                    display: flex;
                                    width: 45%;
                                    padding: 13px 0px;
                                    justify-content: center;
                                    align-items: center;
                                    margin: auto;
                                    border-radius: 8px;
                                    background: #424B70;
                                    border-style: none;
                                    cursor: pointer;
                                    color: #FFF;
                                    font-family: Inter;
                                    font-size: 2.2vmin;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 168.023%;
                                    /* 33.605px */
                                    text-transform: uppercase;
                                }

                                .ph-partner-register {
                                    display: flex;
                                    width: 45%;
                                    padding: 13px 0px;
                                    justify-content: center;
                                    align-items: center;
                                    margin: auto;
                                    border-radius: 8px;
                                    background: $secondary-color;
                                    border-style: none;
                                    cursor: pointer;
                                    color: #FFF;
                                    font-family: Inter;
                                    font-size: 1.8vmin;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 168.023%;
                                    /* 33.605px */
                                    text-transform: uppercase;
                                }

                                .register_ph {
                                    display: flex;
                                    width: 70%;
                                    padding: 7px 0px;
                                    justify-content: center;
                                    align-items: center;
                                    margin: auto;
                                    border-radius: 8px;
                                    background: $primary-color;
                                    border-style: none;
                                    cursor: pointer;
                                    color: #FFF;
                                    font-family: Inter;
                                    font-size: 2.2vmin;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 168.023%;
                                    /* 33.605px */
                                    text-transform: uppercase;

                                    &:disabled {
                                        cursor: not-allowed;
                                    }
                                }

                                label {
                                    color: #000;
                                    font-family: Inter;
                                    font-size: 1.6vmin;
                                    font-weight: 400;
                                }

                                .title {
                                    color: #000;
                                    font-family: Inter;
                                    font-size: 1.7vmin;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 160.523%;
                                    /* 22.473px */
                                    letter-spacing: 0.035px;
                                    width: 90%;
                                    display: flex;
                                    align-items: center;

                                    .privacy-innerline {
                                        color: #0193E5;
                                        text-decoration-line: underline;
                                        cursor: pointer;
                                        // margin-left: 0.4%;
                                    }

                                    .info-icon-wrap {
                                        margin-left: 5%;
                                        display: flex;
                                        align-items: center;

                                        .info-icon {
                                            color: $primary-color;
                                            cursor: pointer;
                                        }
                                    }
                                }

                                .chip-input-container {
                                    display: flex;
                                    flex-wrap: wrap;
                                    align-items: center;

                                    .input-box {
                                        height: 3rem;
                                        border-radius: 4px;
                                        border: 0.5px solid rgba(0, 0, 0, 0.70);
                                        width: 100%;

                                        img {
                                            position: relative;
                                            height: 32%;
                                            width: 4%;
                                            top: 12%;
                                            left: 2%;
                                        }
                                    }
                                }

                                .chip {
                                    background-color: $secondary-color;
                                    border-radius: 16px;
                                    padding: 4px 8px;
                                    margin: 4px;
                                    display: flex;
                                    align-items: center;
                                    color: #fff;
                                    font-size: 1.6vmin;
                                }

                                .chip button {
                                    margin-left: 4px;
                                    background-color: transparent;
                                    border: none;
                                    cursor: pointer;
                                }


                            }


                            #last-one {}

                            .checkbox-over-wrap {
                                height: 6vh;
                                width: 100%;
                                display: flex;
                                flex-direction: row;
                                justify-content: space-evenly;

                                .checkbox-cover {
                                    height: 100%;
                                    width: max-content !important;
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                    justify-content: center;

                                    .check_box {
                                        height: 10px;
                                        min-width: 10px;
                                        margin-right: 2%;
                                        cursor: pointer;
                                        background-color: #D9D9D9;
                                        border-radius: 50%;
                                    }

                                    .checked_box {
                                        height: 10px;
                                        min-width: 10px;
                                        margin-right: 2%;
                                        display: inline-block;
                                        background-color: #E96919;
                                        border-radius: 50%;
                                        cursor: pointer;
                                    }

                                    .label {
                                        color: #000;
                                        font-family: Inter;
                                        font-size: 1.6vmin;
                                        font-weight: bold;
                                        margin-left: 5px;
                                        text-wrap: nowrap;
                                        cursor: pointer;
                                    }
                                }
                            }

                        }

                        .pin_input-group {
                            margin-top: 2rem;
                            display: flex;
                            flex-direction: row;

                            justify-content: space-between;
                            width: 90%;
                            // padding-bottom: 1rem;

                            .input-wrap {
                                width: 90%;

                                label {
                                    color: #000;
                                    font-family: Inter;
                                    font-size: 1.6vmin;
                                    font-weight: 400;
                                }

                                span {
                                    float: left;
                                }

                                .input-box {
                                    height: 2.6rem;
                                    border-radius: 4px;
                                    border: 0.5px solid rgba(0, 0, 0, 0.70);
                                    margin-top: 0.4rem;
                                    width: 100%;

                                    img {
                                        position: relative;
                                        top: 17%;
                                        left: 2%;
                                    }

                                    .eye_icon {
                                        position: absolute;
                                        color: #77787A;
                                        right: 12%;
                                        margin-top: 2%;
                                        cursor: pointer;
                                    }

                                    .err-msg {
                                        display: flex;
                                        flex-direction: column;
                                        margin-top: 1%;

                                        span {
                                            float: left !important;
                                        }
                                    }


                                    span {
                                        color: #E10000;
                                        font-family: Inter;
                                        font-size: 1.5vmin;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 142%;
                                        /* 19.88px */
                                        letter-spacing: 0.035px;
                                        padding-top: 1%;
                                        float: right !important;
                                    }
                                }
                            }

                            .pincode_btn {
                                display: flex;
                                width: 9%;
                                padding: 8px 0px;
                                justify-content: center;
                                align-items: center;
                                border-radius: 50%;
                                background: $font-primay-color;
                                border-style: none;
                                cursor: pointer;
                                font-family: Inter;
                                font-size: 2vmin;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 2vmin;
                                /* 33.605px */
                                text-transform: uppercase;


                                .pincode_search {
                                    color: #fff;
                                }
                            }
                        }
                    }
                }

            }

            .btn-group {
                margin-top: 1rem;
                width: 95%;
                display: flex;

                .register_ph {
                    display: flex;
                    width: 40%;
                    padding: 7px 0px;
                    justify-content: center;
                    align-items: center;
                    margin: auto;
                    border-radius: 8px;
                    background: $primary-color;
                    border-style: none;
                    cursor: pointer;
                    color: #FFF;
                    font-family: Inter;
                    font-size: 2.2vmin;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 168.023%;
                    /* 33.605px */
                    text-transform: uppercase;

                    &:disabled {
                        cursor: not-allowed;
                    }
                }
            }
        }


    }

    .css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel {
        margin-top: 0 !important;
        font-family: Inter;
        font-size: 1.5vmin !important;
    }
}

@media only screen and (min-width:1026px) and (max-width:1440px) {
    #cover_wrap {

        #profile_container {
            .MuiPaper-root {
                width: 45% !important;
                max-width: 100% !important;
                // max-height: none !important;
            }

            .MuiTypography-root {
                display: flex;
                justify-content: center;
                color: #000;
                font-family: Inter !important;
                font-size: 2.5vmin !important;
                font-style: normal;
                font-weight: 500 !important;
                padding-bottom: 1%;
                line-height: 168.023% !important;
                /* 50.407px */
            }

            .MuiDialogContent-root {
                border-top: 0 !important;
                padding-top: 8px;
            }

            .MuiStepConnector-root {
                top: 20px !important;
            }

            .css-ogijw2,
            .css-1fgbf5s {
                cursor: pointer;
            }

            .logout-head {
                justify-content: left !important;
            }

            .logout-msg {
                display: flex;
                justify-content: left;
                color: #000;
                font-family: Inter !important;
                font-size: 3.5vmin !important;
                font-style: normal;
                font-weight: 500 !important;
                line-height: 168.023% !important;
                /* 50.407px */
                margin-bottom: 5%;


            }

            .no-btn {
                position: relative;
                left: -2%;
                background: #424B70 !important;
            }

            .login-btn {
                display: flex;
                float: right;
                width: 25%;
                padding: 8px 40px;
                justify-content: center;
                align-items: center;
                margin: auto;
                border-radius: 8px;
                background: #ed7d21;
                border-style: none;

                color: #FFF;
                font-family: Inter;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 168.023%;
            }

            .fields {
                width: 100%;
                margin: auto;
                padding-bottom: 2%;

                .pwd {
                    color: #000;
                    font-family: Inter;
                    font-size: 2.7vmin;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 168.023%;
                    /* 40.325px */
                    margin-bottom: 2%
                }

                .row {

                    .input-group {
                        margin-top: 0.2rem;
                        // padding-bottom: 1rem;

                        img {
                            position: relative;
                            top: 17%;
                            left: 2%;
                        }

                        span {
                            float: left;
                        }

                        .register {
                            display: flex;
                            width: 45%;
                            padding: 13px 0px;
                            justify-content: center;
                            align-items: center;
                            margin: auto;
                            border-radius: 8px;
                            background: #424B70;
                            border-style: none;
                            cursor: pointer;
                            color: #FFF;
                            font-family: Inter;
                            font-size: 2.2vmin;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 168.023%;
                            /* 33.605px */
                            text-transform: uppercase;
                        }

                        .ph-partner-register {
                            display: flex;
                            width: 45%;
                            padding: 13px 0px;
                            justify-content: center;
                            align-items: center;
                            margin: auto;
                            border-radius: 8px;
                            background: $secondary-color;
                            border-style: none;
                            cursor: pointer;
                            color: #FFF;
                            font-family: Inter;
                            font-size: 1.8vmin;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 168.023%;
                            /* 33.605px */
                            text-transform: uppercase;
                        }

                        label {
                            color: #000;
                            font-family: Inter;
                            font-size: 1.6vmin;
                            font-weight: 400;
                        }

                        #area-label {
                            margin-bottom: 2% !important;
                        }

                        .css-1h51icj-MuiAutocomplete-root {
                            #area_practice {
                                height: 20px;
                                margin-top: 20px;
                            }
                        }

                        #radio-grp {
                            min-width: 19vw;
                            display: flex;
                            flex-direction: row;
                            margin-top: 1%;

                            #first-radio {
                                margin-left: 1%;
                            }

                            span {
                                font-size: 2.1vmin !important;
                            }
                        }
                    }

                    #last-one {
                        display: flex;
                        flex-direction: row;

                        .register {
                            display: flex;
                            width: 45%;
                            padding: 13px 0px;
                            justify-content: center;
                            align-items: center;
                            margin: auto;
                            border-radius: 8px;
                            background: #424B70;
                            border-style: none;
                            cursor: pointer;
                            color: #FFF;
                            font-family: Inter;
                            font-size: 2vmin;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 168.023%;
                            /* 33.605px */
                            text-transform: uppercase;
                        }

                        .ph-partner-register {
                            display: flex;
                            width: 45%;
                            padding: 13px 0px;
                            justify-content: center;
                            align-items: center;
                            margin: auto;
                            border-radius: 8px;
                            background: $secondary-color;
                            border-style: none;
                            cursor: pointer;
                            color: #FFF;
                            font-family: Inter;
                            font-size: 1.8vmin;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 168.023%;
                            /* 33.605px */
                            text-transform: uppercase;
                        }
                    }

                    .pin_input-group {
                        margin-top: 2rem;
                        display: flex;
                        flex-direction: row;

                        justify-content: space-between;
                        width: 100%;
                        // padding-bottom: 1rem;

                        .input-wrap {
                            width: 90%;

                            label {
                                color: #000;
                                font-family: Inter;
                                font-size: 1.6vmin;
                                font-weight: 400;
                            }

                            span {
                                float: left;
                            }

                            .input-box {
                                height: 2.6rem;
                                border-radius: 4px;
                                border: 0.5px solid rgba(0, 0, 0, 0.70);
                                margin-top: 0.4rem;
                                width: 100%;

                                img {
                                    position: relative;
                                    top: 17%;
                                    left: 2%;
                                }

                                .eye_icon {
                                    position: absolute;
                                    color: #77787A;
                                    right: 12%;
                                    margin-top: 2%;
                                    cursor: pointer;
                                }

                                .err-msg {
                                    display: flex;
                                    flex-direction: column;
                                    margin-top: 1%;

                                    span {
                                        float: left !important;
                                    }
                                }


                                span {
                                    color: #E10000;
                                    font-family: Inter;
                                    font-size: 1.5vmin;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 142%;
                                    /* 19.88px */
                                    letter-spacing: 0.035px;
                                    padding-top: 1%;
                                    float: right !important;
                                }
                            }
                        }

                        .pincode_btn {
                            display: flex;
                            width: 8.5%;
                            padding: 8px 0px;
                            justify-content: center;
                            align-items: center;
                            border-radius: 50%;
                            background: $font-primay-color;
                            border-style: none;
                            cursor: pointer;
                            font-family: Inter;
                            font-size: 2vmin;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 2vmin;
                            /* 33.605px */
                            text-transform: uppercase;


                            .pincode_search {
                                color: #fff;
                            }
                        }
                    }

                }
            }

            .input-box {
                height: 2.6rem;
                border-radius: 4px;
                border: 0.5px solid rgba(0, 0, 0, 0.70);
                margin-top: 0.4rem;

                img {
                    position: relative;
                    top: 17%;
                    left: 2%;
                }

                .eye_icon {
                    color: #77787A;
                    right: 12%;
                    cursor: pointer;
                }

                .err-msg {
                    display: flex;
                    flex-direction: column;
                    margin-top: 1%;

                    span {
                        float: left !important;
                    }
                }


                span {
                    color: #E10000;
                    font-family: Inter;
                    font-size: 1.5vmin;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 142%;
                    /* 19.88px */
                    letter-spacing: 0.035px;
                    padding-top: 1%;
                    float: right !important;
                }
            }

            input[type="text"] {
                border: 0;
                outline: none;
                width: 85%;
                position: relative;
                top: 4%;
                height: 90%;
                margin-left: 2%;
                font-family: Inter;
            }

            input[type="password"] {
                border: 0;
                outline: none;
                width: 85%;
                position: relative;
                top: 4%;
                height: 90%;
                margin-left: 2%;
                font-family: Inter;
            }


            .cf_cover {
                display: flex;
                flex-direction: row;

                .check_box {
                    height: 15px;
                    width: 15px;
                    margin-right: 2%;
                    cursor: pointer;
                    border: 0.75px solid #E96919;
                }

                .checked_box {
                    height: 15px;
                    width: 15px;
                    margin-right: 2%;
                    border: 0.75px solid #fff;
                    display: inline-block;
                    background-color: #E96919;
                    cursor: pointer;

                    span {
                        position: relative;
                        left: -40%;
                        top: -3px;
                        -ms-transform: rotate(45deg);
                        /* IE 9 */
                        -webkit-transform: rotate(45deg);
                        /* Chrome, Safari, Opera */
                        transform: rotate(45deg);

                        .checkmark_stem {
                            position: relative;
                            width: 2.5px;
                            height: 12px;
                            background-color: #fff;
                            left: 3px;
                            top: 3px;
                        }

                        .checkmark_kick {
                            position: relative;
                            width: 3px;
                            height: 2.5px;
                            background-color: #fff;
                        }
                    }

                }

                margin-top: 1rem !important;
                width: 100%;
                align-items: center;

                .title {
                    color: #000;
                    font-family: Inter;
                    font-size: 1.7vmin;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 160.523%;
                    /* 22.473px */
                    letter-spacing: 0.035px;
                    width: 90%;
                    display: flex;
                    align-items: center;

                    .privacy-innerline {
                        color: #0193E5;
                        text-decoration-line: underline;
                        cursor: pointer;
                        // margin-left: 0.4%;
                    }

                    .info-icon-wrap {
                        margin-left: 5%;
                        display: flex;
                        align-items: center;

                        .info-icon {
                            color: $primary-color;
                            cursor: pointer;
                        }
                    }
                }

                .check_box {
                    height: 15px;
                    width: 15px;
                    margin-right: 2%;
                    cursor: pointer;
                    border: 0.75px solid #E96919;
                }

                .checked_box {
                    height: 15px;
                    width: 15px;
                    margin-right: 2%;
                    border: 0.75px solid #fff;
                    display: inline-block;
                    background-color: #E96919;
                    cursor: pointer;

                    span {
                        position: relative;
                        left: -40%;
                        top: -3px;
                        -ms-transform: rotate(45deg);
                        /* IE 9 */
                        -webkit-transform: rotate(45deg);
                        /* Chrome, Safari, Opera */
                        transform: rotate(45deg);

                        .checkmark_stem {
                            position: relative;
                            width: 2.5px;
                            height: 12px;
                            background-color: #fff;
                            left: 3px;
                            top: 3px;
                        }

                        .checkmark_kick {
                            position: relative;
                            width: 3px;
                            height: 2.5px;
                            background-color: #fff;
                        }
                    }

                }


            }

            .cf_code-wrap {
                width: 80%;
                height: 50%;
                display: flex;
                flex-direction: row;
                text-align: left;
                align-items: center;
                justify-content: space-around;
                padding: 2% 0;

                span {
                    color: #000;
                    font-family: Roboto;
                    font-size: 2.3vmin;
                    font-weight: 400;
                    width: 80%;
                    // border-bottom: 1px solid #ccc;
                    cursor: pointer;

                }

                .clipboard-icon {
                    margin-left: 5%;
                    color: $secondary-color;
                    cursor: pointer;
                }
            }
        }

    }

    #ph_partner_container {
        .MuiPaper-root {
            width: 70% !important;
            max-width: 100% !important;
            // max-height: none !important;
        }

        .MuiTypography-root {
            display: flex;
            justify-content: center;
            color: #000;
            font-family: Inter !important;
            font-size: 2.7vmin !important;
            font-style: normal;
            font-weight: 500 !important;
            padding-bottom: 1%;
            line-height: 168.023% !important;
            /* 50.407px */
        }

        .MuiDialogContent-root {
            border-top: 0 !important;
            padding-top: 8px;
            display: flex;
            flex-direction: column !important;

            // .dialog-content {
            .ph-content-cover {
                display: flex;
                flex-direction: row !important;
                overflow: auto;

                .ph-content-split {
                    width: 50%;
                    height: 76vh;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .fields {
                        padding-bottom: 2%;
                        width: 100%;
                        margin: auto;

                        .pwd {
                            color: #000;
                            font-family: Inter;
                            font-size: 2.7vmin;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 168.023%;
                            /* 40.325px */
                            margin-bottom: 2%
                        }

                        .row {
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: center;

                            .input-group {
                                margin-top: 2rem;
                                width: 95%;
                                // padding-bottom: 1rem;


                                .input-box {
                                    height: 3rem;
                                    border-radius: 4px;
                                    border: 0.5px solid rgba(0, 0, 0, 0.70);

                                    img {
                                        position: relative;
                                        height: 32%;
                                        width: 4%;
                                        top: 12%;
                                        left: 2%;
                                    }
                                }

                                span {
                                    float: right;
                                    font-size: 1.7vmin;
                                    color: rgb(225, 0, 0);
                                }

                                .register {
                                    display: flex;
                                    width: 45%;
                                    padding: 13px 0px;
                                    justify-content: center;
                                    align-items: center;
                                    margin: auto;
                                    border-radius: 8px;
                                    background: #424B70;
                                    border-style: none;
                                    cursor: pointer;
                                    color: #FFF;
                                    font-family: Inter;
                                    font-size: 2.2vmin;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 168.023%;
                                    /* 33.605px */
                                    text-transform: uppercase;
                                }

                                .ph-partner-register {
                                    display: flex;
                                    width: 45%;
                                    padding: 13px 0px;
                                    justify-content: center;
                                    align-items: center;
                                    margin: auto;
                                    border-radius: 8px;
                                    background: $secondary-color;
                                    border-style: none;
                                    cursor: pointer;
                                    color: #FFF;
                                    font-family: Inter;
                                    font-size: 1.8vmin;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 168.023%;
                                    /* 33.605px */
                                    text-transform: uppercase;
                                }

                                .register_ph {
                                    display: flex;
                                    width: 70%;
                                    padding: 7px 0px;
                                    justify-content: center;
                                    align-items: center;
                                    margin: auto;
                                    border-radius: 8px;
                                    background: $primary-color;
                                    border-style: none;
                                    cursor: pointer;
                                    color: #FFF;
                                    font-family: Inter;
                                    font-size: 2.2vmin;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 168.023%;
                                    /* 33.605px */
                                    text-transform: uppercase;

                                    &:disabled {
                                        cursor: not-allowed;
                                    }
                                }

                                label {
                                    color: #000;
                                    font-family: Inter;
                                    font-size: 1.6vmin;
                                    font-weight: 400;
                                }

                                .title {
                                    color: #000;
                                    font-family: Inter;
                                    font-size: 1.7vmin;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 160.523%;
                                    /* 22.473px */
                                    letter-spacing: 0.035px;
                                    width: 90%;
                                    display: flex;
                                    align-items: center;

                                    .privacy-innerline {
                                        color: #0193E5;
                                        text-decoration-line: underline;
                                        cursor: pointer;
                                        // margin-left: 0.4%;
                                    }

                                    .info-icon-wrap {
                                        margin-left: 5%;
                                        display: flex;
                                        align-items: center;

                                        .info-icon {
                                            color: $primary-color;
                                            cursor: pointer;
                                        }
                                    }
                                }

                                .chip-input-container {
                                    display: flex;
                                    flex-wrap: wrap;
                                    align-items: center;

                                    .input-box {
                                        height: 3rem;
                                        border-radius: 4px;
                                        border: 0.5px solid rgba(0, 0, 0, 0.70);
                                        width: 100%;

                                        img {
                                            position: relative;
                                            height: 32%;
                                            width: 4%;
                                            top: 12%;
                                            left: 2%;
                                        }
                                    }
                                }

                                .chip {
                                    background-color: $secondary-color;
                                    border-radius: 16px;
                                    padding: 4px 8px;
                                    margin: 4px;
                                    display: flex;
                                    align-items: center;
                                    color: #fff;
                                    font-size: 1.6vmin;
                                }

                                .chip button {
                                    margin-left: 4px;
                                    background-color: transparent;
                                    border: none;
                                    cursor: pointer;
                                }


                            }


                            #last-one {}

                            .checkbox-over-wrap {
                                height: 6vh;
                                width: 100%;
                                display: flex;
                                flex-direction: row;
                                justify-content: space-evenly;

                                .checkbox-cover {
                                    height: 100%;
                                    width: max-content !important;
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                    justify-content: center;

                                    .check_box {
                                        height: 10px;
                                        min-width: 10px;
                                        margin-right: 2%;
                                        cursor: pointer;
                                        background-color: #D9D9D9;
                                        border-radius: 50%;
                                    }

                                    .checked_box {
                                        height: 10px;
                                        min-width: 10px;
                                        margin-right: 2%;
                                        display: inline-block;
                                        background-color: #E96919;
                                        border-radius: 50%;
                                        cursor: pointer;
                                    }

                                    .label {
                                        color: #000;
                                        font-family: Inter;
                                        font-size: 1.6vmin;
                                        font-weight: bold;
                                        margin-left: 5px;
                                        text-wrap: nowrap;
                                        cursor: pointer;
                                    }
                                }
                            }

                        }

                        .pin_input-group {
                            margin-top: 2rem;
                            display: flex;
                            flex-direction: row;

                            justify-content: space-between;
                            width: 90%;
                            // padding-bottom: 1rem;

                            .input-wrap {
                                width: 90%;

                                label {
                                    color: #000;
                                    font-family: Inter;
                                    font-size: 1.6vmin;
                                    font-weight: 400;
                                }

                                span {
                                    float: left;
                                }

                                .input-box {
                                    height: 2.6rem;
                                    border-radius: 4px;
                                    border: 0.5px solid rgba(0, 0, 0, 0.70);
                                    margin-top: 0.4rem;
                                    width: 100%;

                                    img {
                                        position: relative;
                                        top: 17%;
                                        left: 2%;
                                    }

                                    .eye_icon {
                                        position: absolute;
                                        color: #77787A;
                                        right: 12%;
                                        margin-top: 2%;
                                        cursor: pointer;
                                    }

                                    .err-msg {
                                        display: flex;
                                        flex-direction: column;
                                        margin-top: 1%;

                                        span {
                                            float: left !important;
                                        }
                                    }


                                    span {
                                        color: #E10000;
                                        font-family: Inter;
                                        font-size: 1.5vmin;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 142%;
                                        /* 19.88px */
                                        letter-spacing: 0.035px;
                                        padding-top: 1%;
                                        float: right !important;
                                    }
                                }
                            }

                            .pincode_btn {
                                display: flex;
                                width: 9%;
                                padding: 8px 0px;
                                justify-content: center;
                                align-items: center;
                                border-radius: 50%;
                                background: $font-primay-color;
                                border-style: none;
                                cursor: pointer;
                                font-family: Inter;
                                font-size: 2vmin;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 2vmin;
                                /* 33.605px */
                                text-transform: uppercase;


                                .pincode_search {
                                    color: #fff;
                                }
                            }
                        }
                    }
                }

            }

            .btn-group {
                margin-top: 1rem;
                width: 95%;
                display: flex;

                .register_ph {
                    display: flex;
                    width: 40%;
                    padding: 7px 0px;
                    justify-content: center;
                    align-items: center;
                    margin: auto;
                    border-radius: 8px;
                    background: $primary-color;
                    border-style: none;
                    cursor: pointer;
                    color: #FFF;
                    font-family: Inter;
                    font-size: 2.2vmin;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 168.023%;
                    /* 33.605px */
                    text-transform: uppercase;

                    &:disabled {
                        cursor: not-allowed;
                    }
                }
            }
        }


    }
}

.css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    margin-top: 0 !important;
    font-family: Inter;
    font-size: 1.5vmin;
}