/* index.css */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import '../../App.scss';

.ant-picker-calendar .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
    display: none;
}

.ant-picker-calendar.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
    color: white;
}



// :where(.css-dev-only-do-not-override-1uq9j6g).ant-table-wrapper .ant-table-row-expand-icon-cell {
//     display: none;
// }

.tableSelectCheckboxOverride {
    display: none;
}


.ant-pagination-item-active {
    background: $primary-color;
    border-radius: 2px;
}

.ant-pagination .ant-pagination-item-active a {
    color: #fff;
    background-color: $primary-color;
    border-radius: 2px;
}

.ant-pagination .ant-pagination-item-active:hover a {
    color: #FFF;
    background: $primary-color;
    border-radius: 2px;
}

.ant-pagination .ant-pagination-item-active,
.ant-pagination .ant-pagination-item-active:hover {
    border-color: $primary-color;
    border: 0px solid;
    border-radius: 2px;
}


thead {
    background-color: $primary-color;
}

.events {
    line-height: 24px;
    list-style: none;
    margin: 0;
    padding: 0;
}

.events li {
    color: #999;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.events li span {
    vertical-align: middle;
}

.events li span:first-child {
    font-size: 9px;
    margin-right: 4px;
}

.event-warning {
    color: #fac450;
}

.event-normal {
    color: #108ee9;
}

.event-error {
    color: #f50;
}

.notes-month {
    text-align: center;
}

.notes-month section {
    font-size: 28px;
}


.z-table {
    // display: flex;
    justify-content: center;
    flex-direction: column;
    width: 96%;
    // margin-top: 1%;
    padding: 2%;

    .z-table-container {
        min-height: 10vh;
        display: flex;
        // width: 100%;
        flex-direction: row;
        align-items: center;
        // border: 0.5px solid rgba(0, 0, 0, 0.50);
        background-color: #fff;
        border-bottom: none;
        border-top-right-radius: 15px;
        border-top-left-radius: 15px;

        .z-left-container {
            gap: 2%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 100%;
            width: 60%;
            margin-left: 2%;
            flex-wrap: wrap;

            .mob-search-icon {
                display: none;
            }

            .z-gst-head {
                color: var(--text-black, #393938);
                font-family: Inter;
                font-size: 3.5vmin;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }

            .z-table_global_search {
                border-radius: 20px;
                width: 58%;
                height: 40px !important;
                background: rgb(243, 243, 249);

                input {
                    background: rgb(243, 243, 249);
                    font-family: Inter;
                }
            }

            .view-notice-header-btn-wrap {
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                // justify-content: space-evenly;

                #z-common-gst-solution-button {
                    font-size: 2.2vmin;
                    font-family: Inter;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    gap: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 3% 2%;
                    min-width: 20%;
                    margin-right: 2%;
                }


                .theme2 {
                    background-color: $primary-color;
                    color: #FFFFFF;
                    border-color: none;
                }

                .theme1 {
                    background-color: #FFFFFF;
                    color: $primary-color;
                    border-color: $primary-color;
                }
            }

            .value_block {
                min-height: 80%;
                width: max-content;
                border-radius: 4px;
                padding: 1.5% 2%;
                color: #0d497f;
                background-color: #a3c9eb;
                border: 1px solid #0d497f;
                font-weight: bold;
                font-size: 1.8vmin;
                margin-top: 1vh;
                margin-bottom: 1vh;
            }
        }

        .z-left-container-drawer {
            gap: 2%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 100%;
            width: 60%;
            margin-left: 2%;

            .mob-search-icon {
                display: none;
            }

            .z-gst-head {
                color: var(--text-black, #393938);
                font-family: Inter;
                font-size: 2.8vmin;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }

            .z-table_global_search {
                border-radius: 20px;
                width: 35%;
                height: 32px !important;
                background: rgb(243, 243, 249);

                input {
                    background: rgb(243, 243, 249);
                    font-family: Inter;
                }
            }


            .view-notice-header-btn-wrap {
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;

                #z-common-gst-solution-button {
                    font-size: 2.2vmin;
                    font-family: Inter;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    gap: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 3% 2%;
                    min-width: 20%;
                    margin-right: 2%;
                }


                .theme2 {
                    background-color: $primary-color;
                    color: #FFFFFF;
                    border-color: none;
                }

                .theme1 {
                    background-color: #FFFFFF;
                    color: $primary-color;
                    border-color: $primary-color;
                }
            }
        }

        .z-left-mobile-container {
            display: none;
            gap: 2%;
            justify-content: flex-start;
            align-items: center;
            height: 100%;
            width: 50%;
            margin-left: 2%;


            .z-gst-head {
                color: var(--text-black, #393938);
                font-family: Inter;
                font-size: 3.5vmin;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }

            .z-table_global_search {
                border-radius: 20px;
                width: 58%;
                height: 40px !important;
                background: rgb(243, 243, 249);

                input {
                    background: rgb(243, 243, 249);
                    font-family: Inter;
                }
            }
        }

        .z-right-container {
            width: 40%;
            display: flex;
            justify-content: flex-end;
            margin-right: 3%;
            margin-left: 0.5%;
            gap: 1%;
            align-items: center;
            height: 100%;

            #z-common-gst-solution-button {
                font-size: 2.2vmin;
                font-family: Inter;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                gap: 5px;
                display: flex;
                align-items: center;
                padding: 3% 2%;

            }


            .theme1 {
                background-color: $primary-color;
                color: #FFFFFF;
                border-color: none;
            }

            .theme2 {
                background-color: $secondary-color;
                color: #FFFFFF;
                border-color: none;
            }

            .theme3 {
                background-color: #FCE7D9;
                color: $secondary-color;
                border-color: $secondary-color;
            }

            .theme4 {
                background-color: #FFFFFF;
                color: $secondary-color;
                border-color: $secondary-color;
            }

            .sync_cnt_cover {
                width: 32vw;
                height: 10vh;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                .title_cover {
                    width: auto;
                    height: auto;
                    font-size: 2.4vmin;
                    color: #000;
                    margin-right: 1%;
                }


            }

        }


    }

    .view-tab-cover-container {
        min-height: 6.5vh;
        display: flex;
        justify-content: end;
        flex-direction: row;
        border-bottom: none;
        border-top: none;
        padding-left: 3%;
        padding-right: 3%;
        background-color: #fff;

        .view-tab-cover {
            height: 4vh;
            min-width: 26vw;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
            border: 1px solid $primary-color;
            border-radius: 6px;

            .view-tab {
                height: 100%;
                font-family: Inter;
                color: $primary-color;
                cursor: pointer;
                font-size: 1.7vmin;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
            }

            .one {
                width: 40%;
                border-right: 1px solid $primary-color;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }

            .two {
                width: 42%;
                border-right: 1px solid $primary-color;
                // border-right: 0px solid black;
            }

            .three {
                width: 23%;
                border-left: 0px solid black;
                border: 1px solid $primary-color;
            }

            .four {
                width: 30%;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }

            .active-tab {
                background-color: $primary-color;
                color: #FFFFFF;
            }
        }
    }

    .manage-tab-cover-container {
        min-height: 6.5vh;
        display: flex;
        justify-content: end;
        flex-direction: row;
        border-bottom: none;
        border-top: none;
        padding-left: 3%;
        padding-right: 3%;
        background-color: #fff;

        .manage-tab-cover {
            height: 4vh;
            min-width: 26vw;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
            border: 1px solid $primary-color;
            border-radius: 6px;

            .manage-tab {
                height: 100%;
                width: 50%;
                font-family: Inter;
                color: $primary-color;
                cursor: pointer;
                font-size: 1.7vmin;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
            }

            .one {
                border-right: 1px solid $primary-color;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }


            .two {
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }

            .active-tab {
                background-color: $primary-color;
                color: #FFFFFF;
            }
        }
    }

    .z-gst-table-container {
        max-height: 70vh;
        width: 100%;
        margin-left: 0;
        margin-top: -0.2%;

        .ant-table-wrapper .ant-table {
            scrollbar-color: auto !important;
        }


    }

}

.ant-table-wrapper .ant-spin-nested-loading {
    min-height: 70vh !important;
}

.gst-err-msg {
    color: #E10000;
    font-family: Inter;
    font-size: 1.8vmin;
    text-align: right;
    margin-top: -6%;
    float: right;
}

.ant-table-wrapper .ant-table-tbody {
    scrollbar-color: $primary-color !important;
}

.ant-table-wrapper table tr th.ant-table-selection-column,
.ant-table-column-title,
.ant-table-thead>tr>th {
    color: var(--text-black, #393938);
    font-family: Inter;
    font-size: 2vmin;
    font-style: normal;
    font-weight: 500 !important;
    line-height: normal;

}


.ant-table-wrapper .ant-table-tbody>tr>td {
    color: #232323;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ant-table-wrapper .ant-table-tbody>tr {
    background-color: #fff;
}

.ant-pagination .ant-pagination-total-text,
.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    color: var(--low-contrast-text, #666);
    font-family: Inter;
    font-size: 1.9vmin;
    font-style: normal;
    font-weight: 500;
}

.ant-pagination .ant-pagination-item-active {
    color: #FFF;
    font-family: Inter;
    font-size: 1.8vmin;
    font-style: normal;
    font-weight: 500;
}

.ant-pagination-total-text {
    position: absolute;
    left: 0;
}

.ant-pagination-options {
    position: absolute;
    right: 0;
}

.ant-select-selector {
    cursor: pointer !important;
    height: 100% !important;
    display: flex;
    align-items: center;
}

.gst-row-filter,
.gst-row-item {
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
    align-items: center;
    flex-direction: row;
    color: var(--text-black, #393938);
    font-family: Inter;
    font-size: 1.7vmin;
    font-style: normal;
    font-weight: 400;
    white-space: pre-line;
}

.ant-table-wrapper .ant-table-thead>tr>th,
.ant-table-wrapper .ant-table-thead>tr>td {
    background: #ECEDF1;
}

// .ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:last-child {
//     border-start-end-radius: 0;
// }
// .ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:first-child {
//     border-start-start-radius: 0;
// }
.ant-table-wrapper tr.ant-table-expanded-row>td {
    background: #ECEDF1;
}

.ant-table-wrapper table {
    // border: 0.5px solid rgba(0, 0, 0, 0.4);
    border-top: none;
}

.ant-table-content {
    border: 0 !important;
}


// #due-date-container {

.upload-popup {

    // .ant-modal-content{
    // padding: 20px 0px;
    // }
    .ant-modal-header {
        // margin-top: 2%;
        text-align: center;
    }

    .ant-upload-wrapper .ant-upload-select {
        width: 100%;
    }

    .upload_box {
        height: 15vh;
        width: 90%;
        margin: auto;
        margin-top: 5%;
        border: 2px dashed $secondary-color;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        cursor: pointer;

        .upload-btn {
            color: $primary-color;
            font-weight: bold;
            border: 1px solid $primary-color;
        }

        .upload-btn:hover {
            color: $primary-color !important;
            border: 1px solid $primary-color !important;
        }

        .selected-file-name {
            font-size: 2vmin;
        }
    }

    .drag-over {
        border-color: $primary-color;
    }

    .drop-zone p {
        margin: 0;
    }

    .downlod-template-wrap {
        width: 90%;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;


        .download-link {
            font-weight: bold;
            text-decoration: underline;
            color: $primary-color !important;
            cursor: pointer;
        }
    }

    .upload-pop-btn {
        margin-top: 1% !important;
        width: 90% !important;
        left: 5% !important;
    }
}

#admin-adduser-container {
    border-radius: 4px;
    background: transparent;
    // box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
    position: relative;
    width: 100%;
    // top: 7%;
    // left: -43%;

    // label {
    //     color: #000;
    //     font-family: Inter;
    //     font-size: 1.7vmin;
    //     font-weight: 600;
    // }



    .fields {
        margin: 0 5% 0 5%;
        padding-bottom: 5%;

        .input-group {
            margin-top: 1rem;
            display: flex;
            flex-direction: column;
            // padding-bottom: 1rem;

            span {
                float: left;
            }

            .register {
                text-transform: uppercase;
            }
        }
    }

    // .input-box {
    //     height: 3rem;
    //     border-radius: 4px;
    //     border: 0.5px solid rgba(0, 0, 0, 0.70);
    //     margin-top: 0.4rem;

    //     img {
    //         position: relative;
    //         top: 17%;
    //         left: 2%;
    //     }

    //     span {
    //         color: #E10000;
    //         font-family: Inter;
    //         font-size: 1.5vmin;
    //         font-style: normal;
    //         font-weight: 400;
    //         line-height: 142%;
    //         /* 19.88px */
    //         letter-spacing: 0.035px;
    //         padding-top: 1%;
    //         float: right !important;
    //     }

    //     .availble{
    //         color: green;
    //         font-family: Inter;
    //         font-size: 1.5vmin;
    //         font-style: normal;
    //         font-weight: 400;
    //         line-height: 142%;
    //         /* 19.88px */
    //         letter-spacing: 0.035px;
    //         padding-top: 1%;
    //         float: right !important;
    //     }
    // }

    input[type="text"] {
        border: 0;
        outline: none;
        width: 85%;
        position: relative;
        top: 4%;
        height: 90%;
        margin-left: 4%;
    }

    input[type="checkbox"] {
        visibility: hidden;
        // margin-right: 10%;
    }

    .check_box {
        height: 15px;
        width: 15px;
        margin-right: 2%;
        cursor: pointer;
        border: 0.75px solid #E96919;
    }

    .checked_box {
        height: 15px;
        width: 15px;
        margin-right: 2%;
        border: 0.75px solid #fff;
        display: inline-block;
        background-color: #E96919;
        cursor: pointer;

        span {
            position: relative;
            left: -40%;
            top: -3px;
            -ms-transform: rotate(45deg);
            /* IE 9 */
            -webkit-transform: rotate(45deg);
            /* Chrome, Safari, Opera */
            transform: rotate(45deg);

            .checkmark_stem {
                position: relative;
                width: 2.5px;
                height: 12px;
                background-color: #fff;
                left: 3px;
                top: 3px;
            }

            .checkmark_kick {
                position: relative;
                width: 3px;
                height: 2.5px;
                background-color: #fff;
            }
        }

    }

    // .title {
    //     color: #000;
    //     font-family: Inter;
    //     font-size: 1.7vmin;
    //     font-style: normal;
    //     font-weight: 600;
    //     line-height: 160.523%;
    //     /* 22.473px */
    //     letter-spacing: 0.035px;
    //     display: flex;

    //     span {
    //         color: #0193E5;
    //         text-decoration-line: underline;
    //         cursor: pointer;
    //         margin-left: 1.4%;
    //     }
    // }

    .privacy_policy {
        display: flex;
        flex-direction: row;
        margin-top: 1rem !important;
        width: 100%;
        align-items: center;

        .title {
            color: #000;
            font-family: Inter;
            font-size: 1.7vmin;
            font-style: normal;
            font-weight: 600;
            line-height: 160.523%;
            /* 22.473px */
            letter-spacing: 0.035px;
            width: 90%;

            .privacy-innerline {
                color: #0193E5;
                text-decoration-line: underline;
                cursor: pointer;
                // margin-left: 0.4%;
            }
        }
    }

    .register_btn {
        margin-top: 1.4rem !important;
    }
}

.ant-modal .ant-modal-footer {
    display: flex;
    justify-content: space-between;
}

@media only screen and (min-width:320px) and (max-width:600px) {
    .z-table {
        width: 91%;
        margin-left: 0%;
        height: auto;
        margin-top: 1%;

        .z-table-container {
            height: 9vh;

            .z-left-mobile-container {
                display: flex;

                .z-gst-head {
                    font-size: 3vmin;
                }

                .mob-search-icon {
                    height: 45% !important;
                }

                .z-table_global_search {
                    width: 100%
                }
            }

            .z-left-container {
                display: none;

            }

            .z-right-container {
                width: 60%;
                // background-color: #fac450;
                display: flex !important;
                justify-content: flex-end !important;
                margin-right: 0%;
                margin-left: 0.5%;
                gap: 2%;
                align-items: center !important;
                height: 100%;

                #z-common-gst-solution-button {
                    font-size: 1.5vmin;
                    font-family: Inter;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    gap: 5px;
                    margin-right: 2%;
                    display: flex;
                    align-items: center;
                    padding: 3% 2%;

                }


                .theme1 {
                    background-color: $primary-color;
                    color: #FFFFFF;
                    border-color: none;
                }

                .theme2 {
                    background-color: $secondary-color;
                    color: #FFFFFF;
                    border-color: none;
                }

                .theme3 {
                    background-color: #FCE7D9;
                    color: $secondary-color;
                    border-color: $secondary-color;
                }

                .theme4 {
                    background-color: #FFFFFF;
                    color: $secondary-color;
                    border-color: $secondary-color;
                }

                .sync_cnt_cover {
                    // background-color: aqua;
                    justify-content: flex-start;
                    width: 0%;

                    .title_cover {
                        display: none;

                    }



                    .tab_style1 {
                        display: none;

                    }

                    .tab_style2 {
                        display: none;

                    }

                    .tab_style3 {
                        display: none;

                    }

                }

            }

            .button-label {

                display: none;
            }

            .get-button {
                font-size: 2.3vmin;
                display: flex;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .get-btn {
                width: 41% !important;
            }
        }

        .today_sync_monitering {
            min-height: 5vh;
            display: flex;
            justify-content: flex-end;
            flex-direction: row;
            align-items: center;
            background-color: #fff;

            .title_cover1 {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 15% !important;
                height: 100%;
                font-size: 2.8vmin;
                color: #000;
                margin-right: 1%;
            }

            .tab_style11 {
                font-size: 2.3vmin;
                border-radius: 25px;
                width: 15%;
                height: 90% !important;
                justify-content: center;
                align-items: center;
                display: flex;
            }

            .tab_style22 {
                font-size: 2.3vmin;
                border-radius: 25px;
                width: 23%;
                height: 50%;
                justify-content: center;
                align-items: center;
                display: flex;
            }

            .tab_style33 {
                font-size: 2.3vmin;
                border-radius: 25px;
                width: 25%;
                height: 50%;
                justify-content: center;
                align-items: center;
                display: flex;
            }
        }

        .z-gst-table-container {
            max-width: 100%;
            overflow-x: auto;
            white-space: nowrap;

            .ant-table-column-title {
                font-size: 2.5vmin;
            }

            .ant-table-thead>tr>th {
                font-size: 2.5vmin;

            }

            .ant-pagination-total-text {
                font-size: 2.7vmin;
            }

            .ant-pagination-item-active {
                font-size: 2.8vmin;

            }

            // .ant-pagination-total-text {
            //     position: absolute;
            //     left: 0;
            // }
            // .ant-pagination-options {
            //     position: absolute;
            //     right: 0;
            // }
        }

        .view-tab-cover-container {
            min-height: 5.5vh !important;

            .view-tab-cover {
                height: 4vh;
                min-width: 60vw;

                .view-tab {

                    font-size: 2vmin !important;
                }
            }
        }
    }

    .action-popup {
        width: 90% !important;

        #popup-submit-btn {
            height: 6vh !important;
            font-size: 4vmin !important;
            margin-left: 10%;
        }

        label {
            font-size: 3.2vmin !important;
        }

        .input-box {
            input {
                width: 82% !important;
                top: 0% !important;
                height: 90% !important;
                margin-left: 15% !important;
            }

            .eye_icon {
                // margin-top: -6% !important;
                position: absolute;
                color: #77787A;
                right: 10%;
                margin-top: 2.5%;
                cursor: pointer;

            }
        }

        .popup-button {
            font-size: 2vmin !important;
        }

        .ant-modal-title {
            font-size: 5vmin !important;
        }

        .form_item {
            margin-bottom: 5px !important;

            .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
                font-size: 3.5vmin !important;
            }

            .attach_file_line {
                font-size: 2.5vmin !important;
            }

            .attach_file_img {
                margin-right: 5px !important;
                height: 3.1vmin !important;
            }

            .create-popup-btn {
                height: 6vh !important;
            }


        }

        .one {
            margin-bottom: 0px !important;
        }

        .two {
            margin-bottom: 10px !important;
        }

        .gst-err-msg {
            font-size: 0vmin !important;
        }
    }

    .sum-drawer {
        display: none;
    }

    .summary-view-popup {

        width: 90% !important;
        padding-bottom: 0% !important;

        .ant-modal-title {
            font-size: 5vmin !important;
            text-align: center;
        }

        .ant-modal-content {
            padding-left: 0%;
            padding-right: 2%;
            padding-bottom: 0.1%;
        }

        .ant-pagination .ant-pagination-item-active a {
            font-size: 3vmin !important;
        }

        .sum-popup {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 100%;
            background-color: #ECEDF1;
            margin-top: 2%;
            padding: 0.2%;
            padding-left: 1%;
            padding-right: 1%;

            .drawer-header {
                display: flex;
                flex-direction: column;
                height: 10vh;
                border-bottom: 1px solid $primary-color;
                padding-bottom: 1%;
                padding-right: 0%;
                padding-left: 0%;


                .drawer-first-line {
                    height: 50%;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .drawer-close-btn {
                        color: #000;
                        height: 53%;
                        cursor: pointer;
                        position: initial;
                    }

                    .summary-heading {
                        margin: auto;
                        color: $font-primay-color ;
                        font-family: 'Inter';
                    }
                }

                .drawer-second-line {
                    height: 50%;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .drawer_global_search {
                        border-radius: 20px;
                        width: 35%;
                        height: 32px !important;
                        background: #fff;
                        margin-right: 3%;

                        input {
                            background: #fff;
                            font-family: Inter;
                        }

                        &:hover {
                            border: 1px solid $font-primay-color;
                        }
                    }

                    .drawer-filter {
                        cursor: pointer;
                        color: $font-primay-color;
                    }

                    .notice-cunt {
                        margin-left: auto;
                        color: #000;
                        font-family: 'Inter';

                        span {
                            color: $primary-color;
                            font-weight: bold;
                        }
                    }
                }
            }

            .drawer-content {
                display: flex;
                flex-direction: column;
                height: 70vh;
                padding-top: 2.5%;
                overflow: auto;
                padding-bottom: -2.5% !important;
                padding-right: 4%;
                padding-left: 4%;

                .drawer-card {
                    border-radius: 18px;
                    background-color: #fff;
                    height: 23vh;
                    width: 100%;
                    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
                    margin-bottom: 2.5%;

                    .card-first-split {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-evenly;
                        height: 35%;
                        border-bottom: 1.5px solid rgba(0, 0, 0, 0.3);

                        .content-group {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            width: max-content;
                            height: 100%;

                            .main-line {
                                font-size: 2vmin !important;
                                line-height: 3.5vmin !important;
                                font-size: bold;
                                color: #424B70;
                                font-family: 'Inter';
                                margin: 0;
                            }

                            .sub-line {
                                font-size: 2vmin !important;
                                line-height: 1.8vmin;
                                font-family: 'Inter';
                                margin: 0;
                            }
                        }

                        .view-icon {
                            color: $secondary-color;
                            cursor: pointer;
                        }
                    }

                    .card-second-split {
                        display: flex;
                        flex-direction: row;
                        height: 65%;
                        justify-content: space-evenly;
                        padding-bottom: 10%;

                        .sub-grid {
                            width: 46%;
                            height: 90%;

                            .header-line {
                                color: rgba(35, 35, 35, 0.7);
                                font-size: 2vmin;
                                line-height: 3.2vmin;
                                font-family: 'Inter';
                                margin: 0;
                            }

                            .desc-cover {
                                border-radius: 6px;
                                height: 70%;
                                box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                padding: 1%;

                                .desc-sub-line {
                                    font-size: 2vmin;
                                    line-height: 2.6vmin;
                                    font-family: 'Inter';
                                    color: #323232;
                                    flex-wrap: nowrap;
                                    margin: 0;
                                }
                            }
                        }
                    }
                }
            }

            .drawer-footer {
                display: flex;
                flex-direction: column;
                height: 7vh;
                border-top: 1px solid $primary-color;
                margin-top: 0px;
                padding: 3%;
            }
        }

    }
}

@media only screen and (min-width:601px) and (max-width:768px) {

    .ant-table-wrapper .ant-table-tbody>tr>td {
        font-size: 1.8vmin !important;
    }

    .z-table {
        width: 91%;
        margin-left: 3%;
        height: auto;
        margin-top: 1%;

        .z-table-container {
            height: 9vh;

            .z-left-mobile-container {
                display: flex;

                .z-gst-head {
                    font-size: 2.5vmin;
                }

                .mob-search-icon {
                    height: 30% !important;
                }

                .z-table_global_search {
                    width: 100%;


                }
            }

            .z-left-container {
                display: none;

            }

            .z-right-container {
                width: 60%;
                // background-color: #fac450;
                display: flex !important;
                justify-content: flex-end !important;
                margin-right: 0%;
                margin-left: 0.5%;
                gap: 2%;
                align-items: center !important;
                height: 100%;

                #z-common-gst-solution-button {
                    font-size: 1.5vmin;
                    font-family: Inter;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    gap: 5px;
                    margin-right: 2%;
                    display: flex;
                    align-items: center;
                    padding: 3% 2%;

                }


                .theme1 {
                    background-color: $primary-color;
                    color: #FFFFFF;
                    border-color: none;
                }

                .theme2 {
                    background-color: $secondary-color;
                    color: #FFFFFF;
                    border-color: none;
                }

                .theme3 {
                    background-color: #FCE7D9;
                    color: $secondary-color;
                    border-color: $secondary-color;
                }

                .theme4 {
                    background-color: #FFFFFF;
                    color: $secondary-color;
                    border-color: $secondary-color;
                }

                .sync_cnt_cover {
                    // background-color: aqua;
                    justify-content: flex-start;
                    width: 0%;

                    .title_cover {
                        display: none;

                    }



                    .tab_style1 {
                        display: none;

                    }

                    .tab_style2 {
                        display: none;

                    }

                    .tab_style3 {
                        display: none;

                    }

                }

            }

            .button-label {

                display: none;
            }

            .get-button {
                font-size: 2.3vmin;
                display: flex;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .get-btn {
                width: 41% !important;
            }
        }

        .today_sync_monitering {
            min-height: 6vh;
            display: flex;
            justify-content: flex-end;
            flex-direction: row;
            align-items: center;
            background-color: #fff;

            .title_cover1 {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 15% !important;
                height: 100%;
                font-size: 2.2vmin;
                color: #000;
                margin-right: 1%;
            }

            .tab_style11 {
                font-size: 2vmin;
                border-radius: 25px;
                width: 15%;
                height: 90% !important;
                justify-content: center;
                align-items: center;
                display: flex;
            }

            .tab_style22 {
                font-size: 2vmin;
                border-radius: 25px;
                width: 20%;
                height: 50%;
                justify-content: center;
                align-items: center;
                display: flex;
            }

            .tab_style33 {
                font-size: 2vmin;
                border-radius: 25px;
                width: 20%;
                height: 50%;
                justify-content: center;
                align-items: center;
                display: flex;
            }
        }

        .z-gst-table-container {
            max-width: 100%;
            overflow-x: auto;
            white-space: nowrap;

            .ant-table-column-title {
                font-size: 1.6vmin;
            }

            .ant-table-thead>tr>th {
                font-size: 1.6vmin;

            }

            .ant-pagination-total-text {
                font-size: 2.7vmin;
            }

            .ant-pagination-item-active {
                font-size: 2.8vmin;

            }

            // .ant-pagination-total-text {
            //     position: absolute;
            //     left: 0;
            // }
            // .ant-pagination-options {
            //     position: absolute;
            //     right: 0;
            // }
        }

        .view-tab-cover-container {
            min-height: 5.5vh !important;

            .view-tab-cover {
                height: 4vh;
                min-width: 60vw;

                .view-tab {

                    font-size: 2vmin !important;
                }
            }
        }
    }

    .action-popup {
        width: 60% !important;

        #popup-submit-btn {
            height: 7vh !important;
            font-size: 3vmin !important;
            margin-left: 10%;
        }
    }

    .sum-drawer {
        display: none;
    }

    .summary-view-popup {

        width: 90% !important;
        padding-bottom: 0% !important;

        .ant-modal-title {
            font-size: 5vmin !important;
            text-align: center;
        }

        .ant-modal-content {
            padding-left: 0%;
            padding-right: 2%;
            padding-bottom: 0.1%;
        }

        .ant-pagination .ant-pagination-item-active a {
            font-size: 3vmin !important;
        }

        .sum-popup {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 100%;
            background-color: #ECEDF1;
            margin-top: 2%;
            padding: 0.2%;
            padding-left: 1%;
            padding-right: 1%;

            .drawer-header {
                display: flex;
                flex-direction: column;
                height: 10vh;
                border-bottom: 1px solid $primary-color;
                padding-bottom: 1%;
                padding-right: 0%;
                padding-left: 0%;


                .drawer-first-line {
                    height: 50%;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .drawer-close-btn {
                        color: #000;
                        height: 53%;
                        cursor: pointer;
                        position: initial;
                    }

                    .summary-heading {
                        margin: auto;
                        color: $font-primay-color ;
                        font-family: 'Inter';
                    }
                }

                .drawer-second-line {
                    height: 50%;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .drawer_global_search {
                        border-radius: 20px;
                        width: 35%;
                        height: 32px !important;
                        background: #fff;
                        margin-right: 3%;

                        input {
                            background: #fff;
                            font-family: Inter;
                        }

                        &:hover {
                            border: 1px solid $font-primay-color;
                        }
                    }

                    .drawer-filter {
                        cursor: pointer;
                        color: $font-primay-color;
                    }

                    .notice-cunt {
                        margin-left: auto;
                        color: #000;
                        font-family: 'Inter';

                        span {
                            color: $primary-color;
                            font-weight: bold;
                        }
                    }
                }
            }

            .drawer-content {
                display: flex;
                flex-direction: column;
                height: 70vh;
                padding-top: 2.5%;
                overflow: auto;
                padding-bottom: -2.5% !important;
                padding-right: 4%;
                padding-left: 4%;

                .drawer-card {
                    border-radius: 18px;
                    background-color: #fff;
                    height: 23vh;
                    width: 100%;
                    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
                    margin-bottom: 2.5%;

                    .card-first-split {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-evenly;
                        height: 35%;
                        border-bottom: 1.5px solid rgba(0, 0, 0, 0.3);

                        .content-group {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            width: max-content;
                            height: 100%;

                            .main-line {
                                font-size: 1.8vmin !important;
                                line-height: 3.5vmin !important;
                                font-size: bold;
                                color: #424B70;
                                font-family: 'Inter';
                                margin: 0;
                            }

                            .sub-line {
                                font-size: 1.8vmin !important;
                                line-height: 1.8vmin;
                                font-family: 'Inter';
                                margin: 0;
                            }
                        }

                        .view-icon {
                            color: $secondary-color;
                            cursor: pointer;
                        }
                    }

                    .card-second-split {
                        display: flex;
                        flex-direction: row;
                        height: 65%;
                        justify-content: space-evenly;
                        padding-bottom: 10%;

                        .sub-grid {
                            width: 46%;
                            height: 90%;

                            .header-line {
                                color: rgba(35, 35, 35, 0.7);
                                font-size: 1.8vmin;
                                line-height: 3.2vmin;
                                font-family: 'Inter';
                                margin: 0;
                            }

                            .desc-cover {
                                border-radius: 6px;
                                height: 70%;
                                box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                padding: 1%;

                                .desc-sub-line {
                                    font-size: 1.8vmin;
                                    line-height: 2.6vmin;
                                    font-family: 'Inter';
                                    color: #323232;
                                    flex-wrap: nowrap;
                                    margin: 0;
                                }
                            }
                        }
                    }
                }
            }

            .drawer-footer {
                display: flex;
                flex-direction: column;
                height: 7vh;
                border-top: 1px solid $primary-color;
                margin-top: 0px;
                padding: 3%;
            }
        }

    }
}

@media only screen and (min-width:769px) and (max-width:1025px) {
    .ant-table-wrapper .ant-table-tbody>tr>td {
        font-size: 1.8vmin !important;
    }

    .z-gst-head {
        font-size: 2.5vmin !important;
    }

    .z-table_global_search {
        width: 35% !important;
    }

    .z-table {



        .z-table-container {


            .z-left-container {


                .mob-search-icon {
                    display: none;
                }

                .z-gst-head {

                    font-size: 2.2vmin !important;

                }

                .z-table_global_search {


                    input {}
                }

                .view-notice-header-btn-wrap {
                    height: 100%;
                    width: 100%;

                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    // justify-content: space-evenly;

                    #z-common-gst-solution-button {
                        font-size: 2.2vmin;
                        font-family: Inter;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        gap: 5px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 3% 2%;
                        min-width: 20%;
                        margin-right: 2%;
                    }


                    .theme2 {
                        background-color: $primary-color;
                        color: #FFFFFF;
                        border-color: none;
                    }

                    .theme1 {
                        background-color: #FFFFFF;
                        color: $primary-color;
                        border-color: $primary-color;
                    }
                }
            }

            .z-left-container-drawer {
                gap: 2%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                height: 100%;
                width: 60%;
                margin-left: 2%;

                .mob-search-icon {
                    display: none;
                }

                .z-gst-head {
                    color: var(--text-black, #393938);
                    font-family: Inter;
                    font-size: 2.8vmin;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                .z-table_global_search {
                    border-radius: 20px;
                    width: 35%;
                    height: 32px !important;
                    background: rgb(243, 243, 249);

                    input {
                        background: rgb(243, 243, 249);
                        font-family: Inter;
                    }
                }


                .view-notice-header-btn-wrap {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    #z-common-gst-solution-button {
                        font-size: 2.2vmin;
                        font-family: Inter;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        gap: 5px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 3% 2%;
                        min-width: 20%;
                        margin-right: 2%;
                    }


                    .theme2 {
                        background-color: $primary-color;
                        color: #FFFFFF;
                        border-color: none;
                    }

                    .theme1 {
                        background-color: #FFFFFF;
                        color: $primary-color;
                        border-color: $primary-color;
                    }
                }
            }

            .z-left-mobile-container {
                display: none;
                gap: 2%;
                justify-content: flex-start;
                align-items: center;
                height: 100%;
                width: 50%;
                margin-left: 2%;


                .z-gst-head {
                    color: var(--text-black, #393938);
                    font-family: Inter;
                    font-size: 3.5vmin;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                .z-table_global_search {
                    border-radius: 20px;
                    width: 58%;
                    height: 40px !important;
                    background: rgb(243, 243, 249);

                    input {
                        background: rgb(243, 243, 249);
                        font-family: Inter;
                    }
                }
            }

            .z-right-container {


                #z-common-gst-solution-button {}


                .theme1 {}

                .theme2 {}

                .theme3 {}

                .theme4 {}

                .sync_cnt_cover {


                    .title_cover {

                        font-size: 2vmin;

                    }

                    // .all_but_container{
                    //     display: none;

                    // }
                    .tab_style1 {
                        font-size: 1.6vmin;
                        width: 20%;
                        height: 40%;

                    }

                    .tab_style2 {
                        font-size: 1.6vmin;
                        width: 25%;
                        height: 40%;

                    }

                    .tab_style3 {
                        font-size: 1.6vmin;
                        width: 25%;
                        height: 40%;

                    }

                }

            }


        }

        .today_sync_monitering {
            display: none;

        }

        .view-tab-cover-container {
            min-height: 6.5vh;
            display: flex;
            justify-content: end;
            flex-direction: row;
            border-bottom: none;
            border-top: none;
            padding-left: 3%;
            padding-right: 1%;
            background-color: #fff;

            .view-tab-cover {
                height: 4vh;
                min-width: 40vw;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-evenly;
                border: 1px solid $primary-color;
                border-radius: 6px;

                .view-tab {
                    height: 100%;
                    font-family: Inter;
                    color: $primary-color;
                    cursor: pointer;
                    font-size: 1.3vmin;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                }

                .one {
                    width: 40%;
                    border-right: 1px solid $primary-color;
                    border-top-left-radius: 5px;
                    font-size: 1.5vmin;
                    border-bottom-left-radius: 5px;
                }

                .two {
                    width: 42%;
                    border-right: 1px solid $primary-color;
                    // border-right: 0px solid black;
                }

                .three {
                    width: 23%;
                    border-left: 0px solid black;
                    border: 1px solid $primary-color;
                }

                .four {
                    width: 30%;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                }

                .active-tab {
                    background-color: $primary-color;
                    color: #FFFFFF;
                }
            }
        }

        .z-gst-table-container {
            width: 100%;
            overflow-x: auto;
            margin-left: 0;
            margin-top: -0.2%;
        }


    }

    .action-popup {
        width: 50% !important;

        #popup-submit-btn {
            margin-left: 10%;
        }
    }

    .summary-view-popup {
        display: none;
    }
}

@media only screen and (min-width:1026px) and (max-width:1440px) {
    .ant-table-wrapper .ant-table-tbody>tr>td {
        font-size: 1.8vmin !important;
    }

    .z-gst-head {
        font-size: 3vmin !important;
    }

    .z-table_global_search {
        width: 43% !important;
    }

    .z-table {



        .z-table-container {


            .z-left-container {


                .mob-search-icon {
                    display: none;
                }

                .z-gst-head {

                    font-size: 2.2vmin !important;

                }

                .z-table_global_search {


                    input {}
                }

                .view-notice-header-btn-wrap {
                    height: 100%;
                    width: 100%;

                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    // justify-content: space-evenly;

                    #z-common-gst-solution-button {
                        font-size: 2.2vmin;
                        font-family: Inter;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        gap: 5px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 3% 2%;
                        min-width: 20%;
                        margin-right: 2%;
                    }


                    .theme2 {
                        background-color: $primary-color;
                        color: #FFFFFF;
                        border-color: none;
                    }

                    .theme1 {
                        background-color: #FFFFFF;
                        color: $primary-color;
                        border-color: $primary-color;
                    }
                }
            }

            .z-left-container-drawer {
                gap: 2%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                height: 100%;
                width: 60%;
                margin-left: 2%;

                .mob-search-icon {
                    display: none;
                }

                .z-gst-head {
                    color: var(--text-black, #393938);
                    font-family: Inter;
                    font-size: 2.8vmin;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                .z-table_global_search {
                    border-radius: 20px;
                    width: 35%;
                    height: 32px !important;
                    background: rgb(243, 243, 249);

                    input {
                        background: rgb(243, 243, 249);
                        font-family: Inter;
                    }
                }


                .view-notice-header-btn-wrap {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    #z-common-gst-solution-button {
                        font-size: 2.2vmin;
                        font-family: Inter;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        gap: 5px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 3% 2%;
                        min-width: 20%;
                        margin-right: 2%;
                    }


                    .theme2 {
                        background-color: $primary-color;
                        color: #FFFFFF;
                        border-color: none;
                    }

                    .theme1 {
                        background-color: #FFFFFF;
                        color: $primary-color;
                        border-color: $primary-color;
                    }
                }
            }

            .z-left-mobile-container {
                display: none;
                gap: 2%;
                justify-content: flex-start;
                align-items: center;
                height: 100%;
                width: 50%;
                margin-left: 2%;


                .z-gst-head {
                    color: var(--text-black, #393938);
                    font-family: Inter;
                    font-size: 3.5vmin;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                .z-table_global_search {
                    border-radius: 20px;
                    width: 58%;
                    height: 40px !important;
                    background: rgb(243, 243, 249);

                    input {
                        background: rgb(243, 243, 249);
                        font-family: Inter;
                    }
                }
            }

            .z-right-container {


                #z-common-gst-solution-button {}


                .theme1 {}

                .theme2 {}

                .theme3 {}

                .theme4 {}

                .sync_cnt_cover {


                    .title_cover {

                        font-size: 2vmin;

                    }

                    // .all_but_container{
                    //     display: none;

                    // }
                    .tab_style1 {
                        font-size: 1.6vmin;
                        width: 20%;
                        height: 40%;

                    }

                    .tab_style2 {
                        font-size: 1.6vmin;
                        width: 25%;
                        height: 40%;

                    }

                    .tab_style3 {
                        font-size: 1.6vmin;
                        width: 25%;
                        height: 40%;

                    }

                }

            }


        }

        .today_sync_monitering {
            display: none;

        }

        .view-tab-cover-container {
            min-height: 6.5vh;
            display: flex;
            justify-content: end;
            flex-direction: row;
            border-bottom: none;
            border-top: none;
            padding-left: 3%;
            padding-right: 1%;
            background-color: #fff;

            .view-tab-cover {
                height: 4vh;
                min-width: 35vw;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-evenly;
                border: 1px solid $primary-color;
                border-radius: 6px;

                .view-tab {
                    height: 100%;
                    font-family: Inter;
                    color: $primary-color;
                    cursor: pointer;
                    font-size: 1.5vmin;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                }

                .one {
                    width: 40%;
                    border-right: 1px solid $primary-color;
                    border-top-left-radius: 5px;
                    font-size: 1.5vmin;
                    border-bottom-left-radius: 5px;
                }

                .two {
                    width: 42%;
                    border-right: 1px solid $primary-color;
                    // border-right: 0px solid black;
                }

                .three {
                    width: 23%;
                    border-left: 0px solid black;
                    border: 1px solid $primary-color;
                }

                .four {
                    width: 30%;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                }

                .active-tab {
                    background-color: $primary-color;
                    color: #FFFFFF;
                }
            }
        }

        .z-gst-table-container {
            width: 100%;
            overflow-x: auto;
            margin-left: 0;
            margin-top: -0.2%;
        }


    }

    #popup-submit-btn {
        margin-left: 10%;
    }

    .summary-view-popup {
        display: none;
    }
}

@media only screen and (min-width:1441px) {
    .ant-table-wrapper .ant-table-tbody>tr>td {
        font-size: 1.8vmin;
    }

    .z-table .z-table-container .z-left-container .z-table_global_search {
        height: 41px !important;
        width: 40%;
        font-size: 2vmin;
    }

    .z-table {



        .z-table-container {


            .z-left-container {


                .mob-search-icon {
                    display: none;
                }

                .z-gst-head {

                    font-size: 2.2vmin !important;

                }

                .z-table_global_search {


                    input {}
                }

                .view-notice-header-btn-wrap {
                    height: 100%;
                    width: 100%;

                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    // justify-content: space-evenly;

                    #z-common-gst-solution-button {
                        font-size: 2.2vmin;
                        font-family: Inter;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        gap: 5px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 3% 2%;
                        min-width: 20%;
                        margin-right: 2%;
                    }


                    .theme2 {
                        background-color: $primary-color;
                        color: #FFFFFF;
                        border-color: none;
                    }

                    .theme1 {
                        background-color: #FFFFFF;
                        color: $primary-color;
                        border-color: $primary-color;
                    }
                }
            }

            .z-left-container-drawer {
                gap: 2%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                height: 100%;
                width: 60%;
                margin-left: 2%;

                .mob-search-icon {
                    display: none;
                }

                .z-gst-head {
                    color: var(--text-black, #393938);
                    font-family: Inter;
                    font-size: 2.8vmin;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                .z-table_global_search {
                    border-radius: 20px;
                    width: 35%;
                    height: 32px !important;
                    background: rgb(243, 243, 249);

                    input {
                        background: rgb(243, 243, 249);
                        font-family: Inter;
                    }
                }


                .view-notice-header-btn-wrap {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    #z-common-gst-solution-button {
                        font-size: 2.2vmin;
                        font-family: Inter;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        gap: 5px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 3% 2%;
                        min-width: 20%;
                        margin-right: 2%;
                    }


                    .theme2 {
                        background-color: $primary-color;
                        color: #FFFFFF;
                        border-color: none;
                    }

                    .theme1 {
                        background-color: #FFFFFF;
                        color: $primary-color;
                        border-color: $primary-color;
                    }
                }
            }

            .z-left-mobile-container {
                display: none;
                gap: 2%;
                justify-content: flex-start;
                align-items: center;
                height: 100%;
                width: 50%;
                margin-left: 2%;


                .z-gst-head {
                    color: var(--text-black, #393938);
                    font-family: Inter;
                    font-size: 3.5vmin;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                .z-table_global_search {
                    border-radius: 20px;
                    width: 58%;
                    height: 40px !important;
                    background: rgb(243, 243, 249);

                    input {
                        background: rgb(243, 243, 249);
                        font-family: Inter;
                    }
                }
            }

            .z-right-container {


                #z-common-gst-solution-button {}


                .theme1 {}

                .theme2 {}

                .theme3 {}

                .theme4 {}

                .sync_cnt_cover {


                    .title_cover {

                        font-size: 2vmin;

                    }

                    // .all_but_container{
                    //     display: none;

                    // }
                    .tab_style1 {
                        font-size: 1.6vmin;
                        width: 20%;
                        height: 40%;

                    }

                    .tab_style2 {
                        font-size: 1.6vmin;
                        width: 25%;
                        height: 40%;

                    }

                    .tab_style3 {
                        font-size: 1.6vmin;
                        width: 25%;
                        height: 40%;

                    }

                }

            }


        }

        .today_sync_monitering {
            display: none;

        }

        .view-tab-cover-container {
            min-height: 6.5vh;
            display: flex;
            justify-content: end;
            flex-direction: row;
            border-bottom: none;
            border-top: none;
            padding-left: 3%;
            padding-right: 1%;
            background-color: #fff;

            .view-tab-cover {
                height: 4vh;
                min-width: 26vw;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-evenly;
                border: 1px solid $primary-color;
                border-radius: 6px;

                .view-tab {
                    height: 100%;
                    font-family: Inter;
                    color: $primary-color;
                    cursor: pointer;
                    font-size: 1.5vmin;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                }

                .one {
                    width: 40%;
                    border-right: 1px solid $primary-color;
                    border-top-left-radius: 5px;
                    font-size: 1.5vmin;
                    border-bottom-left-radius: 5px;
                }

                .two {
                    width: 42%;
                    border-right: 1px solid $primary-color;
                    // border-right: 0px solid black;
                }

                .three {
                    width: 23%;
                    border-left: 0px solid black;
                    border: 1px solid $primary-color;
                }

                .four {
                    width: 30%;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                }

                .active-tab {
                    background-color: $primary-color;
                    color: #FFFFFF;
                }
            }
        }

        .z-gst-table-container {
            width: 100%;
            overflow-x: auto;
            margin-left: 0;
            margin-top: -0.2%;
        }


    }

    .form_item {
        font-size: 3.5vmin !important;

        .ant-input {
            font-size: 2vmin !important;
        }

        .ant-form-item-label>label {
            font-size: 2vmin !important;
        }

        .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
            font-size: 2vmin !important;
        }

        :where(.css-dev-only-do-not-override-18iikkb).ant-select-dropdown .ant-select-item-option-content {
            font-size: 2vmin !important;
        }

        .attach_file_line {
            font-size: 2vmin !important;
        }

        .attach_file_img {
            margin-right: 5px !important;
            height: 2.4vmin !important;
        }
    }

    .summary-view-popup {
        display: none;
    }
}