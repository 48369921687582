$primary-color: #424B70;
$secondary-color: #E96919;
$font-primay-color: #05103E;
$font-secondary-color: #878787;

#latst_dot {
  .MuiBadge-badge {
    background-color: $primary-color;
    margin-top: -1%;
  }
}

.site-link-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.2% 5%;
  border-radius: 126.485px;
  font-size: 2vmin;
  color: #fff;
  background: $primary-color;
  cursor: pointer;
  text-decoration: none;
  letter-spacing: 0.4px;
}

.terminal-window-mainline {
  font-family: Inter;
  font-size: 3.5vmin;
  color: $font-primay-color;
  font-style: normal;
  font-weight: 600;
  line-height: 168.023%;
  text-align: center;
  padding-top: 3%;
  margin-bottom: 0;
}


.terminal-window-subline {
  font-family: Inter;
  font-size: 2vmin;
  color: $font-secondary-color;
  font-style: normal;
  font-weight: 400;
  line-height: 168.023%;
  text-align: center;
  margin: 0 10% 0 10%;
  letter-spacing: 0.04px;
}

.input-label {
  color: #000;
  font-family: Inter;
  font-size: 2vmin;
  font-weight: 400;
  line-height: 19.36px;
  margin-bottom: 1%;
}

.input-box {
  height: 3rem;
  border-radius: 4px;
  border: 0.5px solid #9a9999;
  margin-top: 0.4rem;
}

.input-icon {
  position: relative;
  top: 17%;
  left: 2%;
}

.inputfield {
  color: #4F4F4F !important;
  font-weight: 400;
  font-size: 2vmin !important;
  font-family: Inter;
  padding-left: 0 !important;
}


::placeholder {
  color: $font-secondary-color;
}


.input-end-icon {
  position: absolute;
  color: #77787A;
  right: 8%;
  cursor: pointer;
  margin-top: 2%;
}

.ant-modal-title {
  color: #031742 !important;
  font-family: Inter;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 3.5vmin !important;
}


.input-error-line {
  color: #E10000;
  font-family: Inter;
  font-size: 1.8vmin;
  font-style: normal;
  font-weight: 550;
  line-height: 2.7vmin;
  letter-spacing: 0.035px;
  padding-top: 1%;
  float: right !important;
}

.teminal-primary-btn {
  display: flex;
  height: 7vmin !important;
  width: 85%;
  padding: 13px 0px;
  justify-content: center;
  align-items: center;
  margin: auto;
  cursor: pointer;
  border-radius: 8px;
  background: $primary-color;
  border-style: none;
  color: #FFF;
  font-family: Inter;
  font-size: 2.3vmin;
  font-style: normal;
  font-weight: 400;
}

/* Neutral state (inactive sorter) */
.ant-table-column-sorter-up,
.ant-table-column-sorter-down {
  color: #999;
  /* Neutral color */
}

/* Highlighted up arrow for ascending */
.highlight-sorter-ascend .ant-table-column-sorter-up {
  color: #E96919 !important;
  /* Highlight color for ascending */
}

/* Highlighted down arrow for descending */
.highlight-sorter-descend .ant-table-column-sorter-down {
  color: #E96919 !important;
  /* Highlight color for descending */
}


.teminal-secondary-btn {
  width: 55% !important;
  background: $secondary-color;
  text-decoration: none;
}

.terminal-link {
  color: #000000;
  font-family: Inter;
  font-size: 2.1vmin;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  text-decoration-line: underline;
  padding-top: 3%
}

.filter-primary-btn {
  background-color: $primary-color;
  color: #fff;
}

.filter-secondary-btn {
  color: $primary-color !important;
}

.ant-btn.ant-btn-sm {
  color: $primary-color;
}


.ant-btn-link:hover {
  color: $primary-color !important;
}

.ant-btn-primary {
  background-color: $primary-color !important;
  color: #fff !important;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-selected {
  background-color: transparent;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover {
  background-color: #FDF2EA !important;
}

::-webkit-scrollbar {
  width: 7px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primary-color;
  border-radius: 15px;
}



@media only screen and (min-width:320px) and (max-width:600px) {

  .terminal-window-mainline {
    padding-top: 0%;
    font-size: 4.5vmin;
  }

  .terminal-window-subline {
    font-size: 3.5vmin;
  }

  .input-label {
    font-size: 3.4vmin !important;
  }

  .teminal-primary-btn {
    font-size: 3.3vmin;
    font-weight: bold;
    height: 8vmin !important;
  }

  .terminal-link {
    font-size: 3.5vmin;
    padding-top: 0;
  }

  .inputfield {
    font-size: 3.5vmin !important;
  }

  #error-line {
    font-size: 2.8vmin !important;
  }
}

@media only screen and (min-width:500px) and (max-width:600px) {
  .terminal-window-mainline {
    padding-top: 0;
  }

  .terminal-window-subline {
    font-size: 2.5vmin;
  }

  .site-link-btn {
    font-size: 2.5vmin !important;
  }

  .input-label {
    font-size: 2.8vmin;
  }

  .inputfield {
    font-size: 3vmin !important;
  }

  .terminal-link {
    font-size: 2.5vmin;
  }

  #error-line {
    font-size: 2.5vmin !important;
  }
}

@media only screen and (min-width:601px)and (max-width:768px) {
  .terminal-window-mainline {
    font-size: 3vmin;
  }

  .ant-modal-title {
    font-size: 3.4vmin !important;
  }


  .terminal-window-subline {
    font-size: 1.8vmin;
    line-height: 2.3vmin;
  }

  .teminal-primary-btn {
    height: 6vmin !important;
    font-size: 2vmin;
  }

  .teminal-secondary-btn {
    width: 60% !important;
  }

  .terminal-link {
    font-size: 1.7vmin;
  }
}

@media only screen and (min-width:769px) and (max-width:1024px) {
  .teminal-primary-btn {
    font-size: 2.1vmin;
  }
}