@import '../../App.scss';

.users-approval-container {
    height: 100%;
    width: 100%;
}

#Approv_ph_partner_container {
    .MuiPaper-root {
        width: 70% !important;
        max-width: 100% !important;
        // max-height: none !important;
    }

    .MuiTypography-root {
        display: flex;
        justify-content: center;
        color: #000;
        font-family: Inter !important;
        font-size: 2.7vmin !important;
        font-style: normal;
        font-weight: 500 !important;
        padding-bottom: 1%;
        line-height: 168.023% !important;
        /* 50.407px */
    }

    .MuiDialogContent-root {
        border-top: 0 !important;
        padding-top: 8px;
        display: flex;
        flex-direction: column !important;
        align-items: center;

        // .dialog-content{
        .approve-admin-cover {
            display: flex;
            flex-direction: column;
            overflow: auto;


            .approve-form {
                display: flex;
                flex-direction: row;
                border-bottom: 1px solid black;
                width: 100%;
                height: 84vh;


                .ph-content-split {
                    width: 50%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .fields {
                        margin: 0 2% 0 2%;
                        padding-bottom: 2%;
                        width: 100%;

                        .pwd {
                            color: #000;
                            font-family: Inter;
                            font-size: 2.7vmin;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 168.023%;
                            /* 40.325px */
                            margin-bottom: 2%
                        }

                        .row {
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: center;

                            .input-group {
                                margin-top: 2rem;
                                width: 95%;
                                // padding-bottom: 1rem;


                                .input-box {
                                    height: 3rem;
                                    border-radius: 4px;
                                    border: 0.5px solid rgba(0, 0, 0, 0.70);

                                    img {
                                        position: relative;
                                        height: 32%;
                                        width: 4%;
                                        top: 12%;
                                        left: 2%;
                                    }
                                }

                                span {
                                    float: right;
                                    font-size: 1.7vmin;
                                    color: rgb(225, 0, 0);
                                }

                                .register {
                                    display: flex;
                                    width: 45%;
                                    padding: 13px 0px;
                                    justify-content: center;
                                    align-items: center;
                                    margin: auto;
                                    border-radius: 8px;
                                    background: #424B70;
                                    border-style: none;
                                    cursor: pointer;
                                    color: #FFF;
                                    font-family: Inter;
                                    font-size: 2.2vmin;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 168.023%;
                                    /* 33.605px */
                                    text-transform: uppercase;
                                }

                                .ph-partner-register {
                                    display: flex;
                                    width: 45%;
                                    padding: 13px 0px;
                                    justify-content: center;
                                    align-items: center;
                                    margin: auto;
                                    border-radius: 8px;
                                    background: $secondary-color;
                                    border-style: none;
                                    cursor: pointer;
                                    color: #FFF;
                                    font-family: Inter;
                                    font-size: 1.8vmin;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 168.023%;
                                    /* 33.605px */
                                    text-transform: uppercase;
                                }

                                .register_ph {
                                    display: flex;
                                    width: 70%;
                                    padding: 7px 0px;
                                    justify-content: center;
                                    align-items: center;
                                    margin: auto;
                                    border-radius: 8px;
                                    background: $primary-color;
                                    border-style: none;
                                    cursor: pointer;
                                    color: #FFF;
                                    font-family: Inter;
                                    font-size: 2.2vmin;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 168.023%;
                                    /* 33.605px */
                                    text-transform: uppercase;
                                }

                                label {
                                    color: #000;
                                    font-family: Inter;
                                    font-size: 1.6vmin;
                                    font-weight: 400;
                                }

                                .title {
                                    color: #000;
                                    font-family: Inter;
                                    font-size: 1.7vmin;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 160.523%;
                                    /* 22.473px */
                                    letter-spacing: 0.035px;
                                    width: 90%;
                                    display: flex;
                                    align-items: center;

                                    .privacy-innerline {
                                        color: #0193E5;
                                        text-decoration-line: underline;
                                        cursor: pointer;
                                        // margin-left: 0.4%;
                                    }

                                    .info-icon-wrap {
                                        margin-left: 5%;
                                        display: flex;
                                        align-items: center;

                                        .info-icon {
                                            color: $primary-color;
                                            cursor: pointer;
                                        }
                                    }
                                }


                            }

                            .checkbox-over-wrap {
                                height: 6vh;
                                width: 100%;
                                display: flex;
                                flex-direction: row;
                                justify-content: space-evenly;

                                .checkbox-cover {
                                    height: 100%;
                                    width: max-content !important;
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                    justify-content: center;

                                    .check_box {
                                        height: 10px;
                                        width: 10px;
                                        margin-right: 2%;
                                        cursor: pointer;
                                        background-color: #D9D9D9;
                                        border-radius: 50%;
                                    }

                                    .checked_box {
                                        height: 10px;
                                        width: 10px;
                                        margin-right: 2%;
                                        display: inline-block;
                                        background-color: #E96919;
                                        border-radius: 50%;
                                        cursor: pointer;
                                    }

                                    .label {
                                        color: #000;
                                        font-family: Inter;
                                        font-size: 1.6vmin;
                                        font-weight: bold;
                                        margin-left: 5px;
                                        text-wrap: nowrap;
                                    }
                                }
                            }
                        }

                        .pin_input-group {
                            margin-top: 2rem;
                            display: flex;
                            flex-direction: row;

                            justify-content: space-between;
                            width: 90%;
                            // padding-bottom: 1rem;

                            .input-wrap {
                                width: 90%;

                                label {
                                    color: #000;
                                    font-family: Inter;
                                    font-size: 1.6vmin;
                                    font-weight: 400;
                                }

                                span {
                                    float: left;
                                }

                                .input-box {
                                    height: 2.6rem;
                                    border-radius: 4px;
                                    border: 0.5px solid rgba(0, 0, 0, 0.70);
                                    margin-top: 0.4rem;
                                    width: 100%;

                                    img {
                                        position: relative;
                                        top: 17%;
                                        left: 2%;
                                    }

                                    .eye_icon {
                                        position: absolute;
                                        color: #77787A;
                                        right: 12%;
                                        margin-top: 2%;
                                        cursor: pointer;
                                    }

                                    .err-msg {
                                        display: flex;
                                        flex-direction: column;
                                        margin-top: 1%;

                                        span {
                                            float: left !important;
                                        }
                                    }


                                    span {
                                        color: #E10000;
                                        font-family: Inter;
                                        font-size: 1.5vmin;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 142%;
                                        /* 19.88px */
                                        letter-spacing: 0.035px;
                                        padding-top: 1%;
                                        float: right !important;
                                    }
                                }
                            }

                            .pincode_btn {
                                display: flex;
                                width: 9%;
                                padding: 8px 0px;
                                justify-content: center;
                                align-items: center;
                                border-radius: 50%;
                                background: $font-primay-color;
                                border-style: none;
                                cursor: pointer;
                                font-family: Inter;
                                font-size: 2vmin;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 2vmin;
                                /* 33.605px */
                                text-transform: uppercase;


                                .pincode_search {
                                    color: #fff;
                                }
                            }
                        }

                        .Nearest-office-cover {
                            margin-top: 4%;
                            width: 95%;
                            height: 10vh;

                            .Nearest-office-heading {
                                font-size: 1.9vmin;
                            }

                            ol {
                                margin-top: 0% !important;
                                padding-left: 5% !important;

                                li {
                                    margin-top: 1%;
                                }
                            }
                        }

                        .g-location-cover-wrap {
                            margin-top: 4%;
                            width: 95%;
                            height: 10vh;
                            display: flex;
                            flex-direction: column;

                            .g-location-field {
                                display: flex;
                                flex-direction: row;
                                align-items: center;

                                .location-link {
                                    cursor: pointer;
                                    text-decoration: underline;
                                    color: #20438A;
                                    margin-left: 2%;
                                }
                            }

                            .practice-area-field {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                margin-top: 4%;
                            }
                        }
                    }
                }
            }

            .admin-fields {
                height: 40vh;
                width: 97%;
                margin-top: 1%;

                .admin-field-heading {
                    font-size: 2.4vmin;
                    font-weight: bold;
                }

                .checkbox-cover {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    height: 40%;
                    margin-top: 2%;
                    margin-bottom: 2%;

                    .control-check-cover {
                        width: 68%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;

                        .check-label {
                            font-size: 2vmin;
                            font-weight: bold;
                        }

                        .checkbox-over-wrap {
                            height: 6vh;
                            width: 100%;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-evenly;
                            flex-wrap: wrap;

                            .checkbox_cover {
                                height: 100%;
                                cursor: pointer;
                                width: max-content !important;
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                justify-content: center;

                                .check_box {
                                    height: 10px;
                                    min-width: 10px;
                                    margin-right: 2%;
                                    cursor: pointer;
                                    background-color: #D9D9D9;
                                    border-radius: 50%;
                                }

                                .checked_box {
                                    height: 10px;
                                    min-width: 10px;
                                    margin-right: 2%;
                                    display: inline-block;
                                    background-color: #E96919;
                                    border-radius: 50%;
                                    cursor: pointer;
                                }

                                .label {
                                    color: #000;
                                    font-family: Inter;
                                    font-size: 2.1vmin;
                                    font-weight: normal;
                                    margin-left: 5px;
                                    text-wrap: nowrap;
                                }
                            }
                        }
                    }

                    .relationship-check-cover {
                        width: 32%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;

                        .check-label {
                            font-size: 2vmin;
                            font-weight: bold;
                        }

                        .checkbox-over-wrap {
                            height: 6vh;
                            width: 100%;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-evenly;

                            .checkbox_cover {
                                height: 100%;
                                cursor: pointer;
                                min-width: max-content !important;
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                justify-content: center;

                                .check_box {
                                    height: 10px;
                                    width: 10px;
                                    margin-right: 2%;
                                    cursor: pointer;
                                    background-color: #D9D9D9;
                                    border-radius: 50%;
                                }

                                .checked_box {
                                    height: 10px;
                                    min-width: 10px;
                                    margin-right: 2%;
                                    display: inline-block;
                                    background-color: #E96919;
                                    border-radius: 50%;
                                    cursor: pointer;
                                }

                                .label {
                                    color: #000;
                                    font-family: Inter;
                                    font-size: 2.1vmin;
                                    font-weight: normal;
                                    margin-left: 5px;
                                    text-wrap: nowrap;
                                }
                            }
                        }
                    }
                }

                .remarks-cover-wrap {
                    width: 100%;

                    .remarks-field {
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        .remarks-label {
                            font-weight: bold;
                            width: 14%;
                        }

                        .input-box {
                            width: 86%;
                            height: 3rem;
                            border-radius: 0px;
                            border: none;
                            border-bottom: 1px solid rgba(0, 0, 0, 0.70);

                            input {
                                margin-left: 1%;
                                width: 100%;
                            }
                        }
                    }
                }


            }



        }

        .approv-submit-wrap {
            width: 100%;
            height: 12vh;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-top: 2%;

            #z-common-gst-solution-button {
                font-size: 2.1vmin;
                font-family: Inter;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                gap: 5px;
                display: flex;
                align-items: center;
                padding: 2% 2%;
                margin-right: 2%;

                &:last-child {
                    margin-right: 0%;
                }
            }


            .theme1 {
                background-color: $primary-color;
                color: #FFFFFF;
                border-color: none;
            }

            .theme2 {
                background-color: $secondary-color;
                color: #FFFFFF;
                border-color: none;
            }

            .theme3 {
                background-color: #FCE7D9;
                color: $secondary-color;
                border-color: $secondary-color;
            }

            .theme4 {
                background-color: #FFFFFF;
                color: $secondary-color;
                border-color: $secondary-color;
            }

        }
    }


}