@import '../../App.scss';

.complaince-overall-container {
    height: 97%;
    width: 100%;
    gap: 2%;

    .complaince-content-container {
        max-width: 100vw !important;
        min-height: 10vh;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #fff;
        border-bottom: none;
        border-top-right-radius: 15px;
        border-top-left-radius: 15px;
        margin: 2%;
        margin-bottom: 0%;
        margin-top: 1%;

        .z-left-container {
            gap: 2%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 100%;
            width: 40%;
            margin-left: 2%;

            .mob-search-icon {
                display: none;
            }

            .z-gst-head {
                color: var(--text-black, #393938);
                font-family: Inter;
                font-size: 3.5vmin;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }

            .z-table_global_search {
                border-radius: 20px;
                width: 40% !important;
                height: 40px !important;
                background: rgb(243, 243, 249);

                input {
                    background: rgb(243, 243, 249);
                    font-family: Inter;
                }
            }


        }

        .z-left-container-drawer {
            gap: 2%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 100%;
            width: 60%;
            margin-left: 2%;

            .mob-search-icon {
                display: none;
            }

            .z-gst-head {
                color: var(--text-black, #393938);
                font-family: Inter;
                font-size: 2.8vmin;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }

            .z-table_global_search {
                border-radius: 20px;
                width: 35%;
                height: 32px !important;
                background: rgb(243, 243, 249);

                input {
                    background: rgb(243, 243, 249);
                    font-family: Inter;
                }
            }

        }

        .z-left-mobile-container {
            display: none;
            gap: 2%;
            justify-content: flex-start;
            align-items: center;
            height: 100%;
            width: 50%;
            margin-left: 2%;


            .z-gst-head {
                color: var(--text-black, #393938);
                font-family: Inter;
                font-size: 3.5vmin;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }

            .z-table_global_search {
                border-radius: 20px;
                width: 58%;
                height: 40px !important;
                background: rgb(243, 243, 249);

                input {
                    background: rgb(243, 243, 249);
                    font-family: Inter;
                }
            }
        }

        .z-right-container {
            width: 60%;
            display: flex;
            justify-content: flex-end;
            margin-right: 3%;
            margin-left: 0.5%;
            gap: 1%;
            align-items: center;
            height: 100%;

            #z-common-gst-solution-button {
                font-size: 2.2vmin;
                font-family: Inter;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                gap: 5px;
                display: flex;
                align-items: center;
                padding: 3% 2%;

            }


            .theme1 {
                background-color: $primary-color;
                color: #FFFFFF;
                border-color: none;
            }

            .theme2 {
                background-color: $secondary-color;
                color: #FFFFFF;
                border-color: none;
            }

            .theme3 {
                background-color: #FCE7D9;
                color: $secondary-color;
                border-color: $secondary-color;
            }

            .theme4 {
                background-color: #FFFFFF;
                color: $secondary-color;
                border-color: $secondary-color;
            }

            .dropdown-btn {
                width: 15%;

                .ant-radio-group {
                    display: flex;
                    flex-direction: column;
                }

                .ant-select-selector {
                    height: 45px;
                    align-items: center;
                }
            }

            .tab_cover {
                width: 30%;
                height: 7.7vh;
                margin-top: 2%;
                margin-right: 2%;
                display: flex;

                .tab {
                    width: 50%;
                    border-top-right-radius: 10px;
                    border-top-left-radius: 10px;
                    color: $primary-color;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: bold;
                    font-size: 2vmin;
                    cursor: pointer;
                }

                .active {
                    transition: 0.2s background ease-in;
                    background-color: #ECEDF1;
                }
            }
        }


    }

    .compliance-table-header {
        min-height: 7vh;
        max-width: 100vw !important;
        background-color: #ECEDF1;
        margin: 0% 2%;
        display: flex;
        align-items: center;

        span {
            color: var(--text-black, #393938);
            font-family: Inter;
            font-size: 2vmin;
            font-style: normal;
            font-weight: 500 !important;
            line-height: normal;
        }

        .company-column {
            min-width: 24%;
            margin-left: 2%;
        }

        .gst-column {
            min-width: 22%;
        }

        .status-column {
            min-width: 51%;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            text-align: center;

            span {
                min-width: 15%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-evenly;

                select {
                    margin-top: 3%;
                }
            }
        }
    }

    .compliance-data-container {
        max-width: 100vw !important;
        min-height: 29vh;
        max-height: 57vh;
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-bottom: none;
        border: 2px solid #fff;
        border-top: none;
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
        margin: 0% 2%;

        .compliance-table-content {
            width: 98%;
            height: 10vh;
            min-height: 10vh;
            background-color: #fff;
            border-radius: 20px;
            margin-top: 1%;
            display: flex;
            flex-direction: row;
            color: #232323;
            font-size: 1.8vmin;

            .company-table-column {
                height: 100%;
                width: 22%;
                min-width: 20%;
                border-top-left-radius: 20px;
                border-bottom-left-radius: 20px;
                display: flex;
                align-items: center;
                padding-left: 1%;
                padding-right: 1%;

                .comapany-line {}
            }

            .gst-table-column {
                height: 100%;
                min-width: 20%;
                width: 22%;
                display: flex;
                align-items: center;
                padding-left: 1%;
                padding-right: 1%;
            }

            .status-table-column {
                width: 52%;
                min-width: 45%;
                height: 100%;
                border-top-right-radius: 20px;
                border-bottom-right-radius: 20px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .status-first-row {
                    width: 100%;
                    height: 95%;
                    border-radius: 20px;
                    display: flex;
                    flex-direction: row;
                    align-items: end;

                    .row-header {
                        height: 95%;
                        width: 15%;
                        background-color: #E7F1FA;
                        border-top-left-radius: 18px;
                        border-bottom-left-radius: 18px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        span {
                            font-weight: bold;
                        }
                    }

                    .row-card-wrap {
                        width: 100%;
                        height: 95%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-evenly;

                        .row-card {
                            width: 16%;
                            background-color: #D7E0EC;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-evenly;
                            align-items: center;

                            &:last-child {
                                border-top-right-radius: 18px;
                                border-bottom-right-radius: 18px;
                            }

                            .row-card-mainline {
                                color: $font-primay-color;
                                font-size: 2.1vmin;
                            }

                            .row-card-subline {}
                        }

                        .row-card-filled {
                            border-color: green;
                            /* Border color for 'Filled' status */
                        }

                        .row-card-not-filled {
                            border-color: orange;
                            /* Border color for 'Not Filled' status */
                        }

                        .row-card-na {
                            border-color: red;
                            /* Border color for 'NA' status */
                        }

                        .row-card-active {
                            background-color: #F06548 !important;
                        }
                    }
                }

                .status-second-row {
                    width: 100%;
                    height: 48%;
                    border-radius: 20px;
                    display: flex;
                    flex-direction: row;
                    background-color: #232323;

                    .row-header {
                        height: 95%;
                        width: 15%;
                        background-color: #E7F1FA;
                        border-top-left-radius: 18px;
                        border-bottom-left-radius: 18px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        span {
                            font-weight: bold;
                        }
                    }

                    .row-card-wrap {
                        width: 100%;
                        height: 95%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-evenly;

                        .row-card {
                            width: 16%;
                            background-color: #D7E0EC;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-evenly;
                            align-items: center;

                            &:last-child {
                                border-bottom-right-radius: 18px;
                            }

                            .row-card-mainline {
                                color: $font-primay-color;
                                font-size: 2.1vmin;
                            }

                            .row-card-subline {}
                        }

                        .row-card-active {
                            background-color: #F06548 !important;
                        }
                    }
                }
            }

            &:last-child {
                margin-bottom: 1%;
            }
        }

        .nodata-line {
            color: #a59696;
            margin: auto;
            font-weight: lighter;
        }
    }

    .compliance-table-footer {
        max-width: 100vw !important;
        min-height: 10vh;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-bottom: none;
        align-items: center;
        margin: 2%;
        margin-top: 0%;

        ul {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-left: auto;
        }

        .ant-pagination-total-text {
            position: relative !important;
        }

        .ant-pagination-options {
            position: relative !important;
            margin-left: auto !important;
        }

        :where(.css-dev-only-do-not-override-1ae8k9u).ant-pagination .ant-pagination-prev {
            margin-left: auto !important;
        }

        :where(.css-dev-only-do-not-override-1ae8k9u).ant-pagination .ant-pagination-next {}



        .ant-pagination-item-active {
            background: $primary-color;
            border-radius: 2px;
        }

        .ant-pagination .ant-pagination-item-active a {
            color: #fff;
            background-color: $primary-color;
            border-radius: 2px;
        }

        .ant-pagination .ant-pagination-item-active:hover a {
            color: #FFF;
            background: $primary-color;
            border-radius: 2px;
        }

        .ant-pagination .ant-pagination-item-active,
        .ant-pagination .ant-pagination-item-active:hover {
            border-color: $primary-color;
            border: 0px solid;
            border-radius: 2px;
        }


        .ant-pagination .ant-pagination-item-active {
            color: #FFF;
            font-family: Inter;
            font-size: 1.8vmin;
            font-style: normal;
            font-weight: 500;
        }

        .ant-pagination-total-text {
            position: absolute;
            left: 0;
            margin-right: auto;
        }

        .ant-pagination-options {
            position: absolute;
            right: 0;
        }

    }

}

@media only screen and (min-width:320px) and (max-width:600px) {
    .complaince-overall-container {


        .complaince-content-container {



            .z-left-container {
                width: 60%;

                .mob-search-icon {
                    display: none;
                }

                .z-gst-head {
                    font-size: 2.5vmin;

                }

                .z-table_global_search {
                    border-radius: 20px;
                    width: 60% !important;
                    height: 30px !important;
                    justify-content: center;
                    display: flex;
                    align-items: center;

                    input {
                        font-size: 1.8vmin;
                    }
                }


            }

            .z-left-container-drawer {
                gap: 2%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                height: 100%;
                width: 50%;
                margin-left: 2%;

                .mob-search-icon {
                    display: none;
                }

                .z-gst-head {
                    color: var(--text-black, #393938);
                    font-family: Inter;
                    font-size: 2.8vmin;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                .z-table_global_search {
                    border-radius: 20px;
                    width: 35%;
                    height: 32px !important;
                    background: rgb(243, 243, 249);

                    input {
                        background: rgb(243, 243, 249);
                        font-family: Inter;
                    }
                }

            }

            .z-left-mobile-container {
                display: none;
                gap: 2%;
                justify-content: flex-start;
                align-items: center;
                height: 100%;
                width: 50%;
                margin-left: 2%;


                .z-gst-head {
                    color: var(--text-black, #393938);
                    font-family: Inter;
                    font-size: 3.5vmin;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                .z-table_global_search {
                    border-radius: 20px;
                    width: 58%;
                    height: 40px !important;
                    background: rgb(243, 243, 249);

                    input {
                        background: rgb(243, 243, 249);
                        font-family: Inter;
                    }
                }
            }

            .z-right-container {
                width: 50%;
                justify-content: space-evenly !important;

                display: flex !important;
                align-items: center;

                #z-common-gst-solution-button {
                    font-size: 2vmin;
                    width: 45% !important;

                    justify-content: center;

                }

            }


        }

        .compliance-table-header {
            // background-color: rebeccapurple;
            width: 96%;

            span {
                font-size: 2.5vmin;

            }

            .company-column {
                width: 30%;
            }

            .gst-column {
                min-width: 30%;
            }

            .status-column {
                min-width: 50%;
            }
        }

        .compliance-data-container {
            width: 96vw;
            min-width: 96vw;
            overflow-x: auto;

            .compliance-table-content {
                height: 18vh;
                width: 100vw;
                min-height: 15vh;
                border-radius: 20px;
                margin-top: 1%;
                margin-left: 7%;
                display: flex;
                flex-direction: row;
                color: #232323;
                font-size: 2vmin;

                .company-table-column {
                    height: 100%;
                    width: 20%;
                    min-width: 20%;
                    border-top-left-radius: 20px;
                    border-bottom-left-radius: 20px;
                    display: flex;
                    align-items: center;
                    padding-left: 1%;
                    padding-right: 1%;

                    .comapany-line {}
                }

                .gst-table-column {
                    height: 100%;
                    min-width: 20%;
                    width: 20%;
                    display: flex;
                    align-items: center;
                    padding-left: 1%;
                    padding-right: 1%;
                }

                .status-table-column {
                    width: 60%;
                    min-width: 100%;
                    height: 100%;
                    border-top-right-radius: 20px;
                    border-bottom-right-radius: 20px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .status-first-row {
                        width: 100%;
                        height: 48%;
                        border-radius: 20px;
                        display: flex;
                        flex-direction: row;
                        align-items: end;

                        .row-header {
                            height: 95%;
                            width: 15%;
                            background-color: #E7F1FA;
                            border-top-left-radius: 18px;
                            border-bottom-left-radius: 18px;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            span {
                                font-weight: bold;
                            }
                        }

                        .row-card-wrap {
                            width: 85%;
                            height: 95%;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-evenly;

                            .row-card {
                                width: 19%;
                                background-color: #D7E0EC;
                                display: flex;
                                flex-direction: column;
                                justify-content: space-evenly;
                                align-items: center;

                                &:last-child {
                                    border-top-right-radius: 18px;
                                }

                                .row-card-mainline {
                                    color: $font-primay-color;
                                    font-size: 2.2vmin;
                                }

                                .row-card-subline {}
                            }


                            .row-card-active {
                                background-color: #F06548 !important;
                            }
                        }
                    }

                    .status-second-row {
                        width: 100%;
                        height: 48%;
                        border-radius: 20px;
                        display: flex;
                        flex-direction: row;

                        .row-header {
                            height: 95%;
                            width: 15%;
                            background-color: #E7F1FA;
                            border-top-left-radius: 18px;
                            border-bottom-left-radius: 18px;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            span {
                                font-weight: bold;
                            }
                        }

                        .row-card-wrap {
                            width: 85%;
                            height: 95%;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-evenly;

                            .row-card {
                                width: 19%;
                                background-color: #D7E0EC;
                                display: flex;
                                flex-direction: column;
                                justify-content: space-evenly;
                                align-items: center;

                                &:last-child {
                                    border-bottom-right-radius: 18px;
                                }

                                .row-card-mainline {
                                    color: $font-primay-color;
                                    font-size: 2.2vmin;
                                }

                                .row-card-subline {}
                            }

                            .row-card-active {
                                background-color: #F06548 !important;
                            }
                        }
                    }
                }

            }

            .nodata-line {
                color: #a59696;
                margin: auto;
                font-weight: lighter;
            }
        }

        .compliance-table-footer {
            max-width: 68vw !important;
            min-height: 10vh;
            display: flex;
            flex-direction: row;
            align-items: center !important;
            justify-content: center !important;
            border-bottom: none;
            margin: 2%;
            margin-top: 0%;

            ul {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                margin-left: auto;
            }

            .ant-pagination-total-text {
                position: relative !important;
            }

            .ant-pagination-options {
                position: relative !important;
                margin-left: auto !important;
            }

            :where(.css-dev-only-do-not-override-1ae8k9u).ant-pagination .ant-pagination-prev {
                margin-left: auto !important;
            }

            :where(.css-dev-only-do-not-override-1ae8k9u).ant-pagination .ant-pagination-next {}
        }
    }
}

@media only screen and (min-width:601px) and (max-width:768px) {
    .complaince-overall-container {


        .complaince-content-container {



            .z-left-container {
                width: 60%;

                .mob-search-icon {
                    display: none;
                }

                .z-gst-head {
                    font-size: 2.3vmin;
                }

                .z-table_global_search {
                    height: 30% !important;
                    justify-content: center;
                    align-items: center;
                    display: flex;

                    input {
                        font-size: 2vmin;
                    }
                }


            }



            .z-right-container {


                #z-common-gst-solution-button {
                    font-size: 1.8vmin;
                    width: 40%;
                    justify-content: center;
                    align-items: center;

                }





            }


        }

        .compliance-table-header {


            span {}


        }

        .compliance-data-container {


            .compliance-table-content {

                font-size: 1.5vmin;

                overflow-x: auto;

                .company-table-column {


                    .comapany-line {}
                }

                .gst-table-column {}

                .status-table-column {
                    width: 95%;
                    min-width: 95%;
                    border-radius: 0px;

                    .status-first-row {
                        justify-content: center;
                        align-items: center;

                        .row-header {

                            span {
                                font-size: 1.2vmin;
                            }
                        }

                        .row-card-wrap {


                            .row-card {


                                &:last-child {}

                                .row-card-mainline {
                                    font-size: 1.5vmin;
                                }

                                .row-card-subline {}
                            }


                            .row-card-active {}
                        }
                    }

                    .status-second-row {

                        justify-content: center;
                        align-items: center;

                        .row-header {


                            span {
                                font-size: 1.2vmin;
                            }
                        }

                        .row-card-wrap {


                            .row-card {


                                &:last-child {}

                                .row-card-mainline {
                                    font-size: 1.5vmin;
                                }

                                .row-card-subline {}
                            }

                            .row-card-active {
                                background-color: #F06548 !important;
                            }
                        }
                    }
                }

            }

            .nodata-line {
                color: #a59696;
                margin: auto;
                font-weight: lighter;
            }
        }

        .compliance-table-footer {


            ul {}

            .ant-pagination-total-text {
                font-size: 1.5vmin;
            }





        }
    }
}

@media only screen and (min-width:769px) and (max-width:1025px) {
    .complaince-overall-container {


        .complaince-content-container {
            min-height: 8vh;



            .z-left-container {
                width: 60%;
                gap: 5%;

                .mob-search-icon {
                    display: none;
                }

                .z-gst-head {
                    font-size: 2vmin;

                }

                .z-table_global_search {

                    width: 40% !important;
                    height: 40% !important;
                    justify-content: center;
                    align-items: center;

                    input {
                        background: rgb(243, 243, 249);
                        font-family: Inter;
                        font-size: 1.8vmin;
                    }
                }


            }


            .z-right-container {
                width: 40%;


                #z-common-gst-solution-button {
                    font-size: 1.5vmin !important;
                    width: 40%;
                    justify-content: center;


                }





            }


        }

        .compliance-table-header {


            span {
                font-size: 1.8vmin !important;
            }


        }

        .compliance-data-container {
            overflow: auto;

            .compliance-table-content {

                font-size: 1.7vmin;

                // min-width: 95%;
                // overflow-x: auto;
                .company-table-column {


                    .comapany-line {}
                }

                .gst-table-column {}

                .status-table-column {
                    width: 95%;
                    min-width: 95%;

                    .status-first-row {

                        justify-content: center;
                        align-items: center;

                        .row-header {

                            span {
                                font-size: 1.2vmin;
                            }
                        }

                        .row-card-wrap {


                            .row-card {




                                .row-card-mainline {
                                    font-size: 1.4vmin;
                                }

                                .row-card-subline {}
                            }


                            .row-card-active {
                                background-color: #F06548 !important;
                            }
                        }
                    }

                    .status-second-row {
                        align-items: center;
                        justify-content: center;

                        .row-header {


                            span {
                                font-size: 1.2vmin;
                            }
                        }

                        .row-card-wrap {


                            .row-card {


                                &:last-child {}

                                .row-card-mainline {
                                    font-size: 1.6vmin;
                                }

                                .row-card-subline {}
                            }

                            .row-card-active {
                                background-color: #F06548 !important;
                            }
                        }
                    }
                }

            }

            .nodata-line {
                color: #a59696;
                margin: auto;
                font-weight: lighter;
            }
        }

        .compliance-table-footer {


            ul {}

            .ant-pagination-total-text {}

            .ant-pagination-options {}

            :where(.css-dev-only-do-not-override-1ae8k9u).ant-pagination .ant-pagination-prev {}

            :where(.css-dev-only-do-not-override-1ae8k9u).ant-pagination .ant-pagination-next {}
        }
    }
}

@media only screen and (min-width:1026px) and (max-width:1440px) {
    .complaince-overall-container {
        .complaince-content-container {



            .z-left-container {
                gap: 10%;


                .mob-search-icon {
                    display: none;
                }

                .z-gst-head {
                    font-size: 3vmin;

                }

                .z-table_global_search {
                    width: 40% !important;
                    height: 30% !important;
                    justify-content: center;
                    align-items: center;

                    input {
                        font-size: 1.8vmin;
                    }
                }


            }



            .z-right-container {


                #z-common-gst-solution-button {
                    font-size: 1.8vmin;

                    padding: 2% 2%;

                }





            }


        }

        .compliance-table-header {


            span {
                font-size: 1.8vmin;
            }



        }

        .compliance-data-container {


            .compliance-table-content {

                font-size: 2vmin;

                .company-table-column {


                    .comapany-line {}
                }

                .gst-table-column {}

                .status-table-column {


                    .status-first-row {


                        .row-header {

                            span {
                                font-size: 1.5vmin;
                            }
                        }

                        .row-card-wrap {


                            .row-card {


                                &:last-child {}

                                .row-card-mainline {
                                    font-size: 1.8vmin;
                                }

                                .row-card-subline {}
                            }


                            .row-card-active {
                                background-color: #F06548 !important;
                            }
                        }
                    }

                    .status-second-row {
                        align-items: center;

                        .row-header {


                            span {
                                font-size: 1.5vmin;
                            }
                        }

                        .row-card-wrap {


                            .row-card {

                                &:last-child {}

                                .row-card-mainline {
                                    font-size: 1.8vmin;
                                }

                                .row-card-subline {}
                            }

                            .row-card-active {
                                background-color: #F06548 !important;
                            }
                        }
                    }
                }

            }

            .nodata-line {
                color: #a59696;
                margin: auto;
                font-weight: lighter;
            }
        }

        .compliance-table-footer {
            max-width: 100vw !important;
            min-height: 9vh;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            border-bottom: none;
            align-items: center;
            margin: 2%;
            margin-top: 0%;

            ul {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                margin-left: auto;
            }

            .ant-pagination-total-text {
                position: relative !important;
            }

            .ant-pagination-options {
                position: relative !important;
                margin-left: auto !important;
            }

            :where(.css-dev-only-do-not-override-1ae8k9u).ant-pagination .ant-pagination-prev {
                margin-left: auto !important;
            }

            :where(.css-dev-only-do-not-override-1ae8k9u).ant-pagination .ant-pagination-next {}
        }
    }
}

@media only screen and (min-width:1441px) {
    .complaince-overall-container {


        .complaince-content-container {



            .z-left-container {


                .mob-search-icon {
                    display: none;
                }

                .z-gst-head {
                    font-size: 2.5vmin;

                }

                .z-table_global_search {
                    width: 40% !important;
                    height: 30% !important;
                    justify-content: center;
                    align-items: center;

                    input {
                        font-size: 1.8vmin;
                    }
                }


            }



            .z-right-container {


                #z-common-gst-solution-button {
                    font-size: 1.8vmin;

                    padding: 2% 1%;

                }





            }


        }



        .compliance-data-container {


            .compliance-table-content {


                .company-table-column {}

                .gst-table-column {}

                .status-table-column {


                    .status-first-row {

                        align-items: center;

                        .row-header {


                            span {
                                font-size: 1.6vmin;
                            }
                        }

                        .row-card-wrap {


                            .row-card {

                                .row-card-mainline {
                                    font-size: 1.6vmin;
                                }

                                .row-card-subline {}
                            }


                            .row-card-active {
                                background-color: #F06548 !important;
                            }
                        }
                    }

                    .status-second-row {

                        align-items: center;

                        .row-header {


                            span {
                                font-size: 1.6vmin;
                            }
                        }

                        .row-card-wrap {


                            .row-card {


                                &:last-child {}

                                .row-card-mainline {
                                    font-size: 1.8vmin;
                                }

                                .row-card-subline {}
                            }

                            .row-card-active {
                                background-color: #F06548 !important;
                            }
                        }
                    }
                }

            }

            .nodata-line {
                color: #a59696;
                margin: auto;
                font-weight: lighter;
            }
        }


    }
}