@import '../../../App.scss';


.chat-body-html {

    ol {

        overflow-wrap: break-word !important;

        li {
            min-width: 110px !important;
            list-style-type: decimal !important;
        }

    }

    ul {

        overflow-wrap: break-word !important;

        li {
            min-width: 110px !important;
            list-style-type: disc !important;
        }

    }
}

.chat-support-container-cover {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2%;
    padding-top: 0%;

    .chat-support-header {
        height: 8vh;
        width: 100%;
        display: flex;
        align-items: center;

        .back-nav {
            margin-right: 1%;
            color: #fff;
            background-color: $primary-color;
            display: flex;
            align-items: center;
            justify-content: start;
            font-size: 2.1vmin;
            width: auto;

            .icon {
                font-size: 2.3vmin;
            }
        }
    }

    .chat-support-container {
        width: 100%;
        border-radius: 8px;
        border: 1px solid var(--low-contrast-text, #666);
        // height: 50vh;
        max-height: 55vh;

        height: 55vh;
        border-radius: 6px;
        position: relative;

        .support-header {
            width: 94%;
            display: flex;
            flex-direction: row;
            position: relative;
            left: 3%;
            height: 8vh;
            border-bottom: 0.8px solid rgba(0, 0, 0, 0.2784313725);

            .support-title {
                // width: 15%;
                color: #232323;
                font-family: Inter;
                font-size: 3.5vmin;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                display: flex;
                align-items: center;
            }
        }

        .support-body-wrap {
            width: 100%;
            display: flex;
            flex-direction: row;
            height: 47vh;

            .support-body {
                display: flex;
                flex-direction: column;
                // height: 350px;
                padding: 1% 0;
                height: 100%;

                .chat-group-header {
                    height: 5%;
                }

                .chat-body {
                    width: 100%;
                    height: 85%;
                    // min-height: 200px;
                    // max-height: 250px;
                    overflow-y: auto;

                    .chats {
                        display: flex;
                        color: black;
                        flex-direction: column;
                        // width: 100%;
                        // margin-top: 10%;
                        position: relative;
                        // height: 35px;

                        .chat-parent {
                            position: relative;
                            width: 100%;
                            display: flex;
                            flex-direction: column;

                            .chat-child {
                                // position: absolute;
                                // height: 30px;
                                display: flex;
                                // align-items: center;
                                flex-direction: column;
                                // border-radius: 10px 0 10px 10px;
                                padding: 0.7%;
                                max-width: 45%;
                                min-width: 5%;
                                // word-break: break-word;
                                width: max-content !important;
                            }

                            .attachment-span {
                                cursor: pointer;
                                max-width: 50%;
                                width: max-content;
                                padding: 0.2% 0.6%;
                                border-radius: 30px;
                                margin-bottom: 10px;
                                align-items: center;
                                background-color: #E7ECF6;
                                font-weight: 550;
                                font-family: 'Inter';
                                font-size: 1.5vmin;
                                display: flex;
                                // flex-direction: row;
                                // align-items: center;
                                // justify-content: center;
                                cursor: pointer;

                                img {
                                    margin-right: 5px;
                                    height: 80%;
                                }
                            }
                        }
                    }

                }

                .float-btn {
                    position: absolute;
                    right: 1%;
                    bottom: 5%;
                    background-color: #ECEDF1;


                    .float-icon {
                        height: 50%;
                        width: 50%;
                    }
                }

                .mobile-float-btn {
                    display: none;
                    position: absolute;
                    right: 1%;
                    bottom: 5%;
                    background-color: #ECEDF1;


                    .float-icon {
                        height: 50%;
                        width: 50%;
                    }
                }

                .chat-input {
                    width: 100%;
                    justify-content: center;
                    display: flex;
                    height: 100px;
                    // border: 1px solid var(--low-contrast-text, #666);
                    // position: absolute;
                    // bottom: 2%;

                    .input-box {
                        width: 95%;
                        margin-left: 2.5%;
                        // border: 1px solid var(--low-contrast-text, #666);
                        border-radius: 91px;
                        border: 0.5px solid rgba(0, 0, 0, 0.50);
                        background: #ECEDF1;
                        display: flex;
                        flex-direction: row;
                        //     position: absolute;
                        // bottom: 4%;
                        // padding: 2%;

                        .input-field {
                            outline: none;
                            height: 90%;
                            width: 100%;
                            background: #ECEDF1;
                            border: 0;
                            margin-left: 1.5%;
                            font-size: 2vmin;
                            padding-left: 1%;
                        }

                        .action-icons {
                            position: relative;
                            left: -2.4%;
                            display: flex;
                            align-items: center;
                        }
                    }


                }
            }
        }


    }

    .mail-window {
        display: flex;
        height: 45vh;
        margin-top: 2%;
        border: 1px solid black;
        border-radius: 8px;
        width: 100%;
        flex-direction: column;

        .texteditor {
            height: 85%;
            max-height: 85%;
            display: flex;
            flex-direction: column;
            width: 100%;
            overflow: auto;

            .quill {
                height: 99%;
                max-height: 99%;
                color: black;
            }

            .ql-toolbar {
                border-top: none;
                border-right: none;
                border-left: none;
            }

            .ql-container {
                min-height: 83%;
                max-height: 83% !important;
                border-bottom: none;
                border-right: none;
                border-left: none;
            }
        }

        .window-footer {
            height: 15%;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            background-color: #ECEDF1;
            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 8px;

            .newwin-icon {
                cursor: pointer;
                transform: rotate(270deg);
                margin-left: 1%;
                background-color: $font-primay-color;
                color: white;
            }

            .delete-icon {
                margin-right: 1%;
                cursor: pointer;
                color: $font-primay-color;
                font-size: 1.8vmin;
            }

            .attach-btn {
                background-color: $font-primay-color;
                cursor: pointer;
                margin-left: auto;
                margin-right: 1%;
                height: 80%;
                width: 2.9%;

                .pin-img {
                    color: white;
                    height: 55%;
                }
            }


            .send-btn {
                height: 80%;
                width: 2.9%;
                margin-right: 1%;
                cursor: pointer;
                background-color: $secondary-color;

                .send-img {
                    color: white;
                    width: 45%;
                }
            }

            
        }
    }
}