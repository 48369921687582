.container {
    height: 100vh;
    width: 100vw;
    background-color: #FFF;
    display: flex;
    flex-direction: row;

    .default-container {
        height: 100%;
        width: 70%;

        .logo-image {
            position: absolute;
            margin: 1% 4%;
        }

        .gst-img {
            width: 90%;
            height: 65%;
        }

        .load-container {
            height: 35%;
        }

        // }



        .footer-top {
            max-width: 40%;
            margin-left: 7.5%;
            margin-top: -7%;

            p {
                color: var(--text-black, #393938);
                font-family: Inter;
                font-size: 3.1vmin;
                font-style: normal;
                font-weight: 600;
                line-height: 138%;
                margin-bottom: 10px;
                /* 45.36px */
            }

            span {
                color: #424B70;
                font-family: Inter;
                font-size: 4.2vmin;
                font-style: normal;
                font-weight: 900;
                line-height: 138%;
            }
        }



        .footer-bottom {
            margin-left: 5%;

            .grid-container {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 5px;
                max-width: 80%;


                .grid-item {
                    padding: 10px;
                }

                .footer-box {
                    position: relative;
                    left: 4%;
                    display: flex;
                }

                .logo {
                    float: left;
                    height: 100%;
                    margin: auto;
                }


                .label-text {
                    // margin-left: 14%;
                    margin-left: 5%;

                    .title {
                        color: #000;
                        font-family: Inter;
                        font-size: 1.7vmin;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 160.523%;
                        letter-spacing: 0.035px;
                    }

                    .subTitle {
                        color: #E96919;
                        font-family: Inter;
                        font-size: 1.6vmin;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 160.523%;
                        letter-spacing: 0.035px;
                        margin-right: 1%;
                    }

                }
            }
        }
    }

    .login-container {
        height: auto;
        width: 30%;
        position: relative;

        .site-tab {
            width: 35%;
            position: relative;
            top: 6%;
            left: 48%;
        }

        .site-url {
            text-decoration: none;
        }

    }


}

#mob-container {
    display: none;
}

@media only screen and (min-width:320px) and (max-width:600px) {

    .container {
        display: none;
    }

    #mob-container {
        height: 100vh;
        background-color: #FFF;
        display: flex;
        flex-direction: row;

        .default-container {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;

            // justify-content: center;
            .site-tab {
                width: 25%;
                position: absolute;
                top: 2%;
                margin-left: 57%;
                padding: 2% 7%;
                font-size: 3vmin;
            }

            .gst-img {
                background-image: url('../../assets/terminalImages/banner.png');
                background-repeat: no-repeat;
                object-fit: contain;
                background-size: 113% 95%;
                width: 100%;
                min-height: 30% !important;

                .logo-image {
                    height: 25% !important;
                    margin: 2% 2% !important;
                }
            }


            .login-container {
                height: auto;
                max-width: 100%;
                position: relative;
                display: flex;
                justify-content: center;
            }

            .load-container {
                height: 35%;

                .footer-top {
                    max-width: 100%;
                    margin-left: 6.5%;

                    p {
                        color: var(--text-black, #393938);
                        font-family: Inter;
                        font-size: 3.8vmin;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 138%;
                        margin-bottom: 10px;
                        /* 45.36px */
                    }

                    span {
                        color: #424B70;
                        font-family: Inter;
                        font-size: 4.2vmin;
                        font-style: normal;
                        font-weight: 900;
                        line-height: 138%;
                    }
                }

                .footer-bottom {
                    margin-left: 0%;

                    .grid-container {
                        display: grid;
                        grid-template-columns: repeat(1, 1fr);
                        grid-gap: 2px;
                        max-width: 95%;

                        .grid-item {
                            padding: 7px;

                            &:last-child {
                                margin-bottom: 3%;
                            }
                        }

                        .footer-box {
                            position: relative;
                            left: 5%;
                        }

                        .logo {
                            float: left;
                            height: 100%;
                            margin-right: 2%;
                        }


                        .label-text {
                            margin-left: 18%;

                            .title {
                                color: #000;
                                font-family: Inter;
                                font-size: 3.5vmin;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 160.523%;
                                letter-spacing: 0.035px;
                            }

                            .subTitle {
                                color: #E96919;
                                font-family: Inter;
                                font-size: 3vmin;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 160.523%;
                                letter-spacing: 0.035px;
                            }

                        }
                    }
                }
            }

            // }








        }


    }

}


@media only screen and (min-width:500px) and (max-width:600px) {
    #mob-container {
        .default-container {
            .site-tab {
                margin-left: 66%;
                padding: 1% 5%;
                width: 20%;
            }
        }
    }
}

@media only screen and (min-width:601px) and (max-width:768px) {
    .container {
        .default-container {

            .logo-image {
                width: 22%;
            }

            .gst-img {
                height: 60% !important;
            }

            .footer-top {
                max-width: 75%;
                margin-left: 6.5%;

                p {
                    font-size: 2.8vmin;
                }
            }

            .footer-bottom {
                margin-left: 2%;

                .grid-container {
                    grid-gap: 5px;

                    .grid-item {
                        padding: 8px;
                    }

                    .label-text {
                        margin-left: 27%;
                    }

                    .logo {
                        margin-right: 5%;
                    }
                }
            }
        }

        .login-container {
            .site-tab {
                width: 70%;
                top: 5%;
                left: 12%;
            }
        }
    }

    #mob-container {
        display: none;
    }
}

@media only screen and (min-width:769px) and (max-width:1024px) {
    .container {
        .default-container {
            .logo-image {
                width: 22%;
            }

            .gst-img {
                height: 58%;
            }

            .footer-top {
                max-width: 70%;
            }
        }

        .login-container {
            .site-tab {
                left: 18%;
                width: 57%;
            }
        }
    }
}

@media only screen and (min-width:1025px) and (max-width:1300px) {
    .container {
        .login-container {
            .site-tab {
                width: 42%;
                top: 6%;
                left: 35%;
            }
        }

        .default-container {

            .logo-image {
                width: 22%;
            }

            .footer-top {
                max-width: 70%;
            }

            .footer-bottom {
                .grid-container {
                    grid-gap: 10px;
                }
            }

            .grid-item {
                padding: 11px;

                .footer-box {
                    .label-text {
                        .title {
                            font-size: 2vmin;

                        }

                        .subTitle {
                            font-size: 1.8vmin;

                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width:1301px) and (max-width:1440px) {
    .container {
        .login-container {
            .site-tab {
                width: 42%;
                top: 6%;
                left: 35%;
            }
        }

        .default-container {

            .logo-image {
                width: 18%;
            }

            .footer-top {
                max-width: 70%;
            }

            .footer-box {
                .label-text {
                    .title {
                        font-size: 2.1vmin;

                    }

                    .subTitle {
                        font-size: 1.8vmin;

                    }
                }
            }
        }
    }
}

@media only screen and (min-width:1441px) and (max-width:1700px) {
    .container {
        .login-container {
            .site-tab {
                width: 53%;
                top: 6%;
                left: 20%;
                font-size: 2.2vmin !important;
            }
        }

        .footer-box {
            .label-text {
                .title {
                    font-size: 2.1vmin;

                }

                .subTitle {
                    font-size: 1.7vmin;

                }
            }
        }

        .default-container {

            .logo-image {
                width: 20%;
            }

            .footer-top {
                max-width: 70%;
            }

            .footer-box {
                .label-text {
                    .title {
                        font-size: 2.1vmin !important;

                    }

                    .subTitle {
                        font-size: 1.8vmin !important;

                    }
                }
            }
        }
    }
}


@media only screen and (min-width:1701px) {
    .container {
        .login-container {
            .site-tab {
                width: 53%;
                top: 6%;
                left: 20%;
                font-size: 2.4vmin !important;
            }
        }

        .default-container {
            .logo-image {
                width: 20%;
            }

            .load-container {
                margin-top: 6%;
            }

            .footer-top {
                max-width: 70%;

                p {
                    font-size: 3.4vmin;
                }
            }

            .footer-box {

                .logo {
                    height: 73px !important;
                    margin-right: 1%;

                    img {
                        height: 100%;
                    }
                }

                .label-text {
                    .title {
                        font-size: 2.1vmin !important;

                    }

                    .subTitle {
                        font-size: 1.8vmin !important;

                    }
                }
            }
        }
    }
}